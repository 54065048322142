import React from "react";
import { useResumeBuilderContext } from "../../../provider/ResumeBuilderContext";
import Education from "./resumeFormData/Education";
import Experience from "./resumeFormData/Experience";
import Skills from "./resumeFormData/Skills";
import References from "./resumeFormData/References";
import Personal from "./resumeFormData/Personal";
import Templates from "../Templates";
import Otherfields from "./resumeFormData/Otherfields";
import classes from "../ResumeBuilder.module.css";


const ResumeForm = () => {
  const {
    currentStep,
 
  } = useResumeBuilderContext();

 
  return (
    <div className={classes.formresume} id="formresume">
      {parseInt(currentStep) === 2 && <Templates />}
      {parseInt(currentStep) === 3 && <Personal />}
      {parseInt(currentStep) === 4 && <Education />}
      {parseInt(currentStep) === 5 && <Experience />}
      {parseInt(currentStep) === 6 && <Skills />}
      {parseInt(currentStep) === 7 && <References />}
      {parseInt(currentStep) === 8 && <Otherfields />}
    </div>
  );
};

export default ResumeForm;
