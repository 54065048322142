import React, { useContext, useEffect, useState } from "react";
import classes from "../../../../candidate/companies/Companies.module.css";
import { Row, Col, Button, Pagination } from "react-bootstrap";
import jobclasses from "../../../../candidate/jobsearch/Jobsearch.module.css";
import classes1 from "../../../../home/Home.module.css";
import { FiSearch } from "react-icons/fi";
import { BsGrid3X3Gap } from "react-icons/bs";
import { CiLocationOn } from "react-icons/ci";
import SearchSelect from "../../../../../ui/common/SearchSelect";
import { GrPowerReset } from "react-icons/gr";
import RecruiterBody from "../../../layouts/RecruiterBody";
import CompaniesSkelton from "../../../../candidate/companies/CompaniesSkelton";
import noresume from "../../../../../assets/Noresume.svg";
import { MdStars } from "react-icons/md";
import { RecruiterCandidateListContext } from "../../../../../provider/RecruiterCandidateListContext";
import CandidatesCard from "./CandidatesCard";
import { Link } from "react-router-dom";

const TopCandidatesList = () => {
  const {
    handleChangeFilter,
    onResetFilter,
    isLoading,
    candidatesData,
    fetchCandidates,
    candidateCount,
    filtersCand,
    setFiltersCand,
    fetchCandfilters,
    currentPage,
    setCurrentPage,
    upgradeError,
    setUpgradeError,
  } = useContext(RecruiterCandidateListContext);

  useEffect(() => {
    fetchCandidates(1);
  }, [filtersCand]);

  const [suggestions, setSuggestions] = useState({
    job_role: [],
  });
  const [loading, setLoading] = useState(false);
  const handleInputChange = async (e) => {
    const { value, name } = e.target;

    setFiltersCand((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));

    if (value.length >= 1) {
      setLoading(true);
      const response = await fetchCandfilters(value, name);
      setSuggestions((prevSuggestions) => ({
        ...prevSuggestions,
        [name]: response,
      }));
      setLoading(false);
    } else {
      setSuggestions((prevSuggestions) => ({
        ...prevSuggestions,
        [name]: [],
      }));
    }
  };

  const handleOptionSelect = (option, name) => {
    setFiltersCand((prevFilters) => ({
      ...prevFilters,
      [name]: option,
    }));
    setSuggestions((prevSuggestions) => ({
      ...prevSuggestions,
      [name]: [],
    }));
  };

  const renderPaginationItems = () => {
    const totalPages = Math.ceil(candidateCount / 12);
    const maxPagesToShow = 5;
    const items = [];

    if (totalPages <= maxPagesToShow) {
      for (let number = 1; number <= totalPages; number++) {
        items.push(
          <Pagination.Item
            key={number}
            active={number === currentPage}
            onClick={() => handlePageChange(number)}
          >
            {number}
          </Pagination.Item>
        );
      }
    } else {
      let startPage = currentPage - Math.floor(maxPagesToShow / 2);
      let endPage = currentPage + Math.floor(maxPagesToShow / 2);

      if (startPage < 1) {
        startPage = 1;
        endPage = maxPagesToShow;
      }

      if (endPage > totalPages) {
        endPage = totalPages;
        startPage = totalPages - maxPagesToShow + 1;
      }

      if (startPage > 1) {
        items.push(
          <Pagination.Item
            key={1}
            active={currentPage === 1}
            onClick={() => handlePageChange(1)}
          >
            1
          </Pagination.Item>
        );
        items.push(<Pagination.Ellipsis key="start-ellipsis" />);
      }

      for (let number = startPage; number <= endPage; number++) {
        items.push(
          <Pagination.Item
            key={number}
            active={number === currentPage}
            onClick={() => handlePageChange(number)}
          >
            {number}
          </Pagination.Item>
        );
      }

      if (endPage < totalPages) {
        items.push(<Pagination.Ellipsis key="end-ellipsis" />);
        items.push(
          <Pagination.Item
            key={totalPages}
            active={currentPage === totalPages}
            onClick={() => handlePageChange(totalPages)}
          >
            {totalPages}
          </Pagination.Item>
        );
      }
    }

    return items;
  };

  const handlePageChange = (pageNumber) => {
    const targetElement = document.getElementById("candidates");
    if (targetElement) {
      const targetPosition =
        targetElement.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = targetPosition - 100;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
    setCurrentPage(pageNumber);
    fetchCandidates(pageNumber);
  };

  return (
    <RecruiterBody>
      <div>
        <div className={classes.filterbannerComp + " mt-2 mb-4"}>
          <div className={jobclasses.filterblock + " text-center"}>
            <h3 className="fw-bold mb-3">Browse Candidates</h3>
            <div
              className={
                classes1.smtext +
                " text-secondary d-flex align-items-center justify-content-center "
              }
            >
              <div style={{ maxWidth: "500px", fontWeight: "500" }}>
                Discover and get connected to more than 100K+ highly engaged
                potential candidates.
              </div>
            </div>

            <div className={jobclasses.filtercand}>
              <Row className="mt-2">
                <Col
                  sm={12}
                  lg={10}
                  className="mb-2  d-flex align-items-center"
                >
                  <BsGrid3X3Gap className="text-secondary me-2" />
                  <div className="flex-sm-grow-1">
                    <SearchSelect
                      name="job_role"
                      placeholder="Search Job Title"
                      value={filtersCand.job_role}
                      handleChange={handleInputChange}
                      options={suggestions.job_role}
                      onOptionSelect={(option) =>
                        handleOptionSelect(option, "job_role")
                      }
                      loading={loading}
                    />
                  </div>
                </Col>

                <Col
                  sm={12}
                  lg={2}
                  style={{ fontSize: "14px", cursor: "pointer" }}
                  className="mb-2  d-flex align-items-center justify-content-center"
                >
                  <Button
                    className="d-flex align-items-center pe-4 "
                    onClick={() => fetchCandidates(1)}
                  >
                    <FiSearch />
                    <span className="ps-2">Search</span>
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div className="my-5" id="candidates">
          <div className={jobclasses.boxfilters + " border-bottom pb-3 mb-4"}>
            <Row className="d-flex align-items-center" id="candidates">
              <Col xl={6} lg={5} sm={6} xs={5}>
                <span className={jobclasses.textsmall + " ms-1"}>
                  Showing{" "}
                  <strong>
                    {currentPage * 12 - 11}- {currentPage * 12}
                  </strong>{" "}
                  of
                  <strong> {candidateCount}</strong> candidates
                </span>
              </Col>
              <Col xl={6} lg={7} sm={6} xs={7} className="text-lg-end">
                <div className="d-flex align-items-center justify-content-end">
                  <div
                    className="ms-3 text-primary"
                    style={{
                      fontSize: "12px",
                      cursor: "pointer",
                      padding: "8px",
                    }}
                    onClick={onResetFilter}
                  >
                    Reset all filters
                    <GrPowerReset
                      className="ms-1"
                      style={{ fontSize: "12px" }}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          {candidateCount <= 0 && !isLoading ? (
            <Row className="text-center vh-100">
              <Col className="d-flex  align-items-center flex-column justify-content-center">
                <img
                  src={noresume}
                  alt="noresumes"
                  width={700}
                  className="img-fuid"
                />
                <div className="fw-semibold fs-6">
                  Uh.. Oh no companies available at the moment
                </div>
              </Col>
            </Row>
          ) : (
            <>
              {isLoading ? (
                <Row>
                  {[...Array(8)].map((_, index) => (
                    <Col xl={4} lg={4} md={6} sm={12} xs={12} key={index}>
                      <CompaniesSkelton />
                    </Col>
                  ))}
                </Row>
              ) : (
                <Row>
                  <>
                    {upgradeError ? (
                      <Row className="text-center my-4">
                        <Col className="d-flex  align-items-center flex-column justify-content-center">
                          <img
                            src={noresume}
                            alt="noresumes"
                            width={400}
                            className="img-fuid"
                          />
                          <div className="fw-semibold fs-6 ">
                            Uh.. Oh You've Reached your profile viewing limit !<div className="mt-2 mb-4">Please upgrade your membership for viewing more candidate profiles .</div>
                           <Link to="/upgradeplan"><Button>Upgrade Now <MdStars className="ms-1"/></Button></Link> 
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      <>
                        {candidatesData.map((cand) => (
                          <Col
                            xl={4}
                            lg={4}
                            md={6}
                            sm={12}
                            xs={12}
                            key={cand.id}
                          >
                            <CandidatesCard candidate={cand} />
                          </Col>
                        ))}
                      </>
                    )}
                  </>
                  <div className="mt-3">
                    <Pagination className="custom-pagination">
                      <Pagination.Prev
                        className="custom-page-prev-next"
                        onClick={() =>
                          handlePageChange(
                            currentPage > 1 ? currentPage - 1 : 1
                          )
                        }
                        disabled={currentPage === 1}
                      />
                      {renderPaginationItems()}
                      <Pagination.Next
                        className="custom-page-prev-next"
                        disabled={
                          currentPage === Math.ceil(candidateCount / 12)
                        }
                        onClick={() =>
                          handlePageChange(
                            currentPage < Math.ceil(candidateCount / 12)
                              ? currentPage + 1
                              : currentPage
                          )
                        }
                      />
                    </Pagination>
                  </div>
                </Row>
              )}
            </>
          )}
        </div>
      </div>
    </RecruiterBody>
  );
};

export default TopCandidatesList;
