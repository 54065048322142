import React, { createContext, useContext, useState } from "react";
import axios from "axios";
import { url } from "../data/Constants";
import { useAuthContext } from "./AuthContext";

const CompanyContext = createContext();

export const CompanyProvider = ({ children }) => {
  const { headers } = useAuthContext();
  const [companiesData, setCompaniesData] = useState([]);
  const [companyDetails, setCompanyDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [companyCount, setCompanyCount] = useState(0);
  const [jobFetched, setJobsfetched] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedTab, setSelectedTab] = useState("A");
  const [page, setPage] = useState(1);

  const handleTabChange = (key, id) => {
    if (key === "A") {
      setSelectedTab("A");
    } else if (key === "R") {
      setSelectedTab("R");
      fetchJobsAvailable(id);
    } else if (key === "P") {
      setSelectedTab("P");
    } else if (key === "S") {
      setSelectedTab("S");
    } else {
    }
  };

  const [filtersComp, setFiltersComp] = useState({
    company_name: "",
    job_location: "",
    // companyrating: ""
  });


  // const handleChangeFilter = (name) => (selectedOption) => {
  //   setFiltersComp((prevFilters) => ({
  //     ...prevFilters,
  //     [name]: selectedOption,
  //   }));
  // };

  const onResetFilter = () => {
    setFiltersComp({
      company_name: "",
      job_location: "",
      // companyrating: "" ,
    });
  };

  const fetchCompanies = async (page) => {
    const params = {
      job_location: filtersComp.job_location,
      company_name: filtersComp.company_name,
      page: page,
    };

    Object.keys(params).forEach((key) => {
      if (!params[key]) {
        delete params[key];
      }
    });
    const joburl = `${url}user/job/recruiter_search`;
    setIsLoading(true);
    try {
      const response = await axios.get(joburl, { headers, params });
      // console.log(response.data);
      if (response.data) {
        const data = response.data;
        setCompaniesData(data.values);
        setCompanyCount(data.company_count);
        let pages = page;
        // if (data.next_page !== "null") {
        //   pages = parseInt(data.next_page) - 1;
        // } else if (data.previous_page !== "null") {
        //   pages = parseInt(data.previous_page) + 1;
        // } else {
        //   pages = page;
        // }
        setCurrentPage(pages);
        setIsLoading(false);
      } else {
        const data = response.data;
        setCompaniesData([]);
        setCompanyCount(data.company_count);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.data && err.response.data.error) {
        setIsLoading(false);
      } else {
        alert("something went wrong");
        setIsLoading(false);
      }
      setIsLoading(false);
      return [];
    }
  };

  const fetchSingleCompany = async (id) => {
    setSelectedTab("A");
    const params = {
      recruiter_id: id,
    };

    const joburl = `${url}user/job/external_recruiter_page`;
    setIsLoading(true);
    try {
      const response = await axios.get(joburl, { headers, params });
      if (response.data) {
        const data = response.data;
        setCompanyDetails(data.recruiter_page_values);
        setIsLoading(false);
      } else {
        const data = response.data;
        setCompanyDetails([]);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.data && err.response.data.error) {
        setIsLoading(false);
      } else {
        alert("something went wrong");
        setIsLoading(false);
      }
      setIsLoading(false);
      return [];
    }
  };

  const fetchJobsAvailable = async (id) => {
    const params = {
      recruiter_id: id,
      page: page,
    };

    const joburl = `${url}user/job/external_recruiter_job_list`;
    setIsLoading(true);
    try {
      const response = await axios.get(joburl, { headers, params });
      if (response.data) {
        const data = response.data;
        setJobsfetched(data.jobs);
        setIsLoading(false);
      } else {
        const data = response.data;
        // setJobsfetched([]);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.data && err.response.data.error) {
        setIsLoading(false);
      } else {
        alert("something went wrong");
        setIsLoading(false);
      }
      setIsLoading(false);
      return [];
    }
  };

  const fetchCompfilters = async (value, name) => {
    const params = {
      job_location: "",
      company_name: "",
    };

    if (name in params) {
      params[name] = value;
    }

    Object.keys(params).forEach((key) => {
      if (!params[key]) {
        delete params[key];
      }
    });

    const filterurl = `${url}user/job/external_job_search`;
    try {
      const response = await axios.get(filterurl, { headers, params });
      return response.data.values;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const contextValue = {
    filtersComp,
    setFiltersComp,
    // handleChangeFilter,
    fetchCompanies,
    fetchSingleCompany,
    onResetFilter,
    companyCount,
    companiesData,
    isLoading,
    companyDetails,
    fetchJobsAvailable,
    selectedTab,
    handleTabChange,
    jobFetched,
    fetchCompfilters,
    currentPage,
    setCurrentPage
  };
  return (
    <CompanyContext.Provider value={contextValue}>
      {children}
    </CompanyContext.Provider>
  );
};

export const useCompanyContext = () => useContext(CompanyContext);
