import React, { useEffect, useState } from "react";
import CandidateHeader from "../layouts/CandidateHeader";
import { Container, Row, Col, Button } from "react-bootstrap";
import JobArchives from "./JobArchives";
import Footer from "../../home/layouts/Footer";
import ArchiveFilter from "./ArchiveFilter";
import { GrPowerReset } from "react-icons/gr";
import { useArchiveContext } from "../../../provider/ArchivesContext";
import JobCardskelton from "../jobsearch/JobCardskelton";
import noresume from "../../../assets/Noresume.svg";
import classes from "../jobsearch/Jobsearch.module.css";

const Archives = () => {
  const {
    fetchArchives,
    selectedScore,
    selectedSort,
    selectedJob,
    archivesData,
    archiveLoading,
    archiveCount,
    onResetFilterArchive,
  } = useArchiveContext();

  useEffect(() => {
    fetchArchives();
  }, [selectedScore, selectedSort, selectedJob]);

  const [selectedArchive, setSelectedArchive] = useState(null);

  const handleArchiveCardClick = (archive) => {
    setSelectedArchive(archive);
  };

  return (
    <div>
      <CandidateHeader />
      <Container>
        <Row className="mt-5 mb-2 " style={{ minHeight: "30rem" }}>
          <Col lg={3}>
            <div className="d-flex align-items-center justify-content-between border-bottom py-2 me-3">
              <h6
                className="fw-semibold text-dark"
                style={{ fontSize: "20px" }}
              >
                Advance Filter
              </h6>
              {/* <div onClick={onResetFilterArchive}
                className="text-primary fw-semibold"
                style={{ fontSize: "14px", cursor: "pointer" }}
              >
                Reset
                <GrPowerReset className="ms-1" style={{ fontSize: "12px" }} />
              </div> */}
            </div>
            <ArchiveFilter />
          </Col>
          <Col lg={9}>
            <div className="d-flex align-items-center  border-bottom py-2 ms-3">
              <h6
                className="fw-bold text-dark ps-1"
                style={{ fontSize: "20px" }}
              >
                Applied Jobs
              </h6>
            </div>
            <>
              {archiveLoading ? (
                <div className="my-4 ms-3 pe-3">
                  <JobCardskelton />
                  <JobCardskelton />
                </div>
              ) : (
                <>
                  {archiveCount > 0 ? (
                    <div
                      className="my-4 ms-3 pe-3"
                      style={{ maxHeight: "70vh", overflowY: "scroll" }}
                    >
                      {archivesData.map((archive) => (
                        <JobArchives
                          key={archive.id}
                          archive={archive}
                          onClick={() => handleArchiveCardClick(archive)}
                        />
                      ))}
                    </div>
                  ) : (
                    <div
                      className="text-center mt-3"
                      style={{ height: "70vh" }}
                    >
                      <div
                        className="d-flex  align-items-center justify-content-center"
                        style={{ height: "50vh" }}
                      >
                        <img src={noresume} alt="noresumes" width={400} />
                      </div>
                      <div className="fw-semibold fs-6">
                        Uhhh! You’ve not applied for any Job. <br />
                        To Browse Jobs
                        <a className="ms-1" href="/jobs">
                          Click here
                        </a>
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          </Col>
        </Row>
        <div
          id="block"
          className={classes.sectionbox}
          style={{ marginTop: "5rem" }}
        >
          <Row className="align-items-center">
            <Col lg={8}>
              <div className="mx-5">
                <h2 className="text-white" style={{ fontWeight: "800" }}>
                  Looking for Job ?
                </h2>
                <p
                  className="text-white"
                  style={{
                    fontSize: "14px",
                    textAlign: "justify",
                    maxWidth: "550px",
                  }}
                >
                  Advertise your jobs to millions of monthly users and search
                  15.8 million CVs in our databasAdvertise your jobs to millions
                  of monthly users and search 15.8 million CVs in our database.
                </p>
                <Button>Upload Resume</Button>
              </div>
            </Col>
            <Col lg={4}>
              {/* <Image width={300} src={hiring} alt="hiring" /> */}
            </Col>
          </Row>
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default Archives;
