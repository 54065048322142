import React, { useState } from "react";
import classes from "./Common.module.css";
import { Image } from "react-bootstrap";
import { FaCamera, FaPen } from "react-icons/fa";
import profile from "../../assets/profile.png";
import { useProfileContext } from "../../provider/ProfileContext";

const ImageUpload = () => {
  const {
    profileData,
    setProfileData,
    file,
    setFile,
    handleRemoveImage,
    setEditedPersonal,
    editedPersonal,
  } = useProfileContext();

  const handleImageUpload = (e) => {
    const uploadedFile = e.target.files[0];
    if (uploadedFile) {
      setFile(URL.createObjectURL(uploadedFile));
      setProfileData((prevData) => ({
        ...prevData,

        profImage: uploadedFile,
      }));
    }
  };

  return (
    <>
      {profileData.profImage &&
      profileData.profImage !== null &&
      file !== null ? (
        <div style={{ position: "relative" }} className="text-center">
          <Image
            className={classes.upldimage}
            alt="profilepicture"
            width={100}
            height={100}
            src={file}
            roundedCircle
          />
          <div className={classes.fileuploadimg}>
            <input
              id="fileUploadImg"
              type="file"
              name="profImage"
              accept="image/png, image/jpeg, image/jpg"
              onChange={handleImageUpload}
            />
            <div className={classes.uploadbtnimg}>
              <label htmlFor="fileUploadImg" style={{ cursor: "pointer" }}>
                <FaPen />
              </label>
            </div>
          </div>
          {/* <div
            style={{
              fontSize: "12px",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={handleRemoveImage}
            className="text-danger  py-2 pe-4 fw-semibold"
          >
            remove image
          </div> */}
        </div>
      ) : (
        <div>
          {profileData.profImage === null ? (
            <>
              <Image
                className={classes.upldimage}
                alt="profilepicture"
                src={profile}
                roundedCircle
              />
              <div className={classes.fileuploadimg}>
                <input
                  id="fileUpload"
                  type="file"
                  name="profImage"
                  accept="image/png, image/jpeg, image/jpg"
                  onChange={handleImageUpload}
                />
                <div className={classes.uploadbtnimg}>
                  <label htmlFor="fileUpload" style={{ cursor: "pointer" }}>
                    <FaCamera />
                  </label>
                </div>
              </div>{" "}
              <div
                style={{ fontSize: "12px" }}
                className="text-secondary ps-2 py-2 fw-semibold"
              >
                .jpeg, .jpg, .png only
              </div>
            </>
          ) : (
            <>
              <Image
                className={classes.upldimage}
                alt="profilepicture"
                src={profileData.profImage}
                roundedCircle
              />
              <div className={classes.fileuploadimg}>
                <input
                  id="fileUpload"
                  type="file"
                  name="profImage"
                  accept="image/png, image/jpeg, image/jpg"
                  onChange={handleImageUpload}
                />
                <div className={classes.uploadbtnimg}>
                  <label htmlFor="fileUpload" style={{ cursor: "pointer" }}>
                    <FaCamera />
                  </label>
                </div>
              </div>
              {/* <div
                style={{
                  fontSize: "12px",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={handleRemoveImage}
                className="text-danger  ps-3 pb-3 pe-4 fw-semibold"
              >
                remove image
              </div> */}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default ImageUpload;
