import React, { useState } from "react";
import "quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import classes from "./Input.module.css";
// import { useResumeBuilderContext } from "../../provider/ResumeContext";

const TextEditor = ({ name, placeholder, onChange, value }) => {
  const [prevContent, setPrevContent] = useState(value); // Track previous content
  const [isChanged, setIsChanged] = useState(false); // Track if content changed

  const handleProcedureContentChange = (content, _, source) => {
    if (source === "user") {
      setIsChanged(content !== prevContent);
    }
    if (isChanged) {
      onChange({ target: { name, value: content } });
    }
    setPrevContent(content);
  };

  return (
    <div style={{ minHeight: "220px" }}>
      <ReactQuill
        className={classes.texteditor + " mt-3"}
        name={name}
        theme="snow"
        modules={{
          toolbar: [["bold"], ["link"], [{ list: "bullet" }]],
        }}
        formats={["header", "height", "bold", "list", "bullet", "link"]}
        placeholder={placeholder}
        onChange={handleProcedureContentChange}
        value={value}
      />
    </div>
  );
};

export default TextEditor;
