import React from 'react'
import { Modal,Button } from 'react-bootstrap'

const AlertModal = (props) => {
  return (
    <Modal
    show={props.show}
    onHide={props.handleClose}
    backdrop="static"
    keyboard={false}
  >
    <Modal.Header closeButton>
      <Modal.Title>Modal title</Modal.Title>
    </Modal.Header>
    <Modal.Body>
     {props.children}
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={props.handleClose}>
        Close
      </Button>
      <Button variant="primary">Understood</Button>
    </Modal.Footer>
  </Modal>
  )
}

export default AlertModal
