import React from "react";
import { useNavigate } from "react-router-dom";
import classes from "./TemplateSelection.module.css";
import {useResumeBuilderContext} from "../../../provider/ResumeBuilderContext"
import templateChangeHandling from "../../../utils/TemplateChangeHandling";
import { useAuthContext } from "../../../provider/AuthContext";

const ImageCard = ({ src, alt }) => {
  const navigate = useNavigate();
  const { setResume, selectSection,setSelectedTemplate } = useResumeBuilderContext();
  const { setShowLogin, setShowModal,userData } = useAuthContext();

  const handleTemplateSelection = (newTemplate) => {
    const template = templateChangeHandling(newTemplate);
    if (!userData ) {
      // setShowLogin(true);
      // setShowModal("SignIn");
      navigate("/templates");
      setResume(template);
      selectSection(3);
      localStorage.setItem("currentStep", 3);
    } else {
      navigate("/build/resume");
      selectSection(3);
      setResume(template);
      localStorage.setItem("currentStep", 3);
    }
  };
  return (
      <div
        className={classes.resumeimagecard}
        onClick={() => {
          localStorage.setItem("selectedTemplate", alt);
          setSelectedTemplate(alt);
          handleTemplateSelection(alt);
        }}
      >
        <img src={src} alt={alt} />

        <div className={classes.preview}>
          <button className={classes.previewbutton}>Build Resume</button>
        </div>
      </div>
  );
};

export default ImageCard;
