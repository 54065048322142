import React from "react";
import classes from "./ResumeFormData.module.css";
import { Row, Col } from "react-bootstrap";
import { useResumeBuilderContext } from "../../../../provider/ResumeBuilderContext";
import TextEditor from "../../resumeUI/TextEditor";
import TextEditorView from "../../resumeUI/TextEditorView";

const ProfessionalSummary = () => {
  const { resumeData, handleResumeFormChange } = useResumeBuilderContext();
  return (
    <div  className={classes.resumecontentform}>
      <div className={classes.resumeformheading}>Professional Summary</div>
      <div className={classes.resumeformsubheading}>
        Now, Let's add summary.
      </div>
      <div >
        <Row>
          <Col lg={12} xs={12} md={12}>
            <TextEditor
              name="summary"
              placeholder="describe about you"
              value={resumeData.summary}
              onChange={handleResumeFormChange}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ProfessionalSummary;
