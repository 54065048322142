import React, { useState, useEffect } from "react";
import { Container, Navbar } from "react-bootstrap";

const NavbarCommon = () => {
  // const role = localStorage.getItem("role");



  return (
    <Navbar
      expand="lg"
      // className={`py-3 ${isNavFixed ? "fixed-top bg-white" : ""}`}
    >
      <Container>
        <Navbar.Brand
          href="/"
          className=" fs-4 text-dark d-flex align-items-center "
          style={{ fontWeight: "800" }}
        >
          <div
            className="bg-primary p-1 text-white"
            style={{ borderRadius: "50px", height: "45px", width: "45px" }}
          >
            em
          </div>
          Career
        </Navbar.Brand>
        {/* <Navbar.Toggle aria-controls="navbarScroll" /> */}
      </Container>
    </Navbar>
  );
};

export default NavbarCommon;
