import React from "react";
import classes from "./Input.module.css";

const Input = (props) => {
  return (
    <>
      <div className={classes.form}>
        <label className={classes.inputlabel}>
          {props.text}
          {props.required ? (
            <span className="text-danger ps-1 fw-bold">*</span>
          ) : (
            <span className="text-danger ps-1 fw-bold"></span>
          )}
        </label>
        <input
          required={props.required}
          type={props.type}
          name={props.name}
          value={props.value}
          onChange={props.onChange}
          pattern={props.pattern}
          maxLength={props.maxLength}
          placeholder={props.placeholder}
          disabled={props.disabled}
          className={`${props.error ? classes.inputError : classes.input}`}
        />
        {props.error ? "" : <span className={classes.inputborder}></span>}
      </div>
      {props.error && <div className={classes.errorMessage}>{props.error}</div>}
    </>
  );
};

export default Input;
