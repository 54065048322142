import React from "react";
import { Col, Row, Pagination } from "react-bootstrap";
import JobCard from "./JobCard";
import JobCardskelton from "./JobCardskelton";
import noresume from "../../../assets/Noresume.svg";
import { useJobContext } from "../../../provider/JobContext";

const ExternalJobs = () => {
  const {
    isLoading,
    exJobData,
    exJobsCount,
    currentPage,
    setCurrentPage,
    fetchJobsExt,
  } = useJobContext();

  const renderPaginationItems = () => {
    const totalPages = Math.ceil(exJobsCount / 12);
    const maxPagesToShow = 4;
    const items = [];

    if (totalPages <= maxPagesToShow) {
      for (let number = 1; number <= totalPages; number++) {
        items.push(
          <Pagination.Item
            key={number}
            active={number === currentPage}
            onClick={() => handlePageChange(number)}
          >
            {number}
          </Pagination.Item>
        );
      }
    } else {
      let startPage = currentPage - Math.floor(maxPagesToShow / 2);
      let endPage = currentPage + Math.floor(maxPagesToShow / 2);

      if (startPage < 1) {
        startPage = 1;
        endPage = maxPagesToShow;
      }

      if (endPage > totalPages) {
        endPage = totalPages;
        startPage = totalPages - maxPagesToShow + 1;
      }

      if (startPage > 1) {
        items.push(
          <Pagination.Item
            key={1}
            active={currentPage === 1}
            onClick={() => handlePageChange(1)}
          >
            1
          </Pagination.Item>
        );
        items.push(<Pagination.Ellipsis key="start-ellipsis" />);
      }

      for (let number = startPage; number <= endPage; number++) {
        items.push(
          <Pagination.Item
            key={number}
            active={number === currentPage}
            onClick={() => handlePageChange(number)}
          >
            {number}
          </Pagination.Item>
        );
      }

      if (endPage < totalPages) {
        items.push(<Pagination.Ellipsis key="end-ellipsis" />);
        items.push(
          <Pagination.Item
            key={totalPages}
            active={currentPage === totalPages}
            onClick={() => handlePageChange(totalPages)}
          >
            {totalPages}
          </Pagination.Item>
        );
      }
    }

    return items;
  };

  const handlePageChange = (pageNumber) => {
    const targetElement = document.getElementById("jobsearch");
    if (targetElement) {
      const targetPosition =
        targetElement.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = targetPosition - 100;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
    setCurrentPage(pageNumber);
    fetchJobsExt(pageNumber);
  };
  return (
    <div className="mt-4">
      {exJobsCount <= 0 && !isLoading ? (
        <Row className="text-center vh-100">
          <Col className="d-flex  align-items-center flex-column justify-content-center">
            <img
              src={noresume}
              alt="noresumes"
              width={500}
              className="img-fuid"
            />
            <div className="fw-semibold fs-6">
              Uh.. Oh no jobs available at the moment
            </div>
          </Col>
        </Row>
      ) : (
        <div>
          {isLoading ? (
            <Row>
              {[...Array(6)].map((_, index) => (
                <Col xl={4} lg={4} md={6} sm={12} xs={12} key={index}>
                  <JobCardskelton />
                </Col>
              ))}
            </Row>
          ) : (
            <Row>
              {exJobData.map((job) => (
                <Col xl={4} lg={4} md={6} sm={12} xs={12} key={job.id}>
                  <JobCard key={job.id} job={job} />
                </Col>
              ))}
              <div className="mt-3">
                <Pagination className="custom-pagination">
                  <Pagination.Prev
                    className="custom-page-prev-next"
                    onClick={() =>
                      handlePageChange(currentPage > 1 ? currentPage - 1 : 1)
                    }
                    disabled={currentPage === 1}
                  />
                  {renderPaginationItems()}
                  <Pagination.Next
                    className="custom-page-prev-next"
                    disabled={currentPage === Math.ceil(exJobsCount / 12)}
                    onClick={() =>
                      handlePageChange(
                        currentPage < Math.ceil(exJobsCount / 12)
                          ? currentPage + 1
                          : currentPage
                      )
                    }
                  />
                </Pagination>
              </div>
            </Row>
          )}
        </div>
      )}
    </div>
  );
};

export default ExternalJobs;
