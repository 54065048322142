import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { url } from "../data/Constants";
import { useAuthContext } from "./AuthContext";
import { useNavigate } from "react-router-dom";


const ResumeListContext = createContext();

export const ResumeListProvider = ({ children }) => {
  const { headers } = useAuthContext();
  const user = localStorage.getItem("userData");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [file, setFile] = useState(null);
  const [resumeFiles, setResumeFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [resumeCount, setResumeCount] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [activeResume, setActiveResume] = useState([]);
  const [activating, setActivating] = useState(false);
  // console.log("activeResume",activeResume)

  const fetchResumes = async () => {
    const listurl = `${url}user/list/resume/`;
    setIsLoading(true);
    try {
      const response = await axios.get(listurl, { headers });
      const data = response.data;
      if (data && data.count && data.count > 0) {
        setResumeFiles(data.results);
        setResumeCount(data.count);
        const activeResumes = data.results.filter(
          (resume) => resume.active_res === true
        );
        setActiveResume(activeResumes);
        setIsLoading(false);
      } else {
        setResumeFiles([]);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.data && err.response.data.error) {
        console.log(err.response.data.error);
        setIsLoading(false);
      } else {
        alert("something went wrong");
        setIsLoading(false);
      }
      setIsLoading(false);
      return [];
    }
  };
  
  const handleResumeUpload = async (e) => {
    setIsUploading(true);
    const files = e.target.files;
    setFile(files);
    const formData = new FormData();
    
    for (let i = 0; i < files.length; i++) {
      formData.append("cv", files[i]);
    }
  
    const uploadurl = `${url}user/upload/?builder=False`;
  
    try {
      const response = await axios.post(uploadurl, formData, {
        headers,
      });
  
      const updatedFiles = [...uploadedFiles, ...files];
      console.log("errrrr_____", response.data);
      localStorage.setItem("resumeId", response.data.id);
      setUploadedFiles(updatedFiles);
      setIsUploading(false);
  
      if (response.data.resume_count === 1) {
        navigate("/jobs");
      }
      
      return response;
    } catch (error) {
      setIsUploading(false);
      console.log(error);
      throw error;
    }
  };
  

  const handleActiveResume = async (resume) => {
    setActivating(true);
    try {
      await axios.patch(
        `${url}user/list/resume/${resume.id}`,
        { active_res: true },
        { headers }
      );
      setActiveResume(resume);
      setActivating(false);
      alert("Active resume set successfully");
      localStorage.setItem("resumeId", resume.id);
      fetchResumes();
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        console.log(err.response.data.error);
      } else {
        console.log(err);
        alert("An error occurred in setting the active resume.");
      }
      setActivating(false);
    }
  };
  const handleDeleteResume = async (resume) => {
    setIsDeleting(true);
    // console.log("Deleting resume id:", resume.id);

    if (activeResume.length > 0) {
      for (const activeRes of activeResume) {
        if (resume.id === activeRes.id) {
          alert(
            "Cannot delete the active resume. Select another resume as active to delete it or upload another resume to make it active."
          );
          setIsDeleting(false);
          return;
        }
      }
    }

    try {
      await axios.delete(`${url}user/list/resume/${resume.id}`, { headers });
      alert("Deleted");
      fetchResumes();
      setIsDeleting(false);
    } catch (error) {
      console.log(error);
      setIsDeleting(false);
    }
  };

  const contextValue = {
    fetchResumes,
    handleResumeUpload,
    isLoading,
    resumeCount,
    setResumeCount,
    file,
    isUploading,
    handleDeleteResume,
    resumeFiles,
    setResumeFiles,
    activeResume,
    setActiveResume,
    handleActiveResume,
    isDeleting,
    activating,
  };
  return (
    <ResumeListContext.Provider value={contextValue}>
      {children}
    </ResumeListContext.Provider>
  );
};

export const useResumeListContext = () => useContext(ResumeListContext);
