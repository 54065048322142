import React from 'react'
import RecruiterHeader from "./RecruiterHeader"
import { Container } from 'react-bootstrap'
import Boxlayout from '../../home/layouts/Boxlayout'
import Footer from '../../home/layouts/Footer'

const RecruiterBody = (props) => {
  return (
    <div>
      <RecruiterHeader/>
      <Container>
        {props.children}
        <Boxlayout/>
      </Container>
      <Footer/>
    </div>
  )
}

export default RecruiterBody
