import React, { useEffect, useState } from "react";
import CandidateBody from "../../home/layouts/common/CandidateBody";
import JobFilter from "./JobFilter";
import { useJobContext } from "../../../provider/JobContext";
import { useResumeListContext } from "../../../provider/ResumeListContext";
import { Row, Col, Nav, Tab, Pagination } from "react-bootstrap";
import { GrPowerReset } from "react-icons/gr";
import classes from "./Jobsearch.module.css";
import CustomSelect from "./CustomSelect";
import { sorting } from "../../../data/JobData";
import AdvanceFilter from "./AdvanceFilter";
import EmcareerJobs from "./EmcareerJobs";
import ExternalJobs from "./ExternalJobs";

const JobSearch = () => {
  const { fetchResumes } = useResumeListContext();
  const user = localStorage.getItem("userData");

  const {
    filters,
    handleChangeFilter,
    fetchJobs,
    onResetFilter,
    sort,
    isLoading,
    jobsCount,
    selectedJob,
    handleJobTypeChange,
    fetchJobsExt,
    exJobsCount,searchFilters
  } = useJobContext();
  const [totalPage, setTotalPage] = useState(0);

  console.log(selectedJob);

  useEffect(() => {
    const fetchAndSetTotalPages = async () => {
      if (selectedJob === "E") {
        await fetchJobs(1);
        setTotalPage(jobsCount);
      } else {
        await fetchJobsExt(1);
        setTotalPage(exJobsCount);
      }
     
    };
    fetchAndSetTotalPages();
  }, [selectedJob,searchFilters,filters]);

  useEffect(()=>{
    if (user !== "null") {
      fetchResumes();
    } else {
    }
  },[])

  const handleSelect = async (eventKey) => {
    if (eventKey === "E") {
      await fetchJobs(1);
      setTotalPage(jobsCount);
    } else {
      await fetchJobsExt(1);
      setTotalPage(exJobsCount);
    }
    onResetFilter();
    handleJobTypeChange(eventKey);
    localStorage.setItem("job", eventKey);
  };


  // const handlePageChange = (pageNumber) => {
  //   setCurrentPage(pageNumber);
  //   if (selectedJob === "E") {
  //     fetchJobs(pageNumber);
  //   } else {
  //     fetchJobsExt(pageNumber);
  //   }
  // };

  // const renderPaginationItems = () => {
  //   let totalPages = 0;
  //   if (totalPage > 0) {
  //     totalPages = Math.ceil(totalPage / 6);
  //   }

  //   let items = [];
  //   for (let number = 1; number <= totalPages; number++) {
  //     items.push(
  //       <Pagination.Item
  //         key={number}
  //         active={number === currentPage}
  //         onClick={() => handlePageChange(number)}
  //       >
  //         {number}
  //       </Pagination.Item>
  //     );
  //   }
  //   return items;
  // };



  return (
    <CandidateBody>
      <JobFilter jobs={jobsCount} isLoading={isLoading} />
      <div className="mb-5 " id="jobsearch">
        <Row className="flex-row-reverse">
          <Col lg={9} md={12} sm={12} xs={12}>
            <Row className=" mt-1 ">
              <Col>
                <Tab.Container id="left-tabs-example" defaultActiveKey="E">
                  <Nav
                    className="d-flex  border-bottom "
                    activeKey={selectedJob}
                    onSelect={handleSelect}
                  >
                    <Nav.Item>
                      <Nav.Link
                        eventKey="E"
                        className={`${classes.tabsem} ${
                          selectedJob === "E" ? classes.active : ""
                        }`}
                      >
                        EmCareer Jobs
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="X"
                        className={`${classes.tabsex} ${
                          selectedJob === "X" ? classes.active : ""
                        }`}
                      >
                        External Jobs
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item className=" ms-lg-auto pb-1 d-flex align-items-center">
                      <div className="d-flex align-items-center border rounded px-1 ">
                        <span
                          className="fw-semibold"
                          style={{ fontSize: "12px", color: "#A0ABC3" }}
                        >
                          Sort by :
                        </span>
                        <CustomSelect
                          options={sorting}
                          handleChange={handleChangeFilter("sort")}
                          selectedvalue={filters.sort}
                          fontSize="12px"
                        />
                      </div>
                      <div
                        className="text-primary fw-semibold ms-3"
                        style={{ fontSize: "14px", cursor: "pointer" }}
                        onClick={onResetFilter}
                      >
                        reset <GrPowerReset style={{ fontSize: "12px" }} />
                      </div>
                    </Nav.Item>
                  </Nav>
                  {selectedJob === "E" ? <EmcareerJobs /> : <ExternalJobs />}
                </Tab.Container>
              </Col>
            </Row>
           
          </Col>

          <Col lg={3} md={12} sm={12} xs={12}>
            <div style={{marginTop:"1.2rem"}}>
              <div className={classes.filterjobs}>
                <Row className="d-flex align-items-center">
                  <Col lg={8} md={8} sm={8} xs={8}>
                    <h5 className="fw-bold">Advance Filter</h5>
                  </Col>
                  <Col lg={4} md={4} sm={4} xs={4}>
                    {selectedJob === "E" ? (
                      <div
                        className="text-primary fw-semibold ms-3"
                        style={{ fontSize: "14px", cursor: "pointer" }}
                        onClick={() => {
                          fetchJobs(1);
                        }}
                      >
                        Apply
                      </div>
                    ) : (
                      <div
                        className="text-primary fw-semibold ms-3"
                        style={{ fontSize: "14px", cursor: "pointer" }}
                        onClick={() => {
                          fetchJobsExt(1);
                        }}
                      >
                        Apply
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
              <AdvanceFilter />
            </div>
          </Col>
        </Row>
      </div>
    </CandidateBody>
  );
};

export default JobSearch;
