import React, { useState } from "react";
import classes from "./Input.module.css";
import { Col, Row, Button } from "react-bootstrap";

const TagsInput = (props) => {
  const [tags, setTags] = useState(props.tags);
  const [errorMessage, setErrorMessage] = useState("");

  const removeTags = (indexToRemove) => {
    setTags([...tags.filter((_, index) => index !== indexToRemove)]);
  };

  const addTags = (event) => {
    if (event.key === "Enter") {
      setErrorMessage("")
      const inputValue = event.target.value.trim();
      if (inputValue !== "") {
        if (!tags.includes(inputValue)) {
          setTags([...tags, inputValue]);
          props.selectedTags([...tags, inputValue]);
          event.target.value = "";
          setErrorMessage("");
        } else {
          setErrorMessage("Tag already exists");
        }
      }
    }
  };

  const addTagsOnClick = () => {
    const input = document.getElementById("input-tag");
    const inputValue = input.value.trim();
    if (inputValue !== "") {
      if (!tags.includes(inputValue)) {
        setTags([...tags, inputValue]);
        props.selectedTags([...tags, inputValue]);
        input.value = "";
        setErrorMessage("");
      } else {
        setErrorMessage("Tag already exists");
      }
    }
  };

  const handleInputChange = () => {
    if (errorMessage !== "") {
      setErrorMessage("");
    }
  };
  
  return (
    <div>
      <div className={classes.tagsinput}>
        <Row className="d-flex align-items-center">
          <Col xs={10} md={11} lg={12}>
            <input
              id="input-tag"
              className={`${errorMessage ? classes.inputError : classes.input}`}
              name={props.name}
              type="text"
              onKeyUp={(event) =>
                event.key === "Enter" ? addTags(event) : null
              }
              onChange={handleInputChange}
              placeholder={props.placeholder}
            />

            {errorMessage ? "" : <span className={classes.inputborder}></span>}

            {errorMessage && (
              <div className={classes.errorMessage}>{errorMessage}</div>
            )}
          </Col>

          <Col xs={2} md={1} className="d-block d-md-none">
            <Button
              className="bg-primary text-white p-2 px-3 fw-bolder"
              onClick={addTagsOnClick}
            >
              &#43;
            </Button>
          </Col>
        </Row>
      </div>
      <div className={classes.tags}>
        {tags !== "" &&
          tags.map((tag, index) => (
            <li key={index} className={classes.tag + " mt-2"}>
              <span className={classes.tagtitle}>{tag}</span>
              <span
                className={classes.tagcloseicon}
                onClick={() => {
                  removeTags(index);
                  props.handleTagRemoval(index);
                }}
              >
                ✕
              </span>
            </li>
          ))}
      </div>
    </div>
  );
};

export default TagsInput;
