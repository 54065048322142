import React from "react";
import { Badge } from "react-bootstrap";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { PDFViewer, StyleSheet } from "@react-pdf/renderer";
import { BiSolidError } from "react-icons/bi";

import Resume1 from "../resumes/Resume1";
import Resume5 from "../resumes/Resume5";
import Resume4 from "../resumes/Resume4";
import Resume3 from "../resumes/Resume3";
import Resume2 from "../resumes/Resume2";
import Resume6 from "../resumes/Resume6";
import Resume7 from "../resumes/Resume7";
import Resume8 from "../resumes/Resume8";
import Resume9 from "../resumes/Resume9";
import Resume10 from "../resumes/Resume10";
import Resume11 from "../resumes/Resume11";
import classes1 from "../resumeUI/ScoreCalculator.module.css";
import { useResumeBuilderContext } from "../../../provider/ResumeBuilderContext";

const ResumeScoreLoading = ({
  showScoreType,
  scoreLoading,
  score,
  scoreError,
}) => {
  const { selectedTemplate, resumeData } = useResumeBuilderContext();
  const templates = [
    { name: "Resume1", component: <Resume1 data={resumeData} /> },
    { name: "Resume2", component: <Resume2 data={resumeData} /> },
    { name: "Resume3", component: <Resume3 data={resumeData} /> },
    { name: "Resume4", component: <Resume4 data={resumeData} /> },
    { name: "Resume5", component: <Resume5 data={resumeData} /> },
    { name: "Resume6", component: <Resume6 data={resumeData} /> },
    { name: "Resume7", component: <Resume7 data={resumeData} /> },
    { name: "Resume8", component: <Resume8 data={resumeData} /> },
    { name: "Resume9", component: <Resume9 data={resumeData} /> },
    { name: "Resume10", component: <Resume10 data={resumeData} /> },
    { name: "Resume11", component: <Resume11 data={resumeData} /> },
  ];

  const styles = StyleSheet.create({
    viewer: {
      margin:"1rem 0rem",
      width: "100%",
      height: "60vh",
      PointerEvent: "none",
      backgroundColor: "white",
      border: "none",
      overflow: "hidden",
      overflowY:"none"
    },
  });

  let pathColor = "#198754";
  if (score < 20) {
    pathColor = "#DF4554";
  } else if (score >= 20 && score <= 65) {
    pathColor = "#0D6EFD";
  }
  return (
    <div>
      {showScoreType === "score" && !scoreLoading ? (
        <div className= " d-flex flex-column align-items-center text-center py-3" >
          <div className={classes1.circular}>
            <CircularProgressbar
              className=" fw-semibold"
              value={score}
              text={`${score}%`}
              strokeWidth={8}
              styles={buildStyles({
                strokeLinecap: "butt",
                textColor: "black",
                textSize: "13px",
                pathColor: pathColor,
              })}
            />
          </div>
          <div className="fw-semibold mt-2" style={{ fontSize: "14px" }}>
            Compatibility Score
            {score < 20 ? (
              <Badge className="mb-2 ms-1 bg-danger ">{score} %</Badge>
            ) : score >= 20 && score <= 65 ? (
              <Badge className="mb-2 ms-1 ">{score} %</Badge>
            ) : score > 65 ? (
              <Badge className="mb-2 ms-1 bg-success">{score} %</Badge>
            ) : null}
          </div>
        </div>
      ) : showScoreType === "" && scoreLoading ? (
        <div className={classes1.textcenter + " mb-4"}>
          <div className={classes1.doctext}>
            Analyzing your Resume against the job details
          </div>
          <div className={classes1.docScanner}>
            {" "}
            <PDFViewer showToolbar={false} style={styles.viewer}>
              {templates
                .filter((template) => template.name === selectedTemplate)
                .map((selectedTemplate) => selectedTemplate.component)}
            </PDFViewer>
          </div>

        </div>
      ) : showScoreType === "error" && !scoreLoading ? (
        <div className={classes1.textcenter + " mb-4"}>
          <div className={classes1.doctexterr}> {scoreError} </div>
          <div className={classes1.docScanner}>
            {" "}
            <PDFViewer showToolbar={false} style={styles.viewer}>
              {templates
                .filter((template) => template.name === selectedTemplate)
                .map((selectedTemplate) => selectedTemplate.component)}
            </PDFViewer>
          </div>

        </div>
      ) : (
        <PDFViewer showToolbar={false} style={styles.viewer}>
          {templates
            .filter((template) => template.name === selectedTemplate)
            .map((selectedTemplate) => selectedTemplate.component)}
        </PDFViewer>
      )}
    </div>
  );
};

export default ResumeScoreLoading;
