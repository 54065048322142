import React, { useEffect, useState } from "react";
import {  useLocation } from "react-router-dom";
import { url } from "../../data/Constants";
import AuthenticationBg from "./AuthenticationBg";
import { Button } from "react-bootstrap";
import Loading from "../../ui/common/Loading"
const EmailVerified = () => {
  const location = useLocation();
  const [name, setname] = useState(null);
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
  setTimeout(() => {
    setLoading((loading) => !loading);
  }, 1000)},[]);

  useEffect(() => {
    setLoad(true);
    const token = new URLSearchParams(location.search).get("token");
    const emailurl = `${url}auth/email-verify?token=${token}`;
    if (token) {
      setLoad(true);
      fetch(emailurl)
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              `Network response was not ok: ${response.statusText}`
            );
          }
          return response.json();
        })
        .then((data) => {
          // console.log(data);
          if (data) {
            setname(data);
            setLoad(false);
          }
        })
        .catch((error) => {
          alert("Error during fetch");
          setLoad(false);
        });
    }
  }, [location.search]);

  if (loading) {
    return (<Loading/>);
  } else {
  return (
    <>
      {load === true ? (
        <Loading text="Please wait"/>
      ):(   
      <AuthenticationBg
        subheading="Account Activated"
        heading=" Email verified succesfully"
        const
        subheading2={
          <span>
            Hello
            {name !== null ? (
              <>
                <span className="fw-semibold ms-1 text-dark">{name.first_name}</span>
                <span className="fw-semibold ms-1 text-dark">{name.last_name}</span>
              </>
            ) : (
              ""
            )}
            , <br />
            Please click on the button below to sign in to your account and
            ready to use our exclusive features
            !
          </span>
        }
      >
        <div className=" text-center my-4" style={{ fontSize: "12px" }}>
            <Button variant="dark" className="mb-4 mt-2 w-100" href="/signin" >
             sign in
            </Button>
          <div>
            <a
              href="/"
              style={{
                textDecoration: "underline",
                cursor: "pointer",
                fontSize: "12px",
                fontWeight: "500",
              }}
            >
              Back to Home
            </a>
          </div>
        </div>
      </AuthenticationBg>

      )}
    </>
  );}
};

export default EmailVerified;
