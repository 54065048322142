import React from "react";
import classes from "./Input.module.css";

const TeaxtArea = (props) => {
  return (
    <div className={classes.floatinglabelcontent}>
      <label className={classes.floatinglabel}>
        {props.text}
        {props.required ? (
          <span className="text-danger ps-1 fw-semibold">*required</span>
        ) : (
          ""
        )}
      </label>
      <textarea
        rows={props.rows}
        required={props.required}
        className={classes.floatingtextarea}
        type={props.type}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        pattern={props.pattern}
        maxLength={props.maxLength}
        placeholder={props.placeholder}
        disabled={props.disabled}
      />
      <div className={classes.dsse}></div>
      {props.error && <div className={classes.errorMessage}>{props.error}</div>}
    </div>
  );
};

export default TeaxtArea;
