import React, { useContext } from "react";
import classes from "../../../../candidate/profile/Profile.module.css";
import { Row, Col } from "react-bootstrap";
import { FaLink } from "react-icons/fa";
import { RecruiterCandidateListContext } from "../../../../../provider/RecruiterCandidateListContext";

const SocialLinkPreview = () => {
  const { singleCandidate } = useContext(RecruiterCandidateListContext);
  const socialLinks = singleCandidate ? singleCandidate.social_links : null;
  return (
    <div className={classes.personalinfo}>
      <div className="d-flex align-items-center ">
        <div className={classes.companyName + " text-black "}>Social Links</div>
      </div>
      <div className="border-bottom pt-2 mb-3"></div>
      <Row className="d-flex flex-column" style={{ fontSize: "15px" }}>
        {socialLinks && (
          <>
            {socialLinks.map((social) => (
              <Row className="mt-3" key={social.id}>
                <Col lg={2}>
                  <FaLink className="text-secondary" />
                </Col>
                <Col lg={10}>
                  <div className="text-secondary fw-semibold">
                    {social.name}
                  </div>
                  <a
                    href={social.link}
                    target="_blank"
                    rel="noreferrer"
                    className={classes.linkContainer + " text-primary"}
                    style={{ fontSize: "14px" }}
                  >
                    {social.link}
                  </a>
                </Col>
              </Row>
            ))}

            {/* Additional Links */}
            <Col className="d-flex flex-column mb-2">
              {/* GitHub Link */}
              {socialLinks.github && (
                <Row className="mt-3" key="github">
                  <Col lg={1}>
                    <FaLink className="text-secondary" />
                  </Col>
                  <Col lg={9}>
                    <div className="text-secondary fw-semibold">GitHub</div>
                    <a
                      href={socialLinks.github}
                      target="_blank"
                      rel="noreferrer"
                      className={classes.linkContainer + " text-primary"}
                      style={{ fontSize: "14px" }}
                    >
                      {socialLinks.github}
                    </a>
                  </Col>
                </Row>
              )}
              {/* LinkedIn Link */}
              {socialLinks.linkedin && (
                <Row className="mt-3" key="linkedin">
                  <Col lg={1}>
                    <FaLink className="text-secondary" />
                  </Col>
                  <Col lg={9}>
                    <div className="text-secondary fw-semibold">LinkedIn</div>
                    <a
                      href={socialLinks.linkedin}
                      target="_blank"
                      rel="noreferrer"
                      className={classes.linkContainer + " text-primary"}
                      style={{ fontSize: "14px" }}
                    >
                      {socialLinks.linkedin}
                    </a>
                  </Col>
                </Row>
              )}
              {/* Other Links */}
              {socialLinks.other_links &&
                socialLinks.other_links.length > 0 &&
                socialLinks.other_links.map((link, index) => (
                  <Row className="mt-3" key={`other_link_${index}`}>
                    <Col lg={1}>
                      <FaLink className="text-secondary" />
                    </Col>
                    <Col lg={9}>
                      <div className="text-secondary fw-semibold">
                        Other Link {index + 1}
                      </div>
                      <a
                        href={link}
                        target="_blank"
                        rel="noreferrer"
                        className={classes.linkContainer + " text-primary"}
                        style={{ fontSize: "14px" }}
                      >
                        {link}
                      </a>
                    </Col>
                  </Row>
                ))}
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};

export default SocialLinkPreview;
