
export const personal = {
  profaddress: null,
  profnationality: null,
  profphone: null,
  profjobTitle: null,
  proffirstName: null,
  proflastName: null,
  profemail: null,
  profdob: null,
  profgender: null,
  profImage: null,
  profsummary: null,
};

export const education_prof = {
  course: null,
  institution: null,
  proficiency: 0,
  proficiency_type: "GPA",
  startDate: null,
  endDate: null,
  present: false,
};

export const employement_prof = {
  company: null,
  designation: null,
  startDateWe: null,
  endDateWe: null,
  present: false,
  responsibility: null,
};

export const reference_prof = {
  contact_name: null,
  company: null,
  country: null,
  phone_number: null,
  email: null,
  desc: null,
};

export const social_prof = { name: null, link: null };
export const skill_prof = { softSkill: null };
export const techskills_prof = { techSkill: null };
export const hobby_prof = { hobby: null };
export const interests_prof = { interest: null };
export const certification_prof = { certification: null };
export const achievement_prof = { achievement: null };
export const award_prof = { award: null };
export const language_prof = { language: null };
export const volunteer_prof = { volunteer: null };
export const publication_prof = { publication: null };

export const profile_Info = {
  profaddress: null,
  profnationality: null,
  profphone: null,
  profjobTitle: null,
  proffirstName: null,
  proflastName: null,
  profemail: null,
  profdob: null,
  profgender: null,
  profImage: null,
  profsummary: null,
  education_prof: [
    {
      course: null,
      institution: null,
      proficiency: null,
      proficiency_type: null,
      startDate: null,
      endDate: null,
      present: false,
    },
  ],
  employement_prof: [
    {
      company: null,
      designation: null,
      startDateWe: null,
      endDateWe: null,
      present: false,
      responsibility: null,
    },
  ],
  reference_prof: [
    {
      contact_name: null,
      company: null,
      country: null,
      phone_number: null,
      email: null,
      desc: null,
    },
  ],
  social_prof: [{ name: null, link: null }],
  skill_prof: [{ softSkill: null }],
  techskills_prof: [{ techSkill: null }],
  achievement_prof: [{ achievement: null }],
  language_prof: [{ language: null }],
  award_prof: [{ award: null }],
  hobby_prof: [{ hobby: null }],
  publication_prof: [{ publication: null }],
  volunteer_prof: [{ volunteer: null }],
  certification_prof: [{ certification: null }],
  interests_prof: [{ interest: null }],
};
