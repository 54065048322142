import React from "react";
import {
  Page,
  Text,
  Document,
  StyleSheet,
  View,
  Font,
  Link,
} from "@react-pdf/renderer";
// import MyCustomFont from "../../fonts/Roboto.ttf"
import {
  formatDateOfBirth,
  extractListItems,
  extractParagraphs,
} from "../../../utils/ResumeHelpers";

const Resume3 = ({ data, loading }) => {
  //   Font.register({ family: 'Roboto', src: MyCustomFont });

  const styles = StyleSheet.create({
    body: {
      paddingVertical: 30,
      paddingHorizontal: 30,
    },
    mainheading: {
      fontSize: 18,
      fontFamily: "Helvetica-Bold",
      marginBottom: 5,
      textAlign: "center",
    },
    personalInfo: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "row",
      marginBottom: 5,
    },
    section: {
      padding: 7,
    },
    subheading: {
      fontSize: 14,
      fontFamily: "Times-Roman",
      marginBottom: 5,
      textAlign: "center",
    },
    sectionheading: {
      fontSize: 12,
      marginBottom: 7,
      fontFamily: "Helvetica-Bold",
      textAlign: "center",
    },
    smtext: {
      fontFamily: "Times-Roman",
      fontSize: 12,
      lineHeight: 1.3,
    },
    xsmtext: {
      fontFamily: "Times-Roman",
      fontSize: 10,
    },
    sectionwrap: {
      marginBottom: 8,
    },
    eachsectionwrap: {
      marginBottom: 8,
    },
    sectext: {
      fontSize: 12,
      fontFamily: "Helvetica-Bold",
      marginBottom: 5,
    },
    line: {
      borderBottom: "1pt solid #ccc",
      marginBottom: 5,
    },
    rowContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "baseline",
    },
  });
  return (
    <Document>
      {loading ? (
        <Page>
          <Text>Loading document...</Text>
        </Page>
      ) : (
        <Page style={styles.body} size="A4" wrap>
          {/* personal and weblinks */}
          <View style={styles.section}>
            <Text style={styles.mainheading}>
              {data.firstName} {data.lastName}
            </Text>
            {data.job_title && (
              <Text style={styles.subheading}>{data.job_title}</Text>
            )}
            {data.social && data.social.some((social) => social.link) ? (
              <Text style={{ paddingBottom: 10, textAlign: "center" }}>
                {data.social.map((soc, id) => (
                  <View key={id}>
                    <Link
                      src={soc.link}
                      style={[styles.smtext, { textDecoration: "none" }]}
                    >
                      {soc.name} &nbsp;
                      <Text style={{ color: "black" }}>|</Text> &nbsp;
                    </Link>
                  </View>
                ))}
              </Text>
            ) : null}
            {data.address || data.phone || data.nationality || data.gender ? (
              <Text style={styles.line}></Text>
            ) : null}
            <View style={styles.personalInfo}>
              {data.phone && <Text style={styles.smtext}>{data.phone} | </Text>}
              {data.email && <Text style={styles.smtext}>{data.email} | </Text>}
              {data.dob && (
                <Text style={styles.smtext}>
                  {formatDateOfBirth(data.dob)} |
                </Text>
              )}
              {data.address && (
                <Text style={styles.smtext}>{data.address} | </Text>
              )}
              {data.nationality && (
                <Text style={styles.smtext}>{data.nationality} | </Text>
              )}
              {data.gender === "F" ? (
                <Text style={styles.smtext}>Female</Text>
              ) : data.gender === "M" ? (
                <Text style={styles.smtext}>Male</Text>
              ) : data.gender === "O" ? (
                <Text style={styles.smtext}>Others</Text>
              ) : null}
            </View>
            {data.email ||
            data.address ||
            data.phone ||
            data.nationality ||
            data.gender ? (
              <Text style={styles.line}></Text>
            ) : null}
          </View>
          {/* summary */}
          {data.summary && (
            <View style={styles.section}>
              <View style={styles.sectionwrap}>
                <Text style={styles.sectionheading}>Summary</Text>
                <View style={{ flexDirection: "column", paddingLeft: 8 }}>
                  {extractParagraphs(data.summary).map((paragraph, index) => (
                    <View
                      key={index}
                      style={{ flexDirection: "row", marginBottom: 2 }}
                    >
                      <Text style={styles.smtext}>{paragraph}</Text>
                    </View>
                  ))}
                  {extractListItems(data.summary).map((item, index) => (
                    <View
                      key={index}
                      style={{ flexDirection: "row", marginBottom: 2 }}
                    >
                      <Text style={styles.smtext}>• &nbsp;</Text>
                      <Text style={styles.smtext}>{item}</Text>
                    </View>
                  ))}
                </View>
              </View>
            </View>
          )}
          {/* experience */}
          <View style={styles.section}>
            {data.employement &&
            data.employement.some(
              (wrk) =>
                wrk.company ||
                wrk.designation ||
                wrk.startDateWe ||
                wrk.endDateWe ||
                wrk.responsibilities
            ) ? (
              <View style={styles.sectionwrap}>
                <Text style={styles.sectionheading}>Experience</Text>
                {data.employement.map((emp, id) => (
                  <View style={styles.eachsectionwrap} key={id} wrap={false}>
                    <Text style={styles.sectext}>{emp.company}</Text>
                    <View style={styles.rowContainer}>
                      <Text style={styles.sectext}>{emp.designation} </Text>
                      <Text style={styles.xsmtext}>
                        {emp.startDateWe} {emp.startDateWe && "- "}
                        <Text style={styles.xsmtext}>
                          {emp.present === false ? emp.endDateWe : "Present"}
                        </Text>
                      </Text>
                    </View>
                    {emp.responsibilities && (
                      <View style={{ flexDirection: "column", paddingLeft: 8 }}>
                        {extractParagraphs(emp.responsibilities).map(
                          (paragraph, index) => (
                            <View key={index} style={{ flexDirection: "row" }}>
                              <Text style={styles.smtext}>{paragraph}</Text>
                            </View>
                          )
                        )}
                        {extractListItems(emp.responsibilities).map(
                          (item, index) => (
                            <View key={index} style={{ flexDirection: "row" }}>
                              <Text style={styles.smtext}>• &nbsp;</Text>
                              <Text style={styles.smtext}>{item}</Text>
                            </View>
                          )
                        )}
                      </View>
                    )}
                  </View>
                ))}
              </View>
            ) : null}
          </View>
          {/* skills */}
          <View style={styles.section}>
            {data.techskills && data.techskills.some((skl) => skl.techSkill) ? (
              <View style={styles.sectionwrap} wrap={false}>
                <Text style={styles.sectionheading}>Skills</Text>
                <Text style={styles.sectext}>Technical Skills</Text>

                <Text style={styles.eachsectionwrap}>
                  <Text style={styles.smtext}>
                    {data.techskills.map((tech) => tech.techSkill).join(", ")}
                  </Text>
                </Text>
              </View>
            ) : null}
            {data.skill && data.skill.some((skl) => skl.softSkill) ? (
              <View style={styles.sectionwrap} wrap={false}>
                <Text style={styles.sectext}>Soft Skills</Text>

                <Text style={styles.eachsectionwrap}>
                  <Text style={styles.smtext}>
                    {data.skill.map((soft) => soft.softSkill).join(", ")}
                  </Text>
                </Text>
              </View>
            ) : null}
          </View>
          {/* education */}
          <View style={styles.section}>
            {data.education &&
            data.education.some(
              (edu) =>
                edu.institution ||
                edu.course ||
                edu.startDate ||
                edu.endDate ||
                edu.proficiency
            ) ? (
              <View style={styles.sectionwrap}>
                <Text style={styles.sectionheading}>Education</Text>

                {data.education.map((edu, id) => (
                  <View style={styles.eachsectionwrap} key={id} wrap={false}>
                    <Text style={styles.sectext}>{edu.institution}</Text>

                    <View style={styles.rowContainer}>
                      <Text style={styles.sectext}>{edu.course} </Text>
                      <Text style={styles.xsmtext}>
                        {edu.startDate} {edu.startDate && "- "}
                        <Text style={styles.xsmtext}>
                          {edu.present === false ? edu.endDate : "Present"}
                        </Text>
                      </Text>
                    </View>

                    <Text style={styles.xsmtext}>
                      {edu.proficiency}&nbsp;
                      <Text style={styles.xsmtext}>
                        {edu.proficiency_type === "G"
                          ? "GPA"
                          : edu.proficiency_type === "P"
                          ? "%"
                          : null}
                      </Text>
                    </Text>
                  </View>
                ))}
              </View>
            ) : null}
          </View>
          {/* reference */}
          <View style={styles.section}>
            {data.reference &&
            data.reference.some(
              (ref) =>
                ref.contact_name ||
                ref.company ||
                ref.country ||
                ref.phone_number ||
                ref.desc ||
                ref.email
            ) ? (
              <View>
                <View style={styles.sectionwrap}>
                  <Text style={styles.sectionheading}>Reference</Text>
                  {data.reference.map((ref, id) => (
                    <View style={styles.eachsectionwrap} key={id} wrap={false}>
                      <Text style={styles.sectext}>{ref.contact_name}</Text>
                      <Text style={styles.xsmtext}>{ref.desc}</Text>{" "}
                      <Text style={styles.xsmtext}>
                        {ref.company},
                        <Text style={styles.xsmtext}>{ref.country}</Text>
                      </Text>
                      <Text>
                        <Text style={styles.smtext}>{ref.email} </Text>
                        <Text style={{ fontWeight: 800, fontSize: 11 }}>
                          &nbsp;|&nbsp;
                        </Text>
                        <Text style={styles.xsmtext}> {ref.phone_number}</Text>
                      </Text>
                    </View>
                  ))}
                </View>
              </View>
            ) : null}
          </View>
          <View>
            {data.language && data.language.some((lan) => lan.language) ? (
              <View style={styles.section}>
                <View>
                  <View style={styles.sectionwrap} wrap={false}>
                    <Text style={styles.sectionheading}>Languages</Text>
                    <Text style={styles.eachsectionwrap}>
                      <Text style={styles.smtext}>
                        {data.language.map((lan) => lan.language).join(",  ")}
                      </Text>
                    </Text>
                  </View>
                </View>
              </View>
            ) : null}
          </View>
          <View>
            {data.achievement &&
            data.achievement.some((ach) => ach.achievement) ? (
              <View style={styles.section}>
                <View>
                  <View style={styles.sectionwrap} wrap={false}>
                    <Text style={styles.sectionheading}>Achievements</Text>
                    <Text style={styles.eachsectionwrap}>
                      <Text style={styles.smtext}>
                        {data.achievement
                          .map((ach) => ach.achievement)
                          .join(",")}
                      </Text>
                    </Text>
                  </View>
                </View>
              </View>
            ) : null}
          </View>

          {data.award && data.award.some((aw) => aw.award) ? (
            <View style={styles.section}>
              <View>
                <View style={styles.sectionwrap} wrap={false}>
                  <Text style={styles.sectionheading}>Awards</Text>
                  <Text style={styles.eachsectionwrap}>
                    <Text style={styles.smtext}>
                      {data.award.map((aw) => aw.award).join(",")}
                    </Text>
                  </Text>
                </View>
              </View>
            </View>
          ) : null}

          {data.certification &&
          data.certification.some((cert) => cert.certification) ? (
            <View style={styles.section}>
              <View>
                <View style={styles.sectionwrap} wrap={false}>
                  <Text style={styles.sectionheading}>Certifications</Text>
                  <Text style={styles.eachsectionwrap}>
                    <Text style={styles.smtext}>
                      {data.certification
                        .map((cert) => cert.certification)
                        .join(",")}
                    </Text>
                  </Text>
                </View>
              </View>
            </View>
          ) : null}

          <View>
            {data.publication &&
            data.publication.some((pub) => pub.publication) ? (
              <View style={styles.section}>
                <View>
                  <View style={styles.sectionwrap} wrap={false}>
                    <Text style={styles.sectionheading}>Publications</Text>
                    <Text style={styles.eachsectionwrap}>
                      <Text style={styles.smtext}>
                        {data.publication
                          .map((pub) => pub.publication)
                          .join(",")}
                      </Text>
                    </Text>
                  </View>
                </View>
              </View>
            ) : null}
          </View>

          <View>
            {data.volunteer && data.volunteer.some((vol) => vol.volunteer) ? (
              <View style={styles.section}>
                <View>
                  <View style={styles.sectionwrap} wrap={false}>
                    <Text style={styles.sectionheading}>
                      Volunteer Experience
                    </Text>
                    <Text style={styles.eachsectionwrap}>
                      <Text style={styles.smtext}>
                        {data.volunteer.map((vol) => vol.volunteer).join(",")}
                      </Text>
                    </Text>
                  </View>
                </View>
              </View>
            ) : null}
          </View>

          <View>
            {data.interests && data.interests.some((int) => int.interest) ? (
              <View style={styles.section}>
                <View>
                  <View style={styles.sectionwrap} wrap={false}>
                    <Text style={styles.sectionheading}>Interests</Text>
                    <Text style={styles.eachsectionwrap}>
                      <Text style={styles.smtext}>
                        {data.interests.map((int) => int.interest).join(",")}
                      </Text>
                    </Text>
                  </View>
                </View>
              </View>
            ) : null}
          </View>

          <View>
            {data.hobby && data.hobby.some((hob) => hob.hobby) ? (
              <View style={styles.section}>
                <View>
                  <View style={styles.sectionwrap} wrap={false}>
                    <Text style={styles.sectionheading}>Hobbies</Text>
                    <Text style={styles.eachsectionwrap}>
                      <Text style={styles.smtext}>
                        {data.hobby.map((hob) => hob.hobby).join(",")}
                      </Text>
                    </Text>
                  </View>
                </View>
              </View>
            ) : null}
          </View>
        </Page>
      )}
    </Document>
  );
};

export default Resume3;
