import React, { useState } from "react";
import axios from "axios";
import "./ToggleSelect.css";
import { useHirerJobListContext } from "../../../../../provider/RecruiterJobListContext";
import { useToast } from "../../../../../provider/ToastContext";

const ToggleSelect = ({ jobId, isActive }) => {
  const [isChecked, setIsChecked] = useState(isActive);
  const { setToastMessage, setShowToast } = useToast();

  const handleToggleChange = async () => {
    const confirmChange = window.confirm(
      "Are you sure you want to change the vacancy status?"
    );

    if (confirmChange) {
      setIsChecked(!isChecked);

      try {
        const token = localStorage.getItem("token");
        const updatedData = {
          is_active: !isChecked,
        };

        const response = await axios.patch(
          `${process.env.REACT_APP_BASE_URL}hirer/add/job/${jobId}`,
          updatedData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          setToastMessage("Vacancy status updated successfully!");
          setShowToast(true);
        }

        // updateJobs();
      } catch (error) {
        console.error("Error updating toggle:", error);
      }
    }
  };

  return (
    <>
      {/* <label className="switch">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleToggleChange}
        />
        <span
          className="slider"
          style={{
            backgroundColor: isChecked
              ? "rgb(20, 136, 20)"
              : "rgb(194, 42, 42)",
          }}
        >
        </span>
      </label> */}

      <div class="switch-holder" title="update vacancy status">
        <div class="switch-label">
          <span>Vacancy </span>
        </div>
        <div class="switch-toggle">
          <input
            type="checkbox"
            id="bluetooth"
            checked={isChecked}
            onChange={handleToggleChange}
          />
          <label for="bluetooth"></label>
        </div>
      </div>
    </>
  );
};

export default ToggleSelect;
