import React, { useState } from "react";
import classes from "./Sidebar.module.css";
import {
  BsPersonLinesFill,
  BsPuzzleFill,
  BsBagFill,
  BsFillMegaphoneFill,
  BsHouseDoorFill,
} from "react-icons/bs";
import { HiDocumentSearch } from "react-icons/hi";
import { HiMiniSquaresPlus } from "react-icons/hi2";
import { FaRegHeart, FaUserGraduate } from "react-icons/fa";
import { useResumeBuilderContext } from "../../../provider/ResumeBuilderContext";

const Sidebar = () => {
  const { selectSection, resumeData, errors, showScore, setShowScore } =
    useResumeBuilderContext();
  const [hoveredIcon, setHoveredIcon] = useState(null);
  const handleClose = () => {
    setShowScore(false);
  };
  const iconData = [
    { icon: <HiMiniSquaresPlus />, section: 2, name: "Templates" },
    { icon: <BsPersonLinesFill />, section: 3, name: "Personal" },
    { icon: <FaUserGraduate />, section: 4, name: "Education" },
    { icon: <BsBagFill />, section: 5, name: "Experience" },
    { icon: <BsPuzzleFill />, section: 6, name: "Skills" },
    { icon: <BsFillMegaphoneFill />, section: 7, name: "Reference" },
    { icon: <FaRegHeart />, section: 8, name: "Others" },
    // { icon: <HiDocumentSearch />, section: 9, name: "Score" },
  ];
  const active = localStorage.getItem("currentStep");

  return (
    <div className={classes.sidebar}>
      <ul className={classes.ul}>
        {iconData.map(({ icon, section, name }) => (
          <li
            onClick={() => {
              if (section === 1 || section === 2 || section === 3) {
                selectSection(section);
              } else {
                if (
                  resumeData.phone === null ||
                  errors.phone !== "" ||
                  resumeData.phone === "" ||
                  resumeData.email === null ||
                  resumeData.email === "" ||
                  errors.email !== "" ||
                  resumeData.firstName === null ||
                  resumeData.firstName === "" ||
                  errors.firstName !== "" ||
                  resumeData.job_title === null ||
                  resumeData.job_title === "" ||
                  errors.job_title !== "" ||
                  resumeData.dob === null ||
                  resumeData.dob === "" ||
                  errors.dob !== "" ||
                  resumeData.gender === null ||
                  resumeData.gender === ""
                ) {
                  alert(
                    "Please fill all required fields in PersonalDetails section"
                  );
                } else {
                  selectSection(section);
                }
              }
            }}
            onMouseEnter={() => setHoveredIcon(section)}
            onMouseLeave={() => setHoveredIcon(null)}
            key={section}
            className={`${classes.navButton} ${
              parseInt(active) === section ? classes.activeNav : ""
            }`}
          >
            <div className={classes.icon}>
              {icon}
              {hoveredIcon === section && (
                <div className={classes.tooltip}>{name}</div>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
