import React, { useContext } from "react";
import { Row, Col, Badge } from "react-bootstrap";
import { RecruiterCandidateListContext } from "../../../../../provider/RecruiterCandidateListContext";
import classes from "../../../../candidate/profile/Profile.module.css";

const LanguagePreview = () => {
  const { singleCandidate } = useContext(RecruiterCandidateListContext);
  const languageProfile = singleCandidate.language
    ? singleCandidate.language
    : [];
  return (
    <div>
      {languageProfile.length > 0 ? (
        <div className="fw-bold mb-3 text-secondary">
          <div className="d-flex align-items-center">
            <h4 className="fw-bold text-dark">Languages</h4>
          </div>
          <div className="mt-4">
            {languageProfile.map((language, index) => (
              <Badge key={index} bg="info" className="me-3 mb-3 p-3 text-dark">
                {language}
              </Badge>
            ))}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default LanguagePreview;
