import React from "react";
import classes from "./ResumeFormData.module.css";
import { Row, Col } from "react-bootstrap";
import TagsInput from "../../resumeUI/TagsInput";
import { useResumeBuilderContext } from "../../../../provider/ResumeBuilderContext";
import AccordionField from "../../resumeUI/AccordionField";

const Awards = () => {
  const { resumeData, handleResumeFormChange } = useResumeBuilderContext();

  const handleSelectedTags = (tags) => {
    const updatedResumeData = {
      ...resumeData,
      award: tags.map((tag) => ({ award: tag })),
    };

    handleResumeFormChange({
      target: {
        name: "award",
        value: updatedResumeData.award,
      },
    });
  };

  const handleTagRemoval = (indexToRemove) => {
    const updatedTags = resumeData.award.filter(
      (_, index) => index !== indexToRemove
    );

    handleResumeFormChange({
      target: {
        name: "award",
        value: updatedTags,
      },
    });
  };
  return (
    <div>
      <AccordionField heading="Awards" subheading="Did you receive awards ? List them  in this section.">
          <div>
            <Row>
              <Col>
                <TagsInput
                  selectedTags={handleSelectedTags}
                  handleTagRemoval={handleTagRemoval}
                  tags={
                    resumeData.award.some((awd) => awd.award !== null)
                      ? resumeData.award.map((awd) => awd.award)
                      : []
                  }
                  placeholder="+ Add awards and press enter "
                />
              </Col>
            </Row>
        </div>
      </AccordionField>
    </div>
  );
};

export default Awards;
