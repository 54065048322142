import React, { useState } from "react";
import AuthenticationBg from "./AuthenticationBg";
import { useNavigate } from "react-router-dom";
import { Form, Button, Spinner } from "react-bootstrap";
import FormInput from "../../ui/common/FormInput";
import { validatepassword } from "../../utils/Validation";
import { url } from "../../data/Constants";
import { useAuthContext } from "../../provider/AuthContext";
import axios from "axios";

const ChangePassword = (props) => {
  const navigate = useNavigate();
  const { userData, headers } = useAuthContext();
  const [passwordType1, setPasswordType1] = useState("password");
  const [passwordType2, setPasswordType2] = useState("password");
  const [passwordType3, setPasswordType3] = useState("password");
  const [isLoading, setIsLoading] = useState(false);
  let userid = localStorage.getItem("userid");
  const [error, setError] = useState({
    old_password: "",
    password: "",
    password2: "",
  });

  const [changePwData, setChangePwData] = useState({
    old_password: "",
    password: "",
    password2: "",
  });

  const togglePassword = (setPasswordType) => {
    setPasswordType((prevType) =>
      prevType === "password" ? "text" : "password"
    );
  };

  const handleChangePassword = (e) => {
    if (e && e.target) {
      const { name, value } = e.target;
      let isValid = true;
      let errorMessage = "";
      switch (name) {
        // case "old_password":
        //     isValid = validatepassword(value);
        //     errorMessage = isValid ? "" : "Passwords do not match";
        //     break;
        case "password2":
          isValid = value === changePwData.password;
          errorMessage = isValid ? "" : "Passwords do not match";
          break;
        case "password":
          isValid = validatepassword(value);
          errorMessage = isValid
            ? ""
            : "Password should be at least 8 characters which include numbers and letters";
          break;
        default:
          break;
      }
      setError((prevErrors) => ({
        ...prevErrors,
        [name]: isValid ? "" : errorMessage,
      }));

      setChangePwData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleSubmitChangePassword = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const changepwurl = `${url}auth/change_password/${userid}/`;
      const response = await axios({
        method: "PATCH",
        url: changepwurl,
        headers: headers,
        data: changePwData,
      });
      // console.log(response);
      if (response.status === 200) {
        setIsLoading(false);
        localStorage.clear();
        alert("Password Changed succesfully redirect to signin");
        localStorage.removeItem("userData");
        localStorage.removeItem("token");
        localStorage.removeItem("userid");
        localStorage.removeItem("profile");
        navigate("/signin");
      }
      setIsLoading(false);
    } catch (error) {
      if (error.response.data) {
        setIsLoading(false);
        setError((prevErrors) => ({
          ...prevErrors,
          old_password: error.response.data.old_password?.error || "",
        }));
      }
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <AuthenticationBg
      subheading="Change Password"
      heading="Change Your Password"
      subheading2="Enter a new password"
      type="changepw"
    >
      <Form className="mt-4">
        <FormInput
          form="pw"
          label="Old Password"
          placeholder="********"
          type={passwordType1}
          name="old_password"
          className="mb-3"
          value={changePwData.old_password}
          onChange={handleChangePassword}
          toggle={() => togglePassword(setPasswordType1)}
          error={error.old_password}
          required="required"
        />
        <FormInput
          form="pw"
          name="password"
          type={passwordType2}
          placeholder="********"
          label="New Password "
          value={changePwData.password}
          onChange={handleChangePassword}
          toggle={() => togglePassword(setPasswordType2)}
          error={error.password}
          className="mb-3"
          required="required"
        />
        <FormInput
          form="pw"
          name="password2"
          type={passwordType3}
          placeholder="********"
          label="Confirm New Password"
          value={changePwData.password2}
          onChange={handleChangePassword}
          toggle={() => togglePassword(setPasswordType3)}
          className="mb-3"
          error={error.password2}
          required="required"
        />
        <Button
          onClick={handleSubmitChangePassword}
          variant="dark"
          className=" mt-3 w-100"
          type="submit"
          disabled={
            Object.values(error).some((field) => field !== "") ||
            Object.values(changePwData).some((value) => value === "") ||
            isLoading
          }
        >
          {isLoading ? (
            <Spinner size="sm" className="ms-2" />
          ) : (
            "Change Password "
          )}
        </Button>
      </Form>
    </AuthenticationBg>
  );
};

export default ChangePassword;
