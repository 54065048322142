import React, { useState } from "react";
import AuthenticationBg from "./AuthenticationBg";
import { useNavigate } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import FormInput from "../../ui/common/FormInput";
import { url } from "../../data/Constants";
import axios from "axios";
import { validateEmail } from "../../utils/Validation";
import { useAuthContext } from "../../provider/AuthContext";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState({ email: "" });
  const [error, setError] = useState({
    email: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const { setShowLogin, setShowModal } = useAuthContext();

  const OnshowSignIn = () => {
    setShowModal("SignIn");
  };
  const keyword = "F";
  const OnPasswordChange = (e) => {
    const { name, value } = e.target;
    let isValid = true;
    let errorMessage = "";
    if (name === "email") {
      isValid = validateEmail(value);
      errorMessage = isValid ? "" : "Invalid email";
    }
    setError((prevErrors) => ({
      ...prevErrors,
      [name]: isValid ? "" : errorMessage,
    }));
    setEmail((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmitForgotPassword = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${url}auth/request-reset-email/`,
        email
      );
      if (response.data.success) {
        setIsLoading(false);
        // setShowLogin(false);
        navigate("/verifyemail", {
          state: { value: email.email, key: keyword },
        });
      } else {
        if (response.data && response.data.error) {
          setIsLoading(false);
          alert(response.data.error);
        }
      }
    } catch (error) {
      if (error.response.data && error.response.data.error) {
        alert(error.response.data.error);
      } else {
        alert("Something went wrong");
        console.log(error);
      }
      setIsLoading(false);
    }
  };
  return (
    <AuthenticationBg
      subheading="Forgot Password"
      heading="Reset Your Password"
      subheading2="Enter email address associated with your account and we'll send you a link to reset your password"
    >
      <Form className="mt-4" onSubmit={handleSubmitForgotPassword}>
        <FormInput
          label="Email Address *"
          type="email"
          placeholder="Email"
          name="email"
          value={email.email}
          onChange={OnPasswordChange}
          error={error.email}
        />
        <Button
          //   onClick={handleSubmitForgotPassword}
          variant="dark"
          className=" mt-3 w-100"
          type="submit"
          disabled={
            Object.values(email).some((value) => value === "") || isLoading
          }
        >
          Reset Password
        </Button>
      </Form>
    </AuthenticationBg>
  );
};

export default ForgotPassword;
