import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, FormLabel, Row } from "react-bootstrap";
import { MdRefresh, MdVerified } from "react-icons/md";
import classes from "./postJob.module.css";
import classes2 from "../Screens.module.css";
import RecruiterHeader from "../../../layouts/RecruiterHeader";
import TagsInput from "../../../../../ui/common/TagsInput";
import TextEditor from "../uiForms/TextEditor";
import CustomSelect from "../../../../../ui/common/CustomSelect";
import FormInput from "../../../../../ui/common/FormInput";

import {
  experience,
  jobtype,
  salary,
  sector,
  workmode,
} from "../../../../../data/JobData";
import { useHirerJobListContext } from "../../../../../provider/RecruiterJobListContext";
import { useNavigate, useParams } from "react-router-dom";
import RecruiterBody from "../../../layouts/RecruiterBody";

const PostJob = () => {
  const [input, setInput] = useState("");
  const [tags, setTags] = useState([]);
  const [isKeyReleased, setIsKeyReleased] = useState([]);
  const { postJob, jobs, handlePatchJob, setShowToast, setToastMessage } =
    useHirerJobListContext();
  const navigate = useNavigate();

  const onChange = (e) => {
    setInput(e.target.value);
  };

  const handleSelectChange = (name, selectedOption) => {
    const selectedValue = selectedOption.value;
    setFormData((prevData) => ({
      ...prevData,
      [name]: selectedValue,
    }));
  };

  const [formData, setFormData] = useState({
    job_title: "",
    job_role: "",
    company_name: "",
    experience_req: "",
    job_sector: "",
    job_location: "",
    education_req: "",
    job_type: "",
    work_mode: "",
    salary: "",
    skills_req: [],
    det_desc: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const Company_name = localStorage.getItem("company_name");

  const handleUpdateTags = (updatedTags) => {
    const skillsString = updatedTags.join(", ");
    setFormData((prevData) => ({
      ...prevData,
      skills_req: skillsString,
    }));
  };

  const { jobId } = useParams();

  useEffect(() => {
    if (jobs) {
      const foundJob = jobs.find((job) => job.id === jobId);
      // If job data is found, set formData state with job data
      if (foundJob) {
        setFormData({
          job_title: foundJob.job_title,
          job_role: foundJob.job_role,
          company_name: foundJob.company_name,
          experience_req: foundJob.experience_req,
          job_sector: foundJob.job_sector,
          job_location: foundJob.job_location,
          education_req: foundJob.education_req,
          job_type: foundJob.job_type,
          work_mode: foundJob.work_mode,
          salary: foundJob.salary,
          skills_req: foundJob.skills_req,
          det_desc: foundJob.det_desc,
        });
      }
    }
  }, [jobs, jobId]);
  // console.log("job id from postjob component", jobId);

  const requiredFields = [
    "job_title",
    "job_role",
    // "company_name",
    "experience_req",
    "job_sector",
    "job_location",
    "education_req",
    "job_type",
    "work_mode",
    "salary",
    "skills_req",
    "det_desc",
  ];

  const isAnyFieldEmpty = requiredFields.some((field) => !formData[field]);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (isAnyFieldEmpty) {
      alert("Please fill in all required fields.");
      return;
    }

    try {
      if (jobId) {
        await handlePatchJob(jobId, formData);
      } else {
        await postJob(formData);
        setFormData({
          job_title: "",
          job_role: "",
          company_name: "",
          experience_req: "",
          job_sector: "",
          job_location: "",
          education_req: "",
          job_type: "",
          work_mode: "",
          salary: "",
          skills_req: [],
          det_desc: "",
        });
        setTags([]);
        setInput("");
        // setToastMessage("Job submitted successfully!");
        // setShowToast(true);
      }
      navigate("/hirer/jobs");
    } catch (error) {
      console.error("Error posting job:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <RecruiterBody>
      <div style={{ marginTop: "5rem" }}>
        <h3 className=" text-dark fw-bold px-2">
          {" "}
          {jobId ? "Update Job" : "Post a Job"}
        </h3>
      </div>
      <Row className=" d-flex flex-wrap">
        <Col lg={12}>
          <div className=" d-inline-block w-100 overflow-visible p-2">
            <div className=" bg-white rounded-3 border mb-2 mt-2">
              <div className={classes.bgPostJob + " p-4 "}>
                <h5 className={classes.iconEdu}>Tell us about your role</h5>
                <Row className=" mt-4">
                  <Col lg={9}>
                    <Row>
                      <Form onSubmit={handleSubmit}>
                        <Col lg={12}>
                          <FormInput
                            name="job_title"
                            type="text"
                            placeholder="e.g. Senior Product Designer"
                            label="Job Title"
                            value={formData.job_title}
                            onChange={handleChange}
                            className="mb-3"
                            required="required"
                          />
                        </Col>

                        <Row>
                          <Col lg={6}>
                            <FormInput
                              name="job_role"
                              type="text"
                              placeholder="e.g. Mern Stack Developer"
                              label="Job Role"
                              value={formData.job_role}
                              onChange={handleChange}
                              className="mb-3"
                              required="required"
                            />
                          </Col>
                          <Col lg={6}>
                            {" "}
                            <FormInput
                              name="company_name"
                              type="text"
                              placeholder="e.g. Abc Company"
                              label="Company Name"
                              value={Company_name}
                              onChange={handleChange}
                              className="mb-3"
                              required="required"
                              disabled
                            />
                          </Col>
                        </Row>
                        <Row className=" mb-3">
                          <Col lg={6}>
                            <FormLabel className="ms-1">
                              {experience[0].label} *
                            </FormLabel>
                            <CustomSelect
                              options={experience}
                              selectedvalue={formData.experience_req}
                              handleChange={(selectedOption) =>
                                handleSelectChange(
                                  "experience_req",
                                  selectedOption
                                )
                              }
                              fontSize="14px"
                            />
                          </Col>
                          <Col lg={6}>
                            <FormLabel className="ms-1">
                              {sector[0].label} *
                            </FormLabel>
                            <CustomSelect
                              options={sector}
                              selectedvalue={formData.job_sector}
                              handleChange={(selectedOption) =>
                                handleSelectChange("job_sector", selectedOption)
                              }
                              fontSize="14px"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6}>
                            {" "}
                            <FormInput
                              name="job_location"
                              type="text"
                              placeholder="e.g. Banglore"
                              label="Company Location"
                              value={formData.job_location}
                              onChange={handleChange}
                              className="mb-3"
                              required="required"
                            />
                          </Col>
                          <Col lg={6}>
                            {" "}
                            <FormInput
                              name="education_req"
                              type="text"
                              placeholder="e.g. Bachelors In Computer Science"
                              label="Education"
                              value={formData.education_req}
                              onChange={handleChange}
                              className="mb-3"
                              required="required"
                            />
                          </Col>
                        </Row>
                        <Row className=" mb-3">
                          <Col lg={3}>
                            <FormLabel className="ms-1">
                              {jobtype[0].label} *
                            </FormLabel>
                            <CustomSelect
                              options={jobtype}
                              selectedvalue={formData.job_type}
                              handleChange={(selectedOption) =>
                                handleSelectChange("job_type", selectedOption)
                              }
                              fontSize="14px"
                            />
                          </Col>
                          <Col lg={3}>
                            <FormLabel className="ms-1">
                              {workmode[0].label} *
                            </FormLabel>
                            <CustomSelect
                              options={workmode}
                              selectedvalue={formData.work_mode}
                              handleChange={(selectedOption) =>
                                handleSelectChange("work_mode", selectedOption)
                              }
                              fontSize="14px"
                            />
                          </Col>
                          <Col lg={6}>
                            <FormLabel className="ms-1">
                              {salary[0].label} *
                            </FormLabel>
                            <CustomSelect
                              options={salary}
                              selectedvalue={formData.salary}
                              handleChange={(selectedOption) =>
                                handleSelectChange("salary", selectedOption)
                              }
                              fontSize="14px"
                            />
                          </Col>
                        </Row>
                        <Col lg={12}>
                          <FormLabel className="ms-1">Skills*</FormLabel>
                          <TagsInput
                            onChange={onChange}
                            tags={tags}
                            input={input}
                            setInput={setInput}
                            setTags={setTags}
                            setIsKeyReleased={setIsKeyReleased}
                            isKeyReleased={isKeyReleased}
                            onUpdateTags={handleUpdateTags}
                            initialSkills={
                              Array.isArray(formData.skills_req)
                                ? formData.skills_req.join(",")
                                : formData.skills_req
                            }
                            // Pass skills_req data as initialSkills prop
                          />
                        </Col>
                        <Col lg={12}>
                          <p className={classes.TextEditorLabel}>
                            Job Description
                          </p>
                          <TextEditor
                            name="det_desc"
                            placeholder="Add your Job Description"
                            value={formData.det_desc}
                            onChange={handleChange}
                          />
                        </Col>
                        <Button
                          type="submit"
                          onClick={handleSubmit}
                          disabled={isLoading || isAnyFieldEmpty}
                          className="d-flex align-items-center gap-2"
                        >
                          {isLoading ? (
                            <>
                              <span>
                                <MdRefresh
                                  className={classes2.spinAnimation + " fs-5 "}
                                />
                              </span>
                              {jobId ? "Updating Job..." : "Posting Job..."}
                            </>
                          ) : (
                            <>
                              <span>
                                <MdVerified className="fs-5" />
                              </span>
                              {jobId ? "Update Job" : "Post New Job"}
                            </>
                          )}
                        </Button>
                      </Form>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </RecruiterBody>
  );
};

export default PostJob;
