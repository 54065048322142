import React, { useEffect, useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import InterestsProfile from "./InterestsProfile";
import LanguageProfile from "./LanguageProfile";
import HobbiesProfile from "./HobbiesProfile";
import { Row, Col, Button } from "react-bootstrap";
import AwardProfile from "./AwardProfile";
import AchievementsProfile from "./AchievementsProfile";
import VolunteerProfile from "./VolunteerProfile";
import PublicationProfile from "./PublicationProfile";
import CertificationProfile from "./CertificationProfile";
import ReferenceProfile from "./ReferenceProfile";
import classes from "./Profile.module.css";
import { RecruiterCandidateListContext } from "../../../provider/RecruiterCandidateListContext";
import { useProfileContext } from "../../../provider/ProfileContext";
import LanguagePreview from "../../recruiter/dashboard/screens/candidate/LanguagePreview";
import CertificationPreview from "../../recruiter/dashboard/screens/candidate/CertificationPreview";
import AchievementsPreview from "../../recruiter/dashboard/screens/candidate/AchievementsPreview";
import AwardsPreview from "../../recruiter/dashboard/screens/candidate/AwardsPreview";
import HobbiesPreview from "../../recruiter/dashboard/screens/candidate/HobbiesPreview";

const OtherInfo = () => {
  const { singleCandidate } = useContext(RecruiterCandidateListContext);
  const { profileData } = useProfileContext();
  const location = useLocation();

  const [profile, setProfile] = useState(null);
  let currentPath = location.pathname;
  let candidateProfile = currentPath.includes("/viewcandidate");

  useEffect(() => {
    if (candidateProfile) {
      setProfile(singleCandidate);
    } else {
      setProfile(profileData);
    }
  }, [currentPath, singleCandidate, profileData]);

  const components = [
    candidateProfile ? <LanguagePreview /> : <LanguageProfile />,
    candidateProfile ? <CertificationPreview /> : <CertificationProfile />,
    candidateProfile ? <AchievementsPreview /> : <AchievementsProfile />,
    candidateProfile ? <AwardsPreview /> : <AwardProfile />,
    candidateProfile ? <HobbiesPreview /> : <HobbiesProfile />,
    !candidateProfile && <InterestsProfile />,
    !candidateProfile && <VolunteerProfile />,
    !candidateProfile && <PublicationProfile />,
  ];

  // Filter out null or undefined components
  const availableComponents = components.filter(Boolean);
  console.log(availableComponents);

  return (
    <div>
      {!candidateProfile && (
        <Row className="mb-3">
          <Col lg={8}>
            <ReferenceProfile />
          </Col>
          <Col>
            <div className={classes.jobsearchinfo}>
              <h5 className="text-white fw-semibold mb-3">Looking for Job?</h5>
              <p className="text-white" style={{ fontSize: "14px" }}>
                Advertise your jobs to millions of monthly users and search 16.8
                million CVs in our database.
              </p>
              <Button
                className="bg-white text-primary border-0 fw-semibold"
                as={Link}
                to="/jobs"
              >
                Search Jobs
              </Button>
            </div>
          </Col>
        </Row>
      )}

      <div
       
      >
        {availableComponents.map((Component, index) => (
          <div key={index} className="grid-item">
            {Component}
          </div>
        ))}
      </div>
    </div>
  );
};

export default OtherInfo;
