import React, { useState } from "react";
import classes from "./Profile.module.css";
import { BsBuildings, BsFillPersonLinesFill } from "react-icons/bs";
import { MdOutlineWorkOutline } from "react-icons/md";
import { Tab, Row, Col, Nav } from "react-bootstrap";
import PersonalInfo from "./PersonalInfo";
import OtherInfo from "./OtherInfo";

const ProfileTabs = () => {
  const tabs = [
    { key: "first", label: "About me", icon: <BsFillPersonLinesFill />, component: <PersonalInfo  /> },
    { key: "second", label: "Other Info", icon: <MdOutlineWorkOutline />, component: <OtherInfo/> }
  ];

  const [activeTab, setActiveTab] = useState("first");

  const handleTabClick = (eventKey) => {
    setActiveTab(eventKey);
  };

  return (
    <div>
      <Tab.Container id="profile-tabs" defaultActiveKey="first">
        <Row>
          <Col sm={12} className="mb-4">
            <Nav className="flex-row gap-3">
              {tabs.map(tab => (
                <Nav.Item key={tab.key}>
                  <Nav.Link
                    eventKey={tab.key}
                    className={classes.customNavlink}
                    style={{
                      color: activeTab === tab.key ? "#05264E" : "#4F5E64",
                      fontSize:"16px",
                      border:
                        activeTab === tab.key
                          ? "1px solid #B4C0E0"
                          : "1px solid #eff2fb",

                    }}
                    onClick={() => handleTabClick(tab.key)}
                  >
                    {tab.icon} <span>{tab.label}</span>
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Col>
          <Col sm={12} className="border-bottom mb-3 mt-2"></Col>
          <Col sm={12} className="mt-3">
            <Tab.Content>
              {tabs.map(tab => (
                <Tab.Pane key={tab.key} eventKey={tab.key}>
                  {tab.component}
                </Tab.Pane>
              ))}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
};

export default ProfileTabs;
