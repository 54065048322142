import resume1 from "../assets/resumes/resume1.jpg";
import resume2 from "../assets/resumes/resume2.jpg";
import resume3 from "../assets/resumes/resume3.jpg";
import resume4 from "../assets/resumes/resume4.jpg";
import resume5 from "../assets/resumes/resume5.jpg";
import resume6 from "../assets/resumes/resume6.jpg";
import resume7 from "../assets/resumes/resume7.jpg";
import resume8 from "../assets/resumes/resume8.jpg";
import resume9 from "../assets/resumes/resume9.jpg";
import resume10 from "../assets/resumes/resume10.jpg";
import resume11 from "../assets/resumes/resume11.jpg";


export const resumeimages = [
  { id: 1, src: resume1, alt: "Resume1" },
  { id: 2, src: resume2, alt: "Resume2" },
  { id: 3, src: resume3, alt: "Resume3" },
  { id: 4, src: resume4, alt: "Resume4" },
  { id: 5, src: resume5, alt: "Resume5" },
  { id: 6, src: resume6, alt: "Resume6" },
  { id: 7, src: resume7, alt: "Resume7" },
  { id: 8, src: resume8, alt: "Resume8" },
  { id: 9, src: resume9, alt: "Resume9" },
  { id: 10, src: resume10, alt: "Resume10" },
  { id: 11, src: resume11, alt: "Resume11" },
];
