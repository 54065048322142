import React, { useState } from "react";
import { useProfileContext } from "../../../provider/ProfileContext";
import { Badge, Row, Col, Button, Spinner } from "react-bootstrap";
import { CiEdit } from "react-icons/ci";
import FormInput from "../../../ui/common/FormInput";
import ProfileModal from "./ProfileModal";
import { RxCross1 } from "react-icons/rx";

const AchievementsProfile = () => {
  const {
    profileData,
    handlePersonalChange,
    AddProfileData,
    EditProfileData,
    DeleteProfileData,
    deletingData,
  } = useProfileContext();
  const profileid = localStorage.getItem("profile");

  const [achievements, setAchievements] = useState(
    profileData.achievement_prof || []
  );
  const [achievementProfile, setAchievementProfile] = useState({
    achievement: "",
    profile: profileid,
  });
  const [editId, setEditid] = useState(null);
  const [errors, setErrors] = useState({ achievement: "" });
  const [modalState, setModalState] = useState({ show: false, edit: false });
  const [selectedProfile, setSelectedProfile] = useState(null);

  const handleShowModal = (edit = false, profile = null) => {
    setErrors({ achievement: "" });
    setModalState({ show: true, edit });
    if (edit) {
      setEditid(profile.id);
      setSelectedProfile(profile);
      setAchievementProfile(profile);
    } else {
      setAchievementProfile({ achievement: "", profile: profileid });
    }
  };

  const handleCloseModal = () => {
    setModalState({ show: false, edit: false });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors({ achievement: "" });
    setAchievementProfile((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateForm = (profile) => {
    if (!profile.achievement) {
      setErrors({ achievement: "Please fill in the required field." });
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm(achievementProfile)) return;
    try {
      const response = modalState.edit
        ? await EditProfileData(
            achievementProfile,
            "achieve",
            selectedProfile.id
          )
        : await AddProfileData(achievementProfile, "achieve");

      if (response.status === 201 || response.status === 200) {
        const updatedProfile = response.data;
        setAchievements((prevachievements) => {
          const newachievements = modalState.edit
            ? prevachievements.map((int) =>
                int.id === updatedProfile.id ? updatedProfile : int
              )
            : [...prevachievements, updatedProfile];

          handlePersonalChange({
            target: { name: "achievements_prof", value: newachievements },
          });
          return newachievements;
        });
        handleCloseModal();
      }
    } catch (error) {
      console.error("Error saving achievement profile:", error);
    }
  };

  const handleDelete = async () => {
    try {
      const response = await DeleteProfileData("achieve", editId);
      if (response.status === 204) {
        const updated = achievements.filter((data) => data.id !== editId);
        setAchievements(updated);
        handleCloseModal();
      }
    } catch (error) {
      console.error("Error deleting :", error);
    }
  };

  return (
    <>
      <div className="mb-5 pb-2 ">
        <div className="fw-bold mb-3 text-secondary">
          <div className="d-flex align-items-center">
            <h4 className="fw-bold text-dark">Achievements</h4>
            <div
              className="ms-4 border p-1 rounded text-primary fw-semibold"
              style={{ fontSize: "12px", cursor: "pointer" }}
              onClick={() => handleShowModal()}
            >
              + Add more
            </div>
          </div>
          {achievements.length > 0 ? (
            <div className="mt-4">
              {achievements.map((int) => (
                <Badge
                  key={int.id}
                  bg="info"
                  className="me-3 mb-3 p-3 text-dark"
                >
                  {int.achievement}
                  <CiEdit
                    style={{ cursor: "pointer" }}
                    className="ms-2 fs-6 fw-bold"
                    onClick={() => handleShowModal(true, int)}
                  />
                </Badge>
              ))}
            </div>
          ) : (
            <div
              className="mt-4 text-danger fw-normal"
              style={{ fontSize: "14px" }}
            >
              * No achievement added
            </div>
          )}
        </div>
      </div>
      <ProfileModal
        show={modalState.show}
        handleClose={handleCloseModal}
        formtype={modalState.edit ? "edit" : "add"}
        handleSubmitChange={handleSubmit}
      >
        <Row className="d-flex align-items-center">
          <Col>
            <FormInput
              name="achievement"
              type="text"
              placeholder="Add your achievement"
              label="achievement"
              value={achievementProfile.achievement}
              onChange={handleChange}
              className="mb-3"
              required="required"
              error={errors.achievement}
            />
          </Col>
          {modalState.edit && (
            <Col lg={2}>
               {deletingData ? (
                <Button variant="outline-danger" className=" mt-3 ">
                  Del <Spinner size="sm" className="ms-1" />
                </Button>
              ) : (
                <Button
                  variant="outline-danger"
                  className=" mt-3 "
                  onClick={handleDelete}
                >
                  Del
                  <RxCross1 className="ms-1 fw-bold" />
                </Button>
              )}
            </Col>
          )}
        </Row>
      </ProfileModal>
    </>
  );
};

export default AchievementsProfile;
