import React, { useState } from "react";
import { useProfileContext } from "../../../provider/ProfileContext";
import { Badge, Row, Col, Button, Spinner } from "react-bootstrap";
import { CiEdit } from "react-icons/ci";
import FormInput from "../../../ui/common/FormInput";
import ProfileModal from "./ProfileModal";
import { RxCross1 } from "react-icons/rx";

const CertificationProfile = () => {
  const { profileData, handlePersonalChange, AddProfileData, EditProfileData, DeleteProfileData,
    deletingData, } =
    useProfileContext();
  const profileid = localStorage.getItem("profile");

  const [certifications, setCertifications] = useState(
    profileData.certification_prof || []
  );
  const [certificationProfile, setCertificationProfile] = useState({
    certification: "",
    profile: profileid,
  });
  const [errors, setErrors] = useState({ certification: "" });
  const [editId, setEditid] = useState(null);
  const [modalState, setModalState] = useState({ show: false, edit: false });
  const [selectedProfile, setSelectedProfile] = useState(null);

  const handleShowModal = (edit = false, profile = null) => {
    setErrors({ certification: "" });
    setModalState({ show: true, edit });
    if (edit) {
      setEditid(profile.id);
      setSelectedProfile(profile);
      setCertificationProfile(profile);
    } else {
      setCertificationProfile({ certification: "", profile: profileid });
    }
  };

  const handleCloseModal = () => {
    setModalState({ show: false, edit: false });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors({ certification: "" });
    setCertificationProfile((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateForm = (profile) => {
    if (!profile.certification) {
      setErrors({ certification: "Please fill in the required field." });
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm(certificationProfile)) return;
    try {
      const response = modalState.edit
        ? await EditProfileData(certificationProfile, "certificate", selectedProfile.id)
        : await AddProfileData(certificationProfile, "certificate");

      if (response.status === 201 || response.status === 200) {
        const updatedProfile = response.data;
        setCertifications((prevcertifications) => {
          const newcertifications = modalState.edit
            ? prevcertifications.map((int) =>
                int.id === updatedProfile.id ? updatedProfile : int
              )
            : [...prevcertifications, updatedProfile];

          handlePersonalChange({
            target: { name: "certifications_prof", value: newcertifications },
          });
          return newcertifications;
        });
        handleCloseModal();
      }
    } catch (error) {
      console.error("Error saving certification profile:", error);
    }
  };

  const handleDelete = async () => {
    try {
      const response = await DeleteProfileData("certificate", editId);
      if (response.status === 204) {
        const updated = certifications.filter((data) => data.id !== editId);
        setCertifications(updated);
        handleCloseModal();
      }
    } catch (error) {
      console.error("Error deleting :", error);
    }
  };

  return (
    <>
      <div className="mb-5 pb-2 ">
        <div className="fw-bold mb-3 text-secondary">
          <div className="d-flex align-items-center">
            <h4 className="fw-bold text-dark">Certifications</h4>
            <div
              className="ms-4 border p-1 rounded text-primary fw-semibold"
              style={{ fontSize: "12px", cursor: "pointer" }}
              onClick={() => handleShowModal()}
            >
              + Add more
            </div>
          </div>
          {certifications.length > 0 ? (
            <div className="mt-4">
              {certifications.map((int) => (
                <Badge
                  key={int.id}
                  bg="info"
                  className="me-3 mb-3 p-3 text-dark"
                >
                  {int.certification}
                  <CiEdit
                    style={{ cursor: "pointer" }}
                    className="ms-2 fs-6 fw-bold"
                    onClick={() => handleShowModal(true, int)}
                  />
                </Badge>
              ))}
            </div>
          ) : (
            <div
              className="mt-4 text-danger fw-normal"
              style={{ fontSize: "14px" }}
            >
              * No certification added
            </div>
          )}
        </div>
      </div>
      <ProfileModal
        show={modalState.show}
        handleClose={handleCloseModal}
        formtype={modalState.edit ? "edit" : "add"}
        handleSubmitChange={handleSubmit}
      >
        <Row className="d-flex align-items-center">
          <Col>
            <FormInput
              name="certification"
              type="text"
              placeholder="Add your certification"
              label="certification"
              value={certificationProfile.certification}
              onChange={handleChange}
              className="mb-3"
              required="required"
              error={errors.certification}
            />
          </Col>
          {modalState.edit && (
             <Col lg={2}>
             {deletingData ? (
               <Button variant="outline-danger" className=" mt-3 ">
                 Del <Spinner size="sm" className="ms-1" />
               </Button>
             ) : (
               <Button
                 variant="outline-danger"
                 className=" mt-3 "
                 onClick={handleDelete}
               >
                 Del
                 <RxCross1 className="ms-1 fw-bold" />
               </Button>
             )}
           </Col>
          )}
        </Row>
      </ProfileModal>
    </>
  );
};

export default CertificationProfile;
