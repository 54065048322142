import React, { useState } from "react";
import JobModal from "./JobModal";
import { useJobContext } from "../../../provider/JobContext";
import classes from "../resumes/ResumeList.module.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import ScoreSuggestion from "../../../ui/common/ScoreSuggestion";
import { Badge } from "react-bootstrap";

const CheckScore = ({ job, show, handleClose }) => {
  const {
    onCheckScore,
    fetchJobs,
    scores,
    setIsCheckingScore,
    isCheckingScore,
    selectedJob,
  } = useJobContext();

  const handleCheckScore = async () => {
    setIsCheckingScore(true);
    try {
      const jobScore = {
        jobDesc: job.det_desc,
        scoreType: "DESC",
        jobListing: "IN",
        internalJob: selectedJob === "E" ? job.id : null,
        link: null,
        externalJob: selectedJob === "X" ? job.is : null,
      };

      const data = await onCheckScore(jobScore);
    } catch (error) {
      console.error(error);
      alert("An error occurred");
      setIsCheckingScore(false);
    } finally {
      setIsCheckingScore(false);
    }
  };

  return (
    <JobModal
      show={show}
      handleClose={handleClose}
      compo="score"
      onSubmitScore={handleCheckScore}
      onLoading={isCheckingScore}
      scores={scores}
    >
      {!isCheckingScore && scores.length == 0 ? (
        <div
          className="fw-semibold px-3 text-black"
          style={{ fontSize: "18px" }}
        >
          <h6 className="fw-semibold" style={{ lineHeight: "1.5rem" }}>
            Do you want to check score for job{" "}
            <span className=" text-primary mx-1">{job.job_title} ?</span>
          </h6>
        </div>
      ) : !isCheckingScore && scores && Object.keys(scores).length > 0 ? (
        <div className=" fw-semibold px-3" style={{ fontSize: "18px" }}>
          <div
            className="d-flex align-items-center justify-content-center "
            style={{ fontSize: "14px" }}
          >
            <div style={{ width: 100, height: 100 }} className="ms-2 mb-2">
              <CircularProgressbar
                value={scores.score}
                text={`${scores.score}%`}
                strokeWidth={10}
                // width={100}
                styles={buildStyles({
                  strokeLinecap: "butt",
                  textColor: "black",
                  textSize: "18px",
                  pathColor: "#3C65F5",
                })}
              />
            </div>
          </div>
          <div className="mb-2">
            {" "}
            compatibility score{" "}
            <Badge className="ms-1">{`${scores.score}%`}</Badge>
          </div>

          <ScoreSuggestion suggestionData={scores} />
        </div>
      ) : (
        <div className="fw-semibold px-3 " style={{ fontSize: "18px" }}>
          Fetching score..
          <div className="d-flex align-items-center justify-content-center my-3">
            <div className={classes.loader}></div>
          </div>
        </div>
      )}
    </JobModal>
  );
};

export default CheckScore;
