import React from "react";
import Slider from "react-slick";
import classes from "../Template.module.css"
import { websiteimages } from "../../../data/WebsiteImages";

const ScoreFooter = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (

    <div className={classes.scorefooter}>
    <i className="fw-semibold" style={{ fontSize: "15px" }}>
    supported websites :
    </i>
      <Slider {...settings}>
        {websiteimages.map((image) => (
          <div key={image.id}>
            <img width={80} src={image.src} alt={image.alt} />
          </div>
        ))}
      </Slider>
    </div> 
  );
};
export default ScoreFooter;
