import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { url } from "../data/Constants";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  let useravail = localStorage.getItem("userData");
  let token = localStorage.getItem("token");
  let role = localStorage.getItem("role");
  let userid = localStorage.getItem("userid");
  const [user, setUser] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState(useravail || null);
  const [latestJob, setLatestJob] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [membership, setMembership] = useState("");
  const headers = {
    Authorization: `Bearer ${token !== null ? token : null}`,
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (useravail) {
      setUserData(useravail);
    } else {
      localStorage.setItem("userData", null);
    }
  }, []);

  const register = async (userdata) => {
    try {
      let data = new FormData();
      data.append("username", userdata.username);
      data.append("first_name", userdata.first_name);
      data.append("last_name", userdata.last_name);
      data.append("email", userdata.email);
      data.append("password", userdata.password);
      data.append("password2", userdata.password2);
      data.append("role", role);
      setIsLoading(true);
      const authurl = `${url}auth/register/`;
      const response = await axios({
        method: "POST",
        url: authurl,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      });

      setIsLoading(false);
      return response;
    } catch (err) {
      setIsLoading(false);
      console.error(err);
      throw err;
    }
  };

  console.log("______",useravail)
  const login = async (userdata) => {
    try {
      let data = new FormData();
      data.append("username", userdata.username);
      data.append("password", userdata.password);
      setIsLoading(true);
      const authurl = `${url}auth/login/`;
      const response = await axios({
        method: "POST",
        url: authurl,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      });
      // console.log(response.data);
      setUserData(JSON.stringify(response.data));
      setUser(true);
      localStorage.setItem("userData", JSON.stringify(response.data));
      localStorage.setItem("role", response.data.userrole);
      localStorage.setItem("token", response.data.access);
      localStorage.setItem("userid", response.data.id);
      localStorage.setItem("profile", response.data.profile);
      setIsLoading(false);
      if (response.data.userrole === "C") {
        navigate("/jobs");
      } else if (response.data.userrole === "H") {
        if (response.data.profile === null) {
          navigate("/get-started");
        } else {
          navigate("/company/profile");
        }
      }
      return response;
    } catch (err) {
      setIsLoading(false);
      console.error(err);
      throw err;
    }
  };

  const latestJobsFetch = async (page) => {
    const params = {
      page: page,
    };
    try {
      setIsLoading(true);
      const authurl = `${url}user/job/latest_jobs`;
      const response = await axios.get(authurl, { params });
      if (response.data && response.data.job_count > 0) {
        const data = response.data;
        setLatestJob(data.values);
        setTotalPage(data.job_count);
        let pages = page;
        if (data.next_page !== "null") {
          pages = parseInt(data.next_page) - 1;
        } else if (data.previous_page !== "null") {
          pages = parseInt(data.previous_page) + 1;
        } else {
          pages = 0;
        }
        setCurrentPage(pages);
      } else {
        setLatestJob([]);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.error(err);
      return [];
    }
  };

  const membershipFetch = async () => {
    try {
      setIsLoading(true);
      const authurl = `${url}auth/current_membership`;
      const response = await axios.get(authurl, { headers });
      setMembership(response.data.current_membership);
      console.log(response.data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.error(err);
    }
  };

  const logout = async () => {
    setIsLoading(true);
    setUserData(null);
    setUser(false);
    localStorage.clear();
    navigate("/");
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  };

  const chooseUser = async (userrole) => {
    try {
      let data = new FormData();
      data.append("role", userrole);
      setIsLoading(true);
      const updateurl = `${url}auth/update/${userid}`;
      const response = await axios({
        method: "PATCH",
        url: updateurl,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: data,
      });
      setUser(true);
      localStorage.setItem("role", response.data.role);
      setIsLoading(false);
      if (userrole === "C") {
        navigate("/jobs");
      } else if (userrole === "H") {
        navigate("/get-started");
      } else {
      }
      return response;
    } catch (err) {
      setIsLoading(false);
      console.error(err);
      throw err;
    }
  };

  const contextValue = {
    user,
    setUser,
    userData,
    setUserData,
    login,
    logout,
    register,
    isLoggedIn,
    setIsLoggedIn,
    isLoading,
    headers,
    chooseUser,
    latestJobsFetch,
    latestJob,
    currentPage,
    setCurrentPage,
    totalPage,
    membershipFetch,
    membership,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
