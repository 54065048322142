import React from "react";
import { Badge, Modal } from "react-bootstrap";
import classes from "./Candidate.module.css";
import CircularProgressBar from "./CircularProgressbar";

const CustomModal = ({
  showModal,
  setShowModal,
  senderInitial,
  candidateDetails,
}) => {
  return (
    <Modal
      size="lg"
      show={showModal}
      onHide={() => setShowModal(false)}
      centered
    >
      <Modal.Header closeButton>
        <div
          className="px-4 fw-bold text-primary py-2 "
          style={{ fontSize: "20px" }}
        >
          {candidateDetails.job_title}
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className={classes.card}>
          <div className="d-flex align-items-center justify-content-between">
            <h5 className="fw-bold " style={{ lineHeight: "1.5" }}>
              {" "}
              <div className=" mb-3">
                <h1
                  className=" fw-bold text-black mb-0 d-flex gap-1"
                  style={{ fontSize: "19px" }}
                >
                  {candidateDetails.first_name} {candidateDetails.last_name}
                  <span>
                    {candidateDetails.matched && (
                      <Badge bg="success">Best</Badge>
                    )}
                  </span>
                </h1>
              </div>
              <div
                className="mb-1 d-flex align-items-center gap-1"
                style={{ fontSize: "16px" }}
              >
                <span className=" text-primary">Exam Total Score:</span>
                <span className="">
                  {candidateDetails.exam_score?.total_score}/50
                </span>
              </div>
              <div
                className="mb-2 d-flex align-items-center gap-1"
                style={{ fontSize: "16px" }}
              >
                <span className=" text-primary">Exam Status:</span>
                <span
                  className={
                    candidateDetails.exam_score?.result_status === "Passed"
                      ? "text-success"
                      : "text-danger"
                  }
                >
                  {candidateDetails.exam_score?.result_status}
                </span>
              </div>
            </h5>
            <div style={{ width: "15%" }}>
              <CircularProgressBar
                percentage={candidateDetails.exam_score?.total_percentage}
                pathColor={
                  candidateDetails.exam_score?.result_status === "Passed"
                    ? "#28a745"
                    : "#f10909"
                }
              />
            </div>
          </div>

          <div className="d-flex justify-content-around gap-4 my-4">
            <div
              className={
                " rounded bg-info  p-3 text-dark d-flex align-items-center justify-content-between"
              }
              style={{
                width: "30%",
                boxShadow: "  rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
              }}
            >
              <div>
                <h6 className="fw-semibold">MCQ </h6>
                <div className="fw-semibold" style={{ fontSize: "14px" }}>
                  {candidateDetails.exam_score?.mcq_score}/10
                </div>
              </div>
              <div
                className="bg-white p-1 d-flex align-items-center justify-content-center"
                style={{
                  width: "60px",
                  height: "60px",
                  borderRadius: "50%",
                }}
              >
                <CircularProgressBar
                  percentage={candidateDetails.exam_score?.mcq_percentage}
                />
              </div>
            </div>
            <div
              className={
                " rounded bg-info  p-3 text-dark d-flex align-items-center justify-content-between"
              }
              style={{
                width: "30%",
                boxShadow: "  rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
              }}
            >
              <div>
                <h6 className="fw-semibold">ShortQuestion </h6>
                <div className="fw-semibold" style={{ fontSize: "14px" }}>
                  {candidateDetails.exam_score?.short_questions_score}/10
                </div>
              </div>
              <div
                className="bg-white p-1 d-flex align-items-center justify-content-center"
                style={{
                  width: "60px",
                  height: "60px",
                  borderRadius: "50%",
                }}
              >
                <CircularProgressBar
                  percentage={
                    candidateDetails.exam_score?.short_question_percentage
                  }
                />
              </div>
            </div>
            <div
              className={
                " rounded bg-info  p-3 text-dark d-flex align-items-center justify-content-between"
              }
              style={{
                width: "30%",
                boxShadow: "  rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
              }}
            >
              <div>
                <h6 className="fw-semibold">Programming </h6>
                <div className="fw-semibold" style={{ fontSize: "14px" }}>
                  {candidateDetails.exam_score?.coding_questions_score}/30
                </div>
              </div>
              <div
                className="bg-white p-1 d-flex align-items-center justify-content-center"
                style={{
                  width: "60px",
                  height: "60px",
                  borderRadius: "50%",
                }}
              >
                <CircularProgressBar
                  percentage={
                    candidateDetails.exam_score?.coding_question_percentage
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CustomModal;
