import React, { useState,useEffect } from "react";
import axios from "axios";
import classes from "../Screens.module.css";
import UpgradeCard from "./UpgradeCard";
import { Row, Col, Button } from "react-bootstrap";
import { MdRefresh, MdVerified } from "react-icons/md";
import { useRecruiterProfileContext } from "../../../../../provider/RecruiterProfileContext";
import { url } from "../../../../../data/Constants";
import { useAuthContext } from "../../../../../provider/AuthContext";

const PlanUpgrade = () => {
  const { isLoading, handleSaveChange } = useRecruiterProfileContext();
  const currentPath = window.location.pathname;
  const { headers ,membership} = useAuthContext();
  const [selectedPlan, setSelectedPlan] = useState("NR");
  useEffect(() => {
    if (membership && membership.trim() !== "") {
      setSelectedPlan(membership);
    }
  }, [membership]);
  const [upgradingPlan, setUpgradingPlan] = useState(null);

  const updateMemberShip = async (plan) => {
    setUpgradingPlan(plan);
    const params = { membership: plan };
    try {
      const membershipurl = `${url}auth/membership_update`;
      const response = await axios.get(membershipurl, { headers, params });
      if (response.data && response.data.message) {
        console.log(response.data.message);
        setSelectedPlan(plan);
      }
    } catch (err) {
      console.error(err);
      throw err;
    } finally {
      setUpgradingPlan(null);
    }
  };

  const [plans] = useState({
    normal: {
      plan: "Normal",
      input: "NR",
      price: 0,
      features: [
        "Post Jobs",
        "View Applied Candidates",
        "View Up to 100 External Candidates",
        "Download 100 Resumes",
      ],
    },
    pro: {
      plan: "Pro",
      price: 75,
      input: "PR",
      features: [
        "Post Jobs",
        "View Applied Candidates",
        "View Up to 500 External Candidates",
        "Download 500 Resumes",
      ],
    },
    enterprise: {
      plan: "Enterprise",
      price: 120,
      input: "EN",
      features: [
        "Post Jobs",
        "View Applied Candidates",
        "View Up to 1000 External Candidates",
        "Download 1000 Resumes",
      ],
    },
  });

  return (
    <div className="d-flex flex-column align-items-center">
      <h2 className={classes.companyName + " mb-2 "}>Choose Your Plan</h2>
      <div style={{ fontSize: "14px" }} className="text-light mb-5">
        Simple and flexible plans to meet your needs, with access to premium
        features for hirers.
      </div>
      <Row className="w-100 d-flex justify-content-center">
        {Object.keys(plans).map((key) => (
          <Col lg={4} className="d-flex justify-content-center mb-3" key={key}>
            <UpgradeCard
              plan={plans[key].plan}
              price={plans[key].price}
              features={plans[key].features}
              input={plans[key].input}
              selectedPlan={selectedPlan}
              onSelectPlan={() => updateMemberShip(plans[key].input)}
              upgrading={upgradingPlan === plans[key].input}
            />
          </Col>
        ))}
      </Row>
      {currentPath !== "/upgradeplan" && (
        <Button className="mt-3" onClick={handleSaveChange}>
          <span>
            {isLoading ? (
              <MdRefresh className={classes.spinAnimation + " fs-5 "} />
            ) : (
              <MdVerified className="fs-5 mb-1 me-1 " />
            )}
          </span>
          {isLoading ? "Saving..." : "Create Profile"}
        </Button>
      )}
    </div>
  );
};

export default PlanUpgrade;
