import React, { useContext } from "react";
import { Row, Col, Badge } from "react-bootstrap";
import { RecruiterCandidateListContext } from "../../../../../provider/RecruiterCandidateListContext";
import classes from "../../../../candidate/profile/Profile.module.css";
const HobbiesPreview = () => {
    const { singleCandidate } = useContext(RecruiterCandidateListContext);
    const achieveProfile = singleCandidate.acheivements
      ? singleCandidate.acheivements
      : [];
    return (
      <div className="">
        {achieveProfile.length > 0 ? (
          <div className="fw-bold mt-3 text-secondary mb-5">
            <div className="d-flex align-items-center">
              <h4 className="fw-bold text-dark">Hobbies</h4>
            </div>
            <div className="mt-4">
              {achieveProfile.map((language, index) => (
                <Badge key={index} bg="info" className="me-3 mb-3 p-3 text-dark">
                  {language}
                </Badge>
              ))}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
}

export default HobbiesPreview
