import React from "react";
import axios from "axios";
import Slider from "react-slick";
import { resumeimages } from "../../../data/ResumeImages";
import ImageCard from "./ImageCard";
import classes from "./TemplateSelection.module.css";
import classes1 from "../../home/Home.module.css";
import { Row, Col, Container } from "react-bootstrap";
// import AuthModal from "../../Authentication/AuthModal";
import { useAuthContext } from "../../../provider/AuthContext";
import Loading from "../../../ui/common/Loading";
import CandidateHeader from "../../candidate/layouts/CandidateHeader";
import Footer from "../../home/layouts/Footer";

const TemplateSection = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const { isLoading } = useAuthContext();

  return (
    <>
      {isLoading === true ? (
        <Loading />
      ) : (
        <div>
          <CandidateHeader />
          <section>
            <div className={classes1.bghomepageresume}></div>
            <Container>
             


            <Row
                className={classes1.section}
                style={{
                  padding: "1.5rem ",
                  height: "30vh",
                }}
              >
                <h2
                  className="fw-bold text-dark mb-3"
                  style={{ lineHeight: "2.5rem" }}
                >
                  Elevate your career with
                  <br />
                  <span className={classes1.textbg + " text-primary mx-1"}>
                    Resume Builder
                  </span>
                  in few minutes.
                </h2>
                <p
                  className="text-secondary "
                  style={{ fontWeight: "500", lineHeight: "1.6" }}
                >
                  Transform your career trajectory with our collection of
                  premium resume templates. Crafted meticulously to showcase
                  your accomplishments with precision, each template promises to
                  elevate your professional profile. Our
                  <span className="fw-semibold ms-1">ATS</span>-optimized
                  designs guarantee that your resume catches the eye of
                  recruiters and hiring managers alike.{" "}
                  <div
                    style={{ fontSize: "18px" }}
                    className="fw-semibold text-dark pt-3"
                  >
                    Select your template now and distinguish your job
                    application from the rest!
                  </div>
                </p>
              </Row>
              <div style={{ paddingBottom: "6rem" }} className="pt-3 px-4">
                <Slider {...settings}>
                  {resumeimages.map((image) => (
                    <ImageCard key={image.id} src={image.src} alt={image.alt} />
                  ))}
                </Slider>
              </div>
            </Container>
          </section>
          <Footer />
        </div>
      )}
    </>
  );
};

export default TemplateSection;
