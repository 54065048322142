import React from "react";
import Select from "react-select";

const CustomSelect = (props) => {
  return (
    <Select
      options={props.options}
      defaultValue={props.options[0]}
      value={props.options.find(
        (option) => option.value === props.selectedvalue
      )}
      onChange={props.handleChange}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          fontSize: props.fontSize,
          textAlign: "left",
          // padding: "3px",
          borderColor: "#DFE2E6",
          borderBottom: state.isFocused && "1px solid white",
          boxShadow: state.isFocused && "none",
       
        }),
        option: (baseStyles) => ({
          ...baseStyles,
          fontSize: props.fontSize,
          textAlign: "left",
        }),
      }}
      isSearchable={false}
    />
  );
};

export default CustomSelect;