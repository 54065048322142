import React, { createContext, useContext, useState } from "react";
import axios from "axios";
import {
  isRecruiterEmailValid,
  validateEmail,
  validateUrl,
  validatePhone,
} from "../utils/Validation";
import { useNavigate } from "react-router-dom";
import { useHirerJobListContext } from "./RecruiterJobListContext";
import { useToast } from "./ToastContext";

const RecruiterProfileContext = createContext();

export const RecruiterProfileProvider = ({ children }) => {
  const { showToast, setShowToast, setToastMessage, setToastBg } = useToast();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [loadingProfile, isLoadingProfile] = useState(false);
  const [recruiterProfileData, setRecruiterProfileData] = useState({
    email: "",
    company_phone: "",
    company_name: "",
    company_link: "",
    company_logo: "",
    company_location: "",
    social_link: "",
    company_desc: "",
    company_address: "",
  });

  const [errors, setErrors] = useState({
    email: "",
    company_phone: "",
    company_link: "",
    social_link: "",
  });
  // console.log("recruiter profile Data", recruiterProfileData);

  const [fetchedData, setFetchedData] = useState(null);
  const [activeTab, setActiveTab] = useState("first");
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [isSaved, setIsSaved] = useState(false);

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const handleFormDataChange = (e) => {
    const { name, value, files } = e.target;
    let isValid = true;
    let errorMessage = "";

    if (name === "company_logo") {
      const uploadedFile = files[0];
      const maxSize = 1 * 1024 * 1024; // 1MB in bytes

      if (uploadedFile) {
        if (uploadedFile.type.indexOf("image") === 0) {
          if (uploadedFile.size <= maxSize) {
            setRecruiterProfileData((prevFormData) => ({
              ...prevFormData,
              [name]: uploadedFile,
            }));
          } else {
            alert("File size exceeds 1MB. Please select a smaller file.");
            setRecruiterProfileData((prevFormData) => ({
              ...prevFormData,
              [name]: null,
            }));
          }
        } else {
          alert("Please select an image file.");
          setRecruiterProfileData((prevFormData) => ({
            ...prevFormData,
            [name]: null,
          }));
        }
      }
      e.target.value = null;
      return;
    }
    switch (name) {
      case "email":
        const emailValidationResult = validateEmail(value);
        if (emailValidationResult !== true) {
          isValid = false;
          errorMessage = "email not valid";
        }
        // else {
        //   isValid = isRecruiterEmailValid(value);
        //   errorMessage = isValid ? "" : "Email domain not allowed";
        // }
        break;
      case "company_phone":
        isValid = validatePhone(value);
        errorMessage = isValid ? "" : "Invalid phone";
        break;
      case "company_link":
      case "social_link":
        isValid = validateUrl(value);
        errorMessage = isValid ? "" : "Please enter a valid URL";
        break;
      default:
        break;
    }

    if (!isValid) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: errorMessage,
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }

    setRecruiterProfileData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  console.log(recruiterProfileData);

  const handleSaveChange = async () => {
    try {
      setIsLoading(true);
      const recruiterProfileDataToSend = new FormData();

      Object.entries(recruiterProfileData).forEach(([key, value]) => {
        if (value instanceof File) {
          recruiterProfileDataToSend.append(key, value, value.name);
        } else {
          recruiterProfileDataToSend.append(key, value);
        }
      });

      const url = `${process.env.REACT_APP_BASE_URL}hirer/profile/`;

      const response = await axios.post(url, recruiterProfileDataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 201) {
        setToastMessage("profile updated Successfully");
        setShowToast(true);
        setToastBg("success");
        navigate("/company/profile");
      } else {
        setToastMessage("Error updating profile:");
        setShowToast(true);
        setToastBg("danger");
        throw new Error("Failed to save changes.");
      }

      // console.log(response);
    } catch (error) {
      console.error("An error occurred:", error);
      let errorMessage =
        "An error occurred while saving changes. Please try again later.";

      if (error.response && error.response.data && error.response.data.error) {
        const firstError = error.response.data.error;
        errorMessage = firstError || errorMessage;
        alert(errorMessage);
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Patch request
  const handleSaveChangePatch = async () => {
    const token = localStorage.getItem("token");
    try {
      setIsLoading(true);
      const recruiterProfileDataToSend = new FormData();

      Object.entries(recruiterProfileData).forEach(([key, value]) => {
        if (key === "company_logo") {
          if (value instanceof File) {
            recruiterProfileDataToSend.append(key, value, value.name);
          }
        } else {
          recruiterProfileDataToSend.append(key, value);
        }
      });

      const url = `${process.env.REACT_APP_BASE_URL}hirer/profile/${fetchedData.id}`;

      const response = await axios.patch(url, recruiterProfileDataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // console.log("patch response", response);
      if (response.status === 200) {
        setFetchedData(response.data);
        setToastMessage("profile updated Successfully");
        setShowToast(true);
        setToastBg("success");
      } else {
        setFetchedData(null);
      }
      setIsSaved(true);
      // console.log(response);
    } catch (error) {
      console.error("An error occurred:", error);
      let errorMessage =
        "An error occurred while saving changes. Please try again later.";

      if (error.response && error.response.data && error.response.data.error) {
        const firstError = error.response.data.error;
        errorMessage = firstError || errorMessage;
        alert(errorMessage);
      }
      setToastMessage("Error updating job:", error);
      setShowToast(true);
      setToastBg("danger");
    } finally {
      setIsLoading(false);
    }
  };
  // Get request
  const fetchRecruiterProfile = async () => {
    isLoadingProfile(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}hirer/profile/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log(response);
      if (response.data.count > 0) {
        setFetchedData(response.data.results[0]);
      } else {
        setFetchedData(null);
      }
      isLoadingProfile(false);
      return response.data;
    } catch (error) {
      isLoadingProfile(false);
      console.error(
        "An error occurred while fetching recruiter profile:",
        error
      );
      setToastMessage("Error updating job:", error);
      setShowToast(true);
      setToastBg("danger");
      throw error;
    }
  };

  const contextValue = {
    handleFormDataChange,
    recruiterProfileData,
    handleSaveChange,
    handleTabChange,
    activeTab,
    setFile,
    setPreview,
    isLoading,
    fetchRecruiterProfile,
    fetchedData,
    errors,
    loadingProfile,
    setRecruiterProfileData,
    handleSaveChangePatch,
    isSaved,
    setIsSaved,
  };
  return (
    <RecruiterProfileContext.Provider value={contextValue}>
      {children}
    </RecruiterProfileContext.Provider>
  );
};

export const useRecruiterProfileContext = () =>
  useContext(RecruiterProfileContext);
