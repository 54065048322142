import React,{useEffect,useState} from "react";
import { useLocation } from "react-router-dom";
import AuthenticationBg from "./AuthenticationBg";
import { Button } from "react-bootstrap";
import Loading from "../../ui/common/Loading";

const EmailCheck = () => {
  const location = useLocation();
  const email = location.state && location.state.value;
  const key = location.state && location.state.key
  const [loading, setLoading] = useState(true);

  useEffect(() => {
  setTimeout(() => {
    setLoading((loading) => !loading);
  }, 1000)},[]);

  if (loading) {
    return (<Loading/>);
  } else {
  return (
    <AuthenticationBg
      subheading="You're almost there !"
      heading="Email Verification Mail Sent"
     const subheading2={
      <>
      {key === "F" ? ( <span>
        We have sent you an email to
        <span className="fw-semibold ms-1 text-dark">{email}</span>. Please check your mail
        and click the verification link to reset your password.
      </span>):( <span>
          We have sent you an email to
          <span className="fw-semibold ms-1 text-dark">{email}</span>. Please check your mail
          and click the verification link to complete your signup.
        </span>)} </>
     
      }
    >
      <div className=" text-center my-4" style={{ fontSize: "12px" }}>
        Still can't find email ?
        <div>
        {key === "F" ?(<Button variant="dark" className="mb-4 mt-2 w-100" href="/forgotpassword">
        Resend Email
          </Button>):(<Button variant="dark" className="mb-4 mt-2 w-100" href="/resendemail">
            Resend Email
          </Button>)}     
        </div>
        <div>
          <a
            href="/"
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              fontSize: "12px",
              fontWeight: "500",
            }}
          >
            Back to Home
          </a>
        </div>
      </div>
    </AuthenticationBg>
  );}
};

export default EmailCheck;
