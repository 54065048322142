import React, { useState } from "react";
import ProfileModal from "../ProfileModal";
import FormInput from "../../../../ui/common/FormInput";
import { Row, Col, Form } from "react-bootstrap";
import MonthPickerDate from "../../../resumeBuilder/resumeUI/MonthPickerDate";

const WorkProfileForm = (props) => {
  return (
    <ProfileModal
      show={props.show}
      handleClose={props.handleClose}
      handleSubmitChange={props.add}
      formtype={props.formtype}
    >
      <Row>
        <Col>
          <FormInput
            name="designation"
            type="text"
            placeholder="ex. Frontend Developer"
            label="Designation"
            value={props.data.designation}
            onChange={props.handleChange}
            error={props.errors.designation}
            className="mb-3"
          />
        </Col>
        <Col>
          <FormInput
            name="company"
            type="text"
            placeholder="ex. ABC Private Ltd"
            label="Company"
            value={props.data.company}
            error={props.errors.company}
            onChange={props.handleChange}
            className="mb-3"
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Label className="ms-1 text-muted">Responsibilities</Form.Label>
          <Form.Control
            className="mb-3"
            as="textarea"
            placeholder="Write about your responsibilities"
            name="responsibility"
            value={props.data.responsibility}
            onChange={props.handleChange}
            style={{ height: "100px" }}
            label="Bio"
          />
        </Col>
      </Row>
      <Row className="d-flex align-items-center">
        <Col lg={4} xs={5}>
          <MonthPickerDate
            type="text"
            name="startDateWe"
            placeholder="Start Date"
            value={props.data.startDateWe}
            text="start date"
            font="12px"
            onChange={props.handleChange}
            error={props.errors.startDateWe}
          />
        </Col>
        <Col lg={1} xs={2} className="mt-4 text-center">
          <span style={{ fontSize: "14px" }} className="fw-semibold">
            to
          </span>
        </Col>
        {!props.present && props.formtype === "add" && (
          <Col lg={4} xs={5}>
            <MonthPickerDate
              name="endDateWe"
              placeholder="End Date"
              type="text"
              text="end date"
              font="12px"
              value={props.data.endDateWe}
              onChange={props.handleChange}
              error={props.errors.endDateWe}
            />
          </Col>
        )}
        {props.data.present === false && props.formtype === "edit" && (
          <Col lg={4} xs={5}>
            <MonthPickerDate
              name="endDateWe"
              placeholder="End Date"
              type="text"
              text="end date"
              font="12px"
              value={props.data.endDateWe}
              onChange={props.handleChange}
              // disabled={data.present === true}
              error={props.errors.endDateWe}
            />
          </Col>
        )}
        <Col xs={3} lg={3} style={{ fontSize: "14px" }}>
          {props.formtype === "add" ? (
            <Form.Check
              className="mt-4"
              checked={props.data.present}
              value={!props.present}
              inline
              label="Currently work here"
              name="present"
              type="checkbox"
              onChange={props.onChangeHandler}
            />
          ) : (
            <Form.Check
              className="mt-4"
              checked={props.data.present}
              value={props.data.present}
              inline
              label="Currently work here"
              name="present"
              type="checkbox"
              onChange={props.onChangeHandler}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col lg={4} xs={5}>
          <span style={{ fontSize: "11px" }} className="text-danger">
            {props.errors.startDateWe}
          </span>
        </Col>
        <Col lg={1} xs={2}></Col>
        {!props.present && (
          <Col lg={4} xs={5}>
            <span style={{ fontSize: "11px" }} className="text-danger">
              {props.errors.endDateWe}
            </span>
          </Col>
        )}
        <Col xs={3} lg={3}></Col>
      </Row>
    </ProfileModal>
  );
};

export default WorkProfileForm;
