import React, { useState, useEffect } from "react";
import Header from "../home/layouts/Header";
import {
  Container,
  Row,
  Col,
  Image,
  Dropdown,
  NavItem,
  NavLink,
} from "react-bootstrap";
import Footer from "../home/layouts/Footer";
import classes from "./Authentication.module.css";
import classes1 from "../home/Home.module.css";
import img2 from "../../assets/img-3.svg";
import img1 from "../../assets/img-1.svg";
import google from "../../assets/icon-google.svg";
import { Link } from "react-router-dom";
import { url } from "../../data/Constants";
import CandidateHeader from "../candidate/layouts/CandidateHeader";
import RecruiterHeader from "../recruiter/layouts/RecruiterHeader";
import { replacerole } from "../../utils/Validation";

const AuthenticationBg = (props) => {
  const [selectedOption, setSelectedOption] = useState("C");
  const [animate, setAnimate] = useState(false);
  const role = localStorage.getItem("role");

  useEffect(() => {
    const storedValue = localStorage.getItem("role");
    if (storedValue) {
      setSelectedOption(storedValue);
    }
  }, []);

  const handleOptionSelect = (option) => {
    if (selectedOption !== option) {
      setAnimate(true);
      setTimeout(() => setAnimate(false), 500);
      setSelectedOption(option);
      localStorage.setItem("role", option);
    }
  };

  // const handleOptionSelect = (option) => {
  //   setSelectedOption(option);
  //   localStorage.setItem("role", option);
  // };

  const onHandleGoogle = () => {
    window.location.href = `${url}auth/sso/google/`;
  };

  return (
    <div>
      {props.type === "changepw" ? (
        role === "C" ? (
          <CandidateHeader />
        ) : role === "H" ? (
          <RecruiterHeader />
        ) : (
          <Header type="auth" />
        )
      ) : (
        <Header type="auth" />
      )}
      <Container>
        <div className="pt-3">
          <Row className={classes.loginregcover}>
            <Col
              className={classes.formcover + " mx-auto"}
              lg={4}
              md={6}
              sm={12}
            >
              <div
                style={{ fontWeight: "500" }}
                className={classes1.smtext + " text-dark mb-2 text-center"}
              >
                {props.subheading}
              </div>

              <h3 className=" fw-semibold d-flex align-items-center justify-content-center">
                <div>
                  {props.heading}
                  {props.auth === "signin" || props.auth === "register" ? (
                    <span className="text-primary ms-1">
                      {replacerole(role)}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </h3>
              <div
                style={{ fontWeight: "500" }}
                className={classes1.smtext + " text-dark mb-2 text-center"}
              >
                {props.subheading2}
              </div>
              {props.auth === "signin" || props.auth === "register" ? (
                <div className={`${classes1.togglebtninput} d-flex my-4`}>
                  <div
                    className={`${classes1.highlight} ${
                      selectedOption === "C"
                        ? classes1.candidate
                        : classes1.hirer
                    }`}
                  />
                  <div
                    className={`${classes1.toggleOption} ${
                      selectedOption === "H" ? classes1.active : ""
                    }`}
                    onClick={() => handleOptionSelect("H")}
                  >
                    Hirer
                  </div>
                  <div
                    className={`${classes1.toggleOption} ${
                      selectedOption === "C" ? classes1.active : ""
                    }`}
                    onClick={() => handleOptionSelect("C")}
                  >
                    Candidate
                  </div>
                </div>
              ) : (
                ""
              )}

              <div
                className={`${classes1.authformsection} ${
                  animate ? classes1.animate : ""
                }`}
              >
                <>
                  {props.auth === "signin" ? (
                    <>
                      <button
                        className={classes.sociallogin}
                        onClick={onHandleGoogle}
                      >
                        <img src={google} alt="google" />
                        <span className="ps-1 fw-semibold">
                          Sign in with Google
                        </span>
                      </button>
                      <div
                        className={
                          classes.divider + " my-4 text-center fw-semibold"
                        }
                      >
                        <span>OR</span>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {props.auth === "register" ? (
                    <>
                      <button
                        className={classes.sociallogin}
                        onClick={onHandleGoogle}
                      >
                        <img src={google} alt="google" />
                        <span className="ps-1 fw-semibold">
                          Sign up with Google
                        </span>
                      </button>{" "}
                      <div className={classes.divider + " my-4 text-center"}>
                        <span>OR</span>
                      </div>{" "}
                    </>
                  ) : (
                    ""
                  )}
                </>

                {props.children}

                <>
                  {props.auth === "signin" ? (
                    <div
                      className={
                        classes1.smtext + " text-muted text-center mt-2"
                      }
                    >
                      Don't have an account?
                      <Link className="text-primary ms-1" to="/register">
                        register
                      </Link>
                    </div>
                  ) : props.auth === "register" ? (
                    <>
                      <div
                        className={
                          classes1.smtext + " text-muted text-center mt-2"
                        }
                      >
                        Already registered ?
                        <span classname="ms-2">
                          <a href="/resendemail"> resend mail</a>
                        </span>
                      </div>

                      <div
                        className={
                          classes1.smtext + " text-muted text-center mt-2"
                        }
                      >
                        Already have an account?
                        <Link className="text-primary ms-1" to="/signin">
                          signin
                        </Link>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </>
              </div>
            </Col>
            <div className={classes1.imgauth + " d-none d-lg-block"}>
              <Image src={img1} alt="authsvg" />
            </div>
            <div>
              <Image className={classes.imgAuth2} src={img2} alt="authsvg" />
            </div>
          </Row>
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default AuthenticationBg;
