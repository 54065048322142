import React, { useState, useEffect } from "react";
import {
  Navbar,
  Container,
  Nav,
  Button,
  Stack,
  Dropdown,
  Image,
} from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { FaLinkedin } from "react-icons/fa";

const Header2 = (props) => {
  return (
    <Navbar expand="lg" className={`py-3 "fixed-top bg-white shadow-lg"}`}>
      <Container>
        <Navbar.Brand
          as={Link}
          to="/latest-jobs"
          className=" fs-4 text-dark d-flex align-items-center "
          style={{ fontWeight: "700" }}
        >
          <div
            className="bg-primary p-1 text-white"
            style={{ borderRadius: "50px", height: "45px", width: "45px" }}
          >
            em
          </div>
          Career
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <div className="ms-lg-auto flex-row gap-1 d-lg-flex align-items-center ">
            For Updates
            <Link
              to={"https://www.linkedin.com/company/emcareers/"}
              target="_blank"
              className=" px-1 rounded d-flex align-items-center gap-1"
            >
              <Image
                src="https://play-lh.googleusercontent.com/kMofEFLjobZy_bCuaiDogzBcUT-dz3BBbOrIEjJ-hqOabjK8ieuevGe6wlTD15QzOqw"
                width={22}
                alt="LInkedin Image"
              />
              <div
                className=" d-flex flex-column align-items-center border-start border-2 ps-1 fw-semibold"
                style={{ fontSize: "10px" }}
              >
                Follow us<div style={{ fontSize: "14px" }}>Emcareer</div>
              </div>
            </Link>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header2;
