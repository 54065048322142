import React from "react";
import { Col, Row,Button } from "react-bootstrap";
import { RiVerifiedBadgeLine } from "react-icons/ri";
import classes from "../Home.module.css";
import img1 from "../../../assets/newsletter-left.png";
import img2 from "../../../assets/newsletter-right.png";

const Boxlayout = () => {
  const onChangeEmail =(e)=>{
    console.log(e.target.value)
  }
  return (
    <div className={classes.boxletter}>
      <Row>
        <Col xl={3} lg={12} className="text-center d-none d-xl-block">
          <img src={img1} alt="joxBox" />
        </Col>
        <Col lg={12} xl={6}>
          <h2 className={classes.boxtext + " text-center"}>
            New Things Will Always
            <br /> Update Regularly
          </h2>
          <div className={classes.boxform}>
            <form>
              <input type="text" value="" placeholder="Enter your email here" onChange={onChangeEmail}/>
              <Button size="lg" className="py-3 d-flex align-items-center">
              <RiVerifiedBadgeLine className="me-2 " />
              Subscribe
              </Button>
            </form>
          </div>
        </Col>
        <Col xl={3} lg={12} className=" text-center d-none d-xl-block">
          <img src={img2} alt="joxBox" />
        </Col>
      </Row>
    </div>
  );
};

export default Boxlayout;
