import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import classes from "./ResumeFormData.module.css";
import Input from "../../resumeUI/Input";
import { FaTrashAlt } from "react-icons/fa";
import { validateEmail, validatePhone } from "../../../../utils/Validation";
import AccordionField from "../../resumeUI/AccordionField";

const ReferenceView = ({ data, onChange, index, onDelete, defaultKey }) => {
  const [errors, setErrors] = useState("");

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    let isValid = true;
    let errorMessage = "";

    switch (name) {
      case "email":
        isValid = validateEmail(value);
        errorMessage = isValid ? "" : "Invalid email";
        break;
      case "phone_number":
        isValid = validatePhone(value);
        errorMessage = isValid ? "" : "Invalid phone number";
        break;
      default:
        break;
    }

    if (isValid) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: errorMessage,
      }));
    }

    onChange(e, index);
  };

  return (
    <Row>
      <Col lg={11} xs={11}>
        <AccordionField
          defaultKey={defaultKey}
          index={index}
          key={index}
          subheading={
            data.company || data.contact_name
              ? `${data.contact_name || " "} at ${data.company || ""}`
              : "not specified"
          }
        >
          <Row>
            <Col xs={12} lg={6}>
              <Input
                text="Contact Name"
                name="contact_name"
                value={data.contact_name}
                type="text"
                font="12px"
                onChange={(e) => handleInputChange(e, index)}
              />
            </Col>
            <Col xs={12} lg={6}>
              <Input
                text="Email"
                name="email"
                value={data.email}
                type="email"
                font="12px"
                onChange={(e) => handleInputChange(e, index)}
                error={errors.email}
              />
            </Col>
          </Row>

          <Input
            text="Company "
            name="company"
            value={data.company}
            type="text"
            font="12px"
            onChange={(e) => handleInputChange(e, index)}
          />
          <Input
            text="Designation "
            name="desc"
            value={data.desc}
            type="text"
            font="12px"
            onChange={(e) => handleInputChange(e, index)}
          />
          <Row>
            <Col>
              <Input
                text="Country"
                name="country"
                value={data.country}
                type="text"
                font="12px"
                onChange={(e) => handleInputChange(e, index)}
              />
            </Col>
            <Col>
              <Input
                text="Phone"
                name="phone_number"
                value={data.phone_number}
                type="text"
                font="12px"
                onChange={(e) => handleInputChange(e, index)}
                error={errors.phone_number}
              />
            </Col>
          </Row>
        </AccordionField>
      </Col>
      <Col lg={1} xs={1}>
        <div className={classes.trash + " mt-4"}>
          <FaTrashAlt onClick={() => onDelete(index)} />
        </div>
      </Col>
    </Row>
  );
};

export default ReferenceView;
