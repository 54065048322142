import React from "react";
import { Link } from "react-router-dom";
import jobclasses from "../jobsearch/Jobsearch.module.css";
import classes from "./Companies.module.css";
import logo from "../../../assets/company.png";
import { IoLocationOutline } from "react-icons/io5";
import StarRating from "../../../ui/common/StarRating";
import { useCompanyContext } from "../../../provider/CompanyContext";

const CompanyCard = ({ company }) => {
  const { fetchSingleCompany } = useCompanyContext();
  return (
    <>
      <div className={classes.compcard + " text-center my-3"}>
        <div className={classes.cardimggridcomp}>
          <div className={classes.imageboxcomp}>
            <img
              onError={(e) => {
                e.target.src = logo;
              }}
              src={company.company_logo || logo}
              alt="company logos"
            />
          </div>
          <div className={classes.info + " pt-2"}>
            <h6>
              <Link to={`/company/${company.id}`}>
                <div>{company.company_name}</div>
              </Link>
            </h6>
            <div className="d-flex align-items-center justify-content-center">
              <StarRating rating={company.ambition_box_company_rating} />
              <div
                style={{ fontSize: "12px" }}
                className="text-light ms-1 mt-1"
              >
                ({company.ambition_box_vote_count})
              </div>
            </div>
            <div
              className={jobclasses.description}
              style={{ fontSize: "12px" }}
            >
              ({company.ambition_box_company_rating || "not mentioned"})
            </div>
            <span className="fw-normal">
              <IoLocationOutline
                className="me-1"
                style={{
                  marginBottom: "2px",
                  fontSize: "13px",
                  color: "#a0abb8",
                }}
              />
              {company.company_location || "not mentioned"}
            </span>

            <Link to={`/company/${company.id}`}>
              <div
                className={classes.companybtncard}
                onClick={() => {
                  fetchSingleCompany(company.id);
                }}
              >
                {company.job_count} Jobs Open
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyCard;
