import React, { useEffect } from "react";
import PlanUpgrade from "../dashboard/screens/uiForms/PlanUpgrade";
import RecruiterHeader from "./RecruiterHeader";
import { Container } from "react-bootstrap";
import Boxlayout from "../../home/layouts/Boxlayout";
import Footer from "../../home/layouts/Footer";
import { useAuthContext } from "../../../provider/AuthContext";
import Loading from "../../../ui/common/Loading";

const Upgrade = () => {
  const { membershipFetch, isLoading } = useAuthContext();

  useEffect(() => {
    membershipFetch();
  }, []);

  return (
    <div>
      <RecruiterHeader />
      {isLoading ? (
        <Loading />
      ) : (
        <Container>
          <div className="mt-4 mb-5">
            <PlanUpgrade />
          </div>
          <Boxlayout />
        </Container>
      )}

      <Footer />
    </div>
  );
};

export default Upgrade;
