import React, { createContext, useContext, useState } from "react";
import axios from "axios";
import { url } from "../data/Constants";
import { useAuthContext } from "./AuthContext";
import { useNavigate } from "react-router-dom";
import {
  personal,
  education_prof,
  employement_prof,
  reference_prof,
  social_prof,
  techskills_prof,
  skill_prof,
  hobby_prof,
  interests_prof,
  award_prof,
  certification_prof,
  achievement_prof,
  language_prof,
  volunteer_prof,
  publication_prof,
  profile_Info,
} from "../data/ProfileData";
import {
  validateAddress,
  validateDate,
  validateEmail,
  validatePhone,
} from "../utils/Validation";

const ProfileContext = createContext();

export const ProfileProvider = ({ children }) => {
  const navigate = useNavigate();

  const { headers } = useAuthContext();
  const [isLoadingProfile, setIsLoadingProfile] = useState(false);
  const [isEditingProfile, setIsEditingProfile] = useState(false);
  const [profileData, setProfileData] = useState(profile_Info);
  const [file, setFile] = useState(null);
  const [educationProfile, setEducationProfile] = useState(education_prof);
  const [skillProfile, setSkillProfile] = useState(skill_prof);
  const [editedPersonal, setEditedPersonal] = useState(personal);
  const [IsprofileId, setIsProfileId] = useState(null);
  const [addingData, setAddingData] = useState(false);
  const [deletingData, setDeletingData] = useState(false);
  const [errors, setErrors] = useState({
    profemail: "",
    profphone: "",
    profdob: "",
    // job_title: "",
    profaddress: "",
    // startDate: "",
    // endDate: "",
    // address: "",
  });
  const [showPersonal, setShowPersonal] = useState(false);
  const handleClosePersonal = () => setShowPersonal(false);
  const handleShowPersonal = () => setShowPersonal(true);

  let profileId = localStorage.getItem("profile");
  console.log(profileId)

  const fetchProfile = async (profileId) => {
    const profileurl = `${url}user/profile/${profileId}`;
    setIsLoadingProfile(true);
    try {
      const response = await axios.get(profileurl, { headers });
      if (response.data) {
        const updatedProfileData = {
          ...profileData,
          ...response.data,
        };
        setProfileData(updatedProfileData);
        setIsLoadingProfile(false);
      } else {
        setProfileData([]);
        setIsLoadingProfile(false);
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.data && err.response.data.error) {
        setIsLoadingProfile(false);
      } else {
        alert("something went wrong");
        setIsLoadingProfile(false);
      }
      setIsLoadingProfile(false);
      return [];
    }
  };

  const onCheckProfileExist = async () => {
    const profileurl = `${url}user/profile_id/`;
    setIsLoadingProfile(true);
    try {
      const response = await axios.get(profileurl, { headers });
      if (response.data) {
        setIsProfileId(response.data.profile_id);
        localStorage.setItem("profile", response.data.profile_id);
        navigate("/viewprofile");
        setIsLoadingProfile(false);
      } else {
        setIsProfileId(null);
        setIsLoadingProfile(false);
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.data && err.response.data.error) {
        alert("Please upload a resume to get your profile");
      } else {
        alert("something went wrong");
        setIsLoadingProfile(false);
      }
      setIsProfileId(null);
      setIsLoadingProfile(false);
      return [];
    }
  };

  const AddProfileData = async (data, endpoint) => {
    setAddingData(true);
    try {
      const applyurl = `${url}user/${endpoint}`;
      const response = await axios.post(applyurl, data, { headers });
      setAddingData(false);
      return response;
    } catch (err) {
      console.error(err);
      setAddingData(false);
      throw err;
    }
  };

  const handlePersonalChange = (e) => {
    const { name, value } = e.target;
    let isValid = true;
    let errorMessage = "";
    switch (name) {
      case "profemail":
        isValid = validateEmail(value);
        errorMessage = isValid ? "" : "Invalid email";
        break;
      case "profphone":
        isValid = validatePhone(value);
        errorMessage = isValid ? "" : "Invalid phone";
        break;
      case "profdob":
        isValid = validateDate(value);
        errorMessage = isValid ? "" : "Age must be greater than 10";
        break;
      case "profaddress":
        isValid = validateAddress(value);
        errorMessage = isValid ? "" : "Maximum length 70 characters";
        break;
      default:
        break;
    }
    if (!isValid) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: errorMessage,
      }));
    }

    setProfileData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    if (isValid || value === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const handleRemoveImage = () => {
    setFile(null);
    setProfileData((prevData) => ({
      ...prevData,
      profImage: null,
    }));
  };

  const validateAllFields = () => {
    const newErrors = {};

    if (!validateEmail(profileData.profemail)) {
      newErrors.profemail = "Invalid email";
    }
    if (!validatePhone(profileData.profphone)) {
      newErrors.profphone = "Invalid phone";
    }
    if (!validateDate(profileData.profdob)) {
      newErrors.profdob = "Age must be greater than 10";
    }
    if (!validateAddress(profileData.profaddress)) {
      newErrors.profaddress = "Maximum length 70 characters";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const EditPersonalInfo = async () => {
    if (!validateAllFields()) {
      alert("Please fix the errors in the form before submitting.");
      return;
    }
    const profileurl = `${url}user/profile/${profileId}`;
    setIsEditingProfile(true);
    const data = new FormData();
    if (profileData.profsummary)
      data.append("profsummary", profileData.profsummary);
    if (profileData.profdob) data.append("profdob", profileData.profdob);
    if (profileData.profemail) data.append("profemail", profileData.profemail);
    if (profileData.proffirstName)
      data.append("proffirstName", profileData.proffirstName);
    if (profileData.profjobTitle)
      data.append("profjobTitle", profileData.profjobTitle);
    if (profileData.proflastName)
      data.append("proflastName", profileData.proflastName);
    if (profileData.profnationality)
      data.append("profnationality", profileData.profnationality);
    if (profileData.profphone) data.append("profphone", profileData.profphone);
    if (profileData.profImage !== null && profileData.profImage instanceof File)
      data.append("profImage", profileData.profImage);
    if (profileData.profgender)
      data.append("profgender", profileData.profgender);
    try {
      const response = await axios.patch(profileurl, data, { headers });
      if (response.data) {
        setProfileData(response.data);
        setIsEditingProfile(false);
        setShowPersonal(false);
      } else {
        setProfileData([]);
        setIsEditingProfile(false);
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.data && err.response.data.error) {
        setIsEditingProfile(false);
      } else {
        alert("something went wrong");
        setIsEditingProfile(false);
      }
      setIsEditingProfile(false);
      return [];
    }
  };

  const EditProfileData = async (data, endpoint, id) => {
    setIsEditingProfile(true);
    try {
      const applyurl = `${url}user/${endpoint}/${id}`;
      const response = await axios.patch(applyurl, data, { headers });
      setIsEditingProfile(false);
      return response;
    } catch (err) {
      console.error(err);
      setIsEditingProfile(false);
      throw err;
    }
  };

  const DeleteProfileData = async (endpoint, id) => {
    setDeletingData(true);
    try {
      const applyurl = `${url}user/${endpoint}/${id}`;
      const response = await axios.delete(applyurl, { headers });
      console.log("deleteing____________",response)
      setDeletingData(false);
      return response;
    } catch (err) {
      console.error(err);
      setDeletingData(false);
      throw err;
    }
  };

  // console.log(profileData);

  const contextValue = {
    profileData,
    isLoadingProfile,
    fetchProfile,
    EditPersonalInfo,
    handlePersonalChange,
    isEditingProfile,
    onCheckProfileExist,
    AddProfileData,
    setProfileData,
    file,
    setFile,
    handleRemoveImage,
    errors,
    showPersonal,
    handleClosePersonal,
    handleShowPersonal,
    addingData,
    EditProfileData,
    DeleteProfileData,deletingData
  };

  return (
    <ProfileContext.Provider value={contextValue}>
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfileContext = () => useContext(ProfileContext);
