import React, { useState } from "react";
import { useResumeBuilderContext } from "../../provider/ResumeBuilderContext";
import classes from "./Template.module.css";
import {
  PDFDownloadLink,
  PDFViewer,
  StyleSheet,
  usePDF,
} from "@react-pdf/renderer";
import Resume1 from "./resumes/Resume1";
import Resume5 from "./resumes/Resume5";
import Resume4 from "./resumes/Resume4";
import Resume3 from "./resumes/Resume3";
import Resume2 from "./resumes/Resume2";
import Resume6 from "./resumes/Resume6";
import Resume7 from "./resumes/Resume7";
import Resume8 from "./resumes/Resume8";
import Resume9 from "./resumes/Resume9";
import Resume10 from "./resumes/Resume10";
import Resume11 from "./resumes/Resume11";
import { useAuthContext } from "../../provider/AuthContext";

const ResumeTemplate = () => {
  const styles = StyleSheet.create({
    viewer: {
      marginTop: "1rem",
      width: "70%",
      height: "85vh",
      PointerEvent: "none",
      backgroundColor: "white",
      border: "none",
      overflow: "hidden",
    },
  });

  const {
    handleClose,
    saveResumeApi,
    resumeData,
    errors,
    selectedTemplate,
    setDownloaded,
  } = useResumeBuilderContext();
  const { userData } = useAuthContext();
  const [isDownloading, setIsDownloading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [downloadAvailable, setDownloadAvailable] = useState(false);
  const [selectedComponent, setSelectedComponent] = useState(null);

  const templates = [
    { name: "Resume1", component: <Resume1 data={resumeData} /> },
    { name: "Resume2", component: <Resume2 data={resumeData} /> },
    { name: "Resume3", component: <Resume3 data={resumeData} /> },
    { name: "Resume4", component: <Resume4 data={resumeData} /> },
    { name: "Resume5", component: <Resume5 data={resumeData} /> },
    { name: "Resume6", component: <Resume6 data={resumeData} /> },
    { name: "Resume7", component: <Resume7 data={resumeData} /> },
    { name: "Resume8", component: <Resume8 data={resumeData} /> },
    { name: "Resume9", component: <Resume9 data={resumeData} /> },
    { name: "Resume10", component: <Resume10 data={resumeData} /> },
    { name: "Resume11", component: <Resume11 data={resumeData} /> },
  ];

  const [instance, updateInstance] = usePDF({
    document: selectedComponent,
  });

  React.useEffect(() => {
    const component = templates
      .filter((template) => template.name === selectedTemplate)
      .map((selectedTemplate) => selectedTemplate.component);
    setSelectedComponent(component);
  }, [selectedTemplate, resumeData]);

  const savePDF = async () => {
    if (
      resumeData.phone === null ||
      errors.phone !== "" ||
      resumeData.phone === "" ||
      resumeData.email === null ||
      resumeData.email === "" ||
      errors.email !== "" ||
      resumeData.firstName === null ||
      resumeData.firstName === "" ||
      errors.firstName !== "" ||
      resumeData.job_title === null ||
      resumeData.job_title === "" ||
      errors.job_title !== "" ||
      resumeData.dob === null ||
      resumeData.dob === "" ||
      errors.dob !== "" ||
      resumeData.gender === null ||
      resumeData.gender === ""
    ) {
      alert("Please fill all required fields in PersonalDetails section");
    } else if (
      resumeData.techskills &&
      resumeData.techskills.some((skl) => skl.techSkill) &&
      resumeData.skill &&
      resumeData.skill.some((skl) => skl.softSkill)
    ) {
      
    } else {
      setIsSaving(true);
      const response = await saveResumeApi();
      if (response.status === 201) {
        setDownloaded(true);
        setDownloadAvailable(true);
        setIsSaving(false);
      } else {
        alert("Error: Unable to save resume. Please try again.");
        setIsSaving(false);
      }
    }
  };

  const downloadPDF = () => {
    setDownloadAvailable(false);
  };

  return (
    <div className={classes.templatemain}>
      {/* {downloadAvailable ? (
        <PDFDownloadLink
          document={templates
            .filter((template) => template.name === selectedTemplate)
            .map((template) => template.component)}
          fileName={userData.first_name + userData.last_name}
        >
          {({ downloading, url, error, blob }) =>
            downloading ? (
              <button className={classes.download} disabled>
                Downloading document...
              </button>
            ) : (
              <button className={classes.download} disabled={isDownloading}>
                Download
              </button>
            )
          }
        </PDFDownloadLink>
      ) : (
        <>
          {isSaving ? (
            <button className={classes.download} disabled={isSaving}>
              Saving...
            </button>
          ) : (
            <button
              className={classes.download}
              onClick={savePDF}
              disabled={isSaving}
            >
              Save
            </button>
          )}
        </>
      )} */}

      <div className={classes.template}>
        <PDFViewer
          key={resumeData}
          showToolbar={false}
          style={styles.viewer}
          scrolling="no"
          wrap={false}
        >
          {templates
            .filter((template) => template.name === selectedTemplate)
            .map((selectedTemplate) => selectedTemplate.component)}
        </PDFViewer>
      </div>
      {/* <div >template</div> */}
      {/* <div className="bottom-0">prev</div> */}
    </div>
  );
};

export default ResumeTemplate;
