import React, { useContext, useEffect, useState } from "react";
import {
  Badge,
  Button,
  Col,
  Image,
  Row,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import classes2 from "./Candidate.module.css";
import ResumeImage from "../../../../../assets/resume-preview.png";
import CircularProgressBar from "./CircularProgressbar";
import AccordionHeader from "./AccordionHeader";
import { RecruiterCandidateListContext } from "../../../../../provider/RecruiterCandidateListContext";
import JobDetailsSkelton from "../../../../candidate/jobsearch/JobDetailsSkelton";
import { GrFormNextLink, GrFormPreviousLink } from "react-icons/gr";
import CustomSelect from "../../../../../ui/common/CustomSelect";
import CustomModal from "./CustomModal";
import { useLocation, useParams } from "react-router-dom";

const CandidateDetails = () => {
  const {
    jobs,
    candidateList,
    fetchCandidateList,
    loading,
    nextPageUrl,
    prevPageUrl,
  } = useContext(RecruiterCandidateListContext);

  const [showModal, setShowModal] = useState(false);
  const { jobId } = useParams();
  const location = useLocation();
  const { jobTitle } = location.state || {};
  console.log("JOb title", jobTitle);

  useEffect(() => {
    if (jobId) {
      fetchCandidateList(
        `${process.env.REACT_APP_BASE_URL}hirer/candidate/list_candidates/?job_id=${jobId}&page=1`
      );
    }
  }, [jobId]);

  // Handle next page click
  const handleNextPage = () => {
    if (nextPageUrl) {
      fetchCandidateList(
        `${process.env.REACT_APP_BASE_URL}hirer/candidate/list_candidates/?job_id=${jobId}&page=${nextPageUrl}`
      );
    }
  };

  const handlePrevPage = () => {
    if (prevPageUrl) {
      fetchCandidateList(
        `${process.env.REACT_APP_BASE_URL}hirer/candidate/list_candidates/?job_id=${jobId}&page=${prevPageUrl}`
      );
    }
  };

  // Handle more info button click
  const [candidateDetails, setCandidateDetails] = useState({});

  const handleMoreInfoClick = (details) => {
    setCandidateDetails(details);
    setShowModal(true);
  };

  return (
    <div style={{ marginTop: "5rem" }}>
      <>
        {loading ? (
          <JobDetailsSkelton />
        ) : (
          <>
            <h4 className="fw-bold p-3">
              {jobTitle ? (
                <>
                  <span className=" text-secondary fs-6">Candidates for: </span>
                  <span className=" fs-5 text-dark">{jobTitle}</span>
                </>
              ) : (
                <span style={{ color: "secondary" }}>Candidates</span>
              )}
            </h4>
            <Row className=" d-flex  justify-content-around align-items-center text-center px-3">
              {candidateList && candidateList.length > 0 ? (
                <>
                  <AccordionHeader />
                  {candidateList.map((candidate, index) => (
                    <Row
                      key={index}
                      className={
                        classes2.candiateDetails +
                        " d-flex py-4 mb-4 rounded-2 "
                      }
                    >
                      <Col lg={3} sm={2} className="">
                        <div className="d-flex ">
                          <div className=" d-flex align-items-center">
                            <div className="  rounded-5">
                              <Image
                                src={
                                  candidate.profile_image
                                    ? candidate.profile_image
                                    : "https://w7.pngwing.com/pngs/589/83/png-transparent-account-avatar-contact-people-profile-user-basic-icon.png"
                                }
                                alt="Badge"
                                className=" bg-white border  rounded-5 p-1"
                                style={{ width: "4rem", height: "4rem" }}
                              />
                            </div>
                          </div>
                          <div className=" d-flex flex-column text-start ps-2">
                            <div className="">
                              <h1
                                className=" fw-bold text-secondary mb-0 d-flex gap-2"
                                style={{ fontSize: "19px" }}
                              >
                                {candidate.first_name} {candidate.last_name}
                                <span>
                                  {candidate.matched && (
                                    <Badge bg="success">Best</Badge>
                                  )}
                                </span>
                              </h1>
                            </div>
                            <p
                              className=" fw-semibold text-secondary"
                              style={{ fontSize: "14px" }}
                            >
                              {candidate.profile_job_title}
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col lg={1} sm={6} className=" ">
                        <div
                          className="d-flex flex-column align-items-center justify-content-center "
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                          }}
                          onClick={() =>
                            window.open(candidate.resume_url, "_blank")
                          }
                        >
                          <Image
                            src={ResumeImage}
                            alt="Resume Preview"
                            className="img-fluid "
                            style={{
                              width: "35px",
                              cursor: "pointer",
                            }}
                          />
                          <small className=" mt-1" style={{ fontSize: "10px" }}>
                            Click to view
                          </small>
                        </div>
                      </Col>

                      <Col lg={6} sm={12} className=" ">
                        <Row className=" d-flex justify-content-center gap-3">
                          <Col
                            lg={2}
                            className={classes2.ProgressbarDiv}
                            style={{ width: 100 }}
                          >
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id={`tooltip-top`}>
                                  Skill Match Score: {candidate.skillMatchScore}
                                  %
                                </Tooltip>
                              }
                            >
                              <div>
                                <CircularProgressBar
                                  percentage={candidate.skillMatchScore}
                                />
                              </div>
                            </OverlayTrigger>
                          </Col>
                          <Col
                            lg={2}
                            className={classes2.ProgressbarDiv}
                            style={{ width: 100 }}
                          >
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id={`tooltip-top`}>
                                  Job Match Score: {candidate.jobDescMatchScore}
                                  %
                                </Tooltip>
                              }
                            >
                              <div>
                                <CircularProgressBar
                                  percentage={candidate.jobDescMatchScore}
                                />{" "}
                              </div>
                            </OverlayTrigger>
                          </Col>
                          <Col
                            lg={2}
                            className={classes2.ProgressbarDiv}
                            style={{ width: 100 }}
                          >
                            {" "}
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id={`tooltip-top`}>
                                  Keyword Match Score:{" "}
                                  {candidate.keywordMatchScore}%
                                </Tooltip>
                              }
                            >
                              <div>
                                <CircularProgressBar
                                  percentage={candidate.keywordMatchScore}
                                />{" "}
                              </div>
                            </OverlayTrigger>
                          </Col>
                          <Col
                            lg={2}
                            className={classes2.ProgressbarDiv}
                            style={{ width: 100 }}
                          >
                            {" "}
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id={`tooltip-top`}>
                                  Semantic Score: {candidate.semanticScore}%
                                </Tooltip>
                              }
                            >
                              <div>
                                <CircularProgressBar
                                  percentage={candidate.semanticScore}
                                />{" "}
                              </div>
                            </OverlayTrigger>
                          </Col>
                          <Col
                            lg={2}
                            className={classes2.ProgressbarDiv}
                            style={{ width: 100 }}
                          >
                            {" "}
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id={`tooltip-top`}>
                                  Weighted Score: {candidate.weightedScore}%
                                </Tooltip>
                              }
                            >
                              <div>
                                <CircularProgressBar
                                  percentage={candidate.weightedScore}
                                />
                              </div>
                            </OverlayTrigger>
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        lg={2}
                        sm={1}
                        className="rounded bg-info mt-lg-0 mt-3"
                      >
                        <div className="d-flex justify-content-between align-items-center p-1 py-2">
                          <div
                            className="d-flex flex-column align-items-start justify-content-center fw-semibold gap-1"
                            style={{ fontSize: "14px" }}
                          >
                            <div className="text-black">Exam status:</div>
                            <div
                              className={
                                candidate.exam_score?.result_status === "Passed"
                                  ? "text-success"
                                  : candidate.exam_score?.result_status ===
                                    "Failed"
                                  ? "text-danger"
                                  : candidate.exam_score?.result_status ===
                                    "Pending"
                                  ? "text-white"
                                  : candidate.exam_score?.result_status ===
                                    "Not scheduled"
                                  ? "text-white"
                                  : !candidate.exam_score
                                  ? "text-danger"
                                  : ""
                              }
                            >
                              {!candidate.exam_score
                                ? "Not found"
                                : candidate.exam_score.result_status}
                            </div>
                          </div>
                          <div>
                            <Button
                              className="btn btn-primary border-0 px-2 py-2 "
                              onClick={() => handleMoreInfoClick(candidate)}
                              disabled={
                                !candidate.exam_score ||
                                candidate.exam_score?.result_status ===
                                  "Pending" ||
                                candidate.exam_score?.result_status ===
                                  "Not scheduled"
                              }
                            >
                              More Info
                            </Button>
                          </div>
                        </div>
                      </Col>

                      <CustomModal
                        showModal={showModal}
                        setShowModal={setShowModal}
                        candidateDetails={candidateDetails}
                      />
                    </Row>
                  ))}
                </>
              ) : (
                <div className=" py-5 fw-semibold ">No candidates found!!!</div>
              )}
            </Row>
          </>
        )}
      </>

      {nextPageUrl || prevPageUrl ? (
        <div className="d-flex justify-content-center">
          <button
            className={`border-end-0 rounded-end-0 rounded-3 px-2 py-2 btn d-flex align-items-center gap-1 fw-semibold ${
              !prevPageUrl ? "btn-outline-primary" : "btn-primary"
            } ${prevPageUrl && nextPageUrl ? "border-white" : ""}`}
            onClick={handlePrevPage}
            disabled={!prevPageUrl || loading}
          >
            <GrFormPreviousLink className=" fs-5 fw-bolder bg-white text-primary rounded-5" />{" "}
            Previous
          </button>

          <button
            className={`rounded-start-0 rounded-3 px-3 py-2 p-3 btn d-flex align-items-center gap-1  fw-semibold  ${
              !nextPageUrl ? "btn-outline-primary" : "btn-primary"
            } ${prevPageUrl && nextPageUrl ? "border-white" : ""}`}
            onClick={handleNextPage}
            disabled={!nextPageUrl || loading}
          >
            Next{" "}
            <GrFormNextLink className=" fs-5 fw-bolder bg-white text-primary rounded-5 " />
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default CandidateDetails;
