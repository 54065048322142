import React from "react";
import classes from "./ResumeFormData.module.css";
import { Row, Col } from "react-bootstrap";
import TagsInput from "../../resumeUI/TagsInput";
import { useResumeBuilderContext } from "../../../../provider/ResumeBuilderContext";
import AccordionField from "../../resumeUI/AccordionField";

const Certifications = () => {
  const { resumeData, handleResumeFormChange } = useResumeBuilderContext();

  const handleSelectedTags = (tags) => {
    const updatedResumeData = {
      ...resumeData,
      certification: tags.map((tag) => ({ certification: tag })),
    };

    handleResumeFormChange({
      target: {
        name: "certification",
        value: updatedResumeData.certification,
      },
    });
  };

  const handleTagRemoval = (indexToRemove) => {
    const updatedTags = resumeData.certification.filter(
      (_, index) => index !== indexToRemove
    );

    handleResumeFormChange({
      target: {
        name: "certification",
        value: updatedTags,
      },
    });
  };
  return (
    <div>
      <AccordionField
        heading="Certifications"
        subheading="Add certifications which brings an extra layer of expertise that boost your skills"
      >
          <div>
            <Row>
              <Col>
                <TagsInput
                  selectedTags={handleSelectedTags}
                  handleTagRemoval={handleTagRemoval}
                  tags={
                    resumeData.certification.some(
                      (cert) => cert.certification !== null
                    )
                      ? resumeData.certification.map(
                          (cert) => cert.certification
                        )
                      : []
                  }
                  placeholder="+ Add certification and press enter"
                />
              </Col>
            </Row>
          </div>
      </AccordionField>
    </div>
  );
};

export default Certifications;
