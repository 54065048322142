import React, { useState } from "react";
import CustomSelect from "./CustomSelect";
import { BsBuildings } from "react-icons/bs";
import { LuIndianRupee } from "react-icons/lu";
import { SlCalender } from "react-icons/sl";
import { Row, Col } from "react-bootstrap";
import {
  jobtypefilter,
  workmodeFilter,
  salary,
  experience,
} from "../../../data/JobData";
import { useJobContext } from "../../../provider/JobContext";
import CheckboxRadio from "../../../ui/common/CheckboxRadio";
import SearchSelect from "../../../ui/common/SearchSelect";

const AdvanceFilter = () => {
  const {
    handleChangeFilter,
    filters,
    handleCheckboxChange,
    jobsCount,
    searchFilters,
    fetchExfilters,
    setSearchFilters,
    selectedJob,
    fetchJobs,
    fetchJobsExt,fetchfilters
  } = useJobContext();
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState({
    company_name: [],
  });
  const handleInputChange = async (e) => {
    const { value, name } = e.target;

    setSearchFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));

    if (value.length >= 1) {
      setLoading(true);
      const response = selectedJob==="E" ? await fetchfilters(value, name):await fetchExfilters(value, name);
      setSuggestions((prevSuggestions) => ({
        ...prevSuggestions,
        [name]: response,
      }));
      setLoading(false);
    } else {
      setSuggestions((prevSuggestions) => ({
        ...prevSuggestions,
        [name]: [],
      }));
    }
  };

  const handleOptionSelect = (option, name) => {
    console.log("000000000000000000", option);
    setSearchFilters((prevFilters) => ({
      ...prevFilters,
      [name]: option,
    }));
    setSuggestions((prevSuggestions) => ({
      ...prevSuggestions,
      [name]: [],
    }));
  };

  console.log("search:__________", searchFilters);
  return (
    <div className="mt-4">
      {/* <ReactSlider
        className="horizontal-slider"
        thumbClassName="example-thumb"
        trackClassName="example-track"
        defaultValue={[0, 100]}
        ariaLabel={["Lower thumb", "Upper thumb"]}
        ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
        renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
        pearling
        minDistance={10}
      /> */}
      <Row
        style={{ margin: "0px 1px" }}
        className="d-flex align-items-center border justify-content-center mt-3  py-2 w-100 "
      >
        <Col lg={1}>
          <BsBuildings className="text-secondary mb-1" />
        </Col>
        <Col lg={11}>
          <SearchSelect
            name="company_name"
            placeholder="Search Company.."
            value={searchFilters.company_name}
            handleChange={handleInputChange}
            options={suggestions.company_name}
            onOptionSelect={(option) =>
              handleOptionSelect(option, "company_name")
            }
            loading={loading}
          />
        </Col>
      </Row>
      {selectedJob === "E" && (
        <Row
          style={{ margin: "0px 1px" }}
          className="d-flex align-items-center border justify-content-center mt-5  py-2 w-100 "
        >
          <Col lg={1}>
            <SlCalender className="text-secondary mb-1" />
          </Col>
          <Col lg={11}>
            <CustomSelect
              options={experience}
              handleChange={handleChangeFilter("experience")}
              selectedvalue={filters.experience}
              fontSize="14px"
            />
          </Col>
        </Row>
      )}
      <CheckboxRadio
        title="Job type"
        filters={jobtypefilter}
        selectedValue={filters.jobType.value}
        handleChange={handleCheckboxChange("jobType")}
        count={jobsCount}
      />
      {selectedJob === "E" && (
        <Row
          style={{ margin: " 0px 1px" }}
          className="d-flex align-items-center border justify-content-center   py-2 w-100 mt-4"
        >
          <Col lg={1}>
            <LuIndianRupee className="text-secondary mb-1" />
          </Col>
          <Col lg={11}>
            <CustomSelect
              options={salary}
              handleChange={handleChangeFilter("salary")}
              selectedvalue={filters.salary}
              fontSize="14px"
            />
          </Col>
        </Row>
      )}
      <CheckboxRadio
        title="Work Mode"
        filters={workmodeFilter}
        selectedValue={filters.workMode.value}
        handleChange={handleCheckboxChange("workMode")}
        count={jobsCount}
      />
    </div>
  );
};

export default AdvanceFilter;
