import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuthContext } from "../../provider/AuthContext";
import { url } from "../../data/Constants";
import Loading from "../../ui/common/Loading";
import ChooseUserSSO from "./ChooseUserSSO";
const GoogleSSO = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [load, setLoad] = useState(false);
  const { setUserData } = useAuthContext();

  const code = new URLSearchParams(location.search).get("code");
  const authurl = `${url}auth/sso/google/callback/?code=${code}`;

  useEffect(() => {
    setLoad(true);

    fetch(authurl)
      .then((response) => {
        console.log("data to be fetched ", response);

        if (!response.ok) {
          console.log("error:", response.json());
          navigate("/");
        }

        return response.json().then((data) => ({ response, data }));
      })
      .then(({ response, data }) => {
        console.log(response)
        if (data) {
          console.log(data);
          localStorage.setItem("userData", JSON.stringify(data));
          localStorage.setItem("token", data.token);
          localStorage.setItem("userid", data.user_info.user_id);
          localStorage.setItem("profile", data.user_info.profile);
          setUserData(JSON.stringify(data));

          const role = data.user_info.role;
          const profile = data.user_info.profile;


          if (response.status === 200) {
            localStorage.setItem("role", data.user_info.role);
            localStorage.setItem("userid", data.user_info.user_id);

            if (role === "C") {
              navigate("/jobs");
            } else if (role === "H") {
              if (profile === null) {
                navigate("/get-started");
              } else {
                navigate("/company/profile");
              }
            }
          } else if (response.status === 201) {
            navigate("/chooseuser");
          }

          setLoad(false);
        }
      })
      .catch((error) => {
        console.log("Error during fetch:", error);
        alert("Email id already in use/please continue with login");
        navigate("/signin");
        setLoad(false);
      });
  }, [location.search]);

  return <>{load === true ? <Loading /> : <ChooseUserSSO />}</>;
};

export default GoogleSSO;
