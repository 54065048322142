import React from "react";
import TeaxtArea from "../resumeUI/TeaxtArea";
import Input from "../resumeUI/Input";
import classes1 from "../resumeUI/ScoreCalculator.module.css";
import { Form, Spinner } from "react-bootstrap";
import { FaArrowRotateLeft } from "react-icons/fa6";

const ResumeScoreForm = ({
  selected,
  inpdisabled,
  handleScoreChange,
  urlError,
  scoreData,
  scoreLoading,
  showScoreType,
  onCheckScoreAgain,
  onCheckScore,
  setSelected,
  setScoreData,
}) => {
  const handleRadioChange = (event) => {
    setSelected(event.target.value);
    setScoreData((prevFormData) => ({
      ...prevFormData,
      scoreType: event.target.value,
    }));
  };

  return (
    <div style={{ margin: "2rem 0rem"}}>
      <div key={`inline-radio`}>
        <Form.Check
          style={{ fontSize: "15px" }}
          inline
          label="Url"
          name="scoreType"
          type="radio"
          id="LINK"
          value="LINK"
          onChange={handleRadioChange}
          checked={selected === "LINK"}
          disabled={inpdisabled}
        />
        <Form.Check
          style={{ fontSize: "15px" }}
          inline
          label="Description"
          name="scoreType"
          type="radio"
          id="DESC"
          value="DESC"
          onChange={handleRadioChange}
          checked={selected === "DESC"}
          disabled={inpdisabled}
        />
      </div>

      {selected === "LINK" ? (
        <div className="pe-lg-5 pe-sm-0">
          <Input
            text="Paste url of the job with description"
            type="text"
            name="link"
            placeholder="https://"
            onChange={handleScoreChange}
            disabled={inpdisabled}
            value={scoreData.link}
          />

          {urlError === "" ? (
            <div className="text-secondary" style={{ fontSize: "12px" }}>
              Example: dice.com/jobs/company/position
            </div>
          ) : (
            <div className="text-danger" style={{ fontSize: "12px" }}>
              {urlError}
            </div>
          )}

          {scoreLoading && showScoreType === "" ? (
            <button
              className={classes1.scoreBtn}
              disabled={scoreData.link === null || urlError !== ""}
            >
              <span>
                Calculating Score
                <span className="ps-1">
                  <Spinner size="sm" />
                </span>
              </span>
            </button>
          ) : !scoreLoading && showScoreType === "" ? (
            <button
              className={classes1.scoreBtn}
              disabled={scoreData.link === null || urlError !== ""}
              onClick={onCheckScore}
            >
              <span>Get Your Score</span>
            </button>
          ) : (!scoreLoading && showScoreType === "score") ||
            showScoreType === "error" ? (
            <button
              className={classes1.scoreBtn}
              disabled={scoreData.link === null || urlError !== ""}
              onClick={onCheckScoreAgain}
            >
              <span>
                <FaArrowRotateLeft />
                Try Again
              </span>
            </button>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div className="pe-md-5 pe-sm-0">
          <TeaxtArea
            rows="7"
            text="Paste the detailed description of the job"
            type="text"
            name="jobDesc"
            onChange={handleScoreChange}
            disabled={inpdisabled}
            value={scoreData.jobDesc}
          />

          {scoreLoading && showScoreType === "" ? (
            <button
              className={classes1.scoreBtn}
              disabled={scoreData.jobDesc === null}
            >
              <span>
                Calculating Score
                <span className="ps-1">
                  <Spinner size="sm" />
                </span>
              </span>
            </button>
          ) : !scoreLoading && showScoreType === "" ? (
            <button
              className={classes1.scoreBtn}
              disabled={scoreData.jobDesc === null}
              onClick={onCheckScore}
            >
              <span>Get Your Score</span>
            </button>
          ) : (!scoreLoading && showScoreType === "score") ||
            showScoreType === "error" ? (
            <button
              className={classes1.scoreBtn}
              disabled={scoreData.jobDesc === null || urlError !== ""}
              onClick={onCheckScoreAgain}
            >
              <span>
                <FaArrowRotateLeft />
                Try Again
              </span>
            </button>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
};

export default ResumeScoreForm;
