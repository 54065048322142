import React, { useState, useEffect } from "react";
import classes from "../Home.module.css";
import noresume from "../../../assets/Noresume.svg";
import { Container, Pagination, Row, Col } from "react-bootstrap";
import JobCard from "../../candidate/jobsearch/JobCard";
import JobCardskelton from "../../candidate/jobsearch/JobCardskelton";
import { useAuthContext } from "../../../provider/AuthContext";

const LatestJobs = () => {
  const {
    latestJobsFetch,
    totalPage,
    setTotalPage,
    currentPage,
    setCurrentPage,
    isLoading,
    latestJob,
  } = useAuthContext();

  console.log(latestJob);

  useEffect(() => {
    latestJobsFetch(1);
  }, []);

  const renderPaginationItems = () => {
    const totalPages = Math.ceil(totalPage / 12);
    const maxPagesToShow = 5;
    const items = [];

    if (totalPages <= maxPagesToShow) {
      for (let number = 1; number <= totalPages; number++) {
        items.push(
          <Pagination.Item
            key={number}
            active={number === currentPage}
            onClick={() => handlePageChange(number)}
          >
            {number}
          </Pagination.Item>
        );
      }
    } else {
      let startPage = currentPage - Math.floor(maxPagesToShow / 2);
      let endPage = currentPage + Math.floor(maxPagesToShow / 2);

      if (startPage < 1) {
        startPage = 1;
        endPage = maxPagesToShow;
      }

      if (endPage > totalPages) {
        endPage = totalPages;
        startPage = totalPages - maxPagesToShow + 1;
      }

      if (startPage > 1) {
        items.push(
          <Pagination.Item
            key={1}
            active={currentPage === 1}
            onClick={() => handlePageChange(1)}
          >
            1
          </Pagination.Item>
        );
        items.push(<Pagination.Ellipsis key="start-ellipsis" />);
      }

      for (let number = startPage; number <= endPage; number++) {
        items.push(
          <Pagination.Item
            key={number}
            active={number === currentPage}
            onClick={() => handlePageChange(number)}
          >
            {number}
          </Pagination.Item>
        );
      }

      if (endPage < totalPages) {
        items.push(<Pagination.Ellipsis key="end-ellipsis" />);
        items.push(
          <Pagination.Item
            key={totalPages}
            active={currentPage === totalPages}
            onClick={() => handlePageChange(totalPages)}
          >
            {totalPages}
          </Pagination.Item>
        );
      }
    }

    return items;
  };

  const handlePageChange = (pageNumber) => {
    const targetElement = document.getElementById("latestjobs");
    if (targetElement) {
      const targetPosition =
        targetElement.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = targetPosition - 100;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
    setCurrentPage(pageNumber);
    latestJobsFetch(pageNumber);
  };

  return (
    <section className={classes.sections} id="latestjobs">
      <Container>
        <div className="text-center">
          <h2 className={classes.sectionheading + " mb-2  text-dark "}>
            Jobs of the day
          </h2>
          <div className={classes.normaltext + " text-secondary"}>
            Discover today's Top Job Opportunities{" "}
          </div>
        </div>
        <div className={classes.latestjobs + "  mt-5"}>
          {totalPage <= 0 && !isLoading ? (
            <Row className="text-center vh-100">
              <Col className="d-flex  align-items-center flex-column justify-content-center">
                <img
                  src={noresume}
                  alt="noresumes"
                  width={500}
                  className="img-fuid"
                />
                <div className="fw-semibold fs-6">
                  Uh.. Oh no jobs available at the moment
                </div>
              </Col>
            </Row>
          ) : (
            <div>
              {isLoading ? (
                <Row>
                  {[...Array(10)].map((_, index) => (
                    <Col xl={3} lg={3} md={6} sm={12} xs={12} key={index}>
                      <JobCardskelton />
                    </Col>
                  ))}
                </Row>
              ) : (
                <Row>
                  {latestJob.map((job) => (
                    <Col xl={3} lg={3} md={6} sm={12} xs={12} key={job.id}>
                      <JobCard key={job.id} job={job} section="latestjobs" />
                    </Col>
                  ))}
                </Row>
              )}
            </div>
          )}

          <Pagination className="custom-pagination">
            <Pagination.Prev
              className="custom-page-prev-next"
              onClick={() =>
                handlePageChange(currentPage > 1 ? currentPage - 1 : 1)
              }
              disabled={currentPage === 1}
            />
            {renderPaginationItems()}
            <Pagination.Next
              className="custom-page-prev-next"
              disabled={currentPage === Math.ceil(totalPage / 10)}
              onClick={() =>
                handlePageChange(
                  currentPage < Math.ceil(totalPage / 10)
                    ? currentPage + 1
                    : currentPage
                )
              }
            />
          </Pagination>
        </div>
      </Container>
    </section>
  );
};

export default LatestJobs;
