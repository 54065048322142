import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import classes from "../Screens.module.css";
import RecruiterHeader from "../../../layouts/RecruiterHeader";
import TabContainer from "./TabContainer";
import { useRecruiterProfileContext } from "../../../../../provider/RecruiterProfileContext";

const EditProfile = () => {
  const { activeTab, handleTabChange, fetchRecruiterProfile } =
    useRecruiterProfileContext();

  // const [profileData, setProfileData] = useState(null);
  // const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(null);
  // const [location, setLocation] = useState();

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const data = await fetchRecruiterProfile();
  //       setProfileData(data.results);
  //       localStorage.setItem("HirerProfileId", data.results[0].id);
  //       console.log("HirerProfileId", data.results[0].id);
  //       setLoading(false);
  //       setLocation(data.results[0]?.company_location || "");
  //     } catch (error) {
  //       setError(error);
  //       setLoading(false);
  //     }
  //   };

  //   fetchData();
  // }, [fetchRecruiterProfile]);

  return (
    <>
      <RecruiterHeader />
      <Container
        style={{ backgroundColor: "#F2F6FD", marginTop: "5rem" }}
        className=" py-4  rounded"
      >
        <div>
          <div className="d-flex align-items-center ">
            <h2 className={classes.companyName + " px-3 text-start"}>
              Update your Profile
            </h2>
          </div>
          <TabContainer
            activeTab={activeTab}
            handleTabChange={handleTabChange}
          />{" "}
        </div>
      </Container>
    </>
  );
};

export default EditProfile;
