import React, { useState } from "react";
import ProfileModal from "../ProfileModal";
import FormInput from "../../../../ui/common/FormInput";
import { Row, Col, Form, InputGroup } from "react-bootstrap";
import MonthPickerDate from "../../../resumeBuilder/resumeUI/MonthPickerDate";

const EducationProfileForm = (props) => {
  // console.log(present)
  return (
    <ProfileModal
      show={props.show}
      handleClose={props.handleClose}
      handleSubmitChange={props.add}
      formtype={props.formtype}
    >
      <Row>
        <Col>
          <FormInput
            name="institution"
            type="text"
            placeholder="ex. ABC University"
            label="Institution"
            value={props.data.institution}
            onChange={props.handleChange}
            error={props.errors.institution}
            className="mb-3"
          />
        </Col>
      </Row>
      <Row>
        <Col lg={8} xs={12}>
          <FormInput
            name="course"
            type="text"
            placeholder="ex. Btech  CS"
            label="Course"
            value={props.data.course}
            onChange={props.handleChange}
            error={props.errors.course}
            className="mb-3"
          />
        </Col>
        <Col>
          <Form.Label className="ms-1 text-muted">Proficiency</Form.Label>
          <InputGroup hasValidation>
            <Form.Control
              name="proficiency"
              type="text"
              placeholder="6.8"
              label="Proficiency"
              value={props.data.proficiency}
              onChange={props.handleChange}
              disabled={props.disabled}
              isInvalid={!!props.errors.proficiency}
            />
            <Form.Select
              style={{ fontSize: "14px" }}
              name="proficiency_type"
              value={props.data.proficiency_type}
              onChange={props.handleChange}
            >
              <option value="">Select</option>
              <option value="G">GPA</option>
              <option value="P">%</option>
            </Form.Select>
            <Form.Control.Feedback
              type="invalid"
              style={{
                textAlign: "left",
                color: "red",
                fontSize: "11px",
              }}
            >
              {props.errors.proficiency}
            </Form.Control.Feedback>
          </InputGroup>
        </Col>
      </Row>
      <Row className="d-flex align-items-center">
        <Col lg={4} xs={5}>
          <MonthPickerDate
            type="text"
            name="startDate"
            placeholder="Start Date"
            value={props.data.startDate}
            text="start date"
            font="12px"
            onChange={props.handleChange}
            error={props.errors.startDate}
          />
        </Col>
        <Col lg={1} xs={2} className="mt-4 text-center">
          <span style={{ fontSize: "14px" }} className="fw-semibold">
            to
          </span>
        </Col>
        {!props.present && props.formtype === "add" && (
          <Col lg={4} xs={5}>
            <MonthPickerDate
              name="endDate"
              placeholder="End Date"
              type="text"
              text="end date"
              font="12px"
              value={props.data.endDate}
              onChange={props.handleChange}
              error={props.errors.endDate}
            />
          </Col>
        )}
        {props.data.present === false && props.formtype === "edit" && (
          <Col lg={4} xs={5}>
            <MonthPickerDate
              name="endDate"
              placeholder="End Date"
              type="text"
              text="end date"
              font="12px"
              value={props.data.endDate}
              onChange={props.handleChange}
              // disabled={data.present === true}
              error={props.errors.endDate}
            />
          </Col>
        )}

        <Col xs={3} lg={3} style={{ fontSize: "14px" }}>
          {props.formtype === "add" ? (
            <Form.Check
              className="mt-4"
              checked={props.data.present}
              value={!props.present}
              inline
              label="Currently work here"
              name="present"
              type="checkbox"
              onChange={props.onChangeHandler}
            />
          ) : (
            <Form.Check
              className="mt-4"
              checked={props.data.present}
              value={props.data.present}
              inline
              label="Currently work here"
              name="present"
              type="checkbox"
              onChange={props.onChangeHandler}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col lg={4} xs={5}>
          <span style={{ fontSize: "11px" }} className="text-danger">
            {props.errors.startDate}
          </span>
        </Col>
        <Col lg={1} xs={2}></Col>
        {!props.present && (
          <Col lg={4} xs={5}>
            <span style={{ fontSize: "11px" }} className="text-danger">
              {props.errors.endDate}
            </span>
          </Col>
        )}
        <Col xs={3} lg={3}></Col>
      </Row>
    </ProfileModal>
  );
};

export default EducationProfileForm;
