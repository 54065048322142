import React from "react";
import classes from "../../components/candidate/jobsearch/Jobsearch.module.css";
import { Row, Col, Form, Badge } from "react-bootstrap";

const CheckboxRadio = ({
  title,
  filters,
  selectedValue,
  handleChange,
  count,
}) => {
  const handleCheckboxChange = (item) => {
    if (selectedValue === item.value) {
      handleChange({ value: "", label: "" }); 
    } else {
      handleChange(item); 
    }
  };

  return (
    <div className="border-bottom py-3">
      <h5
        className="fw-bold mt-lg-4 mt-md-3 text-dark"
        style={{ fontSize: "20px" }}
      >
        {title}
      </h5>
      {filters.map((item) => (
        <Row key={item.value} className="d-flex align-items-center py-2">
          <Col lg={1} sm={1} xs={1}>
            <Form.Check
              style={{ fontSize: "20px", fontWeight: "500" }}
              type="checkbox"
              id={item.value}
              name="workMode"
              value={item.value}
              checked={selectedValue === item.value}
              onChange={() => handleCheckboxChange(item)}
            />
          </Col>
          <Col lg={9} sm={9} xs={9}>
            <label className={classes.description + " ms-2"}>
              {item.label}
            </label>
          </Col>
          {/* <Col lg={2} sm={2} xs={2}>
            <Badge bg="info" className="fw-normal text-primary">
              {selectedValue === item.value ? count : 0}
            </Badge>
          </Col> */}
        </Row>
      ))}
    </div>
  );
};

export default CheckboxRadio;

