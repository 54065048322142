export const validateEmail = (email) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;
  if (!email) {
    return "This field  is required";
  }
  return emailRegex.test(email);
};


export const validatepassword = (pw) => {
  const pwRegex = /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$/;

  if (!pw) {
    return "This field is required";
  }

  if (!pwRegex.test(pw)) {
    return false;
  }
  return true;
};

export const validatePhone = (phone) => {
  if (!phone) {
    return "This field is required";
  }

  return /^\+?\d{10,15}$/.test(phone);
};

export const validateusername = (value) => {
  if (value.length < 4) {
    return false;
  }
  return true;
};

export const validateFiled = (value) => {
  if (!value) {
    return "This field  is required";
  }
};

export const validateAddress = (value) => {
  if (value.length > 70) {
    return false;
  }
  return true;
};

export const validateDate = (date) => {
  if (!date) {
    return "This field  is required";
  }
  const today = new Date();
  const inputDate = new Date(date);
  const age = today.getFullYear() - inputDate.getFullYear();

  if (age < 10) {
    return false;
  }
  if (isNaN(inputDate.getTime())) {
    return false;
  }
  return true;
};

export const validateUrl = (value) => {
  const urlRegex =
    /^(https:\/\/|www\.)[a-zA-Z0-9-]+(\.[a-zA-Z0-9]+)+([/?].*)?$/;
  return urlRegex.test(value);
};

const allowedDomains = [
  "google.com",
  "outlook.com",
  "hotmail.com",
  "aol.com",
  "gmail.com",
];

export const isRecruiterEmailValid = (email) => {
  const domain = email.split("@")[1];
  if (allowedDomains.includes(domain)) {
    return false;
  }
  return true;
};

export const replaceUrl = (url, oldPart, newPart) => {
  return url.replace(oldPart, newPart);
};

export const replacerole = (role) => {
  const newRole = role === "H" ? "Hirer" : "Candidate";
  return newRole;
};

export const formatDateOfBirth = (dob) => {
  const originalDate = new Date(dob);
  return `${originalDate.getDate().toString().padStart(2, "0")}-${(
    originalDate.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${originalDate.getFullYear()}`;
};

export const formatDates = (dateString) => {
  const [year, month] = dateString.split("-");
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const monthIndex = parseInt(month, 10) - 1;
  return `${monthNames[monthIndex]} ${year}`;
};

export const toCamelCase = (inputString) => {
  return inputString
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};


export const checkdatevalidation = (startdate, enddate) => {
  let newErrors = {};
  if (
    new Date(startdate) > new Date(enddate) ||
    new Date(enddate) < new Date(startdate)
  ) {
    newErrors = {
      endDate: "End date must be greater than start date",
    };
  } else {
    newErrors = {
      endDate: "",
    };
  }
  return newErrors;
};

export const validateStartDate = (startDate, endDate, today) => {
  if (endDate) {
    return checkdatevalidation(startDate, endDate);
  } else if (new Date(startDate) > today) {
    return { startDate: "Start date must not exceed today's date." };
  }
  return { startDate: "" };
};

export const validateEndDate = (startDate, endDate, today) => {
  if (startDate) {
    return checkdatevalidation(startDate, endDate);
  } else if (new Date(endDate) > today) {
    return { endDate: "End date must not exceed today's date." };
  }
  return { endDate: "" };
};

export const validateProficiency = (value, proficiencyType) => {
  let validatedValue = value;
  if (proficiencyType === "P") {
    if (validatedValue < 0 || validatedValue > 100) {
      return { proficiency: "Percentage must be between 0 and 100." };
    }
  } else if (proficiencyType === "G") {
    if (validatedValue < 0 || validatedValue > 10) {
      return { proficiency: "GPA must be between 0 and 10." };
    }
  } else {
    return {
      proficiency: "select the proficiency type",
    };
  }
};
