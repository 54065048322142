import React from "react";
import { Container } from "react-bootstrap";
import AccordionSection from "./CandidateDetails";
import RecruiterHeader from "../../../layouts/RecruiterHeader";

const Candidates = () => {
  return (
    <Container >
      <RecruiterHeader />
      <AccordionSection />
    </Container>
  );
};

export default Candidates;
