import React, { useState, useEffect } from "react";
import { useResumeListContext } from "../../../provider/ResumeListContext";
import classes from "./ResumeList.module.css";
import { Row, Col, Button, Tab, Nav, Spinner, Image } from "react-bootstrap";
import { FiUpload } from "react-icons/fi";
import {
  FaTrashAlt,
  FaDotCircle,
  FaExternalLinkAlt,
  FaFilePdf,
  FaRegCircle,
} from "react-icons/fa";
import { FaExpand } from "react-icons/fa6";
import { IoCheckmarkCircle } from "react-icons/io5";
import noresume from "../../../assets/Noresume.svg";
import ResumeListLoader from "./ResumeListLoader";
import { replaceUrl } from "../../../utils/Validation";
import { Image_click_url } from "../../../data/Constants";
import resume from "../../../assets/templateresume.png";
import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFJS = window.pdfjsLib;

const UploadResume = ({ resumeFiles, fetchData }) => {
  const [renderedImages, setRenderedImages] = useState({});

  useEffect(() => {
    resumeFiles.forEach((res) => {
      fetchFirstPageImage(res.cv, res.id);
    });
  }, [resumeFiles]);

  const fetchFirstPageImage = async (pdfUrl, id) => {
    try {
      const loadingTask = pdfjs.getDocument(pdfUrl);
      const pdf = await loadingTask.promise;
      const page = await pdf.getPage(1);
      const scale = 1.5;
      const viewport = page.getViewport({ scale });
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };
      await page.render(renderContext).promise;
      const img = canvas.toDataURL("image/png");
      setRenderedImages((prev) => ({ ...prev, [id]: img }));
    } catch (error) {
      console.error("Error rendering PDF page:", error);
    }
  };

  const {
    handleResumeUpload,
    handleDeleteResume,
    isUploading,
    handleActiveResume,
    isLoading,
    isDeleting,
    activating,
  } = useResumeListContext();

  const [uploadedResumeName, setUploadedResumeName] = useState("");
  const [responseStatus, setResponseStatus] = useState("");
  const [activatingResumeId, setActivatingResumeId] = useState(null);
  const [deletingResumeId, setDeletingResumeId] = useState(null);

  const handleResumeUploadChange = async (e) => {
    setResponseStatus("");
    try {
      const response = await handleResumeUpload(e);
      if (response && response.status === 201) {
        console.log(response)
        setUploadedResumeName(response.data.resumeName);
        setResponseStatus("succes");
        fetchData();
      } else {
      }
    } catch (error) {
      setResponseStatus("error");
      console.log(error);
      if (error.message === "Network Error") {
        alert("Network error!");
        setResponseStatus("networkerror");
      } else if (error.response && error.response.status === 400) {
        alert(error.response.data.error);
        setResponseStatus("limiterror");
      }
    }
  };

  const handleResumeDelete = async (resume) => {
    setDeletingResumeId(resume.id);
    try {
      const response = await handleDeleteResume(resume);
      // console.log(response);
    } catch (error) {}
  };

  const handleResumeActive = async (resume) => {
    setActivatingResumeId(resume.id);
    try {
      const response = await handleActiveResume(resume);
      // console.log(response);
    } catch (error) {}
  };

  const handleResumeView = (resume) => {
    window.open(resume, "_blank");
  };

  return (
    <>
      {isLoading ? (
        <ResumeListLoader />
      ) : (
        <Row className=" pb-5 lg-3 mt-lg-0">
          <Col lg={4} md={12} sm={12} xs={12} className="px-lg-4">
            <div className={classes.fileupload}>
              <div
                className={
                  classes.fileselect +
                  " d-flex align-items-center justify-content-center"
                }
              >
                {!isUploading ? (
                  <div className="text-center">
                    <FiUpload className="fs-1 text-primary my-3 fw-bold" />
                    <div
                      className="px-4 mb-3 fw-semibold"
                      style={{ fontSize: "16px", color: "#04364A" }}
                    >
                      Drop your files here
                      <br /> or
                      <br />
                      <Button
                        className="mt-2 px-5"
                        style={{ borderRadius: "50px", fontWeight: "500" }}
                        size="lg"
                      >
                        Browse
                      </Button>
                    </div>
                    <div className="text-muted" style={{ fontSize: "14px" }}>
                      Supported files: PDF only
                    </div>
                    <div
                      className={classes.chosen + " fw-semibold"}
                      id="noFile"
                    ></div>
                  </div>
                ) : (
                  <>
                    <div className="d-flex align-items-center flex-column justify-content-center">
                      <div className="fw-semibold fs-6">Uploading...</div>
                      <div className="my-1" style={{ fontSize: "14px" }}>
                        It usually takes time please wait !
                      </div>
                      <div className={classes.loader + ""}></div>
                    </div>
                    {/* {!isUploading && responseStatus === "success" && (
                      <div className="text-center">
                        <div className="fw-semibold">Upload Complete</div>
                        <Button>Done</Button>
                      </div>
                    )}
                    {!isUploading && responseStatus === "error" && (
                      <div className="text-center">
                        <div className="fw-semibold">
                          Uh oh ... something went wrong!
                        </div>
                        <Button>OK</Button>
                      </div>
                    )} */}
                  </>
                )}

                <input
                  type="file"
                  name="chooseFile"
                  id="chooseFile"
                  placeholder="upload resume only"
                  onChange={handleResumeUploadChange}
                  disabled={isUploading === true}
                  accept="application/pdf"
                />
              </div>
            </div>
          </Col>

          <Col lg={8} md={12} sm={12} xs={12} className="px-4">
            <h5 className="fw-bold mb-4  pb-3 mt-4 mt-lg-0 text-primary">
              RECENT UPLOADS
              <div className={classes.underline} />
            </h5>
            {resumeFiles && resumeFiles.length > 0 ? (
              <div cl>
                <Row>
                  {resumeFiles.map((res) => (
                    <Col lg={4} sm={6} xs={12} key={res.id}>
                      <div className="d-flex flex-column align-items-center justify-content-center">
                        <div >
                          <div className="d-flex align-items-center mb-2 justify-content-end">
                            {res.active_res ? (
                              <span
                                className="text-success fw-semibold d-flex align-items-center"
                                style={{
                                  fontSize: "13px",
                                }}
                              >
                                <IoCheckmarkCircle className="text-success fs-4 me-1" />
                                ACTIVE
                              </span>
                            ) : (
                              <div>
                                {activatingResumeId === res.id && activating ? (
                                  <span className="text-success">
                                    <Spinner
                                      size="sm"
                                      className="text-success"
                                    />
                                    Activating..
                                  </span>
                                ) : (
                                  <span
                                    className="text-danger fw-semibold d-flex align-items-center"
                                    style={{
                                      fontSize: "13px",
                                    }}
                                    onClick={() => handleResumeActive(res)}
                                  >
                                    <FaRegCircle className="fs-5 text-danger me-1" />
                                    INACTIVE
                                  </span>
                                )}
                              </div>
                            )}
                            <div>
                              {deletingResumeId === res.id && isDeleting ? (
                                <span
                                // onClick={() => handleResumeDelete(res)}
                                // style={{ cursor: "pointer" }}
                                >
                                  <Spinner
                                    size="sm"
                                    variant="danger"
                                    className="ms-4"
                                  />
                                </span>
                              ) : (
                                <span
                                  onClick={() => handleResumeDelete(res)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <FaTrashAlt className="fs-6 ms-4 text-danger" />
                                </span>
                              )}
                            </div>
                          </div>

                          <div
                            onClick={() => handleResumeView(res.cv)}
                            style={{
                              width: "200px",
                              height: "270px",
                              cursor: "pointer",
                            }}
                            className={classes.imgcontainer + " border p-2"}
                          >
                            <img
                              src={renderedImages[res.id]}
                              className={classes.resumesuploaded + " w-100"}
                            />
                            <div className={classes.imageoverlay}>
                              <FaExpand className={classes.expandicon} />
                            </div>
                          </div>

                          <div
                            style={{
                              fontSize: "14px",
                              fontWeight: "600",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                            className="text-dark text-center mt-2 mb-3"
                          >
                            {res.resumeName && res.resumeName.length > 20
                              ? `${res.resumeName.slice(0, 20)}...`
                              : res.resumeName}
                          </div>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            ) : (
              <div className="text-center">
                <div className="d-flex  align-items-center justify-content-center ">
                  <img src={noresume} alt="noresumes" width={400} />
                </div>
                <div className="fw-semibold  mt-3">Resumes Not Found!</div>
                <div className="mt-1 text-muted " style={{ fontSize: "12px" }}>
                  you can upload up to 3 resumes.
                </div>
              </div>
            )}

            {/* <>
              {resumeFiles && resumeFiles.length > 0 ? (

                <>
                  <h6 className="fw-semibold my-4">RECENT UPLOADS</h6>
                  {resumeFiles.map((resume) => (
                    <Row
                      key={resume.id}
                      className=" d-flex align-items-center my-4 "
                    >
                      <Col lg={1} xs={2} sm={2}>
                        {resume.active_res ? (
                          <IoCheckmarkCircle className="text-success fs-4" />
                        ) : (
                          <div>
                            {activating ? (
                              <Spinner size="sm" className="text-success" />
                            ) : (
                              <FaRegCircle
                                className="fs-5"
                                onClick={() => handleResumeActive(resume)}
                              />
                            )}
                          </div>
                        )}
                      </Col>
                      <Col lg={6} xs={8} sm={8} md={6}>
                        <a
                         onClick={()=>handleResumeView(resume.cv)}
                        //  href={resume.cv}
                          target="_blank"
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            cursor: "pointer",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {resume.resumeName && resume.resumeName.length > 20
                            ? `${resume.resumeName.slice(0, 20)}...`
                            : resume.resumeName}
                          <FaExternalLinkAlt className="mb-2 ms-2 text-primary" />
                        </a>
                      </Col>
                      <Col
                        lg={1}
                        xs={2}
                        sm={2}
                        md={1}
                        style={{ fontSize: "14px", cursor: "pointer" }}
                        onClick={() => handleResumeDelete(resume)}
                      >
                      
                        <FaTrashAlt className="fs-6 mb-1 ms-3 text-danger" />
                      </Col>
                    </Row>
                  ))}
                </>
              ) : (
                <div className="text-center">
                  <div className="d-flex  align-items-center justify-content-center ">
                    <img src={noresume} alt="noresumes" width={400} />
                  </div>
                  <div className="fw-semibold  mt-3">Resumes Not Found!</div>
                  <div
                    className="mt-1 text-muted "
                    style={{ fontSize: "12px" }}
                  >
                    you can upload / build a resume.
                  </div>
                </div>
              )}
            </> */}
          </Col>
        </Row>
      )}
    </>
  );
};

export default UploadResume;
