import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { MdStars } from "react-icons/md";
import {
  Container,
  Dropdown,
  NavItem,
  NavLink,
  Navbar,
  Nav,
  Image,
  Button,
  Row,
  Col,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../../provider/AuthContext";

const HirerNavbar = () => {
  // const role = localStorage.getItem("role");
  const { logout,membershipFetch } = useAuthContext();

  const [isNavFixed, setIsNavFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 0) {
        setIsNavFixed(true);
      } else {
        setIsNavFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //   const signOut = async (e) => {
  //     e.preventDefault();
  //     logout();
  //   };
  let user = JSON.parse(localStorage.getItem("userData"));

  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/postjob");
  };

  const signOut = async (e) => {
    e.preventDefault();
    logout();
  };

  return (
    <Navbar
      expand="lg"
      className={`py-3 ${isNavFixed ? "fixed-top bg-white shadow-lg" : ""}`}
    >
      <Container>
        <Navbar.Brand
          className=" fs-4 text-dark d-flex align-items-center "
          style={{ fontWeight: "800" }}
          href="/company/profile"
        >
          <div
            className="bg-primary p-1 text-white"
            style={{ borderRadius: "50px", height: "45px", width: "45px" }}
          >
            em
          </div>
          Career
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            style={{ fontSize: "16px" }}
            className="mx-auto my-2 gap-lg-4 gap-0"
            navbarScroll
            variant="underline"
            defaultActiveKey="default"
          >
            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/company/profile"
                eventKey="default"
                active={window.location.pathname === "/company/profile"}
              >
                Profile
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/hirer/jobs"
                eventKey="link1"
                active={window.location.pathname === "/hirer/jobs"}
              >
                Jobs
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/topcandidates"
                eventKey="link-2"
                active={window.location.pathname === "/topcandidates"}
              >
                Candidate
              </Nav.Link>
            </Nav.Item>
          </Nav>
          {/* {user.user_info.picture ? (
            <Image
              width={25}
              src={user.user_info.picture}
              alt="userimage"
              roundedCircle
              className="mt-2 me-2"
            />
          ) : (
            <div className="fs-2 d-none d-lg-block text-secondary me-lg-1">
              <IoPersonCircle />
            </div>
          )} */}
          <Row className="d-flex align-items-center gap-0">
            <Col lg={8}>
              <div className="fs-2 d-none d-lg-block text-secondary">
                <Button
                  onClick={handleClick}
                  className="d-flex align-items-center gap-1 py-2"
                >
                  <span>
                    <FaEdit className=" mb-1 me-1" />
                  </span>{" "}
                  Post Job
                </Button>
              </div>
            </Col>
            {/* <Col lg={2}>
              <Image
                width={45}
                src={
                  "https://img.freepik.com/free-vector/city-skyline-concept-illustration_114360-8923.jpg?w=1380"
                }
                alt="userimage"
                roundedCircle
                className="mt-2 pe-2"
              />
            </Col> */}
            <Col lg={4}>
              <Dropdown as={NavItem} className="mt-lg-2">
                <Dropdown.Toggle
                  as={NavLink}
                  className="text-primary"
                  style={{ fontSize: "14px" }}
                >
                  {user.first_name && user.last_name
                    ? `${user.first_name} ${user.last_name}`
                    : user.user_info.name}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {user && user.user_info ? (
                    ""
                  ) : (
                    <Dropdown.Item as={Link} to="/changepassword">
                      Change Password
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item as={Link} to="/upgradeplan" >
                    Upgrade
                    <MdStars className="mb-1 text-success ms-1 fs-6" />
                  </Dropdown.Item>
                  <Dropdown.Item onClick={signOut}>Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default HirerNavbar;
