import React, { useEffect } from "react";
import { Row, Col, Image, Button, Tab } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { FiPhone } from "react-icons/fi";
import { IoLocationOutline } from "react-icons/io5";
import { BsBuildings } from "react-icons/bs";
import { MdOutlineWorkOutline } from "react-icons/md";
import classes from "../../recruiter/dashboard/screens/Screens.module.css";
import logo from "../../../assets/company.png";
import AboutUs from "../../recruiter/dashboard/screens/companyProfile/tabs/AboutUs";
import CompanyInfo from "../../recruiter/dashboard/screens/companyProfile/tabs/CompanyInfo";
import { useCompanyContext } from "../../../provider/CompanyContext";
import StarRating from "../../../ui/common/StarRating";
import CandidateBody from "../../home/layouts/common/CandidateBody";
import CompanyProfileLoading from "./CompanyProfileLoading";
import recruiterbg from "../../../assets/recruiter-banner.png";
import jobclasses from "../jobsearch/Jobsearch.module.css";
import CompaniesJobList from "./CompaniesJobList";
import PeopleCard from "./PeopleCard";
import SalaryCard from "./SalaryCard";

const CompanyProfileView = () => {
  const {
    fetchSingleCompany,
    companyDetails,
    selectedTab,
    handleTabChange,
    jobFetched,
    isLoading,
  } = useCompanyContext();
  const { companyId } = useParams();

  useEffect(() => {
    if (companyId) {
      fetchSingleCompany(companyId);
    }
  }, [companyId]);

  console.log("__________", companyDetails);

  let employees = [];
  let salaries = [];
  let hrmanagers = [];
  if (companyDetails) {
    employees = companyDetails.top_employees
      ? Object.values(companyDetails.top_employees)
      : [];
    salaries = companyDetails.salaries ? companyDetails.salaries : [];
    hrmanagers = companyDetails.hr_managers ? companyDetails.hr_managers : [];
  }

  console.log(jobFetched);
  return (
    <div>
      <CandidateBody>
        {isLoading ? (
          <CompanyProfileLoading />
        ) : (
          <>
            {companyDetails && (
              <>
                <Row>
                  <Col lg={12} md={6} sm={2}>
                    <Image
                      src={recruiterbg}
                      alt="banner image"
                      fluid={true}
                      width="100%"
                      className="rounded-4 "
                    />
                    <div className={classes.CompanyProfile}>
                      <div className={classes.imageCompany}>
                        <img
                          onError={(e) => {
                            e.target.src = logo;
                          }}
                          src={companyDetails.company_logo || logo}
                          alt="company"
                          fluid={true}
                        />
                      </div>
                      <Row className="mt-5 d-flex justify-content-between">
                        <Col lg={8} md={12}>
                          <h5
                            className={
                              classes.companyName +
                              " d-flex align-items-center  gap-2"
                            }
                          >
                            {companyDetails.company_name}
                            <div
                              className="ps-2 fw-normal mt-1 text-secondary"
                              style={{ fontSize: "14px" }}
                            >
                              ( {companyDetails.about_company} )
                            </div>
                          </h5>

                          <div className="d-flex align-items-center">
                            <StarRating
                              rating={
                                companyDetails.ambition_box_company_rating
                              }
                            />
                            <span
                              className="text-light ms-1 mt-1"
                              style={{ fontSize: "12px" }}
                            >
                              ( {companyDetails.ambition_box_vote_count} )
                            </span>
                          </div>

                          <div
                            className={classes.lighttxt + " fw-normal  mb-4"}
                          >
                            <IoLocationOutline className="mb-1 me-1" />
                            {companyDetails.company_location}
                          </div>
                        </Col>
                        <Col lg={4} md={12} className="text-lg-end">
                          <Button
                            size="lg"
                            className=" d-inline-flex align-items-center gap-2 fw-semibold"
                          >
                            <FiPhone className=" fs-5" />
                            <a
                              href={companyDetails.company_link}
                              target="_blank"
                              rel="noopener"
                            >
                              <span
                                style={{ fontSize: "16px" }}
                                className="text-white"
                              >
                                Contact Us
                              </span>
                            </a>
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="A">
                      <div className="d-flex flex-wrap gap-4">
                        <div
                          eventKey="A"
                          className={classes.customNavlink + " p-2"}
                          style={{
                            cursor: "pointer",
                            fontWeight: "500",
                            color: selectedTab === "A" ? "#05264E" : "#4F5E64",
                            border:
                              selectedTab === "A"
                                ? "1px solid #B4C0E0"
                                : "1px solid #e0e6f7",
                          }}
                          onClick={() => handleTabChange("A", companyId)}
                        >
                          <BsBuildings /> <span>About us</span>
                        </div>

                        <div
                          eventKey="R"
                          className={classes.customNavlink + " p-2"}
                          style={{
                            cursor: "pointer",
                            fontWeight: "500",
                            color: selectedTab === "R" ? "#05264E" : "#4F5E64",
                            border:
                              selectedTab === "R"
                                ? "1px solid #B4C0E0"
                                : "1px solid #e0e6f7",
                          }}
                          onClick={() => handleTabChange("R", companyId)}
                        >
                          <MdOutlineWorkOutline /> <span>Recruitments</span>
                        </div>

                        <div
                          eventKey="P"
                          className={classes.customNavlink + " p-2"}
                          style={{
                            cursor: "pointer",
                            fontWeight: "500",
                            color: selectedTab === "P" ? "#05264E" : "#4F5E64",
                            border:
                              selectedTab === "P"
                                ? "1px solid #B4C0E0"
                                : "1px solid #e0e6f7",
                          }}
                          onClick={() => handleTabChange("P", companyId)}
                        >
                          <MdOutlineWorkOutline /> <span>People</span>
                        </div>

                        <div
                          eventKey="S"
                          className={classes.customNavlink + " p-2"}
                          style={{
                            cursor: "pointer",
                            fontWeight: "500",
                            color: selectedTab === "S" ? "#05264E" : "#4F5E64",
                            border:
                              selectedTab === "S"
                                ? "1px solid #B4C0E0"
                                : "1px solid #e0e6f7",
                          }}
                          onClick={() => handleTabChange("S", companyId)}
                        >
                          <MdOutlineWorkOutline /> <span>Salaries</span>
                        </div>
                      </div>

                      <div sm={12} className="border-bottom  mt-4 mb-5"></div>
                      {selectedTab === "A" ? (
                        <>
                          {companyDetails && (
                            <Row className={classes.companyprofile}>
                              <Col lg={8} sm={12} className="  mb-4">
                                <AboutUs company={companyDetails} />
                                <div className={classes.aboutus + " mt-3"}>
                                  <div className={jobclasses.joboverview}>
                                    <h5 className="border-bottom pb-3 mb-3">
                                      About Company
                                    </h5>
                                    <Row className=" pt-2">
                                      <Col md={6} className="d-flex">
                                        <div className=" d-flex ms-1 pt-3">
                                          <div
                                            className={jobclasses.textdes}
                                            industry-icon
                                          >
                                            Company Type
                                          </div>
                                          <div
                                            className={jobclasses.smallheading}
                                          >
                                            {companyDetails.company_type}
                                          </div>
                                        </div>
                                      </Col>
                                      <Col md={6} className="d-flex">
                                        <div className=" d-flex ms-1 pt-3">
                                          <div
                                            className={jobclasses.textdes}
                                            joblevel-icon
                                          >
                                            Company Size
                                          </div>
                                          <div
                                            className={jobclasses.smallheading}
                                          >
                                            {companyDetails.company_size}
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className=" pt-2">
                                      <Col md={6} className="d-flex">
                                        <div className=" d-flex ms-1 pt-3">
                                          <div className={jobclasses.textdes}>
                                            Industry{" "}
                                          </div>
                                          <div
                                            className={jobclasses.smallheading}
                                          >
                                            {companyDetails.industry}
                                          </div>
                                        </div>
                                      </Col>
                                      <Col md={6} className="d-flex">
                                        <div className=" d-flex ms-1 pt-3">
                                          <div className={jobclasses.textdes}>
                                            Employees
                                          </div>
                                          <div
                                            className={jobclasses.smallheading}
                                          >
                                            {companyDetails.total_employee}
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              </Col>
                              <Col lg={4} sm={12} className="mt-5">
                                <CompanyInfo data={companyDetails} />
                              </Col>
                            </Row>
                          )}
                        </>
                      ) : selectedTab === "R" ? (
                        <Row>
                          <Col
                            lg={8}
                            className={
                              classes.aboutus + " d-flex flex-column mb-4"
                            }
                          >
                            <h2 className=" fw-bold mb-4 ">Featured Jobs</h2>
                            {jobFetched.map((job) => (
                              <CompaniesJobList job={job} />
                            ))}
                          </Col>
                          <Col lg={4} className="mt-5">
                            <CompanyInfo data={companyDetails} />
                          </Col>
                        </Row>
                      ) : selectedTab === "P" ? (
                        <Row>
                          <Col
                            lg={8}
                            className={
                              classes.aboutus + " d-flex flex-column mb-4"
                            }
                          >
                            <h2 className=" fw-bold mb-4 ">
                              Top Employees in the Company
                            </h2>
                            <div className="mt-2">
                              {employees.map((employee, index) => (
                                <div className="mb-4" key={index}>
                                  <PeopleCard employee={employee} />
                                </div>
                              ))}
                            </div>
                            {hrmanagers.length > 0 && (
                              <>
                                <h2 className=" fw-bold my-4 ">
                                  HR Managers in the Company
                                </h2>
                                <div className="mt-2">
                                  {hrmanagers.map((hr, index) => (
                                    <div className="mb-4" key={index}>
                                      <PeopleCard employee={hr} />
                                    </div>
                                  ))}
                                </div>
                              </>
                            )}
                          </Col>
                          <Col lg={4} className="mt-5">
                            <CompanyInfo data={companyDetails} />
                          </Col>
                        </Row>
                      ) : selectedTab === "S" ? (
                        <Row>
                          <Col
                            lg={8}
                            className={
                              classes.aboutus + " d-flex flex-column mb-4"
                            }
                          >
                            <h2 className=" fw-bold mb-4 ">
                              Salaries in different departments
                            </h2>
                            <div
                              className="mt-2"
                              style={{
                                maxHeight: "40rem",
                                overflowY: "scroll",
                                overflowX: "hidden",
                              }}
                            >
                              {salaries.map((salary, index) => (
                                <div className="mb-4" key={index}>
                                  <SalaryCard salary={salary} />
                                </div>
                              ))}
                            </div>
                          </Col>
                          <Col lg={4} className="mt-5">
                            <CompanyInfo data={companyDetails} />
                          </Col>
                        </Row>
                      ) : (
                        ""
                      )}
                    </Tab.Container>
                  </Col>
                </Row>{" "}
              </>
            )}
          </>
        )}
      </CandidateBody>
    </div>
  );
};

export default CompanyProfileView;
