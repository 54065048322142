import React from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { useProfileContext } from "../../../provider/ProfileContext";

const DeleteProfile = (props) => {
  const { isEditingProfile, deletingData } = useProfileContext();

  return (
    <Modal
      backdrop="static"
      keyboard={false}
      size="sm"
      show={props.show}
      onHide={props.handleClose}
    >
      <Modal.Body className="p-5 ">
        <div className="text-center fw-semibold " style={{ fontSize: "18px" }}>
          Are you sure you want do delete this ?
          <div className="mt-4">
            {deletingData ? (
              <Button
                variant="danger"
                className="me-1 py-2"
                size="sm"
                disabled={true}
              >
                Deleting
                <Spinner size="sm" className="ms-1" />
              </Button>
            ) : (
              <Button
                size="sm"
                variant="danger"
                className="me-3 py-2"
                onClick={props.handleDelete}
              >
                Delete
              </Button>
            )}
            {deletingData ? (
              <Button size="sm" className="ms-2 py-2" disabled={true}>
                Cancel
              </Button>
            ) : (
              <Button
                size="sm"
                className="ms-2 py-2"
                onClick={props.handleClose}
              >
                Cancel
              </Button>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteProfile;
