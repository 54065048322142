import React, { useState, useEffect } from "react";
import classes from "./filehandle.module.css";
import { Modal, Button } from "react-bootstrap";
import { FaUpload } from "react-icons/fa";
import { useResumeBuilderContext } from "../../../provider/ResumeBuilderContext";
import Loader from "./Loader";
import { cleardata } from "../../../data/ResumeData";
import { data } from "../../../data/ResumeData";

const UploadResume = () => {
  const {
    handleResumeUpload,
    uploading,
    fileName,
    selectSection,
    showPopUp,
    setShowPopUp,
    setResumeData,
    setFileName,
  } = useResumeBuilderContext();

  const [timer, setTimer] = useState(0);
  let intervalId;

  const uploadTime = () => {
    intervalId = setInterval(
      () => setTimer((prevTimer) => prevTimer + 1),
      1000
    );
  };

  const stopTimer = () => {
    clearInterval(intervalId);
  };

  const handleResumeUploadChange = async (e) => {
    try {
      uploadTime();
      const response = await handleResumeUpload(e);

      if (response && response.status === 201) {
        stopTimer();
        setTimer(0);
        selectSection(3);
        setShowPopUp(false);
      } else {
        setFileName("");
        setShowPopUp(false);
      }
    } catch (error) {
      alert(error.message);
      console.log(error);
      stopTimer();
      setTimer(0);
      setShowPopUp(false);
      setFileName("");
    }
  };

  useEffect(() => {
    return () => stopTimer();
  }, [timer]);

  // console.log("timeTaken: ", timer);

  // const handleUpload = async (e) => {
  //   try {
  //     setStartTime(performance.now());
  //     const response = await handleResumeUpload(e);
  //     setEndTime(performance.now());

  //     if (response && response.status === 201) {
  //       selectSection(3);
  //       setShowPopUp(false);
  //     } else {
  //     }
  //   } catch (error) {
  //     console.error(error.message);
  //     alert(error.message);
  //   }
  // };

  // const closePopUp = () => {
  //   selectSection(3);
  //   setShowPopUp(false);
  //   setResumeData(cleardata);
  //   setFileName("");
  //   setTimeout(() => {
  //     setResumeData(data);
  //   }, 1000);
  // };

  const clearData = () => {
    selectSection(3);
    setShowPopUp(false);
    setResumeData(cleardata);
    setTimeout(() => {
      setResumeData(data);
    }, 1000);
  };
  // const abortFn = () => {};

  // console.log("uploading_______", uploading);

  return (
    <Modal
      show={showPopUp}
      onHide={() => setShowPopUp(false)}
      backdrop="static"
      keyboard={false}
    >
      {!uploading && (
        <Modal.Header className="border-0" closeButton></Modal.Header>
      )}
      <Modal.Body>
        {fileName !== "" ? (
          <div div className="text-center ">
            {!uploading && (
              <h5 className="mb-3 text-dark fw-bold">Change Uploaded Resume</h5>
            )}
          </div>
        ) : (
          <div className=" text-center  ">
            <h5 className="mb-3 text-dark fw-bold">
              How do you want to start ?
            </h5>
            <Button onClick={clearData} className="my-2">
              CREATE RESUME FROM SCRATCH
            </Button>
            <div className="fw-semibold">OR</div>
          </div>
        )}

        <div className={classes.fileupload}>
          <div className={classes.fileselect}>
            {uploading ? (
              <>
                <Loader timer={timer} />
              </>
            ) : (
              <div className="text-center justify-content-center">
                <div className=" fs-1 mb-2 mt-5 text-dark">
                  <FaUpload />
                </div>
                <div className="px-4 mb-3 " style={{ fontSize: "12px" }}>
                  Upload your existing resume
                  <br /> ( PDF Format Only )
                </div>
                <div className={" text-danger fw-semibold"} id="noFile">
                  {fileName !== "" ? fileName : "No file chosen..."}
                </div>
              </div>
            )}
            <input
              type="file"
              name="chooseFile"
              id="chooseFile"
              placeholder="upload resume only"
              onChange={handleResumeUploadChange}
              disabled={uploading === true}
              accept="application/pdf"
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UploadResume;
