import React from 'react'
import classes from "./ResumeList.module.css"

const ResumeViewButton = () => {
  return (
    <div>
      
    </div>
    // <button className={classes.fixedbtn}>
    //   click me
    // </button>
  )
}

export default ResumeViewButton
