import React, { useState,useEffect } from "react";
import AuthenticationBg from "./AuthenticationBg";
import { Form, Button, Row, Col, Spinner } from "react-bootstrap";
import FormInput from "../../ui/common/FormInput";
import {
  validateEmail,
  validatepassword,
  validateusername,
} from "../../utils/Validation";
import { useAuthContext } from "../../provider/AuthContext";
import axios from "axios";
import { url } from "../../data/Constants";
import { useNavigate } from "react-router-dom";

const Register = () => {
  const navigate = useNavigate();
  const { register, isLoading } = useAuthContext();
  const [passwordType, setPasswordType] = useState("password");
  const [passwordType1, setPasswordType1] = useState("password");
  const [isCheckingUname, setIsCheckingUname] = useState(false);
  const [isCheckingEname, setIsCheckingEname] = useState(false);
  const [emailCheck, setEmailCheck] = useState("");
  const [userCheck, setUserCheck] = useState("");

  const [error, setError] = useState({
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    password: "",
    password2: "",
    terms: "",
  });

  const [registerData, setRegisterData] = useState({
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    password: "",
    password2: "",
    terms: false,
  });

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    const role = localStorage.getItem("role");
    if (role) {
      localStorage.setItem("role", role);
    } else {
      localStorage.setItem("role", "C");
    }
  }, []);

  const onChangeAgree = (e) => {
    if (e && e.target) {
      const { name } = e.target;

      setRegisterData((prevFormData) => ({
        ...prevFormData,
        [name]: !registerData.terms,
      }));
    }
    handleChangeRegister();
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const togglePassword2 = () => {
    if (passwordType1 === "password") {
      setPasswordType1("text");
      return;
    }
    setPasswordType1("password");
  };

  const handleCombinedChange = (event) => {
    handleCheck(event);
    handleChangeRegister(event);
  };

  const handleCheck = (e) => {
    if (e && e.target) {
      const { name, value } = e.target;

      const emailurl = `${url}auth/check-username-email/?email=${value}&username=`;
      const usernameurl = `${url}auth/check-username-email/?email=&username=${value}`;
      let checkurl = ``;
      if (name === "email") {
        checkurl = emailurl;
      } else {
        checkurl = usernameurl;
      }

      setTimeout(() => {
        if (name === "username" && value && value.length >= 4) {
          setIsCheckingUname(true);
          axios
            .get(checkurl)
            .then((response) => {
              if (response.data.success) {
                setIsCheckingUname(false);
                setUserCheck("success");
              } else {
                setError((prevErrors) => ({
                  ...prevErrors,
                  ["username"]: response.data.error,
                }));
                setIsCheckingUname(false);
                setUserCheck("error");
              }
            })
            .catch((error) => {
              setIsCheckingUname(false);
              console.log(error);
            });
        } else if (name === "email" && value && validateEmail(value) === true) {
          setIsCheckingEname(true);
          axios
            .get(checkurl)
            .then((response) => {
              // console.log(response.data);
              if (response.data.success) {
                setIsCheckingEname(false);
                setEmailCheck("success");
              } else {
                setError((prevErrors) => ({
                  ...prevErrors,
                  ["email"]: response.data.error,
                }));
                setIsCheckingEname(false);
                setEmailCheck("error");
              }
            })
            .catch((error) => {
              setIsCheckingEname(false);
              console.log(error);
            });
        } else {
          // console.log("fetching not started");
          setEmailCheck("");
          setUserCheck("");
          setIsCheckingEname(false);
          setIsCheckingUname(false);
        }
      }, 500);
    }
  };

  const handleChangeRegister = (e) => {
    if (e && e.target) {
      const { name, value } = e.target;
      let isValid = true;
      let errorMessage = "";
      switch (name) {
        case "email":
          isValid = validateEmail(value);
          errorMessage = isValid ? "" : "Invalid email";
          break;
        case "password2":
          isValid = value === registerData.password;
          errorMessage = isValid ? "" : "Passwords do not match";
          break;
        case "password":
          isValid = validatepassword(value);
          errorMessage = isValid
            ? ""
            : "Password should be at least 8 characters which include numbers and letters";
          break;
        case "username":
          isValid = validateusername(value);
          errorMessage = isValid
            ? ""
            : "username must contain atleast 4 letters";
          break;
        default:
          break;
      }
      setError((prevErrors) => ({
        ...prevErrors,
        [name]: isValid ? "" : errorMessage,
      }));

      setRegisterData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleSubmitRegister = async (e) => {
    e.preventDefault();
    try {
      const response = await register(registerData);
      navigate("/verifyemail", { state: { value: registerData.email } });
    } catch (error) {
      console.error(error);
      if (error.response && error.response.data) {
        const { username, password } = error.response.data;
        const showAlert = (field) => {
          if (field && field.length > 0) {
            const errorMessage = field[0];
            alert(errorMessage);
          }
        };
        showAlert(username);
        showAlert(password);
      } else {
        alert("An error occurred!");
      }
    }
  };

  return (
    <AuthenticationBg
      heading="Register as"
      subheading="Let's get started!"
      subheading2="Access to all features. No credit card required."
      auth="register"
    >
      <Form noValidate onSubmit={handleSubmitRegister}>
        <Row>
          <Col sm={12} lg={6} className="pe-lg-1">
            <FormInput
              name="first_name"
              type="text"
              placeholder="John"
              label="First Name"
              value={registerData.first_name}
              error={error.first_name}
              onChange={handleChangeRegister}
              className="mb-3"
              required="required"
            />
          </Col>
          <Col sm={12} lg={6} className="ps-lg-1">
            <FormInput
              name="last_name"
              type="text"
              placeholder="Doe"
              label="Last Name"
              value={registerData.last_name}
              error={error.last_name}
              onChange={handleChangeRegister}
              className="mb-3"
              required="required"
            />
          </Col>
        </Row>
        <FormInput
          name="email"
          type="email"
          placeholder="johndoe@gmail.com"
          label="Email address"
          value={registerData.email}
          error={error.email}
          onChange={handleCombinedChange}
          className="mb-3"
          required="required"
          isCheckingE={isCheckingEname}
          emailCheck={emailCheck}
        />

        <FormInput
          name="username"
          type="text"
          placeholder="johndoe"
          label="Username"
          value={registerData.username}
          error={error.username}
          onChange={handleCombinedChange}
          className="mb-3"
          required="required"
          isCheckingU={isCheckingUname}
          userCheck={userCheck}
        />

        <FormInput
          form="pw"
          name="password"
          type={passwordType}
          placeholder="********"
          label="Password"
          value={registerData.password}
          error={error.password}
          onChange={handleChangeRegister}
          toggle={togglePassword}
          className="mb-3"
          required="required"
        />

        <FormInput
          form="pw"
          name="password2"
          type={passwordType1}
          placeholder="********"
          label="Confirm Password"
          value={registerData.password2}
          error={error.password2}
          onChange={handleChangeRegister}
          toggle={togglePassword2}
          className="mb-3"
          required="required"
        />

        <div
          className="d-flex align-items-center mb-3"
          style={{ fontSize: "13px" }}
        >
          <Form.Check
            className="text-muted me-2"
            type="checkbox"
            checked={registerData.terms}
            style={{ cursor: "pointer" }}
            id="custom-check"
            name="terms"
            onChange={onChangeAgree}
            isInvalid={!registerData.terms}
            required="required"
          />
          <label htmlFor="custom-check">
            Agree our
            <a
              className="mx-1"
              href="/termsandconditions"
              target="blank"
              rel="noopener"
            >
              terms
            </a>
            and
            <a
              className="ms-1"
              href="/privacypolicy"
              target="blank"
              rel="noopener"
            >
              policy
            </a>
          </label>
        </div>
        <Button
          type="submit"
          variant="dark"
          className="my-2 w-100"
          disabled={
            Object.values(error).some((field) => field !== "") ||
            Object.values(registerData).some((value) => value === "") ||
            registerData.terms === false ||
            isLoading
          }
        >
          {isLoading ? <Spinner size="sm" /> : "Register"}
        </Button>
      </Form>
    </AuthenticationBg>
  );
};

export default Register;
