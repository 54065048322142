import React, { useEffect } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { IoLocationOutline, IoTimeOutline } from "react-icons/io5";
import classes from "../../Screens.module.css";
import { MdOutlineWorkOutline } from "react-icons/md";
import { useHirerJobListContext } from "../../../../../../provider/RecruiterJobListContext";
import { getLabelByValue } from "../../../../../../utils/jobutils";
import { salary, workmode } from "../../../../../../data/JobData";
import JobDetailsSkelton from "../../../../../candidate/jobsearch/JobDetailsSkelton";
import { LiaRupeeSignSolid } from "react-icons/lia";

import { Link } from "react-router-dom";

const JobCard = () => {
  const { loading, jobs, fetchJobsData } = useHirerJobListContext();

  useEffect(() => {
    fetchJobsData(`${process.env.REACT_APP_BASE_URL}hirer/job/list?page=1`);
  }, []);

  return (
    <>
      {loading ? (
        <p>
          <JobDetailsSkelton />
        </p>
      ) : (
        <>
          {jobs.length > 0 ? (
            jobs.map((job) => (
              <Container key={job.id} className={classes.jobCard + ' mb-2 '}>
                <Row className=" d-flex justify-content-between align-items-center mb-2">
                  <Col lg={10} className=" d-flex flex-column gap-2">
                    <h4 className=" fw-bold text-secondary ">
                      {job.job_title}
                    </h4>
                    <div
                      className=" d-flex justify-content-start flex-wrap gap-lg-3 gap-2"
                      style={{ fontSize: "14px" }}
                    >
                      <p className={classes.smText}>
                        <MdOutlineWorkOutline />
                        <span> {getLabelByValue(job.work_mode, workmode)}</span>
                      </p>
                      <p className={classes.smText}>
                        <IoTimeOutline />
                        <span>{job.created_at}</span>
                      </p>{" "}
                      <p className={classes.smText}>
                        <IoLocationOutline />
                        <span style={{ fontSize: "14px" }}>
                          {job.job_location}
                        </span>
                      </p>
                      <p className={classes.smText}>
                        <LiaRupeeSignSolid />
                        <span style={{ fontSize: "14px" }}>
                          {getLabelByValue(job.salary, salary)}
                        </span>
                      </p>
                    </div>
                  </Col>
                  <Col lg={2}>
                    <Link
                      to={"/hirer/jobs"}
                      className=" btn btn-link fs-6 text-nowrap"
                    >
                      View more
                    </Link>
                  </Col>
                </Row>
                <Row>
                  <div className=" d-flex justify-content-between">
                    <Col lg={8} className="">
                      {job.skills_req.split(",").map((skill, index) => (
                        <div
                          key={index}
                          className={
                            classes.skillBadge + ` mb-2 px-2 me-2 py-1 `
                          }
                        >
                          {skill.trim()}
                        </div>
                      ))}
                    </Col>
                    <Col lg={3} className=" text-lg-end">
                    <Button
                      className={`${classes.applyNow} ${
                        job.is_active
                          ? classes.openVacancy
                          : classes.closedVacancy
                      }`}
                    >
                      {job.is_active ? "Vacancy Open" : "Vacancy Closed"}
                    </Button></Col>
                  </div>
                </Row>
              </Container>
            ))
          ) : (
            <p>No jobs found</p>
          )}
        </>
      )}
    </>
  );
};

export default JobCard;
