import React, { useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";
import classes from "./ResumeFormData.module.css";
import AccordionField from "../../resumeUI/AccordionField";
import Input from "../../resumeUI/Input";
import MonthPickerDate from "../../resumeUI/MonthPickerDate";
import TextEditor from "../../resumeUI/TextEditor";

const ExperienceView = ({ data, onChange, index, onDelete, defaultKey }) => {
  const [errors, setErrors] = useState({ startDateWe: "", endDateWe: "" });
  const [present, setPresent] = useState(false);

  const onChangeHandler = (e) => {
    setPresent(!present);
    handleInputChange(e, index);
  };

  function checkdatevalidation(startdate, enddate) {
    let newErrors = { ...errors };
    const today = new Date();

    if (new Date(startdate) > new Date(enddate)) {
      newErrors = {
        ...newErrors,
        endDateWe: "  Enddate must be greater than start date ",
      };
    } else if (new Date(startdate) > today || new Date(enddate) > today) {
      newErrors = {
        ...newErrors,
        endDateWe: "Dates must not exceed today's date.",
      };
    } else {
      newErrors = {
        ...newErrors,
        endDateWe: "",
      };
    }
    setErrors(newErrors);
  }

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const today = new Date();

    if (name === "startDateWe") {
      const endDate = data.endDateWe;
      const startDate = value;

      if (endDate) {
        checkdatevalidation(startDate, endDate);
      } else if (new Date(startDate) > today) {
        setErrors({
          ...errors,
          startDateWe: "Start date must not exceed today's date.",
        });
      } else {
        setErrors({ ...errors, startDateWe: "" });
      }
    } else if (name === "endDateWe") {
      const startDate = data.startDateWe;
      const endDate = value;

      if (startDate) {
        checkdatevalidation(startDate, endDate);
      } else if (new Date(endDate) > today) {
        setErrors({
          ...errors,
          endDatWe: "End date must not exceed today's date.",
        });
      } else {
        setErrors({ ...errors, endDateWe: "" });
      }
    } else {
      setErrors({ startDateWe: "", endDateWe: "" });
    }

    onChange(e, index);
  };

  return (
    <Row>
      <Col lg={11} xs={11}>
        <AccordionField
          defaultKey={defaultKey}
          index={index}
          key={index}
          subheading={
            data.designation || data.company
              ? `${data.designation || " "} at ${data.company || ""}`
              : "not specified"
          }
        >
          <Row>
            <Col lg={12} xs={12}>
              <Input
                type="text"
                name="designation"
                value={data.designation}
                text="Your Position"
                font="12px"
                onChange={(e) => handleInputChange(e, index)}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Input
                type="text"
                name="company"
                value={data.company}
                text="Your Company"
                font="12px"
                onChange={(e) => handleInputChange(e, index)}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextEditor
                name="responsibilities"
                text="Enter your responsibilities"
                onChange={(e) => handleInputChange(e, index)}
                value={data.responsibilities}
              />
            </Col>
          </Row>
          <Row className="d-flex align-items-center mt-5">
            <Col lg={4} xs={5}>
              <MonthPickerDate
                type="text"
                name="startDateWe"
                placeholder="Start Date"
                value={data.startDateWe}
                text="start date"
                font="12px"
                onChange={(e) => handleInputChange(e, index)}
                error={errors.startDateWe}
              />
            </Col>
            <Col lg={1} xs={2} className="mt-2">
              <span style={{ fontSize: "12px" }} className="fw-semibold">
                to
              </span>
            </Col>
            {!data.present && (
              <Col lg={4} xs={5}>
                <MonthPickerDate
                  name="endDateWe"
                  placeholder="End Date"
                  type="text"
                  text="end date"
                  font="12px"
                  value={data.endDateWe}
                  onChange={(e) => handleInputChange(e, index)}
                  disabled={data.present === true}
                  error={errors.endDateWe}
                />
              </Col>
            )}
            <Col xs={3} lg={3} style={{ fontSize: "14px" }}>
              <Form.Check
                className="mt-4 ms-2"
                checked={data.present}
                value={!present}
                inline
                label="Present"
                name="present"
                type="checkbox"
                id={`present-${index}`}
                onChange={onChangeHandler}
              />
            </Col>
            <span
              style={{ fontSize: "12px" }}
              className="text-danger fw-semibold"
            >
              {errors.endDateWe}
            </span>
          </Row>
        </AccordionField>
      </Col>
      <Col lg={1} xs={1}>
        <div className={classes.trash + " mt-4"}>
          <FaTrashAlt onClick={() => onDelete(index)} />
        </div>
      </Col>
    </Row>
  );
};

export default ExperienceView;
