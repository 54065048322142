import React, { useState } from "react";
import { IoIosAddCircle } from "react-icons/io";
import classes from "./ResumeFormData.module.css";
import classes1 from "../../resumeUI/Input.module.css";
import { useResumeBuilderContext } from "../../../../provider/ResumeBuilderContext";
import SocialLinkData from "./SocialLinkData";
import AccordionField from "../../resumeUI/AccordionField";

const SocialLinks = () => {
  const { resumeData, handleResumeFormChange } = useResumeBuilderContext();
  const [socialProfiles, setSocialProfiles] = useState(resumeData.social || []);

  const addData = () => {
    const updatedSocialProfiles = [
      ...socialProfiles,
      { name: null, link: null },
    ];
    setSocialProfiles(updatedSocialProfiles);
  };

  const handleSocialProfileChange = (e, index) => {
    const { name, value } = e.target;
    const updatedSocialProfiles = [...socialProfiles];
    updatedSocialProfiles[index] = {
      ...updatedSocialProfiles[index],
      [name]: value,
    };
    setSocialProfiles(updatedSocialProfiles);
    handleResumeFormChange({
      target: { name: "social", value: updatedSocialProfiles },
    });
  };

  const removeData = (index) => {
    const updatedSocialProfiles = [...socialProfiles];
    updatedSocialProfiles.splice(index, 1);
    setSocialProfiles(updatedSocialProfiles);
    handleResumeFormChange({
      target: { name: "social", value: updatedSocialProfiles },
    });
  };
  return (
    <div className={classes.resumecontentform}>
      <div className={classes.resumeformheading}>Websites and Social Links</div>
      <div className={classes.resumeformsubheading}>
        Include a direct link to your portfolio or socialprofiles
      </div>
      <div >
        {socialProfiles.map((socialProfile, index) => (
          <SocialLinkData
            index={index}
            data={socialProfile}
            onChange={(e, index) => handleSocialProfileChange(e, index)}
            onDelete={(index) => removeData(index)}
          />
        ))}
        <div className={classes.addicon + " mt-3"} onClick={addData}>
          <IoIosAddCircle style={{ fontSize: "20px", cursor: "pointer" }} />
          <div className={classes1.addformtext}>Add</div>
        </div>
      </div>
    </div>
  );
};

export default SocialLinks;
