import React, { useState } from "react";
import { Col, Container, Row, Button, Spinner, Stack } from "react-bootstrap";
import classes from "../Home.module.css";
import jobclasses from "../../candidate/jobsearch/Jobsearch.module.css";
import banner1 from "../../../assets/banner1.png";
import banner2 from "../../../assets/banner2.png";
import top from "../../../assets/icon-top-banner.png";
import bottom from "../../../assets/icon-bottom-banner.png";
import { useNavigate, Link } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import { IoBagHandleOutline } from "react-icons/io5";
import { BsGrid3X3Gap } from "react-icons/bs";
import { CiLocationOn } from "react-icons/ci";
import { useJobContext } from "../../../provider/JobContext";
import CustomSelect from "../../candidate/jobsearch/CustomSelect";
import SearchSelect from "../../../ui/common/SearchSelect";
import { sector } from "../../../data/JobData";

const IntroSection = () => {
  const navigate = useNavigate();
  const {
    handleChangeFilter,
    filters,
    fetchJobsExt,
    searchFilters,
    fetchExfilters,
    setSearchFilters,
    selectedJob,
    fetchJobs,
    isLoading,
    fetchfilters,
  } = useJobContext();

  const userData = JSON.parse(localStorage.getItem("userData"));
  const role = localStorage.getItem("role");

  const getStarted = () => {
    if (userData) {
      navigate("/signin");
    } else {
      navigate("/select");
    }
  };

  const [suggestions, setSuggestions] = useState({
    job_location: [],
    company_name: [],
    job_title: [],
    job_role: [],
  });

  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleInputChange = async (e) => {
    const { value, name } = e.target;

    setSearchFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));

    if (value.length > 0) {
      setLoading(true);
      let response = [];
      response = await fetchExfilters(value, name);
      setSuggestions((prevSuggestions) => ({
        ...prevSuggestions,
        [name]: response,
      }));
      setLoading(false);
    } else {
      setSuggestions((prevSuggestions) => ({
        ...prevSuggestions,
        [name]: [],
      }));
    }
  };

  const handleOptionSelect = (option, name) => {
    setSearchFilters((prevFilters) => ({
      ...prevFilters,
      [name]: option,
    }));
    setSuggestions((prevSuggestions) => ({
      ...prevSuggestions,
      [name]: [],
    }));
  };

  const fetchJobsList = () => {
    setLoading(true);

    setTimeout(() => {
      if (selectedJob === "E") {
        fetchJobs(1, "free");
      } else {
        fetchJobsExt(1, "free");
      }

      setLoading(false);

      navigate("/jobs");
    }, 1000);
  };

  return (
    <section>
      <div className={classes.bghomepage}></div>
      <Container>
        <Row className={classes.section} style={{ position: "relative" }}>
          <Col sm={12} xl={8} md={8} lg={8}>
            <h4
              className={classes.mainheading + " mb-4  text-dark"}
              style={{ fontWeight: "800" }}
            >
              <span className="pe-2">The #1</span>
              <span className={classes.textbg + " text-primary"}>
                Job Board for
              </span>
              <span className="d-md-block"> Hiring or Find your next job</span>
            </h4>
            <div className={classes.mainnormaltext + " text-secondary pe-5"}>
              Each month, more than 3 million job seekers turn to website in
              their search for work, making over 140,000 applications every
              single day
            </div>

            {/* filter */}
            <div className={classes.filterblock}>
              <div className={jobclasses.filterform}>
                <Row className="mt-2">
                  <Col sm={12} lg={5} className="mb-2 ">
                    <div className="d-flex align-items-center flex-grow-1 w-100">
                      <CiLocationOn className="text-dark fw-lighter me-2" />
                      <SearchSelect
                        name="job_location"
                        placeholder="Location"
                        value={searchFilters.job_location}
                        handleChange={handleInputChange}
                        options={suggestions.job_location}
                        onOptionSelect={(option) =>
                          handleOptionSelect(option, "job_location")
                        }
                        loading={loading}
                      />
                    </div>
                  </Col>
                  <Col sm={12} lg={5} className="mb-2 ">
                    <div className="d-flex align-items-center flex-grow-1 w-100">
                      <BsGrid3X3Gap className="text-secondary fw-lighter me-2" />
                      {selectedJob === "E" ? (
                        <SearchSelect
                          name="job_role"
                          placeholder="Enter  your  Job  Role ..."
                          value={searchFilters.job_role}
                          handleChange={handleInputChange}
                          options={suggestions.job_role}
                          onOptionSelect={(option) =>
                            handleOptionSelect(option, "job_role")
                          }
                          loading={loading}
                        />
                      ) : (
                        <SearchSelect
                          name="job_title"
                          placeholder="Enter your job role"
                          value={searchFilters.job_title}
                          handleChange={handleInputChange}
                          options={suggestions.job_title}
                          onOptionSelect={(option) =>
                            handleOptionSelect(option, "job_title")
                          }
                          loading={loading}
                        />
                      )}
                    </div>
                  </Col>
                  <Col
                    sm={12}
                    lg={2}
                    style={{ fontSize: "14px", cursor: "pointer" }}
                    className="mb-2 d-flex align-items-center justify-content-center"
                  >
                    <Button
                      className="d-flex align-items-center"
                      onClick={fetchJobsList}
                    >
                      {!loader ? (
                        <>
                          <FiSearch />
                          <span className=" ps-1" style={{ fontSize: "13px" }}>
                            Search
                          </span>
                        </>
                      ) : (
                        <Stack direction="horizontal" gap={2}>
                          <Spinner
                            animation="grow"
                            variant="white"
                            style={{ width: "10px", height: "10px" }}
                          />
                          <Spinner
                            animation="grow"
                            variant="white"
                            style={{ width: "10px", height: "10px" }}
                          />
                          <Spinner
                            animation="grow"
                            variant="white"
                            style={{ width: "10px", height: "10px" }}
                          />
                        </Stack>
                      )}
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
            <div
              className="mt-5 text-muted"
              style={{ fontSize: "14px", color: "" }}
            >
              <span className="fw-bold pe-1">Popular Searches:</span>Designer,
              Web, IOS, Developer, PHP, Senior, Engineer
            </div>
            {/* <Stack
              direction="horizontal"
              gap={3}
              className={classes.homeBtn + " my-4"}
            >
              <Button size="lg" onClick={getStarted}>
                Get Started
              </Button>
              <Button size="lg" variant="outline-primary" href="#toolssection">
                Learn more
              </Button>
            </Stack> */}
          </Col>
          <Col
            xl={4}
            lg={4}
            className="text-center d-md-none d-none d-lg-block "
          >
            <div className={classes.bannerimages} id="tools-section w-100">
              <div className={classes.shape1}>
                <img src={banner1} />
              </div>
              <div className={classes.shape2}>
                <img src={banner2} />
              </div>
              <div className={classes.shape3}>
                <img src={top} />
              </div>
              <div className={classes.shape4}>
                <img src={bottom} />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default IntroSection;
