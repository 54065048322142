import React, { useEffect } from "react";
import { Modal, Button, Form, Row, Col, Image } from "react-bootstrap";
import { MdRefresh, MdVerified } from "react-icons/md";
import FormInput from "../../../../../ui/common/FormInput";
import { useRecruiterProfileContext } from "../../../../../provider/RecruiterProfileContext";
import TextEditor from "../uiForms/TextEditor";
import classes from "../Screens.module.css";

const EditProfileModal = ({ show, handleCloseModal, fetchedData }) => {
  const {
    handleFormDataChange,
    recruiterProfileData,
    isLoading,
    linkError,
    errors,
    setRecruiterProfileData,
    handleSaveChangePatch,
    isSaved,
    setIsSaved,
  } = useRecruiterProfileContext();

  useEffect(() => {
    if (fetchedData) {
      const newData = {
        first_name: fetchedData.first_name,
        last_name: fetchedData.last_name,
        email: fetchedData.email,
        company_phone: fetchedData.company_phone,
        company_logo: fetchedData.company_logo,
        company_name: fetchedData.company_name,
        company_link: fetchedData.company_link,
        company_address: fetchedData.company_address,
        company_location: fetchedData.company_location,
        social_link: fetchedData.social_link,
        company_desc: fetchedData.company_desc,
      };

      setRecruiterProfileData(newData);
    }
  }, [fetchedData]);

  useEffect(() => {
    if (isSaved) {
      handleCloseModal();
      setIsSaved(false);
    }
  }, [isSaved, handleCloseModal, setIsSaved]);

  const handleRemoveFile = () => {
    setRecruiterProfileData((prevData) => ({
      ...prevData,
      company_logo: "",
    }));
  };

  return (
    <Modal
      show={show}
      onHide={handleCloseModal}
      size="xl"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title className=" px-4 py-1">Edit Company Profile</Modal.Title>{" "}
      </Modal.Header>
      <Modal.Body className=" px-5 mb-3">
        <Form className="">
          <Row>
            <Col>
              <div className="d-flex align-items-center mb-3">
                {recruiterProfileData.company_logo ? (
                  <>
                    <Image
                      src={
                        typeof recruiterProfileData.company_logo === "string"
                          ? recruiterProfileData.company_logo
                          : URL.createObjectURL(
                              recruiterProfileData.company_logo
                            )
                      }
                      alt="Uploaded"
                      className="img-thumbnail mr-2 p-2"
                      style={{ maxWidth: "100px", height: "90px" }}
                    />
                    <div className=" d-flex flex-column gap-lg-2 gap-3 ms-4">
                      <div>
                        <label
                          htmlFor="customFile"
                          className="btn btn-sm btn-primary rounded-2 py-2"
                        >
                          Change Picture
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          id="customFile"
                          name="company_logo"
                          onChange={handleFormDataChange}
                          accept="image/*"
                          style={{ display: "none" }}
                        />
                      </div>
                      <Button
                        size="sm"
                        variant="outline-primary"
                        onClick={handleRemoveFile}
                        className="   rounded-2 py-1"
                      >
                        Remove Picture
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <Image
                      src="https://static.careerarc.com/images/3_0/icon_company_no-logo_200x200.jpg"
                      alt="Default"
                      className="img-thumbnail mr-2"
                      style={{ maxWidth: "100px", height: "90px" }}
                    />
                    <div className="d-flex flex-column ms-4">
                      <label
                        htmlFor="customFile"
                        className="btn btn-primary mb-1"
                      >
                        Upload File
                      </label>
                      <small className="text-secondary fst-italic">
                        Please upload only image/* files
                      </small>
                    </div>
                  </>
                )}
                <input
                  type="file"
                  className="form-control"
                  id="customFile"
                  name="company_logo"
                  onChange={handleFormDataChange}
                  accept="image/*"
                  style={{ display: "none" }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <FormInput
                name="firstName"
                type="text"
                placeholder="First Name"
                label="First name"
                value={`${recruiterProfileData.first_name} ${recruiterProfileData.last_name}`}
                disabled
                className="mb-3"
                required={true}
              />
            </Col>
            <Col lg={4}>
              <FormInput
                name="email"
                type="email"
                placeholder="name@company.com"
                label="Email address"
                value={recruiterProfileData.email}
                onChange={handleFormDataChange}
                className="mb-3 "
                required="required"
                error={errors.email}
              />
            </Col>
            <Col lg={4}>
              <FormInput
                name="company_phone"
                type="text"
                placeholder="company_phone"
                label="Company Phone"
                value={recruiterProfileData.company_phone}
                onChange={handleFormDataChange}
                disabled
                className="mb-3"
                required="required"
                error={errors.company_phone}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <FormInput
                name="company_name"
                type="text"
                placeholder=""
                label="Company Name"
                value={recruiterProfileData.company_name}
                onChange={handleFormDataChange}
                className="mb-3"
                required="required"
              />
            </Col>
            <Col lg={6} className="position-relative">
              <FormInput
                name="company_link"
                type="text"
                placeholder="https://"
                label="Company Website Link"
                value={recruiterProfileData.company_link}
                onChange={handleFormDataChange}
                className="mb-3"
                required="required"
                error={errors.company_link}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col lg={4}>
              <FormInput
                name="company_address"
                type="text"
                placeholder=""
                label="Company Address"
                value={recruiterProfileData.company_address}
                onChange={handleFormDataChange}
                className="mb-3"
              />
            </Col>
            <Col lg={4}>
              <FormInput
                name="company_location"
                type="text"
                placeholder=""
                label="Company Location"
                value={recruiterProfileData.company_location}
                onChange={handleFormDataChange}
                className="mb-3"
              />
            </Col>
            <Col lg={4}>
              <FormInput
                name="social_link"
                type="text"
                placeholder=""
                label="Social Accounts"
                error={errors.social_link}
                value={recruiterProfileData.social_link}
                onChange={handleFormDataChange}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <TextEditor
                name="company_desc"
                placeholder="Write Description about company"
                value={recruiterProfileData.company_desc}
                onChange={handleFormDataChange}
              />
            </Col>
          </Row>
          <Row>
            <div>
              <Button
                onClick={!linkError && handleSaveChangePatch}
                className="d-flex align-items-center gap-2"
                disabled={
                  Object.values(errors).some((field) => field !== "") ||
                  Object.values(recruiterProfileData).some(
                    (value) => value === ""
                  ) ||
                  isLoading
                }
              >
                <span>
                  {isLoading ? (
                    <MdRefresh className={classes.spinAnimation + " fs-5"} />
                  ) : (
                    <MdVerified className="fs-5" />
                  )}
                </span>
                {isLoading ? "Saving..." : "Save Changes"}
              </Button>
            </div>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditProfileModal;
