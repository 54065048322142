import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import classes from "./Jobsearch.module.css";
import classes1 from "../../home/Home.module.css";
import logo from "../../../assets/company.png";
import { Container } from "react-bootstrap";
import banner from "../../../assets/thumb.png";
import CandidateHeader from "../layouts/CandidateHeader";
import { Button, Stack, Row, Col, Badge } from "react-bootstrap";
import { SlHandbag } from "react-icons/sl";
import { IoIosTimer } from "react-icons/io";
import { RiVerifiedBadgeLine } from "react-icons/ri";
import { IoLocationOutline } from "react-icons/io5";
import { LuIndianRupee } from "react-icons/lu";
import {
  salary,
  experience,
  workmode,
  jobtype,
  job,
} from "../../../data/JobData";
import { toCamelCase } from "../../../utils/Validation";
import {
  formatTimeDifference,
  formatDate,
  getLabelByValue,
} from "../../../utils/jobutils";
import { useJobContext } from "../../../provider/JobContext";
import JobApply from "./JobApply";
import parse from "html-react-parser";
import SliderCarousel from "../../../ui/common/SliderCarousel";
import CandidateBody from "../../home/layouts/common/CandidateBody";
import CheckScore from "./CheckScore";
import map from "../../../assets/maps.png";
import JobDetailsSkelton from "./JobDetailsSkelton";
import StarRating from "../../../ui/common/StarRating";
import { useResumeListContext } from "../../../provider/ResumeListContext";

const JobDetails = ({ fadeIn }) => {
  const { fetchResumes } = useResumeListContext();
  const user = localStorage.getItem("userData");

  const {
    scores,
    setScores,
    job,
    similarJobs,
    otherJobs,
    fetchSingleJob,
    isLoading,
    selectedJob,
    fetchSingleExJob,
  } = useJobContext();
  const { jobId } = useParams();
  const [show, setShow] = useState(false);
  const [showScore, setShowScore] = useState(false);

  useEffect(() => {
    if (jobId) {
      if (selectedJob === "E") {
        fetchSingleJob(jobId);
      } else {
        fetchSingleExJob(jobId);
      }
      if (user !== "null") {
        fetchResumes();
      } else {
      }
    }
  }, [jobId]);

  const generateGoogleMapsLink = () => {
    return `https://www.google.com/maps/place/${encodeURIComponent(
      job.address
    )}`;
  };
  const handleCloseScore = () => {
    setShowScore(false);
    setScores([]);
  };
  const handleShowScore = () => setShowScore(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <CandidateBody>
        {isLoading ? (
          <JobDetailsSkelton />
        ) : (
          <div>
            <div className={classes.bannerimage}>
              <img src={banner} alt="banner image" />
            </div>
            <Row className="mt-4">
              <Col lg={8} sm={12}>
                <h3 className="text-dark fw-bold fs-3">{job.job_title} </h3>
                <div className={classes.jobicons + " mb-2"}>
                  <span className="me-3">
                    <SlHandbag
                      className="me-1"
                      style={{ marginBottom: "4px" }}
                    />
                    {getLabelByValue(job.job_type, jobtype)}
                  </span>
                  <span>
                    <IoIosTimer
                      className="me-1 fw-light"
                      style={{ marginBottom: "4px", fontSize: "14px" }}
                    />
                    {formatTimeDifference(job.created_at)}
                  </span>
                </div>
              </Col>

              <Col lg={4} sm={12} className="text-lg-end">
                {user !== "null" && selectedJob === "E" ? (
                  <Button size="lg" onClick={handleShow}>
                    <RiVerifiedBadgeLine className="me-2" />
                    Apply now
                  </Button>
                ) : (
                  <a
                    href={job.job_link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button size="lg">
                      <RiVerifiedBadgeLine className="me-2" />
                      Apply now
                    </Button>
                  </a>
                )}
              </Col>
            </Row>
            <div className="border-bottom py-3"></div>
            <Row className="mt-5">
              <Col lg={8} md={12} sm={12}>
                <div className={classes.joboverview}>
                  <h5 className="border-bottom pb-3 mb-3">
                    Employment Information
                  </h5>
                  <Row className=" pt-2">
                    <Col md={6} className="d-flex">
                      <div className=" d-flex ms-1 pt-3">
                        <div className={classes.textdes} industry-icon>
                          Job Sector
                        </div>
                        <div className={classes.smallheading}>
                          {job.job_sector || job.industries || "not mentioned"}
                        </div>
                      </div>
                    </Col>
                    <Col md={6} className="d-flex">
                      {/* <div className={classes.sidebariconitem}></div> */}
                      <div className=" d-flex ms-1 pt-3">
                        <div className={classes.textdes} joblevel-icon>
                          Location
                        </div>
                        <div className={classes.smallheading}>
                          {job.job_location || "not mentioned"}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className=" pt-2">
                    <Col md={6} className="d-flex">
                      {/* <div className={classes.sidebariconitem}></div> */}
                      <div className=" d-flex ms-1 pt-3">
                        <div className={classes.textdes}>Job Role</div>
                        <div className={classes.smallheading}>
                          {job.job_role || "not mentioned"}
                        </div>
                      </div>
                    </Col>
                    <Col md={6} className="d-flex">
                      {/* <div className={classes.sidebariconitem}></div> */}
                      <div className=" d-flex ms-1 pt-3">
                        <div className={classes.textdes}>Job Type</div>
                        <div className={classes.smallheading}>
                          {getLabelByValue(job.job_type, jobtype)}
                          {job.work_mode &&
                            ` / ${getLabelByValue(job.work_mode, workmode)}`}
                        </div>
                      </div>
                    </Col>
                  </Row>{" "}
                  <Row className=" pt-2">
                    <Col md={6} className="d-flex">
                      {/* <div className={classes.sidebariconitem}></div> */}
                      <div className=" d-flex ms-1 pt-3">
                        <div className={classes.textdes}>Salary</div>
                        <div className={classes.smallheading}>
                          {" "}
                          {getLabelByValue(job.salary, salary) ||
                            "not mentioned"}
                        </div>
                      </div>
                    </Col>
                    <Col md={6} className="d-flex">
                      {/* <div className={classes.sidebariconitem}></div> */}
                      <div className=" d-flex ms-1 pt-3">
                        <div className={classes.textdes}>Experience</div>
                        <div className={classes.smallheading}>
                          {" "}
                          {getLabelByValue(job.experience_req, experience) ||
                            "not mentioned "}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className=" pt-2">
                    <Col md={6} className="d-flex">
                      {/* <div className={classes.sidebariconitem}></div> */}
                      <div className=" d-flex ms-1 pt-3">
                        <div className={classes.textdes}>Posted On</div>
                        <div className={classes.smallheading}>
                          {formatDate(job.job_posted_date) || "not mentioned "}
                        </div>
                      </div>
                    </Col>
                    <Col md={6} className="d-flex">
                      {/* <div className={classes.sidebariconitem}></div> */}
                      <div className="d-flex ms-1 pt-3">
                        <div className={classes.textdes}>Education</div>
                        <div className={classes.smallheading}>
                          {/* {job.education_req && job.education_req.join("\n")} */}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className={classes.contentsingle}>
                  <div>
                    <h4>Job description</h4>
                    <p>
                      {job.job_description_html &&
                        job.job_description_html
                          .split(/\r?\n/)
                          .map((line, index) => (
                            <div className={classes1.mdtext} key={index}>
                              {parse(line)}
                            </div>
                          ))}
                        {job.det_desc &&
                        job.det_desc.split(/\r?\n/).map((line, index) => (
                          <div className={classes1.mdtext} key={index}>
                            {parse(line)}
                          </div>
                        ))}
                    </p>
                  </div>
                  <div>
                    <h4>Skill overview </h4>
                    <div>
                      {job.soft_skills &&
                        job.soft_skills.map(
                          (skill, index) =>
                            skill.trim() !== "" && (
                              <Badge
                                key={index}
                                bg="info"
                                className="fw-normal p-2 me-3 mb-3"
                                style={{ fontSize: "14px", color: "#4f5e64" }}
                              >
                                {skill.trim()}
                              </Badge>
                            )
                        )}
                    </div>

                        <div>
                      {job.skills_req &&
                        job.skills_req.split(",").map((skill) => (
                          <Badge
                            bg="info"
                            className="fw-normal p-2 me-3 mb-3"
                            style={{ fontSize: "14px", color: "#4F5E64" }}
                          >
                            {skill.trim()}
                          </Badge>
                        ))}
                    </div>
                  </div>
                </div>
                        
                <div className={classes.authorsingle}>
                  -<span>{job.company_name}</span>-
                </div>

                <div className={classes.applyjobs}>
                 <Row className="align-items-center">
                    <Col>
                      {user !== "null" && selectedJob === "E" ? (
                        <Button size="lg" className="me-2" onClick={handleShow}>
                          Apply now
                        </Button>
                      ) : (
                        <a
                          href={job.job_link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button size="lg" className="me-2">
                            <RiVerifiedBadgeLine className="me-2" />
                            Apply now
                          </Button>
                        </a>
                      )}
                      {user === "null" ? (
                        ""
                      ) : (
                        <Button
                          size="lg"
                          variant="outline-dark"
                          className="fw-semibold"
                          onClick={handleShowScore}
                        >
                          Check Score
                        </Button>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col
                lg={4}
                md={12}
                sm={12}
                className="ps-2 ps-lg-4 mt-lg-0 mt-2 "
              >
                <div className={classes.sidebarborder}>
                  <div className="pt-4">
                    <figure>
                      <img
                        onError={(e) => {
                          e.target.src = logo;
                        }}
                        src={job.company_logo || logo}
                        alt="company logos"
                      />
                    </figure>
                    <div className={classes.sidebarinfo}>
                      <a
                        className={classes.sidebarcompany + " text-dark"}
                        target="_blank"
                        rel="noopener"
                        href={job.company_link}
                      >
                        {job.company_name}
                      </a>
                      {job.ambition_box_company_rating && (
                        <StarRating rating={job.ambition_box_company_rating} />
                      )}
                      <span className={classes.companyloc}>
                        <IoLocationOutline className="mb-1 me-1" />
                        {job.job_location}
                      </span>
                      <u className={classes.linkunderline + " mt-2"}>
                        {job.jobs_posted} Open Jobs
                      </u>
                    </div>
                  </div>
                  <div className={classes.sidebarlistjob}>
                    {job.address && job.address.length > 0 && (
                      <div className={`${classes.boxmap} pt-3 border-top`}>
                        <a
                          href={job.address[0].direction}
                          target="_blank"
                          rel="noreferrer"
                          title={job.address[0].company_address}
                        >
                          <img src={map} alt="map" />
                        </a>
                      </div>
                    )}
                    {job.address && job.address.length > 0 && (
                      <ul className={classes.uldisc}>
                        <li>{job.address[0].company_address}</li>
                        <li>Phone: {job.phone || "not disclosed"}</li>
                        <li>Email: {job.email || "not disclosed"}</li>
                      </ul>
                    )}
                  </div>
                </div>

                {similarJobs.length > 0 && (
                  <div className={classes.sidebarborder}>
                    <h6 className={classes.h6 + " pt-4"}>Similar jobs</h6>
                    <div className={classes.sidebarlistjob}>
                      <ul>
                        {similarJobs.map((sjob) => (
                          <li>
                            <div className={classes.cardlist4}>
                              <div className={classes.image}>
                                <img
                                  onError={(e) => {
                                    e.target.src = logo;
                                  }}
                                  src={sjob.company_logo || logo}
                                  alt="company logos"
                                />
                              </div>
                              <div className={classes.infotext + " ps-4"}>
                                {selectedJob === "E" ? (
                                  <Link to={`/job/${sjob.id}`}>
                                    <h5
                                      onClick={() => {
                                        fetchSingleJob(sjob.id);
                                      }}
                                    >
                                      {sjob.job_title}
                                    </h5>
                                  </Link>
                                ) : (
                                  <a
                                    href={sjob.job_link}
                                    target="_blank"
                                    rel="noopener"
                                  >
                                    <h5>{sjob.job_title}</h5>
                                  </a>
                                )}
                                <a
                                  href={sjob.company_link}
                                  target="_blank"
                                  className={classes.cardprice}
                                >
                                  {sjob.company_name}
                                </a>
                                <div className={classes.cardtime + " pt-2 "}>
                                  <IoLocationOutline className="me-1 mb-1" />
                                  {sjob.location}
                                </div>
                                <div
                                  className={
                                    classes.cardtime + " text-end mt-1"
                                  }
                                >
                                  {formatTimeDifference(sjob.date)}
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
              </Col>
            </Row>
            {otherJobs.length > 0 && (
              <div className="my-5">
                <div className="text-left pb-5">
                  <h2 className="fw-bold text-dark">Featured Jobs</h2>
                  <p
                    className="text-secondary"
                    style={{
                      fontSize: "18px",
                      fontWeight: 500,
                      lineHeight: "26px",
                    }}
                  >
                    Get the latest jobs from {job.company_name}
                  </p>
                </div>

                <SliderCarousel jobs={otherJobs} />
              </div>
            )}
          </div>
        )}
      </CandidateBody>
      <JobApply
        show={show}
        handleClose={handleClose}
        job={job}
        setShow={setShow}
      />
      <CheckScore
        show={showScore}
        handleClose={handleCloseScore}
        job={job}
        setShow={setShow}
      />
    </>
  );
};

export default JobDetails;
