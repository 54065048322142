import React from "react";
import TechnicalSkills from "./TechincalSkills";
import SoftSkills from "./SoftSkills";
import classes from "./ResumeFormData.module.css";
import CustomButton from "../../resumeUI/CustomButton";
import CustomButtonOutline from "../../resumeUI/CustomButtonOutline";
import { useResumeBuilderContext } from "../../../../provider/ResumeBuilderContext";

const Skills = () => {
  const { resumeData, handleResumeFormChange, handleNext, handlePrevious } =
    useResumeBuilderContext();
  return (
    <div>
      <div className={classes.resumecontentform}>
        <div className={classes.resumeformheading}>
          Skills
          <sup style={{ fontSize: "14px" }} className="text-danger fw-semibold">
            *required
          </sup>
        </div>
        <div className={classes.resumeformsubheading}>
          Review your skills
        </div>
        <TechnicalSkills />
        <SoftSkills />
        <div className="d-flex align-items-center justify-content-between">
        <CustomButtonOutline text="Back" onClickFn={handlePrevious} />
        <CustomButton text="Next" onClickFn={handleNext} />
      </div>
      </div>
      
    </div>
  );
};

export default Skills;
