import React, { useEffect} from "react";
import ProfileView from "./ProfileView";
import { useProfileContext } from "../../../provider/ProfileContext";
import Loading from "../../../ui/common/Loading";
import CandidateHeader from "../layouts/CandidateHeader";
import Footer from "../../home/layouts/Footer";
import WorkProfile from "./WorkProfile";

const Profile = () => {
  const { profileData, isLoadingProfile, fetchProfile } = useProfileContext();
  let profileId = localStorage.getItem("profile");
  useEffect(() => {
    fetchProfile(profileId);
  }, [profileId]);
  return (
    <div>
      {isLoadingProfile ? (
        <Loading />
      ) : (
        <>
          <CandidateHeader />
          <ProfileView />
          <Footer />
        </>
      )}
    </div>
  );
};

export default Profile;
