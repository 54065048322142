import React from "react";
import classes from "./CustomButton.module.css"
import { Button } from "react-bootstrap";
import { FaArrowCircleLeft } from "react-icons/fa";


const CustomButtonOutline = (props) => {
  return (
    <div className="mt-3">
      <Button
      size="sm"
      className="px-4 py-2 fw-semibold d-flex align-items-center rounded-5"         type={props.type}
       variant="outline-dark" onClick={props.onClickFn}>
       <FaArrowCircleLeft className="me-2 fs-6"/> {props.text}
      </Button>
    </div>
  );
};

export default CustomButtonOutline;
