import React from "react";
import classes from "./Authentication.module.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import hirer from "../../assets/hirerselect.png";
import seeker from "../../assets/jobseekerchoose.png";
import { useNavigate } from "react-router-dom";

const ChooseRole = () => {
  const navigate = useNavigate();

  const redirectToSeekerSignin = () => {
    localStorage.setItem("role", "C");
    navigate("/register");
  };

  const redirectToEmpSignin = () => {
    localStorage.setItem("role", "H");
    navigate("/register");
  };

  return (
    <div className={classes.choosebg} >
      <header className={classes.chooseRole}>
        <Container>
          <div className="text-center">
            <a
              href="/"
              className=" fs-4 text-dark d-flex align-items-center "
              style={{ fontWeight: "700" }}
            >
              <div
              
                className="bg-primary p-1 text-white"
                style={{ borderRadius: "50px", height: "45px", width: "45px" }}
              >
                em
              </div>
              Career
            </a>
          </div>
        </Container>
      </header>
      <Row>
        <div className=" text-center" style={{ marginTop: "5rem" }}>
          <h3 className="fw-bold">Let's get Started !</h3>
          <div className="text-secondary fw-semibold">Choose Your Role</div>
        </div>
        <Col
          md={12}
          sm={12}
          lg={6}
          className=" d-flex flex-colomn  justify-content-center"
        >
          <div className={classes.hirerside}>
            <div className="d-flex align-items-center justify-content-center">
              <img className={classes.hirer} alt="hirer" src={hirer} />
            </div>
            <div className="fs-4  text-primary my-3 fw-semibold">
              For Employers
            </div>
            <div
              style={{ fontSize: "14px", textAlign: "justify" }}
              className="text-muted my-3"
            >
              Looking to build your dream team? Explore a pool of talented
              individuals ready to contribute to your projects. 
            </div>
            <Button
              className=" text-center w-100"
              onClick={redirectToEmpSignin}
            >
              Hire Now
            </Button>
          </div>
        </Col>
        <Col
          md={12}
          sm={12}
          lg={6}
          className=" d-flex flex-colomn  justify-content-center"
        >
          <div className={classes.hirerside}>
            <div className="d-flex align-items-center justify-content-center">
              <img className={classes.hirer} alt="hirer" src={seeker} />
            </div>
            <div className="fs-4  text-dark my-3 fw-semibold">
              For Employees
            </div>
            <div
              style={{ fontSize: "14px", textAlign: "justify" }}
              className="text-muted my-3"
            >
              Ready to take the next step in your career? Browse exciting job
              opportunities from verified companies. 
            </div>
            <Button
            variant="dark"
              className=" text-center w-100"
              onClick={redirectToSeekerSignin}
            >
              Get Hired
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ChooseRole;
