import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import templateChangeHandling from "../utils/TemplateChangeHandling";
import { data } from "../data/ResumeData";
import {
  validateEmail,
  validatePhone,
  validateDate,
  validateAddress,
} from "../utils/Validation";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { url } from "../data/Constants";
import { useAuthContext } from "./AuthContext";

const ResumeBuilderContext = createContext();

export const ResumeBuilderProvider = ({ children }) => {
  const { headers } = useAuthContext();
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const resumeRef = useRef(null);
  const [present, setPresent] = useState(false);
  const [disableEditor, setDisableEditor] = useState(false);
  const [showResume, setShowResume] = useState(false);
  const [resumeData, setResumeData] = useState(data);
  const [resumeid, setresumeid] = useState(() => {
    return localStorage.getItem("resumeId") || null;
  });
  const [currentStepPage, setCurrentStepPage] = useState(() => {
    return localStorage.getItem("currentStep") || 3;
  });

  const [selectedTemplate, setSelectedTemplate] = useState(() => {
    return localStorage.getItem("selectedTemplate") || "Resume1";
  });
  const [downloaded, setDownloaded] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [resume, setResume] = useState(null);
  const [errors, setErrors] = useState({
    email: "",
    phone: "",
    dob: "",
    job_title: "",
    firstName: "",
    startDate: "",
    endDate: "",
    address: "",
  });
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [showError, setShowError] = useState(true);
  const [showPopUp, setShowPopUp] = useState(false);
  const [page, setPage] = useState(2);
  const [showScore, setShowScore] = useState(false);
  const [pdfReady, setPdfReady] = useState(false);

  const dobInOriginalFormat = resumeData.dob;
  const originalDate = new Date(dobInOriginalFormat);
  const formattedDate = `${originalDate
    .getDate()
    .toString()
    .padStart(2, "0")}-${(originalDate.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${originalDate.getFullYear()}`;

  useEffect(() => {
    localStorage.setItem("currentStep", currentStepPage);
    localStorage.setItem("selectedTemplate", selectedTemplate);
    localStorage.setItem("resumeId", resumeid);
    // localStorage.setItem("data", JSON.stringify(data));

    const temp = templateChangeHandling(selectedTemplate);
    setResume(temp);
    localStorage.setItem("resume", resume);
  }, []);

  const handleNext = () => {
    if (currentStepPage === 6) {
      if (
        resumeData.techskills &&
        resumeData.techskills.some((skl) => skl.techSkill) &&
        resumeData.skill &&
        resumeData.skill.some((skl) => skl.softSkill)
      ) {
        setCurrentStepPage((prevStep) => +prevStep + 1);
        localStorage.setItem("currentStep", Number(currentStepPage) + 1);
      } else {
        alert(
          "Kindly ensure that you provide information in both skills section"
        );
        setCurrentStepPage(6);
        localStorage.setItem("currentStep", 6);
      }
    } else {
      setCurrentStepPage((prevStep) => +prevStep + 1);
      localStorage.setItem("currentStep", Number(currentStepPage) + 1);
    }
  };

  const handleOpen = () => {
    setShowResume(true);
  };
  const handleClose = () => {
    setShowResume(false);
  };

  const handlePrevious = () => {
    setCurrentStepPage((prevStep) => +prevStep - 1);
    localStorage.setItem("currentStep", Number(currentStepPage) - 1);
  };

  const selectPage = (page) => {
    if (page === 7) {
      if (
        resumeData.techskills &&
        resumeData.techskills.some((skl) => skl.techSkill) &&
        resumeData.skill &&
        resumeData.skill.some((skl) => skl.softSkill)
      ) {
        setCurrentStepPage(page);
        localStorage.setItem("currentStep", page);
      } else {
        alert(
          "Kindly ensure that you provide information in both skills section"
        );
        setCurrentStepPage(page - 1);
        localStorage.setItem("currentStep", page - 1);
      }
    }
    // else if (page === 9) {
    //   if (!downloaded) {
    //     alert("please save your resume first . ");
    //   } else {
    //     setShowScore(true);
    //     setCurrentStepPage(page);
    //     localStorage.setItem("currentStep", page);
    //   }
    // }
    else {
      setCurrentStepPage(page);
      localStorage.setItem("currentStep", page);
    }
  };

  const handleResumeUpload = async (e) => {
    setUploading(true);
    const files = e.target.files;
    setFile(files);
    const file = files[0];
    // console.log(file);
    const filename = file.name;
    setFileName(filename);
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("cv", files[i]);
    }

    try {
      const response = await axios.post(`${url}user/resume_builder_upload/`, formData, {
        headers,
      });
      setDownloaded(true);
      const updatedFiles = [...uploadedFiles, ...files];
      localStorage.setItem("resumeId", response.data.id);
      setUploadedFiles(updatedFiles);

      const updatedResumeData = {
        ...resumeData,
        ...response.data,
      };

      setResumeData(updatedResumeData);
      setUploading(false);
      return response;
    } catch (error) {
      setUploading(false);
      console.log(error);
      // console.log(error);
      if (error.message === "Network Error") {
        throw new Error("Network error!");
      } else if (error.response && error.response.status === 400 && error.response.data.error ) {

        throw new Error(error.response.data.error[0]);
      }else{
        throw new Error("Resume Extraction Failed");

      }
      throw error;
    }
  };

  const handleResumeFormChange = (e) => {
    if (e && e.target) {
      setPdfReady(true);
      setDownloaded(false);
      const { name, value } = e.target;

      let isValid = true;
      let errorMessage = "";

      switch (name) {
        case "email":
          isValid = validateEmail(value);
          errorMessage = isValid ? "" : "Invalid email";
          break;
        case "phone":
          isValid = validatePhone(value);
          errorMessage = isValid ? "" : "Invalid phone";
          break;
        case "dob":
          isValid = validateDate(value);
          errorMessage = isValid ? "" : "Age must be greater than 10";
          break;
        case "address":
          isValid = validateAddress(value);
          errorMessage = isValid ? "" : "Maximum length 70 characters";
          break;
        default:
          break;
      }

      if (!isValid) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: errorMessage,
        }));
      }

      setResumeData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));

      if (isValid || value === "") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
      }
    }
  };

  const filterNullValues = (data) => {
    if (Array.isArray(data)) {
      const filteredArray = data
        .map((item) => filterNullValues(item))
        .filter((item) => Object.keys(item || {}).length > 0);
      return filteredArray.length > 0 ? filteredArray : null;
    } else if (typeof data === "object" && data !== null) {
      const filteredObject = Object.fromEntries(
        Object.entries(data)
          .map(([key, value]) => [key, filterNullValues(value)])
          .filter(([key, value]) => key !== "cv" && value !== null)
      );

      return Object.keys(filteredObject || {}).length > 0
        ? filteredObject
        : null;
    } else {
      return data;
    }
  };

  const saveResumeApi = async () => {
    const refinedData = filterNullValues(resumeData);
    const response = await axios.post(`${url}user/build/`, refinedData, {
      headers,
    });
    localStorage.setItem("resumeId", response.data.id);
    setresumeid(response.data.id);
    return response;
  };

  const contextValue = {
    resumeData,
    setResumeData,
    handleResumeFormChange,
    saveResumeApi,
    currentStep: currentStepPage,
    setCurrentStep: setCurrentStepPage,
    handleNext,
    handlePrevious,
    selectSection: selectPage,
    resume,
    setResume,
    handleResumeUpload,
    selectedTemplate,
    setSelectedTemplate,
    errors,
    setErrors,
    uploading,
    setUploading,
    fileName,
    setFileName,
    downloading,
    setDownloading,
    showError,
    setShowError,
    showPopUp,
    setShowPopUp,
    resumeRef,
    present,
    setPresent,
    file,
    setFile,
    formattedDate,
    handleOpen,
    handleClose,
    showResume,
    setShowResume,
    page,
    setPage,
    showScore,
    setShowScore,
    downloaded,
    setDownloaded,
  };

  // console.log(resumeData);
  // console.log("downloded_____",downloaded)

  return (
    <ResumeBuilderContext.Provider value={contextValue}>
      {children}
    </ResumeBuilderContext.Provider>
  );
};

export const useResumeBuilderContext = () => useContext(ResumeBuilderContext);
