import Naukri from "../assets/websiteimages/Naukri.png";
import Seek from "../assets/websiteimages/Seek.png";
import Dice from "../assets/websiteimages/Dice.png";
import FresherWorld from "../assets/websiteimages/FresherWorld.png";
import Talent from "../assets/websiteimages/Talent.png";
import Rigzone from "../assets/websiteimages/Rigzone.png";

export const websiteimages = [
  { id: 1, src: Naukri, alt: "Naukri" },
  { id: 2, src: Seek, alt: "Seek" },
  { id: 3, src: Dice, alt: "Dice" },
  { id: 4, src: FresherWorld, alt: "FresherWorld" },
  { id: 5, src: Talent, alt: "Talent" },
  { id: 6, src: Rigzone, alt: "Rigzone" },
];
