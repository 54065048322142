import React from "react";
import { Placeholder, Stack } from "react-bootstrap";
import classes from "./Companies.module.css";

const CompaniesSkelton = () => {
  return (
    <div className="mb-3">
      <div className={classes.compcard + " text-center my-3"}>
        <Placeholder
          as="div"
          animation="glow"
          style={{ padding: "40px 20px 15px 20px" }}
        >
          <Placeholder
            bg="secondary"
            style={{
              borderRadius: "10px",
              height: "60px",
              width: "60px",
            }}
          />

          <Placeholder
            bg="secondary"
            as="div"
            size="lg"
            sm={10}
            className=" mt-3"
          />
          <Placeholder
            bg="secondary"
            as="div"
            size="xs"
            sm={8}
            className=" mt-2"
          />
         
          <Placeholder
            bg="secondary"
            as="div"
            size="xs"
            sm={8}
            className=" mt-2"
          />
          <div className="text-center mt-5">
            {" "}
            <Placeholder
              bg="secondary"
              sm={5}
              style={{ borderRadius: "5px", height: "50px" }}
            />
          </div>
        </Placeholder>
      </div>
    </div>
  );
};

export default CompaniesSkelton;
