import React, { useEffect } from "react";
import classes from "./Common.module.css";
import { Col, Row } from "react-bootstrap";

const TagsInput = ({
  initialSkills,
  input,
  onChange,
  tags,
  setTags,
  setInput,
  isKeyReleased,
  setIsKeyReleased,
  onUpdateTags,
}) => {
  const onKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = input.trim();

    if (key === "," && trimmedInput.length && !tags.includes(trimmedInput)) {
      e.preventDefault();
      setTags((prevState) => [...prevState, trimmedInput]);
      setInput("");
    }

    if (key === "Enter") {
      // Check if Enter key is pressed
      e.preventDefault();
      if (trimmedInput.length && !tags.includes(trimmedInput)) {
        setTags((prevState) => [...prevState, trimmedInput]);
        setInput("");
      }
    }

    if (key === "Backspace" && !input.length && tags.length && isKeyReleased) {
      const tagsCopy = [...tags];
      const poppedTag = tagsCopy.pop();
      e.preventDefault();
      setTags(tagsCopy);
      setInput(poppedTag);
    }

    setIsKeyReleased(false);
  };

  useEffect(() => {
    onUpdateTags(tags);
  }, [tags]);

  // console.log("Tags input", tags);
  const deleteTag = (index) => {
    setTags((prevState) => prevState.filter((tag, i) => i !== index));
  };
  const onKeyUp = () => {
    setIsKeyReleased(true);
  };

  useEffect(() => {
    // Initialize tags state with initialSkills prop when component mounts
    if (initialSkills) {
      setTags(initialSkills.split(",").map(skill => skill.trim()));
    }
  }, [initialSkills]);
  return (
    <div className={classes.tagsinput}>
      {" "}
      <Row className="d-flex align-items-center">
        <Col xs={12} md={11} lg={12}>
          <input
            id="input-tag"
            className={classes.input}
            name="Tags input"
            // required
            value={input}
            placeholder="Enter skills and press enter or ,"
            onKeyDown={onKeyDown}
            onChange={onChange}
          />
        </Col>
      </Row>
      <div className={classes.tags}>
        {tags.map((tag, index) => (
          <li key={index} className={classes.tag + " mt-2"}>
            <span className={classes.tagtitle}>{tag}</span>
            {/* {tag} */}
            <span
              className={classes.tagcloseicon + "  px-1"}
              onClick={() => deleteTag(index)}
            >
              ✕
            </span>
          </li>
        ))}
      </div>
    </div>
  );
};

export default TagsInput;