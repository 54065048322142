import React, { useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import { BsBuildings } from "react-icons/bs";
import { MdOutlineWorkOutline } from "react-icons/md";
import classes from "../../components/recruiter/dashboard/screens/Screens.module.css";

const RecruiterTabs = (props) => {
  const [activeTab, SetactiveTab] = useState("first");
  const handleTabClick = (eventKey) => {
    SetactiveTab(eventKey);
  };
  return (
    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
      <Nav className="flex-row gap-lg-4 gap-5">
        <Nav.Item>
          <Nav.Link
            eventKey="first"
            className={classes.customNavlink}
            style={{
              fontWeight: "500",
              color: activeTab === "first" ? "#05264E" : "#4F5E64",
              border:
                activeTab === "first"
                  ? "1px solid #B4C0E0"
                  : "1px solid #e0e6f7",
            }}
            onClick={() => handleTabClick("first")}
          >
            <BsBuildings /> <span>About us</span>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            eventKey="second"
            className={classes.customNavlink}
            style={{
              fontWeight: "500",
              color: activeTab === "second" ? "#05264E" : "#4F5E64",

              border:
                activeTab === "second"
                  ? "1px solid #B4C0E0"
                  : "1px solid #e0e6f7",
            }}
            onClick={() => handleTabClick("second")}
          >
            <MdOutlineWorkOutline />
            <span> Recruitments</span>
          </Nav.Link>
        </Nav.Item>
      </Nav>
      <div sm={12} className="border-bottom  mt-4 mb-5"></div>
      <Tab.Content>{props.children}</Tab.Content>
    </Tab.Container>
  );
};

export default RecruiterTabs;
