import React, { useEffect } from "react";
import jobclass from "../jobsearch/Jobsearch.module.css";
import CustomSelect from "../jobsearch/CustomSelect";
import { Row, Col, Button, Pagination } from "react-bootstrap";
import { companyrating } from "../../../data/JobData";
import { GrPowerReset } from "react-icons/gr";
import CompanyFilter from "./CompanyFilter";
import CompanyCard from "./CompanyCard";
import { useCompanyContext } from "../../../provider/CompanyContext";
import CandidateBody from "../../home/layouts/common/CandidateBody";
import noresume from "../../../assets/Noresume.svg";
import CompaniesSkelton from "./CompaniesSkelton";

const Companies = () => {
  const {
    filters,
    handleChangeFilter,
    onResetFilter,
    fetchCompanies,
    companiesData,
    companyCount,
    isLoading,
    currentPage,
    setCurrentPage,
  } = useCompanyContext();

  useEffect(() => {
    fetchCompanies(1);
  }, []);

  const renderPaginationItems = () => {
    const totalPages = Math.ceil(companyCount / 12);
    const maxPagesToShow = 5;
    const items = [];

    if (totalPages <= maxPagesToShow) {
      for (let number = 1; number <= totalPages; number++) {
        items.push(
          <Pagination.Item
            key={number}
            active={number === currentPage}
            onClick={() => handlePageChange(number)}
          >
            {number}
          </Pagination.Item>
        );
      }
    } else {
      let startPage = currentPage - Math.floor(maxPagesToShow / 2);
      let endPage = currentPage + Math.floor(maxPagesToShow / 2);

      if (startPage < 1) {
        startPage = 1;
        endPage = maxPagesToShow;
      }

      if (endPage > totalPages) {
        endPage = totalPages;
        startPage = totalPages - maxPagesToShow + 1;
      }

      if (startPage > 1) {
        items.push(
          <Pagination.Item
            key={1}
            active={currentPage === 1}
            onClick={() => handlePageChange(1)}
          >
            1
          </Pagination.Item>
        );
        items.push(<Pagination.Ellipsis key="start-ellipsis" />);
      }

      for (let number = startPage; number <= endPage; number++) {
        items.push(
          <Pagination.Item
            key={number}
            active={number === currentPage}
            onClick={() => handlePageChange(number)}
          >
            {number}
          </Pagination.Item>
        );
      }

      if (endPage < totalPages) {
        items.push(<Pagination.Ellipsis key="end-ellipsis" />);
        items.push(
          <Pagination.Item
            key={totalPages}
            active={currentPage === totalPages}
            onClick={() => handlePageChange(totalPages)}
          >
            {totalPages}
          </Pagination.Item>
        );
      }
    }

    return items;
  };

  const handlePageChange = (pageNumber) => {
    const targetElement = document.getElementById("companies");
    if (targetElement) {
      const targetPosition =
        targetElement.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = targetPosition - 100;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
    setCurrentPage(pageNumber);
    fetchCompanies(pageNumber);
  };
  return (
    <CandidateBody>
      <CompanyFilter />
      <div className="my-5">
        <div className={jobclass.boxfilters}>
          <Row className="d-flex align-items-center border-bottom pb-2 px-0"  id="companies">
            <Col xl={6} lg={5} sm={6} xs={5}>
              <span className={jobclass.textsmall + " ms-1"}>
                Showing <strong>{(currentPage*12)-11}- {currentPage*12}</strong> of
                <strong> {companyCount}</strong> companies
              </span>
            </Col>
            <Col xl={6} lg={7} sm={6} xs={7} className="text-lg-end">
              {/* <div className="d-flex align-items-center justify-content-end">
                <div className="d-flex align-items-center border rounded px-1">
                  <span
                    className="fw-semibold"
                    style={{ fontSize: "12px", color: "#A0ABC3" }}
                  >
                    rating :
                  </span>
                  <CustomSelect
                    options={companyrating}
                    // handleChange={handleChangeFilter("companyrating")}
                    // selectedvalue={filters.companyrating}
                    fontSize="12px"
                  />
                </div>
                <Button
                  className="ms-3"
                  style={{
                    fontSize: "12px",
                    cursor: "pointer",
                    padding: "8px",
                  }}
                  onClick={onResetFilter}
                >
                  Reset
                  <GrPowerReset className="ms-1" style={{ fontSize: "12px" }} />
                </Button>
              </div> */}
            </Col>
          </Row>
        </div>

        {companyCount <= 0 && !isLoading ? (
          <Row className="text-center vh-100">
            <Col className="d-flex  align-items-center flex-column justify-content-center">
              <img
                src={noresume}
                alt="noresumes"
                width={700}
                className="img-fuid"
              />
              <div className="fw-semibold fs-6">
                Uh.. Oh no companies available at the moment
              </div>
            </Col>
          </Row>
        ) : (
          <>
            {isLoading ? (
              <Row>
                {[...Array(8)].map((_, index) => (
                  <Col xl={3} lg={3} md={6} sm={12} xs={12} key={index}>
                    <CompaniesSkelton />
                  </Col>
                ))}
              </Row>
            ) : (
              <Row>
                {companiesData.map((com) => (
                  <Col xl={3} lg={3} md={6} sm={12} xs={12}>
                    <CompanyCard company={com} key={com.id} />
                  </Col>
                ))}
                <div className="mt-3">
                  <Pagination className="custom-pagination">
                    <Pagination.Prev
                      className="custom-page-prev-next"
                      onClick={() =>
                        handlePageChange(currentPage > 1 ? currentPage - 1 : 1)
                      }
                      disabled={currentPage === 1}
                    />
                    {renderPaginationItems()}
                    <Pagination.Next
                      className="custom-page-prev-next"
                      disabled={currentPage === Math.ceil(companyCount / 12)}
                      onClick={() =>
                        handlePageChange(
                          currentPage < Math.ceil(companyCount / 12)
                            ? currentPage + 1
                            : currentPage
                        )
                      }
                    />
                  </Pagination>
                </div>
              </Row>
            )}
          </>
        )}
      </div>
    </CandidateBody>
  );
};

export default Companies;
