import React from "react";
import Star from "./Star";

const StarRating = ({ rating }) => {
  const totalStars = 5;
  const stars = [];

  for (let i = 1; i <= totalStars; i++) {
    const fullStar = Math.floor(rating);
    const fractionalPart = rating - fullStar;

    if (i <= fullStar) {
      stars.push(<Star key={i} fill={true} />);
    } else if (i === fullStar + 1 && fractionalPart > 0) {
      stars.push(
        <span
          key={i}
          style={{ color: "#e4e5e9", position: "relative", fontSize: "1.2rem" }}
        >
          &#9733;
          <span
            style={{
              color: "#FFD700",
              position: "absolute",
              left: "0",
              fontSize: "1.2rem",
              width: `${fractionalPart * 100}%`,
              overflow: "hidden",
            }}
          >
            &#9733;
          </span>
        </span>
      );
    } else {
      stars.push(<Star key={i} fill={false} />);
    }
  }

  return <div>{stars}</div>;
};

export default StarRating;
