import Resume1 from "../components/resumeBuilder/resumes/Resume1";
import Resume2 from "../components/resumeBuilder/resumes/Resume2";
import Resume3 from "../components/resumeBuilder/resumes/Resume3";
import Resume4 from "../components/resumeBuilder/resumes/Resume4";
import Resume5 from "../components/resumeBuilder/resumes/Resume5";
import Resume6 from "../components/resumeBuilder/resumes/Resume6";
import Resume7 from "../components/resumeBuilder/resumes/Resume7";
import Resume8 from "../components/resumeBuilder/resumes/Resume8";
import Resume9 from "../components/resumeBuilder/resumes/Resume9";
import Resume10 from "../components/resumeBuilder/resumes/Resume10";

const templateChangeHandling = (selectedTemplate) => {
  let templatePreview = null;

  switch (selectedTemplate) {
    case "Resume1":
      templatePreview = <Resume1 />;
      break;
    case "Resume2":
      templatePreview = <Resume2 />;
      break;
    case "Resume3":
      templatePreview = <Resume3 />;
      break;
    case "Resume4":
      templatePreview = <Resume4 />;
      break;
    case "Resume5":
      templatePreview = <Resume5 />;
      break;
    case "Resume6":
      templatePreview = <Resume6 />;
      break;
    case "Resume7":
      templatePreview = <Resume7 />;
      break;
    case "Resume8":
      templatePreview = <Resume8 />;
      break;
    case "Resume9":
      templatePreview = <Resume9 />;
      break;
    case "Resume10":
      templatePreview = <Resume10 />;
      break;

    default:
      break;
  }

  return templatePreview;
};

export default templateChangeHandling;
