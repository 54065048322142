import React, { useState } from "react";
import classes from "../Home.module.css";
import { FaTools } from "react-icons/fa";
import { Stack, Button, Container } from "react-bootstrap";
import JobTools from "./JobTools";
import EmpTools from "./EmpTools";
import EmpSectionBox from "./EmpSectionBox";
import CanSectionBox from "./CanSectionBox";

const ToolsSection = () => {
  const [activeTools, setActiveTools] = useState("C");
  const OnSelectTools = (value) => {
    setActiveTools(value);
  };
  return (
    <section className={classes.sections} id="toolssection">
      <Container>
        <div className="text-center">
          <h2 className={classes.sectionheading + " mb-2 fw-bold text-dark "}>
            Get Familiar With Our
            <span className={classes.textbg + " text-primary"}> Tools </span>
            <span className="text-primary px-1">
              <FaTools />
            </span>
          </h2>
          <div className={classes.normaltext + " text-secondary"}>
            Explore our features
          </div>
          <Stack
            direction="horizontal"
            gap={5}
            className="my-5 justify-content-center"
          >
            <Button
              variant={activeTools === "C" ? "primary" : "outline-primary"}
              onClick={() => OnSelectTools("C")}
            >
              Job Seeker
            </Button>
            <Button
              variant={activeTools === "H" ? "primary" : "outline-primary"}
              onClick={() => OnSelectTools("H")}
            >
              Employer
            </Button>
          </Stack>
        </div>
        {activeTools === "C" ? <JobTools /> : <EmpTools />}
      </Container>
      {activeTools === "C" ? <CanSectionBox /> : <EmpSectionBox />}
    </section>
  );
};

export default ToolsSection;
