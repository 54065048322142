import React from "react";
import classes from "./Companies.module.css";
import { Image, Row, Col } from "react-bootstrap";
import { FaLinkedin } from "react-icons/fa";

const PeopleCard = ({ employee }) => {
  console.log(employee);
  return (
    <Row
      className={
        classes.peoplecard + " border rounded py-3 px-2 "
      }
    >
      <Col lg={2}>
        <div style={{ width: "80px", height: "80px" }}>
          <Image
            src={employee.employee_profile_image}
            alt="profile"
            rounded
            fluid
          />
        </div>
      </Col>
      <Col lg={10}>
        <div className="d-flex justify-content-between align-items-center">
          <a
            style={{ fontSize: "20px" }}
            className="text-dark fw-bold"
            href={employee.employee_link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {employee.employee_name}
          </a>
          <a
            style={{ fontSize: "20px" }}
            className="text-dark fw-bold"
            href={employee.employee_link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin
              className="text-primary"
              style={{ fontSize: "24px", cursor: "pointer" }}
            />
          </a>
        </div>

        <div style={{ fontSize: "14px" }} className="text-secondary pt-1">
          {employee.employee_role}
        </div>
      </Col>
    </Row>
  );
};

export default PeopleCard;
