import React from "react";
import classes from "../components/home/Home.module.css";

const ToolsCard = ({ tools, onSelectTool }) => {
  const selectedTool = (tool) => {
    onSelectTool(tool);
  };
  return (
    <>
      <div
        style={{ cursor: "pointer" }}
        className={classes.toolcard}
        onClick={() => selectedTool(tools.name)}
      >
        <div className={classes.toolicon + " text-primary px-3"}>
          <img alt="toolsicon" src={tools.image} />
        </div>
        <div>
          <div className={classes.subheading + " text-primary fw-bold"}>
            {tools.name}
          </div>
          <div
            className={classes.smtext + " text-secondary"}
            style={{ textAlign: "justify" }}
          >
            {tools.description}
        </div></div>
      </div>
    </>
  );
};

export default ToolsCard;
