import React from "react";
import classes from "./ResumeFormData.module.css";
import Hobbies from "./Hobbies";
import Interests from "./Interests";
import CustomButton from "../../resumeUI/CustomButton";
import CustomButtonOutline from "../../resumeUI/CustomButtonOutline";
import { useResumeBuilderContext } from "../../../../provider/ResumeBuilderContext";
import Languages from "./Languages";
import Achievements from "./Achievements";
import Awards from "./Awards";
import Certifications from "./Certifications";
import Publications from "./Publications";
import VolunteerExperience from "./VolunteerExperience";
import SocialLinks from "./SocialLinks";

const Otherfields = () => {
  const { setShowScore, handlePrevious, handleNext } =
    useResumeBuilderContext();

  return (
    <div className="mb-4">
      <SocialLinks />
      <div className={classes.resumecontentform}>
        <div className={classes.resumeformheading}>Other Fields</div>
        <div className={classes.resumeformsubheading}>
          Highlighting your additional details along with the standard resume
          sections.
        </div>
        <Hobbies />
        <Interests />
        <Languages />
        <Achievements />
        <Awards />
        <Certifications />
        <Publications />
        <VolunteerExperience />
        <div className="d-flex align-items-center justify-content-between">
          <CustomButtonOutline text="Back" onClickFn={handlePrevious} />
          {/* <CustomButton text="Check Score" onClickFn={handleNext} /> */}
        </div>
      </div>
    </div>
  );
};

export default Otherfields;
