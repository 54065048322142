import React, { useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { useProfileContext } from "../../../provider/ProfileContext";
import classes from "./Profile.module.css";
import { FaLink } from "react-icons/fa";
import ProfileModal from "./ProfileModal";
import DeleteProfile from "./DeleteProfile";
import FormInput from "../../../ui/common/FormInput";
import { LuClipboardEdit } from "react-icons/lu";
import { BiTrash } from "react-icons/bi";
import { validateUrl } from "../../../utils/Validation";

const SocialLinksView = () => {
  const {
    profileData,
    handlePersonalChange,
    AddProfileData,
    EditProfileData,
    DeleteProfileData,
    deletingData,
  } = useProfileContext();
  
  const profileid = localStorage.getItem("profile");
  const [disabled, setDisabled] = useState(true);
  const [errors, setErrors] = useState({ name: "", link: "" });
  const [socialProfiles, setSocialProfiles] = useState(
    profileData.social_prof || []
  );
  const [social, setSocial] = useState({
    name: null,
    link: null,
    profile: profileid,
  });
  const [showSocial, setShowSocial] = useState(false);
  const [selectedSocialProfile, setSelectedSocialProfile] = useState(null);
  const [editId, setEditid] = useState(null);
  const [showEditSocial, setShowEditSocial] = useState(false);
  const [showDel, setShowDel] = useState(false);

  const handleCloseSocial = () => setShowSocial(false);
  const handleCloseEditProfile = () => setShowEditSocial(false);

  const handleShowSocial = () => {
    setErrors({ name: "", link: "" });
    setDisabled(true);
    setShowSocial(true);
  };

  const handleShowEditSocial = (id) => {
    setErrors({ name: "", link: "" });
    setDisabled(false);
    setEditid(id);
    const selectedProfile = socialProfiles.find((profile) => profile.id === id);
    setSelectedSocialProfile(selectedProfile);
    setShowEditSocial(true);
  };

  const handleShowDelSocial = (id) => {
    setEditid(id);
    setShowDel(true);
  };

  const handleCloseDelSocial = () => setShowDel(false);

  const handleChange = (e, setProfileState) => {
    const { name, value } = e.target;
    setDisabled(name === "name" && value === "");
    validateAndSetErrors(name, value);
    setProfileState((prevState) => ({ ...prevState, [name]: value }));
  };

  const validateAndSetErrors = (name, value) => {
    if (name === "link" && value !== "") {
      const valid = validateUrl(value);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: valid ? "" : "Enter a valid link",
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const validateProfileData = (profile) => {
    let hasError = false;
    let newErrors = { ...errors };

    if (!profile.link) {
      newErrors.link = "Please fill in the required field.";
      hasError = true;
    }
    if (!profile.name) {
      newErrors.name = "Please fill in the required field.";
      hasError = true;
    }
    if (Object.values(newErrors).some((error) => error !== "")) {
      alert("Please fix the errors in the form before submitting.");
      hasError = true;
    }
    setErrors(newErrors);
    return hasError;
  };

  const handleSubmit = async (profile, apiCall, successCallback) => {
    if (validateProfileData(profile)) {
      return;
    }
    try {
      const response = await apiCall(profile, "social");
      if (response.status === 201 || response.status === 200) {
        successCallback(response.data);
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  const addSocialData = () => {
    handleSubmit(social, AddProfileData, (newSocial) => {
      setSocialProfiles((prev) => [...prev, newSocial]);
      handlePersonalChange({
        target: { name: "social_prof", value: [...socialProfiles, newSocial] },
      });
      setShowSocial(false);
      setSocial({ name: null, link: null, profile: profileid });
    });
  };

  const addEditSocialData = () => {
    handleSubmit(
      selectedSocialProfile,
      (profile) => EditProfileData(profile, "social", editId),
      (newSoc) => {
        setSocialProfiles((prev) =>
          prev.map((soc) => (soc.id === newSoc.id ? newSoc : soc))
        );
        handlePersonalChange({
          target: {
            name: "social_prof",
            value: socialProfiles.map((soc) =>
              soc.id === newSoc.id ? newSoc : soc
            ),
          },
        });
        setShowEditSocial(false);
      }
    );
  };

  const handleDeleteSocial = async () => {
    try {
      const response = await DeleteProfileData("social", editId);
      if (response.status === 204) {
        const updated = socialProfiles.filter((data) => data.id !== editId);
        setSocialProfiles(updated);
        setShowDel(false);
      }
    } catch (error) {
      console.error("Error deleting :", error);
    }
  };

  return (
    <div>
      <div className={classes.personalinfo}>
        <div className="d-flex align-items-center ">
          <div className={classes.companyName + " text-black "}>
            Social Links
          </div>
          <div
            className="ms-4 border p-1 rounded text-primary fw-semibold"
            style={{ fontSize: "12px", cursor: "pointer" }}
            onClick={handleShowSocial}
          >
            + Add more
          </div>
        </div>
        <div className="border-bottom pt-2 mb-3"></div>
        <Row className=" d-flex flex-column" style={{ fontSize: "15px" }}>
          <Col className=" d-flex flex-column mb-2">
            {socialProfiles &&
             socialProfiles.map((social) => (
                <Row className="mt-3 " key={social.id}>
                  <Col lg={1}>
                    <FaLink className="text-secondary" />
                  </Col>
                  <Col lg={9}>
                    <div className="text-secondary fw-semibold">
                      {social.name}
                    </div>
                    <a
                      href={social.link}
                      target="_blank"
                      rel="noreferrer"
                      className={classes.linkContainer + " text-primary"}
                      style={{ fontSize: "14px" }}
                    >
                      {social.link}
                    </a>
                  </Col>
                  <Col lg={1}>
                    <LuClipboardEdit
                      style={{ cursor: "pointer" }}
                      className="ms-1 fs-6 fw-bold"
                      onClick={() => handleShowEditSocial(social.id)}
                    />
                  </Col>{" "}
                  <Col lg={1}>
                    <BiTrash
                      style={{ cursor: "pointer" }}
                      className="ms-1 fs-5 fw-bold text-danger"
                      onClick={() => handleShowDelSocial(social.id)}
                    />
                  </Col>
                </Row>
              ))}
          </Col>
        </Row>
      </div>
      <ProfileModal
        show={showSocial}
        handleClose={handleCloseSocial}
        formtype="add"
        handleSubmitChange={addSocialData}
      >
        <Form.Select
          size="lg"
          onChange={(e) => handleChange(e, setSocial)}
          name="name"
          value={socialProfiles.name}
          isInvalid={!!errors.name}
        >
          <option value="">Select</option>
          <option value="Linkedin">LinkedIn</option>
          <option value="Github">GitHub</option>
          <option value="Website">Website</option>
          <option value="Otherlinks">Other</option>
        </Form.Select>
        <Form.Control.Feedback
          type="invalid"
          style={{
            textAlign: "left",
            color: "red",
            fontSize: "11px",
          }}
        >
          {errors.name}
        </Form.Control.Feedback>
        <FormInput
          name="link"
          type="text"
          placeholder="ex:https://linkedIn.com"
          label="Link"
          value={socialProfiles.link}
          onChange={(e) => handleChange(e, setSocial)}
          className="my-3"
          required="required"
          error={errors.link}
          disabled={disabled}
        />
      </ProfileModal>

      {showEditSocial && (
        <ProfileModal
          show={showEditSocial}
          handleClose={handleCloseEditProfile}
          formtype="edit"
          handleSubmitChange={addEditSocialData}
        >
          <Form.Select
            size="lg"
            onChange={(e) => handleChange(e, setSelectedSocialProfile)}
            name="name"
            value={selectedSocialProfile.name}
            isInvalid={!!errors.name}
          >
            <option value="">Select</option>
            <option value="LinkedIn">LinkedIn</option>
            <option value="GitHub">GitHub</option>
            <option value="Website">Website</option>
          </Form.Select>
          <Form.Control.Feedback
            type="invalid"
            style={{
              textAlign: "left",
              color: "red",
              fontSize: "11px",
            }}
          >
            {errors.name}
          </Form.Control.Feedback>
          <FormInput
            name="link"
            type="text"
            placeholder="ex:https://linkedIn.com"
            label="Link"
            value={selectedSocialProfile.link}
            onChange={(e) => handleChange(e, setSelectedSocialProfile)}
            className="my-3"
            required="required"
            error={errors.link}
            disabled={disabled}
          />
        </ProfileModal>
      )}

      <DeleteProfile
        show={showDel}
        handleClose={handleCloseDelSocial}
        handleDelete={handleDeleteSocial}
      />
    </div>
  );
};

export default SocialLinksView;
