import React from "react";
import classes from "../jobsearch/Jobsearch.module.css";
import logo from "../../../assets/company.png";
import { Row, Col, Badge } from "react-bootstrap";
import { IoLocationOutline } from "react-icons/io5";
import classes1 from "./Companies.module.css";
import { toCamelCase } from "../../../utils/Validation";
import { jobtype, salary } from "../../../data/JobData";
import { getLabelByValue, formatTimeDifference } from "../../../utils/jobutils";
import { SlHandbag } from "react-icons/sl";
import { IoIosTimer } from "react-icons/io";
import { LuIndianRupee } from "react-icons/lu";
import { Link, useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import { useJobContext } from "../../../provider/JobContext";

const CompaniesJobList = ({ job }) => {
  const { fetchSingleExJob } = useJobContext();
  let trimmedDescription = "";
  console.log("_________", job);

  if (job.job_description) {
    trimmedDescription =
      job.job_description.replace(/\n/g, "").trim().slice(0, 180) +
      (job.job_description.length > 80 ? "..." : "");
  }

  return (
    <div className={classes1.joblistcard}>
      <Row className={classes.cardimggrid}>
        {/* {job.current_state ? (
          <span
            className={
              classes.flash +
              " bg-success fw-semibold py-1 px-2 text-white rounded-1"
            }
          >
            Open
          </span>
        ) : (
          <span
            className={
              classes.flash +
              " bg-danger fw-semibold py-1 px-2 text-white rounded-1"
            }
          >
            Close
          </span>
        )} */}

        <Col lg={1}>
          <img
            width={60}
            className={classes.imagebox + " rounded-1"}
            onError={(e) => {
              e.target.src = logo;
            }}
            src={job.company_logo || logo}
            alt="company logos"
          />
        </Col>
        <Col lg={5} className={classes.rightinfo + " ps-2"}>
          <h5>{job.company_name}</h5>
          <span className="fw-normal">
            <IoLocationOutline
              className="me-1"
              style={{ marginBottom: "2px", fontSize: "13px" }}
            />
            {job.job_location}
          </span>
        </Col>
        <Col lg={6} className="text-end">
          <div>
            {job.soft_skills && job.soft_skills.length > 0 && (
              <div>
                {job.soft_skills.slice(0, 2).map((skill, index) => (
                  <Badge
                    key={index}
                    bg="info"
                    className="m-1  p-2 text-dark fw-normal"
                  >
                    {skill.length > 20 ? `${skill.slice(0, 20)}...` : skill}
                  </Badge>
                ))}
              </div>
            )}
            {job.technical_skills && job.technical_skills.length > 0 && (
              <div>
                {job.technical_skills.slice(0, 2).map((skill, index) => (
                  <Badge
                    key={index}
                    bg="info"
                    className="m-1  p-2 text-dark fw-normal"
                  >
                    {skill.length > 20 ? `${skill.slice(0, 20)}...` : skill}
                  </Badge>
                ))}
              </div>
            )}
          </div>
        </Col>
      </Row>

      <div className={classes.cardimggriddet}>
        <h6 className="text-dark">{toCamelCase(job.job_title)}</h6>
        <div className={classes.jobicons + " mt-2"}>
          <span className="me-3">
            <SlHandbag className="me-1" style={{ marginBottom: "4px" }} />
            {getLabelByValue(job.job_type, jobtype)}
          </span>
          <span>
            <IoIosTimer
              className="me-1 fw-light"
              style={{ marginBottom: "4px", fontSize: "14px" }}
            />
            {formatTimeDifference(job.job_posted_date)}{" "}
          </span>
        </div>

        <div className={classes.description + " pt-3 pb-2"}>
          {parse(trimmedDescription)}
        </div>
        <div className={classes.skillreq}>
          {/* {job.job.soft_skills &&
            job.job.soft_skills
              .split(",")
              .slice(0, 5)
              .map(
                (skill, index) =>
                  skill.trim() !== "" && (
                    <a
                      key={index}
                      className="btn btn-grey-small mr-5 mb-2"
                      href="/frontend/jobs-grid"
                    >
                      {skill.trim()}
                    </a>
                  )
              )}
          {job.job.soft_skills && job.job.soft_skill.split(",").length > 5 && (
            <span className=" text-secondary ps-1" style={{ fontSize: "12px" }}>
              more...
            </span>
          )} */}
        </div>
        <div className="mt-4">
          <Row className="d-flex align-items-center">
            <Col lg={6} sm={6}>
              {job.salary === "nan" ? (
                ""
              ) : (
                <span className={classes.salprice}>
                  <LuIndianRupee
                    style={{ marginBottom: "3px", fontSize: "16px" }}
                  />
                  {getLabelByValue(job.salary, salary)}
                </span>
              )}
            </Col>
            <Col
              lg={4}
              sm={6}
              className="text-center justify-content-end ms-auto"
            >
              <Link to={`/exjob/${job.id}`}>
                <div
                  className={classes.applybtncard}
                  onClick={() => {
                    fetchSingleExJob(job.id);
                  }}
                >
                  View Details
                </div>
              </Link>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default CompaniesJobList;
