import React, { useEffect, useState } from "react";
import { Col, Image, Row, Button, Tab } from "react-bootstrap";
import classes from "../Screens.module.css";
import { IoLocationOutline } from "react-icons/io5";
import { FaRegEdit } from "react-icons/fa";
import Loading from "../../../../../ui/common/Loading";
import { useRecruiterProfileContext } from "../../../../../provider/RecruiterProfileContext";
import EditProfileModal from "./EditProfileModal";
import RecruiterTabs from "../../../../../ui/common/RecruiterTabs";
import AboutUs from "./tabs/AboutUs";
import JobCard from "./tabs/JobCard";
import CompanyInfo from "./tabs/CompanyInfo";
import RecruiterBody from "../../../layouts/RecruiterBody";
import recruiterbg from "../../../../../assets/recruiter-banner.png";
import { MdVerifiedUser } from "react-icons/md";
import logo from "../../../../../assets/company.png"

const CompanyProfile = () => {
  const { fetchRecruiterProfile, fetchedData, loadingProfile } =
    useRecruiterProfileContext();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    fetchRecruiterProfile();
  }, []);

  useEffect(() => {
    if (fetchedData && fetchedData.company_name) {
      localStorage.setItem("company_name", fetchedData.company_name);
    }
  }, [fetchedData]);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <>
      {loadingProfile ? (
        <Loading />
      ) : (
        fetchedData && (
          <RecruiterBody>
            <Row>
              <Col lg={12} md={6} sm={2}>
                <Image
                  src={recruiterbg}
                  alt="banner image"
                  fluid={true}
                  width="100%"
                  className="rounded-4 "
                />
                <div className={classes.CompanyProfile}>
                  <div className={classes.imageCompany}>
                    <img
                      src={fetchedData.company_logo}
                      alt={fetchedData.company_name}
                      onError={(e) => {
                        e.target.src = logo;
                      }}
                      src={fetchedData.company_logo || logo}
                      fluid={true}
                    />
                  </div>
                  <Row className="mt-5 d-flex justify-content-between">
                    <Col lg={8} md={12}>
                      <h5
                        className={
                          classes.companyName + " d-flex flex-column gap-2"
                        }
                      >
                        <div className="d-flex align-items-center">
                          {fetchedData.company_name}{" "}
                          <MdVerifiedUser className="ps-2 text-success fs-3" />
                        </div>
                        <p className={classes.smText + " fw-normal "}>
                          <IoLocationOutline />
                          {fetchedData.company_location}
                        </p>
                      </h5>
                    </Col>
                    <Col lg={4} md={12} className="text-lg-end">
                      <Button
                        onClick={handleShowModal}
                        className=" d-inline-flex align-items-center gap-2"
                      >
                        <FaRegEdit /> <span>Edit Profile</span>
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col>
                <RecruiterTabs>
                  <Tab.Pane eventKey="first">
                    <Row className={classes.companyprofile}>
                      <Col lg={8} sm={12} className="  mb-4">
                        <AboutUs company={fetchedData} />
                      </Col>
                      <Col lg={4} sm={12}>
                        <CompanyInfo data={fetchedData} />
                      </Col>
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <Row>
                      <Col lg={8} className=" d-flex flex-column mb-4">
                        <h4 className=" fw-bold text-secondary mb-4">
                          Jobs Posted
                        </h4>
                        <JobCard />
                      </Col>
                      <Col lg={4}>
                        <CompanyInfo data={fetchedData} />
                      </Col>
                    </Row>
                  </Tab.Pane>
                </RecruiterTabs>
              </Col>
            </Row>
          </RecruiterBody>
        )
      )}
      <EditProfileModal
        show={showModal}
        handleCloseModal={handleCloseModal}
        fetchedData={fetchedData}
      />
    </>
  );
};

export default CompanyProfile;
