import React from "react";
import classes from "./ResumeFormData.module.css";
import { Row, Col, Accordion } from "react-bootstrap";
import TagsInput from "../../resumeUI/TagsInput";
import { useResumeBuilderContext } from "../../../../provider/ResumeBuilderContext";
import AccordionField from "../../resumeUI/AccordionField";

const SoftSkills = () => {
  const { resumeData, handleResumeFormChange } =
    useResumeBuilderContext();

  const handleSelectedTags = (tags) => {
    const updatedResumeData = {
      ...resumeData,
      skill: tags.map((tag) => ({ softSkill: tag })),
    };

    handleResumeFormChange({
      target: {
        name: "skill",
        value: updatedResumeData.skill,
      },
    });
  };

  const handleTagRemoval = (indexToRemove) => {
    const updatedTags = resumeData.skill.filter(
      (_, index) => index !== indexToRemove
    );

    handleResumeFormChange({
      target: {
        name: "skill",
        value: updatedTags,
      },
    });
  };

  return (
    <div className=" my-4">
      <AccordionField heading="Soft Skills" >
        <div>
          <Row>
            <Col>
              <TagsInput
                name="skill"
                selectedTags={handleSelectedTags}
                handleTagRemoval={handleTagRemoval}
                tags={
                  resumeData.skill &&
                  (resumeData.skill.some((hob) => hob.softSkill !== null)
                    ? resumeData.skill.map((hob) => hob.softSkill)
                    : [])
                }
                placeholder="Add Skills and press enter"
              />
            </Col>
          </Row>
        </div>
      </AccordionField>
    </div>
  );
};

export default SoftSkills;
