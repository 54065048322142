import React, { useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { RecruiterCandidateListContext } from "../../../../../provider/RecruiterCandidateListContext";
import classes from "../../../../candidate/profile/Profile.module.css";

const EducationPreview = () => {
  const { singleCandidate } = useContext(RecruiterCandidateListContext);
  const educationProfile = singleCandidate.education
    ? singleCandidate.education
    : [];
  return (
    <div className="mb-2 pb-2 border-bottom">
      {educationProfile && educationProfile.length > 0 ? (
        <>
          <div className="d-flex align-items-center mb-4 fw-bold ">
            <h4 className=" fw-bold text-dark ">Education</h4>
          </div>
          <div>
            {educationProfile.map((edu) => (
              <Row key={edu.id} className="mb-4">
                <Col lg={1} sm={2} xs={2}>
                  {edu.course && (
                    <div className={classes.eduelement}>
                      {edu.course.charAt(0)}
                    </div>
                  )}
                </Col>
                <Col lg={11} sm={10} xs={10}>
                  <div className="d-flex flex-column flex-sm-row align-items-start align-items-sm-center">
                    <div
                      className="fw-semibold text-dark"
                      style={{ fontSize: "16px" }}
                    >
                      {edu.course}
                    </div>

                    <div
                      className="d-none d-sm-block ms-lg-5 text-danger"
                      style={{ fontSize: "13px" }}
                    >
                      {edu.proficiency}
                      <span className="ms-1">
                        {edu.proficiency_type === "G"
                          ? "GPA"
                          : edu.proficiency_type === "P"
                          ? "%"
                          : null}{" "}
                      </span>
                    </div>

                    <div
                      className="d-sm-none mt-1 text-dark"
                      style={{ fontSize: "12px" }}
                    >
                      {edu.proficiency}
                      {/* <span className="ms-1">
                        {edu.proficiency_type === "G"
                          ? "GPA"
                          : edu.proficiency_type === "P"
                          ? "%"
                          : null}
                      </span> */}
                    </div>
                  </div>

                  <div style={{ fontSize: "14px" }} className="text-danger">
                    {edu.institution}
                  </div>
                  {edu.start_date && edu.end_date && (
                    <div
                      className=" mt-1 text-secondary"
                      style={{ fontSize: "13px" }}
                    >
                      {edu.start_date} - {edu.end_date}
                    </div>
                  )}
                </Col>
              </Row>
            ))}
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default EducationPreview;
