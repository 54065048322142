import React from "react";
import { Button, Col, Container, Image } from "react-bootstrap";
import classes from "../Screens.module.css";
import TabContainer from "./TabContainer";
import NavbarCommon from "../../../layouts/NavbarCommon";
import { useRecruiterProfileContext } from "../../../../../provider/RecruiterProfileContext";
import { Link } from "react-router-dom";

const CreateProfile = () => {
  const { activeTab, handleTabChange } = useRecruiterProfileContext();

  return (
    <>
      <NavbarCommon />
      <Container
        className="py-4 rounded"
        style={{ backgroundColor: "#F2F6FD" }}
      >
        <TabContainer activeTab={activeTab} handleTabChange={handleTabChange} />
      </Container>
    </>
  );
};

export default CreateProfile;
