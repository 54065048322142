import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { useToast } from "./ToastContext";
import { url } from "../data/Constants";
import { useAuthContext } from "./AuthContext";
import { profile_Info } from "../data/ProfileData";

export const RecruiterCandidateListContext = createContext();

export const RecruiterCandidateListProvider = ({ children }) => {
  const { headers } = useAuthContext();
  const [candidateList, setCandidateList] = useState([]);
  const [profileData, setProfileData] = useState(profile_Info);
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({});
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [prevPageUrl, setPrevPageUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [candidatesData, setCandidatesData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [singleCandidate, setSingleCandidate] = useState([]);
  const [candidateCount, setCandidateCount] = useState(0);
  const { showToast, setShowToast, setToastMessage, setToastBg } = useToast();
  const [upgradeError,setUpgradeError]=useState(false)
  const [filtersCand, setFiltersCand] = useState({
    job_role: "",
  });

  const handleChangeFilter = (name) => (selectedOption) => {
    setFiltersCand((prevFilters) => ({
      ...prevFilters,
      [name]: selectedOption,
    }));
  };

  const onResetFilter = () => {
    setFiltersCand({
      job_role: "",
    });
    fetchCandidates(1);
  };

  const fetchCandidates = async (page) => {
    const params = {
      job_role: filtersCand.job_role,
      page: page,
    };

    Object.keys(params).forEach((key) => {
      if (!params[key]) {
        delete params[key];
      }
    });
    const joburl = `${url}hirer/candidate_search/`;
    setIsLoading(true);
    try {
      const response = await axios.get(joburl, { headers, params });
      // console.log(response.data);
      if (response.data) {
        setUpgradeError(false)
        const data = response.data;
        setCandidatesData(data.values);
        setCandidateCount(data.value_count);
        let pages = page;
        // if (data.next_page !== "null") {
        //   pages = parseInt(data.next_page) - 1;
        // } else if (data.previous_page !== "null") {
        //   pages = parseInt(data.previous_page) + 1;
        // } else {
        //   pages = 0;
        // }
        setCurrentPage(pages);
        setIsLoading(false);
      } else {
        const data = response.data;
        if(response.data && response.data.error){

        }
        setCandidatesData([]);
        // setCompanyCount(data.company_count);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.data && err.response.data.error) {
        setUpgradeError(true)
        setIsLoading(false);
      } else {
        alert("something went wrong");
        setIsLoading(false);
      }
      setIsLoading(false);
      return [];
    }
  };

  const fetchSingleCandidate = async (id) => {
    const params = {
      external_candidate_id: id,
    };
    const joburl = `${url}hirer/external_candidate_profile/`;
    setIsLoading(true);
    try {
      const response = await axios.get(joburl, { headers, params });
      console.log("recruiter_____", response.data);
      if (response.data) {
        const data = response.data;
        setSingleCandidate(data.values);
        setIsLoading(false);
      } else {
        const data = response.data;
        setSingleCandidate([]);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.data && err.response.data.error) {
      } else {
        alert("Something went wrong");
      }
      setIsLoading(false);
    }
  };

  const fetchProfile = async (profileId) => {
    const params = {
       id:profileId,
    };
    const profileurl = `${url}hirer/internal_candidate_profile/`;
    setIsLoading(true);
    try {
      const response = await axios.get(profileurl, { headers,params });
      if (response.data) {
        const data = response.data;
        setSingleCandidate(data.values);
        setIsLoading(false);
      } else {
        setSingleCandidate([]);
        setIsLoading(false);
      }
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      if (err.response && err.response.data && err.response.data.error) {
      } else {
        alert("something went wrong");
      }
      setIsLoading(false);
      return [];
    }
  };

  const fetchCandfilters = async (value, name) => {
    const params = {
      job_role: "",
    };

    if (name in params) {
      params[name] = value;
    }

    Object.keys(params).forEach((key) => {
      if (!params[key]) {
        delete params[key];
      }
    });

    const filterurl = `${url}hirer/candidate_search_suggestions/`;
    try {
      const response = await axios.get(filterurl, { headers, params });
      return response.data.values;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const updateCandidateList = (newList) => {
    setCandidateList(newList.applied_candidates);
    setJobs(newList.jobs);
    setPagination({
      next: newList.next,
      previous: newList.previous,
    });
  };

  const fetchCandidateList = async (url) => {
    const token = localStorage.getItem("token");
    setLoading(true);
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log("response data candidate", response.data);
      updateCandidateList(response.data);
      setNextPageUrl(response.data.next || null);
      setPrevPageUrl(response.data.previous || null);
    } catch (error) {
      console.error("Error fetching candidates:", error);
      setToastMessage(`Error updating job: ${error.message}`);
      setShowToast(true);
      setToastBg("danger");
    } finally {
      setLoading(false);
    }
  };

  const fetchNextPage = () => {
    if (nextPageUrl) {
      fetchCandidateList(nextPageUrl);
    }
  };

  const fetchPreviousPage = () => {
    if (prevPageUrl) {
      fetchCandidateList(prevPageUrl);
    }
  };

  const contextValue = {
    fetchCandidateList,
    fetchNextPage,
    fetchPreviousPage,
    candidateList,
    jobs,
    pagination,
    loading,
    nextPageUrl,
    prevPageUrl,
    handleChangeFilter,
    onResetFilter,
    filtersCand,
    isLoading,
    candidatesData,
    candidateCount,
    fetchCandidates,
    fetchCandfilters,
    setFiltersCand,
    singleCandidate,
    setSingleCandidate,
    fetchSingleCandidate,
    fetchProfile,
    currentPage,
    setCurrentPage,upgradeError,setUpgradeError
  };

  return (
    <RecruiterCandidateListContext.Provider value={contextValue}>
      {children}
    </RecruiterCandidateListContext.Provider>
  );
};
