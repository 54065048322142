import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";

import classes1 from "./jobs.module.css";
import JobDetails from "./JobDetails";
import { useHirerJobListContext } from "../../../../../provider/RecruiterJobListContext";
import JobDetailsSkelton from "../../../../candidate/jobsearch/JobDetailsSkelton";

const SortTabs = ({ handlePrevPage, handleNextPage }) => {
  const { jobs, loading, jobCount, activeJobCount, closedJobCount } =
    useHirerJobListContext();

  const [activeTab, SetactiveTab] = useState("first");

  const handleTabClick = (eventKey) => {
    SetactiveTab(eventKey);
  };

  return (
    <>
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Row>
          <Col sm={12} className="mb-3 d-lg-flex justify-content-between">
            <Nav className="flex-row gap-3">
              <Nav.Item>
                <Nav.Link
                  eventKey="first"
                  className={classes1.customNavlink}
                  style={{
                    color: activeTab === "first" ? "#ffff" : "#000",

                    backgroundColor:
                      activeTab === "first" ? "#3C65F5" : "white",
                  }}
                  onClick={() => handleTabClick("first")}
                >
                  All{" "}
                  <span
                    style={{ color: activeTab === "first" ? "#fff" : "#ccc" }}
                  >
                    {jobCount}
                  </span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="second"
                  className={classes1.customNavlink}
                  style={{
                    color: activeTab === "second" ? "#ffff" : "#000",

                    backgroundColor:
                      activeTab === "second" ? "#3C65F5" : "white",
                  }}
                  onClick={() => handleTabClick("second")}
                  disabled={loading}
                >
                  Opened{" "}
                  <span
                    style={{ color: activeTab === "second" ? "#fff" : "#ccc" }}
                  >
                    {activeJobCount}
                  </span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="third"
                  className={classes1.customNavlink}
                  style={{
                    color: activeTab === "third" ? "#ffff" : "#000",
                    backgroundColor:
                      activeTab === "third" ? "#3C65F5" : "#ffff",
                  }}
                  onClick={() => handleTabClick("third")}
                  disabled={loading}
                >
                  Closed{" "}
                  <span
                    style={{ color: activeTab === "third" ? "#fff" : "#ccc" }}
                  >
                    {closedJobCount}
                  </span>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={12} className="mt-1">
            <Tab.Content>
              {/* All jobs */}
              <Tab.Pane eventKey="first">
                {loading ? (
                  <p>
                    <JobDetailsSkelton />
                  </p>
                ) : jobs.length > 0 ? (
                  <JobDetails
                    handleNextPage={handleNextPage}
                    handlePrevPage={handlePrevPage}
                  />
                ) : (
                  <p>No jobs available</p>
                )}
              </Tab.Pane>

              {/* Opened Jobs */}
              <Tab.Pane eventKey="second">
                {jobs.length > 0 && (
                  <JobDetails
                    jobs={jobs.filter((job) => job.is_active)}
                    activeTab={activeTab}
                  />
                )}
              </Tab.Pane>
              {/* Closed Jobs */}
              <Tab.Pane eventKey="third">
                {jobs.length > 0 && (
                  <JobDetails
                    jobs={jobs.filter((job) => !job.is_active)}
                    activeTab={activeTab}
                  />
                )}
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </>
  );
};

export default SortTabs;
