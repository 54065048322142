import React from "react";
import classes from "./ResumeFormData.module.css";
import { Row, Col } from "react-bootstrap";
import TagsInput from "../../resumeUI/TagsInput";
import { useResumeBuilderContext } from "../../../../provider/ResumeBuilderContext";
import AccordionField from "../../resumeUI/AccordionField";

const Hobbies = () => {
  const { resumeData, handleResumeFormChange, } =
    useResumeBuilderContext();

  const handleSelectedTags = (tags) => {
    const updatedResumeData = {
      ...resumeData,
      hobby: tags.map((tag) => ({ hobby: tag })),
    };

    handleResumeFormChange({
      target: {
        name: "hobby",
        value: updatedResumeData.hobby,
      },
    });
  };

  const handleTagRemoval = (indexToRemove) => {
    const updatedTags = resumeData.hobby.filter(
      (_, index) => index !== indexToRemove
    );

    handleResumeFormChange({
      target: {
        name: "hobby",
        value: updatedTags,
      },
    });
  };
  return (
      <AccordionField heading={"Hobbies"} subheading="List your hobbies in this section" name="hobby" fieldname="hobby">
        <div>
          <Row>
            <Col>
              <TagsInput
                name="hobby"
                selectedTags={handleSelectedTags}
                handleTagRemoval={handleTagRemoval}
                tags={
                  resumeData.hobby &&
                  (resumeData.hobby.some((hob) => hob.hobby !== null)
                    ? resumeData.hobby.map((hob) => hob.hobby)
                    : [])
                }
                placeholder="+ Add Hobby and press enter"
              />
            </Col>
          </Row>
        </div>
      </AccordionField>
  );
};

export default Hobbies;
