import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const CircularProgressBar = ({ percentage, pathColor }) => {
  return (
    <div>
      <CircularProgressbar
        value={percentage}
        text={`${percentage}%`}
        styles={buildStyles({
          // Rotation of path and trail, in number of turns (0-1)
          rotation: 0.25,
          strokeLinecap: "round",
          // Text size
          textSize: "20px",
          fontWeight: "bold",
          pathTransitionDuration: 0.9,
          // Colors
          pathColor: pathColor || `rgba(60, 101, 215, ${percentage / 100})`,
          textColor: "#05264E",
          trailColor: "#d6d6d6",
          backgroundColor: "#3C65F5",
        })}
      />
    </div>
  );
};

export default CircularProgressBar;
