import React from "react";
import {
  Page,
  Text,
  Document,
  StyleSheet,
  View,
  Font,
} from "@react-pdf/renderer";
import {
  formatDateOfBirth,
  extractListItems,
  extractParagraphs,
} from "../../../utils/ResumeHelpers";

const Resume11 = ({ data }) => {
  const styles = StyleSheet.create({
    body: {
      paddingVertical: 30,
      paddingHorizontal: 30,
    },
    mainheading: {
      display: "flex",
      flexDirection: "column",
      fontSize: 18,
      fontFamily: "Helvetica-Bold",
      marginBottom: 10,
    },
    personalInfo: {
      display: "flex",
      flexDirection: "coloumn",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      marginBottom: 5,
      color: "gray",
      textAlign: "right",
    },
    section: {
      padding: 7,
    },
    sectionheading: {
      fontSize: 14,
      marginBottom: 10,
      fontFamily: "Helvetica-Bold",
    },
    smtext: {
      fontFamily: "Times-Roman",
      fontSize: 12,
      lineHeight: 1.3,
    },
    xsmtext: {
      fontFamily: "Times-Roman",
      fontSize: 10,
    },
    sectionwrap: {
      marginBottom: 8,
    },
    eachsectionwrap: {
      marginBottom: 8,
    },
    sectext: {
      fontSize: 12,
      fontFamily: "Helvetica-Bold",
      marginBottom: 5,
    },
    line: {
      borderBottom: "1pt solid #ccc",
      marginBottom: 5,
    },
    rowContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "baseline",
    },
  });
  return (
    <Document>
      <Page style={styles.body} size="A4" wrap>
        {/* personal and weblinks */}
        <View
          style={[
            styles.section,
            {
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            },
          ]}
        >
          <View>
            <Text style={styles.mainheading}>
              {data.firstName} {data.lastName}
            </Text>

            {data.job_title && (
              <Text
                style={[styles.sectionheading, { fontFamily: "Helvetica" }]}
              >
                {data.job_title}
              </Text>
            )}
          </View>

          <View style={styles.personalInfo}>
            {data.address && <Text style={styles.xsmtext}>{data.address}</Text>}
            {data.email && <Text style={styles.xsmtext}>{data.email}</Text>}
            {data.phone && <Text style={styles.xsmtext}>{data.phone}</Text>}
            {data.dob && (
              <Text style={styles.xsmtext}>{formatDateOfBirth(data.dob)}</Text>
            )}
            {data.nationality && (
              <Text style={styles.xsmtext}>{data.nationality}</Text>
            )}
            {data.gender === "F" ? (
              <Text style={styles.xsmtext}>Female</Text>
            ) : data.gender === "M" ? (
              <Text style={styles.xsmtext}>Male</Text>
            ) : data.gender === "O" ? (
              <Text style={styles.xsmtext}>Others</Text>
            ) : null}
          </View>
        </View>
        {/* summary */}

        {data.summary && (
          <View style={styles.section}>
            <View style={styles.sectionwrap}>
              <Text style={styles.sectionheading}>Summary</Text>
              <Text style={styles.line}></Text>
              <View style={{ flexDirection: "column", paddingLeft: 5 }}>
                {extractParagraphs(data.summary).map((paragraph, index) => (
                  <View
                    key={index}
                    style={{ flexDirection: "row", marginBottom: 2 }}
                  >
                    <Text style={styles.smtext}>{paragraph}</Text>
                  </View>
                ))}
                {extractListItems(data.summary).map((item, index) => (
                  <View
                    key={index}
                    style={{ flexDirection: "row", marginBottom: 2 }}
                  >
                    <Text style={styles.smtext}>• &nbsp;</Text>
                    <Text style={styles.smtext}>{item}</Text>
                  </View>
                ))}
              </View>
            </View>
          </View>
        )}

        {/* experience */}

        <View style={styles.section}>
          {data.employement &&
          data.employement.some(
            (wrk) =>
              wrk.company ||
              wrk.designation ||
              wrk.startDateWe ||
              wrk.endDateWe ||
              wrk.responsibilities
          ) ? (
            <View>
              <View style={styles.sectionwrap}>
                <Text style={styles.sectionheading}>Experience</Text>
                <Text style={styles.line}></Text>
                {data.employement.map((emp, id) => (
                  <View style={styles.eachsectionwrap} key={id}>
                    <View style={styles.rowContainer}>
                      <Text style={styles.sectext}>{emp.designation}</Text>
                      <Text style={styles.smtext}>
                        {emp.startDateWe} {emp.startDateWe && "- "}
                        <Text style={styles.smtext}>
                          {emp.present === false ? emp.endDateWe : "Present"}
                        </Text>
                      </Text>
                    </View>
                    <Text style={styles.smtext}>{emp.company}</Text>
                    {emp.responsibilities && (
                      <View
                        style={{
                          flexDirection: "column",
                          paddingLeft: 5,
                          marginTop: 5,
                        }}
                      >
                        {extractParagraphs(emp.responsibilities).map(
                          (paragraph, index) => (
                            <View key={index} style={{ flexDirection: "row" }}>
                              <Text style={styles.smtext}>{paragraph}</Text>
                            </View>
                          )
                        )}
                        {extractListItems(emp.responsibilities).map(
                          (item, index) => (
                            <View key={index} style={{ flexDirection: "row" }}>
                              <Text style={styles.smtext}>• &nbsp;</Text>
                              <Text style={styles.smtext}>{item}</Text>
                            </View>
                          )
                        )}
                      </View>
                    )}
                  </View>
                ))}
              </View>
            </View>
          ) : null}
        </View>

        {/* skills */}

        <View style={styles.section}>
          <View>
            <View style={styles.sectionwrap}>
              {data.techskills &&
              data.techskills.some((skl) => skl.techSkill) ? (
                <View>
                  <View style={styles.sectionwrap}>
                    <Text style={styles.sectext}>Technical Skills</Text>
                    <Text style={styles.line}></Text>

                    <Text style={styles.eachsectionwrap}>
                      <Text style={styles.smtext}>
                        {data.techskills
                          .map((tech) => tech.techSkill)
                          .join(", ")}
                      </Text>
                    </Text>
                  </View>
                </View>
              ) : null}

              {data.skill && data.skill.some((skl) => skl.softSkill) ? (
                <View>
                  <Text style={styles.sectext}>Soft Skills</Text>
                  <Text style={styles.line}></Text>

                  <Text style={styles.eachsectionwrap}>
                    <Text style={styles.smtext}>
                      {data.skill.map((soft) => soft.softSkill).join(", ")}
                    </Text>
                  </Text>
                </View>
              ) : null}
            </View>
          </View>
        </View>

        {/* education */}
        <View style={styles.section}>
          {data.education &&
          data.education.some(
            (edu) =>
              edu.institution ||
              edu.course ||
              edu.startDate ||
              edu.endDate ||
              edu.proficiency
          ) ? (
            <View>
              <View style={styles.sectionwrap}>
                <Text style={styles.sectionheading}>Education</Text>
                <Text style={styles.line}></Text>
                {data.education.map((edu, id) => (
                  <View style={styles.eachsectionwrap} key={id}>
                    <View style={styles.rowContainer}>
                      <Text style={styles.sectext}>{edu.course}</Text>
                      <Text style={styles.xsmtext}>
                        {edu.proficiency}&nbsp;
                        <Text style={styles.xsmtext}>
                          {edu.proficiency_type === "G"
                            ? "GPA"
                            : edu.proficiency_type === "P"
                            ? "%"
                            : null}
                        </Text>
                      </Text>
                    </View>

                    <Text style={styles.smtext}>{edu.institution}</Text>
                    <Text style={styles.smtext}>
                      {edu.startDate} {edu.startDate && "- "}
                      <Text style={styles.smtext}>
                        {edu.present === false ? edu.endDate : "Present"}
                      </Text>
                    </Text>
                  </View>
                ))}
              </View>
            </View>
          ) : null}
        </View>

        <View style={styles.section}>
          {data.reference &&
          data.reference.some(
            (ref) =>
              ref.contact_name ||
              ref.company ||
              ref.country ||
              ref.phone_number || ref.desc ||
              ref.email
          ) ? (
            <View>
              <View style={styles.sectionwrap}>
                <Text style={styles.sectionheading}>Reference</Text>
                <Text style={styles.line}></Text>
                {data.reference.map((ref, id) => (
                  <View style={styles.eachsectionwrap} key={id}>
                    <Text style={styles.sectext}>{ref.contact_name}</Text>
                    <Text style={styles.xsmtext}>{ref.desc}</Text>
                    <Text style={styles.xsmtext}>
                      {ref.company},
                      <Text style={styles.xsmtext}>{ref.country}</Text>
                    </Text>
                    <Text>
                      <Text style={styles.smtext}>{ref.email} </Text>
                      <Text style={{ fontWeight: 800, fontSize: 11 }}>
                        &nbsp;|&nbsp;
                      </Text>
                      <Text style={styles.xsmtext}> {ref.phone_number}</Text>
                    </Text>
                  </View>
                ))}
              </View>
            </View>
          ) : null}
        </View>

        <View>
          {data.language && data.language.some((lan) => lan.language) ? (
            <View style={styles.section}>
              <View>
                <View style={styles.sectionwrap}>
                  <Text style={styles.sectext}>Languages</Text>
                  <Text style={styles.line}></Text>
                  <Text style={styles.eachsectionwrap}>
                    <Text style={styles.smtext}>
                      {data.language.map((lan) => lan.language).join(",")}
                    </Text>
                  </Text>
                </View>
              </View>
            </View>
          ) : null}
        </View>

        <View>
          {data.achievement &&
          data.achievement.some((ach) => ach.achievement) ? (
            <View style={styles.section}>
              <View>
                <View style={styles.sectionwrap}>
                  <Text style={styles.sectext}>Achievements</Text>
                  <Text style={styles.line}></Text>
                  <Text style={styles.eachsectionwrap}>
                    <Text style={styles.smtext}>
                      {data.achievement.map((ach) => ach.achievement).join(",")}
                    </Text>
                  </Text>
                </View>
              </View>
            </View>
          ) : null}
        </View>

        {data.award && data.award.some((aw) => aw.award) ? (
          <View style={styles.section}>
            <View>
              <View style={styles.sectionwrap}>
                <Text style={styles.sectext}>Awards</Text>
                <Text style={styles.line}></Text>
                <Text style={styles.eachsectionwrap}>
                  <Text style={styles.smtext}>
                    {data.award.map((aw) => aw.award).join(",")}
                  </Text>
                </Text>
              </View>
            </View>
          </View>
        ) : null}

        {data.certification &&
        data.certification.some((cert) => cert.certification) ? (
          <View style={styles.section}>
            <View>
              <View style={styles.sectionwrap}>
                <Text style={styles.sectext}>Certifications</Text>
                <Text style={styles.line}></Text>
                <Text style={styles.eachsectionwrap}>
                  <Text style={styles.smtext}>
                    {data.certification
                      .map((cert) => cert.certification)
                      .join(",")}
                  </Text>
                </Text>
              </View>
            </View>
          </View>
        ) : null}

        <View>
          {data.publication &&
          data.publication.some((pub) => pub.publication) ? (
            <View style={styles.section}>
              <View>
                <View style={styles.sectionwrap}>
                  <Text style={styles.sectext}>Publications</Text>
                  <Text style={styles.line}></Text>
                  <Text style={styles.eachsectionwrap}>
                    <Text style={styles.smtext}>
                      {data.publication.map((pub) => pub.publication).join(",")}
                    </Text>
                  </Text>
                </View>
              </View>
            </View>
          ) : null}
        </View>

        <View>
          {data.volunteer && data.volunteer.some((vol) => vol.volunteer) ? (
            <View style={styles.section}>
              <View>
                <View style={styles.sectionwrap}>
                  <Text style={styles.sectext}>Volunteer Experience</Text>
                  <Text style={styles.line}></Text>
                  <Text style={styles.eachsectionwrap}>
                    <Text style={styles.smtext}>
                      {data.volunteer.map((vol) => vol.volunteer).join(",")}
                    </Text>
                  </Text>
                </View>
              </View>
            </View>
          ) : null}
        </View>

        <View>
          {data.interests && data.interests.some((int) => int.interest) ? (
            <View style={styles.section}>
              <View>
                <View style={styles.sectionwrap}>
                  <Text style={styles.sectext}>Interests</Text>
                  <Text style={styles.line}></Text>
                  <Text style={styles.eachsectionwrap}>
                    <Text style={styles.smtext}>
                      {data.interests.map((int) => int.interest).join(",")}
                    </Text>
                  </Text>
                </View>
              </View>
            </View>
          ) : null}
        </View>

        <View>
          {data.hobby && data.hobby.some((hob) => hob.hobby) ? (
            <View style={styles.section}>
              <View>
                <View style={styles.sectionwrap}>
                  <Text style={styles.sectext}>Hobbies</Text>
                  <Text style={styles.line}></Text>
                  <Text style={styles.eachsectionwrap}>
                    <Text style={styles.smtext}>
                      {data.hobby.map((hob) => hob.hobby).join(",")}
                    </Text>
                  </Text>
                </View>
              </View>
            </View>
          ) : null}
        </View>
      </Page>
    </Document>
  );
};

export default Resume11;
