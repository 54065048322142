import React, { useState } from "react";
import "quill/dist/quill.snow.css";
import ReactQuill from "react-quill";

const TextEditor = ({ name, placeholder, onChange, value }) => {
  const [prevContent, setPrevContent] = useState(value); // Track previous content
  const [isChanged, setIsChanged] = useState(false); // Track if content changed

  const handleProcedureContentChange = (content, _, source) => {
    if (source === "user") {
      setIsChanged(content !== prevContent);
    }
    if (isChanged) {
      onChange({ target: { name, value: content } });
    }
    setPrevContent(content);
  };

  return (
    <div style={{ minHeight: "220px" }}>
      <ReactQuill
        style={{ height: "160px", borderRadius: "25px" }}
        name={name}
        theme="snow"
        modules={{
          toolbar: [
            [{ header: [1, 2, 3, 4, false] }],
            ["bold"],
            ["link"],
            [{ list: "ordered" }, { list: "bullet" }],
          ],
        }}
        formats={["header", "bold", "list", "bullet", "link"]}
        placeholder={placeholder}
        onChange={handleProcedureContentChange}
        value={value}
      />
    </div>
  );
};

export default TextEditor;
