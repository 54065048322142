import React, { useState } from "react";
import { useProfileContext } from "../../../provider/ProfileContext";
import { Row, Col } from "react-bootstrap";
import classes from "./Profile.module.css";
import { LuClipboardEdit } from "react-icons/lu";
import ReferenceProfileForm from "./profileForms/ReferenceProfileForm";
import { BiTrash } from "react-icons/bi";
import DeleteProfile from "./DeleteProfile";

const ReferenceProfile = () => {
  const {
    profileData,
    AddProfileData,
    handlePersonalChange,
    EditProfileData,
    DeleteProfileData,
  } = useProfileContext();
  const profileid = localStorage.getItem("profile");

  const [referenceProfile, setReferenceProfile] = useState(
    profileData.reference_prof || []
  );
  const [reference, setReference] = useState({
    contact_name: null,
    company: null,
    country: null,
    phone_number: null,
    email: null,
    desc: null,
    profile: profileid,
  });
  const [errors, setErrors] = useState({
    contact_name: "",
    company: "",
    country: "",
    phone_number: "",
    email: "",
    desc: "",
  });
  const [selectedReferenceProfile, setSelectedReferenceProfile] =
    useState(null);
  const [showRefProfile, setShowRefProfile] = useState(false);
  const handleCloseEduProfile = () => setShowRefProfile(false);
  const [present, setPresent] = useState(false);
  const [editId, setEditid] = useState(null);
  const [showEditRef, setShowEditRef] = useState(false);
  const [showDel, setShowDel] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const handleCloseEditProfile = () => setShowEditRef(false);
  const handleShowRefProfile = () => {
    setDisabled(true);
    setErrors({
      contact_name: "",
      company: "",
      country: "",
      phone_number: "",
      email: "",
      desc: "",
    });
    setReference({
      contact_name: null,
      company: null,
      country: null,
      phone_number: null,
      email: null,
      desc: null,
      profile: profileid,
    });
    setPresent(false);
    setShowRefProfile(true);
  };

  const handleShowEditProfile = (id) => {
    setDisabled(true);
    setErrors({
      contact_name: "",
      company: "",
      country: "",
      phone_number: "",
      email: "",
      desc: "",
    });
    setEditid(id);
    const selectedProfile = referenceProfile.find(
      (profile) => profile.id === id
    );
    setSelectedReferenceProfile(selectedProfile);
    setShowEditRef(true);
  };

  const handleShowDelRef = (id) => {
    setEditid(id);
    setShowDel(true);
  };
  const handleCloseDelRef = () => setShowDel(false);

  const handleReferenceChange = (e, profile, setProfile) => {
    const { name, value } = e.target;
    const updatedProfile = { ...profile, [name]: value };

    let error = "";

    if (name === "contact_name" || name === "desc" || name === "company") {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }

    setProfile(updatedProfile);
  };

  const handleRefChange = (e) => {
    handleReferenceChange(e, reference, setReference);
  };

  const onChangeHandler = (e) => {
    setPresent(!present);
    handleRefChange(e);
  };

  const onChangeEditHandler = (e) => {
    const { name, checked } = e.target;
    setSelectedReferenceProfile((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const validateProfileData = (profile) => {
    let hasError = false;
    let newErrors = { ...errors };

    if (!profile.contact_name) {
      newErrors.contact_name = "Please fill in the required field.";
      hasError = true;
    }

    if (!profile.company) {
      newErrors.company = "Please fill in the required field.";
      hasError = true;
    }
    if (!profile.desc) {
      newErrors.desc = "Please fill in the required field.";
      hasError = true;
    }

    // if (Object.values(newErrors).some((error) => error !== "")) {
    //   alert("Please fix the errors in the form before submitting.");
    //   hasError = true;
    // }

    setErrors(newErrors);
    return hasError;
  };

  const handleSubmit = async (profile, apiCall, successCallback) => {
    if (validateProfileData(profile)) {
      return;
    }

    let updatedProfile = { ...profile };

    try {
      const response = await apiCall(updatedProfile);
      if (response.status === 201 || response.status === 200) {
        successCallback(response.data);
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  const addEduData = () => {
    handleSubmit(
      reference,
      (data) => AddProfileData(data, "reference"),
      (newEdu) => {
        setReferenceProfile((prevEdu) => {
          const updatedEdu = [...prevEdu, newEdu];
          handlePersonalChange({
            target: { name: "reference_prof", value: updatedEdu },
          });
          return updatedEdu;
        });
        setShowRefProfile(false);
        setReference({
          contact_name: null,
          company: null,
          country: null,
          phone_number: null,
          email: null,
          desc: null,
          profile: profileid,
        });
      }
    );
  };

  const handleEditEduChange = (e) => {
    handleReferenceChange(
      e,
      selectedReferenceProfile,
      setSelectedReferenceProfile
    );
  };

  const addEditEduData = () => {
    handleSubmit(
      { ...selectedReferenceProfile, profile: profileid },
      (data) => EditProfileData(data, "reference", editId),
      (newEdu) => {
        setReferenceProfile((prevEdu) => {
          const updatedEdu = prevEdu.map((edu) =>
            edu.id === newEdu.id ? newEdu : edu
          );
          handlePersonalChange({
            target: { name: "reference_prof", value: updatedEdu },
          });
          return updatedEdu;
        });
        setShowEditRef(false);
      }
    );
  };

  const handleDeleteRef = async () => {
    try {
      const response = await DeleteProfileData("reference", editId);
      if (response.status === 204) {
        const updated = referenceProfile.filter((data) => data.id !== editId);
        setReferenceProfile(updated);
        setShowDel(false);
      }
    } catch (error) {
      console.error("Error deleting :", error);
    }
  };
  return (
    <div>
      <div className="mb-2 pb-2">
        <div className="d-flex align-items-center mb-4 fw-bold ">
          <h4 className=" fw-bold text-dark ">Reference</h4>
          <div
            className="ms-4 border p-1 rounded text-primary fw-semibold"
            style={{ fontSize: "12px", cursor: "pointer" }}
            onClick={handleShowRefProfile}
          >
            + Add more
          </div>
        </div>
        {referenceProfile && referenceProfile.length > 0 ? (
          <div>
            {referenceProfile.map((edu) => (
              <Row key={edu.id} className="mb-4">
                <Col lg={1} sm={2} xs={2}>
                  {edu.contact_name && (
                    <div className={classes.eduelement}>
                      {edu.contact_name.charAt(0)}
                    </div>
                  )}
                </Col>
                <Col lg={9} sm={8} xs={8}>
                  <div className="d-flex flex-column flex-sm-row align-items-start align-items-sm-center">
                    <div
                      className="fw-semibold text-dark"
                      style={{ fontSize: "16px" }}
                    >
                      {edu.contact_name}
                    </div>

                    <div
                      className="d-none d-sm-block ms-lg-5 text-danger"
                      style={{ fontSize: "13px" }}
                    >
                      {edu.proficiency}
                      <span className="ms-1">
                        {edu.proficiency_type === "G"
                          ? "GPA"
                          : edu.proficiency_type === "P"
                          ? "%"
                          : null}
                      </span>
                    </div>

                    <div
                      className="d-sm-none mt-1 text-dark"
                      style={{ fontSize: "12px" }}
                    >
                      {edu.proficiency}
                      <span className="ms-1">
                        {edu.proficiency_type === "G"
                          ? "GPA"
                          : edu.proficiency_type === "P"
                          ? "%"
                          : null}
                      </span>
                    </div>
                  </div>
                  <div style={{ fontSize: "14px" }} className="text-danger">
                    {edu.desc}
                  </div>
                  <div className="d-flex align-items-center">
                    <div style={{ fontSize: "14px" }} className="text-muted">
                      {edu.company}
                    </div>
                    <div style={{ fontSize: "14px" }} className="text-muted">
                      <span className="pe-1">,</span> {edu.country}
                    </div>
                  </div>

                  <div className="d-flex align-items-center fst-italic mt-1">
                    {edu.phone_number && (
                      <div style={{ fontSize: "13px" }} className="text-muted">
                        {edu.phone_number}
                        <span className="px-1 text-dark">|</span>
                      </div>
                    )}
                    <div style={{ fontSize: "13px" }} className="text-muted">
                      {edu.email}
                    </div>
                  </div>
                </Col>
                <Col  lg={1}
                  sm={1}
                  xs={1}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleShowEditProfile(edu.id)}
                >
                  <LuClipboardEdit />
                </Col> <Col
                  lg={1}
                  sm={1}
                  xs={1}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleShowDelRef(edu.id)}
                >
                  <BiTrash className="ms-1 fs-5 fw-bold text-danger" />
                </Col>
              </Row>
            ))}
          </div>
        ) : (
          <div className="my-3 text-danger" style={{ fontSize: "14px" }}>
            * No reference added
          </div>
        )}
      </div>
      <ReferenceProfileForm
        show={showRefProfile}
        handleClose={handleCloseEduProfile}
        data={referenceProfile}
        formtype="add"
        add={addEduData}
        handleChange={handleRefChange}
        onChangeHandler={onChangeHandler}
        errors={errors}
        present={present}
        disabled={disabled}
      />
      {showEditRef && (
        <ReferenceProfileForm
          show={showEditRef}
          handleClose={handleCloseEditProfile}
          data={selectedReferenceProfile}
          formtype="edit"
          add={addEditEduData}
          handleChange={handleEditEduChange}
          onChangeHandler={onChangeEditHandler}
          errors={errors}
          disabled={disabled}
        />
      )}  <DeleteProfile
      show={showDel}
      handleClose={handleCloseDelRef}
      handleDelete={handleDeleteRef}
      />
    </div>
  );
};

export default ReferenceProfile;
