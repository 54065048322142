import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IoPersonCircle } from "react-icons/io5";
import { Spinner, Stack, Button } from "react-bootstrap";
import { useAuthContext } from "../../../provider/AuthContext";
import { useProfileContext } from "../../../provider/ProfileContext";
import { TbFileUpload, TbFileDownload } from "react-icons/tb";
import { useResumeBuilderContext } from "../../../provider/ResumeBuilderContext";
import {
  PDFDownloadLink,
  PDFViewer,
  StyleSheet,
  usePDF,
} from "@react-pdf/renderer";
import Resume1 from "../resumes/Resume1";
import Resume2 from "../resumes/Resume2";
import Resume3 from "../resumes/Resume3";
import Resume4 from "../resumes/Resume4";
import Resume5 from "../resumes/Resume5";
import Resume6 from "../resumes/Resume6";
import Resume7 from "../resumes/Resume7";
import Resume8 from "../resumes/Resume8";
import Resume9 from "../resumes/Resume9";
import Resume10 from "../resumes/Resume10";
import Resume11 from "../resumes/Resume11";

const RBHeader = () => {
  let user = JSON.parse(localStorage.getItem("userData"));
  const { logout, userData } = useAuthContext();
  const {
    handleClose,
    saveResumeApi,
    resumeData,
    errors,
    selectedTemplate,
    setShowPopUp,
    setDownloaded,downloaded
  } = useResumeBuilderContext();
  const [isSaving, setIsSaving] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  // const [downloadAvailable, setDownloadAvailable] = useState(false);

  const templates = [
    { name: "Resume1", component: <Resume1 data={resumeData} /> },
    { name: "Resume2", component: <Resume2 data={resumeData} /> },
    { name: "Resume3", component: <Resume3 data={resumeData} /> },
    { name: "Resume4", component: <Resume4 data={resumeData} /> },
    { name: "Resume5", component: <Resume5 data={resumeData} /> },
    { name: "Resume6", component: <Resume6 data={resumeData} /> },
    { name: "Resume7", component: <Resume7 data={resumeData} /> },
    { name: "Resume8", component: <Resume8 data={resumeData} /> },
    { name: "Resume9", component: <Resume9 data={resumeData} /> },
    { name: "Resume10", component: <Resume10 data={resumeData} /> },
    { name: "Resume11", component: <Resume11 data={resumeData} /> },
  ];

  const loadUpload = () => {
    setShowPopUp(true);
  };

  const savePDF = async () => {
    const requiredFields = [
      { field: resumeData.phone, error: errors.phone },
      { field: resumeData.email, error: errors.email },
      { field: resumeData.firstName, error: errors.firstName },
      { field: resumeData.job_title, error: errors.job_title },
      { field: resumeData.dob, error: errors.dob },
      { field: resumeData.gender, error: "" },
    ];

    const isFormValid = requiredFields.every(
      ({ field, error }) => field !== null && field !== "" && error === ""
    );

    if (!isFormValid) {
      alert("Please fill all required fields in the Personal Details section");
      return;
    }

    try {
      setIsSaving(true);
      const response = await saveResumeApi();
      console.log("here________", response);

      if (response.status === 201) {
        setDownloaded(true);
      } else {
        alert("Error: Unable to save resume. Please try again.");
      }
    } catch (error) {
      console.log("Error saving resume:");
      alert("Error: Unable to save resume. Please try again.");
    } finally {
      setIsSaving(false);
    }
  };



  // const signOut = async (e) => {
  //   e.preventDefault();
  //   logout();
  // };

  return (
    <Stack
      style={{ height: "9vh" }}
      className="py-3 px-5  d-flex bg-dark"
      direction="horizontal"
      gap={3}
    >
      <Link
        to="/build/templates"
        className=" fs-5 text-white d-flex align-items-center "
        style={{ fontWeight: "700" }}
      >
        <div
          className="bg-primary p-1 text-white"
          style={{ borderRadius: "40px", height: "40px", width: "40px" }}
        >
          em
        </div>
        Career
      </Link>
      {/* <div className="border p-2 ms-3 rounded text-info" style={{ fontSize: "14px",cursor:"pointer" }}>
        Check Resume Score
      </div> */}
      <div className="ms-auto ">
        <Button
          size="sm"
          className="fw-semibold d-flex align-items-center text-white"
          onClick={loadUpload}
        >
          Upload
          <TbFileUpload className="ms-1 fs-5" />
        </Button>
      </div>
      <div className=" text-dark">
        {downloaded ? (
          <PDFDownloadLink
            document={templates
              .filter((template) => template.name === selectedTemplate)
              .map((template) => template.component)}
            fileName={userData.first_name + userData.last_name}
          >
            {({ downloading, url, error, blob }) =>
              downloading ? (
                <Button
                  size="sm"
                  className="fw-semibold d-flex align-items-center"
                  disabled
                >
                  Downloading document...
                  <TbFileDownload className="ms-1 fs-5" />
                </Button>
              ) : (
                <Button
                  size="sm"
                  className="fw-semibold d-flex align-items-center"
                  disabled={isDownloading}
                >
                  Download <TbFileDownload className="ms-1 fs-5" />
                </Button>
              )
            }
          </PDFDownloadLink>
        ) : (
          <Button
            onClick={savePDF}
            disabled={isSaving}
            variant="outline-info"
            size="sm"
            className="fw-semibold d-flex align-items-center"
          >
            {isSaving ? "Saving" : "Save"}
            <TbFileDownload className="ms-1 fs-5" />
          </Button>
        )}
      </div>
    </Stack>
  );
};

export default RBHeader;
