import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import JobCard from "../../components/candidate/jobsearch/JobCard";
import { job } from "../../data/JobData";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import { GrFormPrevious } from "react-icons/gr";
import classes from "../../components/candidate/jobsearch/Jobsearch.module.css";
import { Pagination, Navigation, HashNavigation, A11y } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const SliderCarousel = (jobs) => {

  let otherJob = [];
  if(jobs.jobs.length>0){
    otherJob=jobs.jobs;
  }else{
  }

  const swiperRef = useRef(null);
  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };
  return (
    <div className={classes.boxswiper}>
      <Swiper
        ref={swiperRef}
        spaceBetween={10}
        slidesPerView={1}
        lazy={true}
        modules={[Navigation, Pagination, A11y]}
        className=""
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 40,
          },
          968: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          1020: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          1200: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
        }}
      >
        <Swiper>
      {otherJob.map((jobs) => (
        <SwiperSlide key={jobs.id}>
          <JobCard job={jobs} />
        </SwiperSlide>
      ))}
    </Swiper>
      </Swiper>
      <div className={classes.swiperbuttons}>
        <div onClick={handlePrev} className={classes.btns + " border  "}>
          <MdArrowBackIos className=" ms-1" />
        </div>
        <div onClick={handleNext} className={classes.btns + " border "}>
          <MdArrowForwardIos className=" ms-1" />
        </div>
      </div>
    </div>
  );
};

export default SliderCarousel;
