import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../components/home/Home";
import Signin from "../components/authentication/Signin";
import ChooseRole from "../components/authentication/ChooseRole";
import Register from "../components/authentication/Register";
import EmailCheck from "../components/authentication/EmailCheck";
import EmailVerified from "../components/authentication/EmailVerified";
import JobSearch from "../components/candidate/jobsearch/JobSearch";
import GoogleSSO from "../components/authentication/GoogleSSO";
import ResendMail from "../components/authentication/ResendMail";
import ForgotPassword from "../components/authentication/ForgotPassword";
import ResetPassword from "../components/authentication/ResetPassword";
import ResumeList from "../components/candidate/resumes/ResumeList";
import Archives from "../components/candidate/archives/Archives";
import Profile from "../components/candidate/profile/Profile";
import ChooseUserSSO from "../components/authentication/ChooseUserSSO";
import CompanyProfile from "../components/recruiter/dashboard/screens/companyProfile/CompanyProfile";
import EditProfile from "../components/recruiter/dashboard/screens/uiForms/EditProfile";
import PostJob from "../components/recruiter/dashboard/screens/post job/PostJob";
import Jobs from "../components/recruiter/dashboard/screens/jobs/Jobs";
import Candidates from "../components/recruiter/dashboard/screens/candidate/Candidates";
import CreateProfile from "../components/recruiter/dashboard/screens/uiForms/CreateProfile";
import GetStarted from "../components/recruiter/layouts/GetStarted";
import ChangePassword from "../components/authentication/ChangePassword";
import PrivateRouteHirer from "./PrivateRouteHirer";
import PrivateRouteCandidate from "./PrivateRouteCandidate";
import Termsandconditions from "../eula_privacy_cookies_disclaimer_terms/Termsandconditions";
import Privacypolicy from "../eula_privacy_cookies_disclaimer_terms/Privacypolicy";
import Eula from "../eula_privacy_cookies_disclaimer_terms/Eula";
import Cookies from "../eula_privacy_cookies_disclaimer_terms/Cookies";
import Disclaimer from "../eula_privacy_cookies_disclaimer_terms/Disclaimer";
import Templatesection from "../components/resumeBuilder/templatesections/TemplateSection";
import ResumeBuilder from "../components/resumeBuilder/ResumeBuilder";
import NotFound from "../ui/common/NotFound";
import JobDetails from "../components/candidate/jobsearch/JobDetails";
import Companies from "../components/candidate/companies/Companies";
import CompanyProfileView from "../components/candidate/companies/CompanyProfileView";
import TopCandidatesList from "../components/recruiter/dashboard/screens/candidate/TopCandidatesList";
import Upgrade from "../components/recruiter/layouts/Upgrade";
import CandidateView from "../components/recruiter/dashboard/screens/candidate/CandidateView";
import LatestJobs from "../components/home/LatestJobs/LatestJobs";

const MainRoutes = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/select" element={<ChooseRole />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/register" element={<Register />} />
        <Route path="/verifyemail" element={<EmailCheck />} />
        <Route path="/resendemail" element={<ResendMail />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/auth/email-verify" element={<EmailVerified />} />
        <Route
          path="/auth/password-reset/:uid/:token/"
          element={<ResetPassword />}
        />
        <Route path="/changepassword" element={<ChangePassword />} />
        <Route path="/chooseuser" element={<ChooseUserSSO />} />
        <Route path="/auth/sso/google/callback/" element={<GoogleSSO />} />
        <Route path="/termsandconditions" element={<Termsandconditions />} />
        <Route path="/privacypolicy" element={<Privacypolicy />} />
        <Route path="/eula" element={<Eula />} />
        <Route path="/cookies" element={<Cookies />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/notFound" element={<NotFound />} />
        <Route path="/jobs" element={<JobSearch />} />

        <Route
          path="/latest-jobs/data-analytics/fresher"
          element={<LatestJobs />}
        />
        <Route path="/latest-jobs/data-analytics" element={<LatestJobs />} />
        <Route path="/latest-jobs/gen-ai/fresher" element={<LatestJobs />} />
        <Route path="/latest-jobs/gen-ai" element={<LatestJobs />} />
        <Route
          path="/latest-jobs/frontend-developer"
          element={<LatestJobs />}
        />
        <Route
          path="/latest-jobs/frontend-developer/fresher"
          element={<LatestJobs />}
        />
        <Route
          path="/latest-jobs/backend-developer/fresher"
          element={<LatestJobs />}
        />
        <Route path="/latest-jobs/backend-developer" element={<LatestJobs />} />
        <Route
          path="/latest-jobs/fullstack-developer/fresher"
          element={<LatestJobs />}
        />
        <Route
          path="/latest-jobs/fullstack-developer"
          element={<LatestJobs />}
        />
        <Route path="/latest-jobs" element={<LatestJobs />} />

        {/* Candidate */}
        {/* <Route element={<PrivateRouteCandidate />}> */}
        <Route path="/job/:jobId" element={<JobDetails />} />
        <Route path="/exjob/:jobId" element={<JobDetails />} />
        <Route path="/companies" element={<Companies />} />
        <Route path="/company/:companyId" element={<CompanyProfileView />} />
        <Route path="/resumes" element={<ResumeList />} />
        <Route path="/build/templates" element={<Templatesection />} />
        <Route path="/build/resume" element={<ResumeBuilder />} />
        <Route path="/archives" element={<Archives />} />
        <Route path="/viewprofile" element={<Profile />} />
        {/* </Route> */}

        {/* Recruiter */}
        {/* <Route element={<PrivateRouteHirer />}> */}
        <Route path="/get-started" element={<GetStarted />} />
        <Route path="/company/profile" element={<CompanyProfile />} />
        <Route path="/createprofile" element={<CreateProfile />} />
        <Route path="/edit-profile" element={<EditProfile />} />
        <Route path="/postJob/:jobId" element={<PostJob />} />
        <Route path="/postJob" element={<PostJob />} />
        <Route path="/hirer/jobs" element={<Jobs />} />
        <Route path="/hirer/candidate-list/:jobId" element={<Candidates />} />
        <Route path="/topcandidates" element={<TopCandidatesList />} />
        <Route path="/viewcandidate/:candidateId" element={<CandidateView />} />
        <Route path="/upgradeplan" element={<Upgrade />} />
        {/* </Route> */}
      </Routes>
    </div>
  );
};

export default MainRoutes;
