import React from "react";
import classes from "./ResumeFormData.module.css";
import { Row, Col } from "react-bootstrap";
import TagsInput from "../../resumeUI/TagsInput";
import { useResumeBuilderContext } from "../../../../provider/ResumeBuilderContext";
import AccordionField from "../../resumeUI/AccordionField";

const Interests = () => {
  const { resumeData, handleResumeFormChange } = useResumeBuilderContext();

  const handleSelectedTags = (tags) => {
    const updatedResumeData = {
      ...resumeData,
      interests: tags.map((tag) => ({ interest: tag })),
    };

    handleResumeFormChange({
      target: {
        name: "interests",
        value: updatedResumeData.interests,
      },
    });
  };

  const handleTagRemoval = (indexToRemove) => {
    const updatedTags = resumeData.interests.filter(
      (_, index) => index !== indexToRemove
    );

    handleResumeFormChange({
      target: {
        name: "interests",
        value: updatedTags,
      },
    });
  };
  return (
    <AccordionField
      heading="Interests"
      subheading="Explore the interests that are aligned to your role"
      name="interests"
    >
      <div>
        <Row>
          <Col>
            <TagsInput
              name="interests"
              selectedTags={handleSelectedTags}
              handleTagRemoval={handleTagRemoval}
              tags={
                resumeData.interests.some((int) => int.interest !== null)
                  ? resumeData.interests.map((int) => int.interest)
                  : []
              }
              placeholder="+ Add interest and press enter"
            />
          </Col>
        </Row>
      </div>
    </AccordionField>
  );
};

export default Interests;
