import React from "react";
import classes from "./Companies.module.css";
import { Image, Row, Col } from "react-bootstrap";
import { FaLinkedin } from "react-icons/fa";

const SalaryCard = ({ salary }) => {
  return (
    <Row
      className={
        classes.peoplecard +
        "  border rounded py-3 px-2 d-flex justify-content-between mx-2"
      }
    >
      <Col>
        <div style={{ fontSize: "16px" }} className="fw-semibold text-dark">
          {salary.Designation}
        </div>
        <div style={{ fontSize: "13px" }} className="text-secondary">
          {salary.Experience}
        </div>
      </Col>
      <Col className="text-end">
        <div
          className="text-primary  "
          style={{ fontWeight: "800", fontSize: "18px", lineHeight: "2" }}
        >
          {salary.Average_Salary}
        </div>
        <div style={{ fontSize: "11px" }} className="text-secondary">
          {salary.Salary_Range}
        </div>
      </Col>
    </Row>
  );
};

export default SalaryCard;
