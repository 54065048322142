import React from "react";
import { Placeholder, Stack, Row, Col } from "react-bootstrap";

const ResumeListLoader = () => {
  return (
    <Row>
      <Col lg={4} md={6} sm={12} >
        <Placeholder as="p" animation="glow">
          <Stack direction="horizontal" gap={3}>
            <Placeholder
              bg="primary"
              style={{
                borderRadius: "10px",
                height: "25rem",
              }}
              sm={12}
            />
          </Stack>
        </Placeholder>
      </Col>
      <Col lg={8} md={6} sm={6}>
        <Placeholder as="p" animation="glow" className="mt-3">
        <Placeholder
            className="m-4"
            size="lg"
            bg="secondary"
            as="div"
            sm={8}
          
          />
          <Placeholder
            className="m-4"
            size="lg"
            bg="secondary"
            as="div"
            sm={3}
            style={{
              height: "18rem",
            }}
          />
          <Placeholder
            className="m-4"
            size="lg"
            bg="secondary"
            as="div"
            sm={3}
            style={{
              height: "18rem",
            }}
          />
          <Placeholder
            className="m-4"
            size="lg"
            bg="secondary"
            as="div"
            sm={3}
            style={{
              height: "18rem",
            }}
          />
        </Placeholder>
      </Col>
    </Row>
  );
};

export default ResumeListLoader;
