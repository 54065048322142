import React from "react";
import classes from "./Loader.module.css";

const Loader = ({ timer}) => {
  return (
    <>
    <div className="text-center py-3" style={{fontSize:"12px"}} >
          Your Resume is being parsed thoroughly.
          <div >It usually takes around 30 seconds.</div>
        </div>
      <div className={classes.ocrloader}>
        <em></em>
        <div>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
        </div>
        <span></span>
      </div>
      <div className={classes.wrapper + " text-center fw-semibold "}>
        
        <div style={{marginTop:"8rem"}}>
          Time spent : <span className="text-danger">{timer}</span> s
        </div>
       
      </div>
    </>
  );
};

export default Loader;
