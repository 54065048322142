import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import classes1 from "./jobs.module.css";
import classes from "../Screens.module.css";
import parse from "html-react-parser";
import ToggleSelect from "./ToggleSelect";
import { HiDotsHorizontal } from "react-icons/hi";
import Dropdown from "react-bootstrap/Dropdown";
import { getLabelByValue } from "../../../../../utils/jobutils";
import { salary, workmode } from "../../../../../data/JobData";
import { useHirerJobListContext } from "../../../../../provider/RecruiterJobListContext";
import { useNavigate } from "react-router-dom";
import { GrFormPreviousLink, GrFormNextLink } from "react-icons/gr";
import { IoLocationOutline, IoTimeOutline } from "react-icons/io5";
import { MdOutlineWorkOutline } from "react-icons/md";
import { FaRupeeSign } from "react-icons/fa";
import { LiaRupeeSignSolid } from "react-icons/lia";

const JobDetails = ({ activeTab, handleNextPage, handlePrevPage }) => {
  const { jobs, nextPageUrl, prevPageUrl } = useHirerJobListContext();
  const navigate = useNavigate();

  const handleDropdownItemClick = (jobId, action, jobTitle) => {
    if (action === "edit") {
      navigate(`/postJob/${jobId}`);
    } else if (action === "view") {
      navigate(`/hirer/candidate-list/${jobId}`, { state: { jobTitle } });
    }
  };
  const MAX_LENGTH = 200;
  const [expandedJobs, setExpandedJobs] = useState({});

  const toggleReadMore = (jobId) => {
    setExpandedJobs((prevExpandedJobs) => ({
      ...prevExpandedJobs,
      [jobId]: !prevExpandedJobs[jobId],
    }));
  };

  const getTruncatedDescription = (description) => {
    if (description.length <= MAX_LENGTH) {
      return description;
    }
    return `${description.slice(0, MAX_LENGTH)}...`;
  };
  if (jobs.length === 0) {
    return (
      <Container>
        <Row
          className={classes1.jobDetails + " py-4 px-4 shadow-sm text-center "}
        >
          <Col>
            <p>No jobs available</p>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container>
      {jobs
        .filter((job) => {
          if (activeTab === "first") return true;
          if (activeTab === "second") return job.is_active;
          if (activeTab === "third") return !job.is_active;
          return true;
        })
        .map((job) => (
          <>
            <Container
              key={job.id}
              className={`${classes.jobCard + " mb-4 "} `}
            >
              <div className=" d-flex justify-content-between mb-2">
                <div>
                  <h4 className=" fw-bold text-dark ">{job.job_title}</h4>
                  <div
                    className=" d-flex justify-content-start gap-3"
                    style={{ fontSize: "14px" }}
                  >
                    <p className={classes.smText}>
                      <MdOutlineWorkOutline />
                      <span> {getLabelByValue(job.work_mode, workmode)}</span>
                    </p>
                    <p className={classes.smText}>
                      <IoTimeOutline />
                      <span>{job.created_at}</span>
                    </p>{" "}
                    <p className={classes.smText}>
                      <IoLocationOutline />
                      <span style={{ fontSize: "14px" }}>
                        {job.job_location}
                      </span>
                    </p>
                    <p className={classes.smText}>
                      <LiaRupeeSignSolid />
                      <span style={{ fontSize: "14px" }}>
                        {getLabelByValue(job.salary, salary)}
                      </span>
                    </p>
                  </div>
                </div>

                <div>
                  <Dropdown>
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className={classes1.dropdownToggle + " py-0 p-1"}
                    >
                      <HiDotsHorizontal className=" fs-4" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu style={{ fontSize: "14px" }}>
                      <Dropdown.Item
                        onClick={() => handleDropdownItemClick(job.id, "edit")}
                      >
                        Edit Job
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() =>
                          handleDropdownItemClick(job.id, "view", job.job_title)
                        }
                      >
                        View all Candidates
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <Row>
                <p className="text-secondary">
                  {expandedJobs[job.id]
                    ? parse(job.det_desc)
                    : parse(getTruncatedDescription(job.det_desc))}
                  {job.det_desc.length > MAX_LENGTH && (
                    <span
                      className="text-primary"
                      style={{ cursor: "pointer" }}
                      onClick={() => toggleReadMore(job.id)}
                    >
                      {expandedJobs[job.id] ? " Read Less" : " Read More"}
                    </span>
                  )}
                </p>

                <div className=" d-flex align-items-center justify-content-between">
                  <Col lg={8} className="">
                    {job.skills_req.split(",").map((skill, index) => (
                      <div
                        key={index}
                        className={classes.skillBadge + ` mb-2 px-2 me-2 py-1 `}
                      >
                        {skill.trim()}
                      </div>
                    ))}
                  </Col>
                  <div className="text-lg-center">
                    <ToggleSelect isActive={job.is_active} jobId={job.id} />
                  </div>
                </div>
              </Row>
            </Container>
          </>
        ))}
      {nextPageUrl || prevPageUrl ? (
        <div className="d-flex justify-content-center">
          <button
            className={`border-end-0 rounded-end-0 rounded-3 px-2 py-2 btn d-flex align-items-center gap-1 fw-semibold ${
              !prevPageUrl ? "btn-outline-primary" : "btn-primary"
            } ${prevPageUrl && nextPageUrl ? "border-white" : ""}`}
            onClick={handlePrevPage}
            disabled={!prevPageUrl}
          >
            <GrFormPreviousLink className=" fs-5 fw-bolder bg-white text-primary rounded-5" />{" "}
            Previous
          </button>

          <button
            className={`rounded-start-0 rounded-3 px-3 py-2 p-3 btn d-flex align-items-center gap-1  fw-semibold  ${
              !nextPageUrl ? "btn-outline-primary" : "btn-primary"
            } ${prevPageUrl && nextPageUrl ? "border-white" : ""}`}
            onClick={handleNextPage}
            disabled={!nextPageUrl}
          >
            Next{" "}
            <GrFormNextLink className=" fs-5 fw-bolder bg-white text-primary rounded-5 " />
          </button>
        </div>
      ) : null}
    </Container>
  );
};

export default JobDetails;
