import React, { useState } from "react";
import AuthenticationBg from "./AuthenticationBg";
import { IoMdHome } from "react-icons/io";
import seeker from "../../assets/seeker.png";
import hirer from "../../assets/hirerimg.svg";
import classes from "./Authentication.module.css";
import { useAuthContext } from "../../provider/AuthContext";
import { Row, Col } from "react-bootstrap";
import Loading from "../../ui/common/Loading";
import { replacerole } from "../../utils/Validation";
import { useEffect } from "react";

const ChooseUserSSO = () => {
  const { chooseUser, isLoading } = useAuthContext();
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    const role = localStorage.getItem("role");
    setUserRole(role)
  }, [])
  

  const onChooseUser = (userrole) => {
    chooseUser(userrole);
  };

  const changeRole = () => {
    if (userRole === "C") {
      setUserRole("H");
      localStorage.setItem("role", "H");
    } else {
      setUserRole("C");
      localStorage.setItem("role", "C");
    }
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <AuthenticationBg
          heading={`Continue as ${replacerole(userRole)}`}
          subheading2="Click the image below to get started !"
          subheading="Choose your role."
        >
          <div className=" mt-5 d-flex flex-colomn align-items-center justify-content-center">
            {userRole === "C" ? (
              <div className={classes.col}>
                <img src={seeker} alt="jobBox" />
                <div className={classes.content}>
                  <h5 className="fw-bold" onClick={() => onChooseUser("C")}>
                    I want to work
                  </h5>
                </div>
              </div>
            ) : (
              <div className={classes.col1}>
                <img src={hirer} alt="jobBox" />
                <div className={classes.content}>
                  <h5 className="fw-bold" onClick={() => onChooseUser("H")}>
                    I want to hire
                  </h5>
                </div>
              </div>
            )}
          </div>
          <div
            className=" fst-italic  d-flex align-items-center mt-3 text-center justify-content-center"
            style={{ fontSize: "14px" }}
          >
            I want to continue as
            <span className="ms-1 fw-semibold">
              {" "}
              {userRole === "H" ? "Candidate" : "Hirer"}{" "}
            </span>
            <u
              className="  text-primary ms-1"
              style={{ cursor: "pointer" }}
              onClick={changeRole}
            >
              Click here
            </u>
          </div>
        </AuthenticationBg>
      )}
    </>
  );
};

export default ChooseUserSSO;
