import React from "react";
import { Form, Spinner } from "react-bootstrap";
import { FaRegEye, FaRegEyeSlash, FaCheck } from "react-icons/fa";
import { IoIosCheckmarkCircle } from "react-icons/io";

const FormInput = (props) => {
  return (
    <Form.Group className={props.className} style={{ position: "relative" }}>
      {props.required ? (
        <>
          <Form.Label className="ms-1 text-muted fw-semibold" style={{fontSize:"13px"}}>
            {props.label}
            <span className="text-dark fw-semibold" style={{ fontSize: "12px" }}>
              *
            </span>
          </Form.Label>
          <Form.Control
            name={props.name}
            type={props.type}
            placeholder={props.placeholder}
            onChange={props.onChange}
            value={props.value}
            defaultValue={props.defaultValue}
            isInvalid={!!props.error}
            disabled={props.disabled}
            required
          />
        </>
      ) : (
        <>
          <Form.Label className="ms-1 text-muted">{props.label}</Form.Label>
          <Form.Control
            name={props.name}
            type={props.type}
            placeholder={props.placeholder}
            onChange={props.onChange}
            value={props.value}
            defaultValue={props.defaultValue}
            isInvalid={!!props.error}
            disabled={props.disabled}
          />
        </>
      )}

      {props.isCheckingU ? (
        <Spinner
          style={{ position: "absolute", right: "22px", top: "48px" }}
          size="sm"
          className="text-primary"
        />
      ) : !props.isCheckingU && props.userCheck === "success" ? (
        <IoIosCheckmarkCircle
          style={{
            position: "absolute",
            right: "22px",
            top: "48px",
            color: "5DC245",
            fontSize: "18px",
          }}
        />
      ) : (
        ""
      )}

      {props.isCheckingE ? (
        <Spinner
          style={{ position: "absolute", right: "22px", top: "48px" }}
          size="sm"
          className="text-primary"
        />
      ) : !props.isCheckingE && props.emailCheck === "success" ? (
        <IoIosCheckmarkCircle
          style={{
            position: "absolute",
            right: "22px",
            top: "48px",
            color: "5DC245",
            fontSize: "18px",
          }}
        />
      ) : (
        ""
      )}

      {props.form === "pw" && props.type === "password" ? (
        <FaRegEye
          onClick={props.toggle}
          style={{
            position: "absolute",
            right: "1rem",
            top: "3.2rem",
            cursor: "pointer",
          }}
        />
      ) : props.form === "pw" && props.type === "text" ? (
        <FaRegEyeSlash
          onClick={props.toggle}
          style={{
            position: "absolute",
            right: "1rem",
            top: "3.2rem",
            cursor: "pointer",
          }}
        />
      ) : (
        ""
      )}
      <Form.Control.Feedback
        type="invalid"
        style={{
          textAlign: "left",
          color: "red",
          fontSize: "11px",
        }}
      >
        {props.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default FormInput;
