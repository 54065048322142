import React, { useState } from "react";
import PersonalDetails from "./PersonalDetails";
import ProfessionalSummary from "./ProfessionalSummary";
import SocialLinks from "./SocialLinks";
import CustomButton from "../../resumeUI/CustomButton";
import CustomButtonOutline from "../../resumeUI/CustomButtonOutline";
import { useResumeBuilderContext } from "../../../../provider/ResumeBuilderContext";
import { Alert } from "react-bootstrap";

const Personal = () => {
  const {
    handleNext,
    handlePrevious,
    showError,
    setShowError,
    errors,
    resumeData,
  } = useResumeBuilderContext();

  return (
    <div>
      <PersonalDetails />
      <ProfessionalSummary />
      <div className="d-flex align-items-center justify-content-end mb-5">   
        <CustomButton
          text="Next"
          onClickFn={() => {
            if (
              resumeData.phone === null ||
              errors.phone !== "" ||
              resumeData.phone === "" ||
              resumeData.email === null ||
              resumeData.email === "" ||
              errors.email !== "" ||
              resumeData.firstName === null ||
              resumeData.firstName === "" ||
              errors.firstName !== "" ||
              resumeData.job_title === null ||
              resumeData.job_title === "" ||
              errors.job_title !== "" ||
              resumeData.dob === null ||
              resumeData.dob === "" ||
              errors.dob !== "" ||
              resumeData.gender === null ||
              resumeData.gender === ""
            )  {
              alert(
                "Please fill all required fields in PersonalDetails section"
              );
            } else {
              handleNext();
            }
          }}
        />
      </div>
    </div>
  );
};

export default Personal;
