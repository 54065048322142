import React, { useState, useEffect } from "react";
import {
  Navbar,
  Container,
  Nav,
  Button,
  Stack,
  Dropdown,
} from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";

const signin = {
  heading: "Member Login",
  subheading: "Register",
  subheading2: "Access to all features. No credit card required.",
};
const register = {
  heading: "Let's get started",
  subheading: "Welcome back!",
  subheading2: "Access to all features. No credit card required.",
};

const Header = (props) => {
  const role = localStorage.getItem("role");
  const navigate = useNavigate();
  const [isNavFixed, setIsNavFixed] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 0) {
        setIsNavFixed(true);
      } else {
        setIsNavFixed(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navigateSigninHirer = () => {
    localStorage.setItem("role", "H");
    navigate("/signin");
  };

  const navigateSigninCandidate = () => {
    localStorage.setItem("role", "C");
    navigate("/signin");
  };

  const navigateRegister = () => {
    if (role === null) {
      navigate("/select");
    } else {
      navigate("/register");
    }
  };

  return (
    <Navbar
      expand="lg"
      className={`py-3 ${isNavFixed ? "fixed-top bg-white shadow-lg" : ""}`}
    >
      <Container>
        <Navbar.Brand
          as={Link}
          to="/"
          className=" fs-4 text-dark d-flex align-items-center "
          style={{ fontWeight: "700" }}
        >
          <div
            className="bg-primary p-1 text-white"
            style={{ borderRadius: "50px", height: "45px", width: "45px" }}
          >
            em
          </div>
          Career
        </Navbar.Brand>

        {props.type === "auth" ? (
          ""
        ) : (
          <>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <div className="ms-lg-auto flex-row gap-5 d-lg-flex align-items-center ">
                <div
                  className="text-dark my-3"
                  onClick={navigateRegister}
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  Register
                </div>
                <Dropdown className="custom-dropdown">
                  <Dropdown.Toggle id="dropdown-basic">
                    Sign In
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={navigateSigninHirer}>
                      as Hirer
                    </Dropdown.Item>
                    <Dropdown.Item onClick={navigateSigninCandidate}>
                      as Candidate
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Navbar.Collapse>
          </>
        )}
      </Container>
    </Navbar>
  );
};

export default Header;
