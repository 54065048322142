import React, { useEffect, useState } from "react";
import { Col, Container, Placeholder, Row } from "react-bootstrap";
import classes1 from "./jobs.module.css";
import SortTabs from "./SortTabs";
import RecruiterHeader from "../../../layouts/RecruiterHeader";
import { useHirerJobListContext } from "../../../../../provider/RecruiterJobListContext";
import RecruiterBody from "../../../layouts/RecruiterBody";

const Jobs = () => {
  const { fetchJobsData, nextPageUrl, prevPageUrl, jobCount } =
    useHirerJobListContext();

  useEffect(() => {
    // Fetch initial page of jobs
    fetchJobsData(`${process.env.REACT_APP_BASE_URL}hirer/job/list?page=1`);
  }, []);

  const handleNextPage = () => {
    if (nextPageUrl) {
      const currentPage = nextPageUrl.split("=")[1];
      const nextPage = `${
        process.env.REACT_APP_BASE_URL
      }hirer/job/list?page=${Number(currentPage)}`;
      fetchJobsData(nextPage);
    }
  };

  const handlePrevPage = () => {
    if (prevPageUrl) {
      let currentPage;
      if (prevPageUrl.includes("?page=")) {
        currentPage = prevPageUrl.split("=")[1];
      } else {
        currentPage = 1;
      }
      const prevPage = `${
        process.env.REACT_APP_BASE_URL
      }hirer/job/list?page=${Number(currentPage)}`;
      fetchJobsData(prevPage);
    }
  };

  return (
    <RecruiterBody>
      <Row>
        <Col lg={12}>
          <div className="d-flex align-items-center gap-2">
            <h2 className={classes1.heading}>Jobs Posted</h2>
            {jobCount !== null ? (
              <p
                className="fw-semibold text-secondary"
                style={{ fontSize: "14px" }}
              >
                Showing {jobCount} Available Jobs
              </p>
            ) : null}
          </div>
          <div className="w-100 border-bottom pt-2"></div>
        </Col>
      </Row>
      <Row>
        <Col className="py-4">
          <SortTabs
            handleNextPage={handleNextPage}
            handlePrevPage={handlePrevPage}
          />
        </Col>
      </Row>
    </RecruiterBody>
  );
};

export default Jobs;
