import React from "react";
import CustomSelect from "../jobsearch/CustomSelect";
import { score, joblisting, archivesorting } from "../../../data/JobData";
import { Form, Row, Col, Badge } from "react-bootstrap";
import { useArchiveContext } from "../../../provider/ArchivesContext";

const ArchiveFilter = () => {
  const {
    selectedScore,
    handleChangeScore,
    selectedSort,
    handleChangeSort,
    selectedJob,
    handleCheckboxChange,
    archiveCount,
  } = useArchiveContext();

  return (
    <div className="py-4  ">
      <h5 className="fw-semibold mt-lg-4 text-dark" style={{ fontSize: "20px" }}>
        JobListing
      </h5>
      <div className="border-bottom py-4 ">
        {joblisting.map((job) => (
          <Row className=" ">
            <Col lg={10} sm={10} xs={10}>
              <Form.Check
                key={job.value}
                className="py-2 text-muted"
                style={{ fontSize: "15px", fontWeight: "500" }}
                type="checkbox"
                id={job.value}
                label={job.label}
                value={job.value}
                checked={selectedJob === job.value}
                onChange={() => handleCheckboxChange(job.value)}
              />
            </Col>
            <Col lg={2} sm={2} xs={2}>
              {selectedJob === job.value && (
                <Badge bg="primary">{archiveCount}</Badge>
              )}
            </Col>
          </Row>
        ))}
      </div>
      <Row className="mt-3">
        <h5
          className="fw-semibold mt-2 mb-4 text-dark d-lg-none"
          style={{ fontSize: "20px" }}
        >
          sort by
        </h5>
        <Col lg={12} md={12} sm={6} xs={6}>
          <div className="d-lg-none ms-2 mb-1 fst-italic text-secondary">
            score :
          </div>
          <div className="d-flex align-items-center border rounded me-3 p-2 mt-lg-3">
            <span
              className="fw-semibold d-none d-lg-inline"
              style={{ fontSize: "12px", color: "#A0ABC3" }}
            >
              Sort by
              <span className=" mx-1">Score Value</span>:
            </span>
            <CustomSelect
              options={score}
              handleChange={handleChangeScore}
              selectedvalue={selectedScore}
              fontSize="14px"
            />
          </div>
        </Col>
        <Col lg={12} md={12} sm={6} xs={6}>
          <div className="d-lg-none ms-2 mb-1 fst-italic text-secondary">
            applied at :
          </div>
          <div className="d-flex align-items-center border rounded me-3 p-2 mt-lg-4 ">
            <span
              className="fw-semibold d-none d-lg-inline"
              style={{ fontSize: "12px", color: "#A0ABC3" }}
            >
              Sort by
              <span className=" mx-1">Applied at</span>:
            </span>
            <CustomSelect
              options={archivesorting}
              handleChange={handleChangeSort}
              selectedvalue={selectedSort}
              fontSize="14px"
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ArchiveFilter;
