import React, { useState } from "react";
import classes from "./ResumeFormData.module.css";
import { Row, Col } from "react-bootstrap";
import Input from "../../resumeUI/Input";
import Select from "../../resumeUI/Select";
import { FaTrashAlt } from "react-icons/fa";
import AccordionField from "../../resumeUI/AccordionField";
import { validateUrl } from "../../../../utils/Validation";

const SocialLinkData = ({ data, onChange, index, onDelete }) => {
  const [disabled, setDisabled] = useState(true);
  const [errors, setErrors] = useState("");

  const social = [
    { value: "Linkedin", label: "LinkedIn" },
    { value: "GitHub", label: "Github" },
    { value: "Website", label: "Website" },
    { value: "Otherlinks", label: "Other" },
    // { value: "Twitter", label: "Twitter" },
  ];

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;

    if (name === "name" && value !== " ") {
      setDisabled(false);
    }
    if ((name === "link") & (value !== "")) {
      const valid = validateUrl(value);
      if (!valid) {
        setErrors("Enter a valid link");
      } else {
        setErrors("");
      }
    } else {
      setErrors("");
    }

    onChange(e, index);
  };

  return (
    <Row>
      <Col lg={11} xs={11}>
        <AccordionField
          // defaultKey={defaultKey}
          index={index}
          key={index}
          subheading={data.link ? `${data.name || " "}` : "not specified"}
        >
          <Row key={index}>
            <Col lg={4} xs={12} className="mt-1">
              <Select
                name="name"
                options={social}
                value={data.name}
                text="Name"
                placeholder="Select an option"
                onChange={(e) => handleInputChange(e, index)}
              />
            </Col>
            <Col lg={8} xs={12}>
              <Input
                name="link"
                type="text"
                text="Link"
                font="14px"
                value={data.link}
                onChange={(e) => handleInputChange(e, index)}
                disabled={disabled}
                error={errors}
              />
            </Col>
          </Row>
        </AccordionField>
      </Col>
      <Col lg={1} xs={1}>
        <div className={classes.trash + " mt-4"}>
          <FaTrashAlt onClick={() => onDelete(index)} />
        </div>
      </Col>
    </Row>
  );
};

export default SocialLinkData;
