import React from "react";
import { Row, Col } from "react-bootstrap";
import { useProfileContext } from "../../../provider/ProfileContext";
import classes from "./Profile.module.css";
import { IoLocationOutline } from "react-icons/io5";
import { MdOutlinePerson2, MdOutlineFlag } from "react-icons/md";
import { SlCalender } from "react-icons/sl";
import { formatDateOfBirth } from "../../../utils/Validation";

const PersonalDetailsCard = ({ profileDataDetails }) => {
  const hasPersonalInfo =
    profileDataDetails.profaddress ||
    profileDataDetails.profnationality ||
    profileDataDetails.profdob ||
    profileDataDetails.profgender;

  return (
    <>
      {hasPersonalInfo && (
        <div className={classes.personalinfo}>
          <p className={classes.companyName + " text-black "}>Personal Info</p>
          <div className="border-bottom pt-2 mb-3"></div>
          <Row className="d-flex flex-column" style={{ fontSize: "15px" }}>
            <Col className="d-flex flex-column mb-2">
              {profileDataDetails.profaddress && (
                <>
                  <h2 className={classes.mdText}>
                    <IoLocationOutline className={classes.iconStyles} />
                    <span>From</span>
                  </h2>
                  <p className="ms-5 fw-bold">
                    {profileDataDetails.profaddress}
                  </p>
                </>
              )}

              {profileDataDetails.profnationality && (
                <>
                  <h2 className={classes.mdText}>
                    <MdOutlineFlag className={classes.iconStyles} />
                    <span>Nationality</span>
                  </h2>
                  <p className="ms-5 fw-bold mb-3">
                    {profileDataDetails.profnationality}
                  </p>
                </>
              )}

              {profileDataDetails.profdob && (
                <>
                  <h2 className={classes.mdText}>
                    <SlCalender className={classes.iconStyles} />
                    <span>Date of Birth</span>
                  </h2>
                  <p className="ms-5 fw-bold mb-3">
                    {formatDateOfBirth(profileDataDetails.profdob)}
                  </p>
                </>
              )}

              {profileDataDetails.profgender && (
                <>
                  <h2 className={classes.mdText}>
                    <MdOutlinePerson2 className={classes.iconStyles} />
                    <span>Gender</span>
                  </h2>
                  <p className="ms-5 fw-bold">
                    {profileDataDetails.profgender === "F"
                      ? "Female"
                      : profileDataDetails.profgender === "M"
                      ? "Male"
                      : "Others"}
                  </p>
                </>
              )}
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default PersonalDetailsCard;
