import React from "react";
import classes from "../Screens.module.css";
import { Row, Col, Button } from "react-bootstrap";
import { FaCircleCheck } from "react-icons/fa6";
import { GiCheckMark } from "react-icons/gi";

const UpgradeCard = ({
  plan,
  price,
  features,
  input,
  selectedPlan,
  onSelectPlan,
  upgrading,
}) => {
  const isSelected = selectedPlan === input;

  return (
    <div
      className={
        isSelected ? `${classes.plancardsel} selected` : classes.plancard
      }
    >
      <div className="d-flex align-items-center justify-content-between mb-3">
        <h5 className="fw-bold">{plan}</h5>
        {isSelected && <FaCircleCheck className="text-primary fs-4 mb-1" />}
      </div>

      <h3 className="fw-bold">
        ₹ {price}{" "}
        <span style={{ fontSize: "14px" }} className="text-light fw-semibold">
          / month
        </span>
      </h3>
      <div className="text-center w-100 my-4">
        {isSelected ? (
          <Button
            className="w-100 rounded-2 py-2 fw-semibold"
            size="sm"
            disabled
          >
            Current Plan
          </Button>
        ) : (
          <Button
            className="w-100 rounded-2 py-2 fw-semibold"
            size="sm"
            onClick={() => onSelectPlan(input)}
            disabled={upgrading} // Disable the button if upgrading
          >
            {upgrading ? "Upgrading..." : "Upgrade"}
          </Button>
        )}
      </div>
      <div>
        {features.map((feature, index) => (
          <Row
            key={index}
            className="d-flex mb-2"
            style={{ fontSize: "12px", fontWeight: "500" }}
          >
            <Col lg={1}>
              <GiCheckMark className="text-primary fw-bold" />
            </Col>
            <Col lg={10} className="text-dark fst-italic ">
              {feature}
            </Col>
          </Row>
        ))}
      </div>
    </div>
  );
};

export default UpgradeCard;
