import React from "react";
import classes1 from "../../Screens.module.css";
import { Button, Row, Col } from "react-bootstrap";
import { IoLocationOutline, IoShareSocialOutline } from "react-icons/io5";
import { MdWorkOutline, MdOutlineEmail } from "react-icons/md";
import { toCamelCase } from "../../../../../../utils/Validation";
import classes from "../../../../../candidate/jobsearch/Jobsearch.module.css";
import map from "../../../../../../assets/maps.png";
import StarRating from "../../../../../../ui/common/StarRating";

const CompanyInfo = ({ data }) => {
  const generateGoogleMapsLink = () => {
    return `https://www.google.com/maps/place/${encodeURIComponent(
      data.company_location
    )}`;
  };

  return (
    <>
      {data && (
        <div className={classes.sidebarborder + " pb-4"}>
          <div className="py-4 border-bottom">
            <a href={data.company_link} target="_blank" rel="noopener">
              {data.company_name && (
                <span className={classes.sidebarcompany + " text-dark"}>
                  {toCamelCase(data.company_name)}
                </span>
              )}
            </a>
            {data.ambition_box_company_rating && (
              <div className="d-flex align-items-center">
                <StarRating rating={data.ambition_box_company_rating} />
                <span
                  className="text-light ms-1 mt-1"
                  style={{ fontSize: "12px" }}
                >
                  ( {data.ambition_box_vote_count} )
                </span>
              </div>
            )}

            <span className={classes.companyloc}>
              <IoLocationOutline className="mb-1 me-1" />
              {data.company_location}
            </span>
          </div>
          <div className={classes1.boxmap + " py-3 border-bottom"}>
            <a
              href={generateGoogleMapsLink(data.company_location)}
              target="_blank"
              rel="noreferrer"
              title={data.company_location}
            >
              <img src={map} alt="map" />
            </a>
          </div>

          <div className={classes1.sidebarlist + " pt-3 border-bottom"}>
            <Row className={classes1.sidebartextinfo}>
              <Col lg={4} className={classes1.textdescription}>
                <IoLocationOutline className="fs-6 text-secondary  me-2 mb-1" />
                Location
              </Col>
              <Col lg={8} className={classes1.smallheading + " fw-bold"}>
                {data.company_location}
              </Col>
            </Row>
            <Row className={classes1.sidebartextinfo}>
              <Col lg={4} className={classes1.textdescription}>
                <IoShareSocialOutline className="fs-6 text-secondary  me-2 mb-1" />
                Social Link
              </Col>
              {(data.social_link &&
                data.social_link.split("\n").map((link, index) => (
                  <Col
                    lg={8}
                    className={classes1.smallheading + " fw-bold"}
                    key={index}
                  >
                    <a href={link} target="_blank" className="text-dark">
                      {link.trim().slice(0, 25) +
                        (data.social_link.length > 25 ? "..." : "")}
                    </a>
                  </Col>
                ))) || (
                <Col lg={8} className={classes1.smallheading + " fw-bold"}>
                  <a
                    href={data.company_linkedin_link}
                    target="_blank"
                    className="text-dark"
                  >
                    {data.company_linkedin_link &&
                      data.company_linkedin_link.trim().slice(0, 25) +
                        (data.company_linkedin_link.length > 20 ? "..." : "")}
                  </a>
                </Col>
              )}
            </Row>
            <Row className={classes1.sidebartextinfo}>
              <Col lg={4} className={classes1.textdescription}>
                <MdWorkOutline className="fs-6 text-secondary  me-2 mb-1" />
                Jobs 
              </Col>
              <Col lg={8} className={classes1.smallheading + " fw-bold"}>
                {data.posted_jobs || data.jobs_posted}
              </Col>
            </Row>
          </div>

          <ul className={classes.uldisc + " pt-3"}>
            {data.hirer_name && <li>{data.hirer_name}</li>}
            {data.company_phone && <li>Phone: {data.company_phone}</li>}
            {data.email && <li>Email: {data.email}</li>}
            {data.company_address.company_address && (
              <li>Address: {data.company_address.company_address}</li>
            )}
          </ul>
          <a href={`mailto:${data.email}`}>
            <Button
              disabled={!data.email}
              size="lg"
              className="fw-bold my-1"
              style={{ fontSize: "16px" }}
            >
              <MdOutlineEmail
                className="mb-1 me-2"
                style={{ fontSize: "18px" }}
              />
              Send Message
            </Button>
          </a>
        </div>
      )}
    </>
  );
};

export default CompanyInfo;
