import React, { useEffect, useState } from "react";
import AuthenticationBg from "./AuthenticationBg";
import { Form, Button, Spinner } from "react-bootstrap";
import FormInput from "../../ui/common/FormInput";
import { useNavigate, Link } from "react-router-dom";
import { useAuthContext } from "../../provider/AuthContext";

const Signin = () => {
  const navigate = useNavigate();
  const { isLoading, login } = useAuthContext();
  const [passwordType, setPasswordType] = useState("password");

  const [signInData, setSignInData] = useState({
    username: "",
    password: "",
  });

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    const role = localStorage.getItem("role");
    if (role) {
      localStorage.setItem("role", role);
    } else {
      localStorage.setItem("role", "C");
    }
  }, []);


  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const handleChangeSignIn = (e) => {
    if (e && e.target) {
      const { name, value } = e.target;

      setSignInData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleSubmitSignin = async (e) => {
    e.preventDefault();
    try {
      const response = await login(signInData);
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        const { email, username, password, error, detail } = err.response.data;
        const showAlert = (field) => {
          if (field && field.length > 0) {
            const errorMessage = field[0];
            alert(errorMessage);
          }
        };
        const showAccAlert = (field) => {
          alert(field);
        };
        if (err.response.data.email) {
          showAlert(email);
        }
        if (err.response.data.username) {
          showAlert(username);
        }
        if (err.response.data.password) {
          showAlert(password);
        }
        if (err.response.data.error) {
          showAlert(error);
        }
        if (err.response.data.detail) {
          showAccAlert(detail);
        }
      } else {
      }
    }
  };
  return (
    <AuthenticationBg
      heading="Login as"
      subheading="Welcome Back!"
      subheading2="Access to all features. No credit card required."
      auth="signin"
    >
      <Form onSubmit={handleSubmitSignin}>
        <FormInput
          name="username"
          type="text"
          placeholder="Username"
          label="Email address / Username"
          value={signInData.username}
          onChange={handleChangeSignIn}
          className="mb-3"
          required="required"
        />
        <FormInput
          form="pw"
          name="password"
          type={passwordType}
          placeholder="********"
          label="Password"
          value={signInData.password}
          onChange={handleChangeSignIn}
          toggle={togglePassword}
          className="mb-3"
          required="required"
        />

        <div
          className="d-flex justify-content-end my-2 mb-3"
          style={{ fontSize: "14px" }}
        >
          {/* <Form.Check
            className="text-muted me-2"
            type="checkbox"
            label="Remember me"
          /> */}
          <Link
            to="/forgotpassword"
            className="text-primary"
            style={{ cursor: "pointer" }}
          >
            Forgot password?
          </Link>
        </div>
        <Button
          variant="dark"
          className="my-2 w-100"
          type="submit"
          disabled={
            Object.values(signInData).some((value) => value === "") || isLoading
          }
        >
          {isLoading ? <Spinner size="sm" /> : "Login"}
        </Button>
      </Form>
    </AuthenticationBg>
  );
};

export default Signin;
