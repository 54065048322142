import React, { useContext } from "react";
import { Badge } from "react-bootstrap";
import { RecruiterCandidateListContext } from "../../../../../provider/RecruiterCandidateListContext";

const CertificationPreview = () => {
  const { singleCandidate } = useContext(RecruiterCandidateListContext);
  const certificationProfile = singleCandidate.certifications
    ? singleCandidate.certifications
    : [];
  return (
    <div className="fw-bold text-secondary ">
      {certificationProfile.length > 0 && (
        <div>
          <div className="d-flex align-items-center">
            <h4 className="fw-bold text-dark">Certifications</h4>
          </div>

          <div className="mt-4">
            {certificationProfile.map((certification, index) => {
              const certificationText =
                typeof certification === "object"
                  ? certification.certification
                  : certification;

              return (
                <Badge
                  key={index}
                  bg="info"
                  className="me-3 mb-3 p-3 text-dark"
                >
                  {certificationText}
                </Badge>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default CertificationPreview;
