import React, { useState, useEffect, useRef } from "react";
import classes from "./Common.module.css";

const SearchSelect = (props) => {
  const { name, value, placeholder, handleChange, options, loading } = props;
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  // Close dropdown on outside click
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleInputFocus = () => {
    setShowDropdown(true);
  };
  const handleInputClick = () => {
    setShowDropdown(true);
  };
  return (
    <div className="d-flex flex-column align-items-center position-relative w-100">
      <input
        className={classes.searchinput + " py-2 w-100"}
        type="text"
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={handleChange}
        onFocus={handleInputFocus}
        onClick={handleInputClick}
        style={{ fontSize: "16px" }}
        autocomplete="off"
      />
      {showDropdown && (
        <div
          ref={dropdownRef}
          className={
            classes.options +
            " bg-white text-start w-100 position-absolute  z-2"
          }
          style={{ minHeight: "1rem" }}
        >
          <div className="dropdownsearch">
            {loading ? (
              <div
                className="text-light fw-800  px-3 py-2"
                style={{ fontSize: "14px" }}
              >
                Loading...
              </div>
            ) : options.length > 0 ? (
              options.map((option, index) => (
                <div
                  key={index}
                  className={classes.search + " text-dark "}
                  style={{ fontSize: "14px", cursor: "pointer" }}
                  onClick={() => {
                    props.onOptionSelect(option);
                    setShowDropdown(false);
                  }}
                >
                  {option.length > 18 ? `${option.slice(0, 23)}...` : option}
                </div>
              ))
            ) : (
              <div
                className="text-light fw-800  px-3 py-2"
                style={{ fontSize: "14px" }}
              >
                No options
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchSelect;
