import React, { useContext, useState, useEffect } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import { useProfileContext } from "../../../provider/ProfileContext";
import classes from "./Profile.module.css";
import { Link, useLocation } from "react-router-dom";
import SkillProfile from "./SkillProfile";
import PersonalDetailsCard from "./PersonalDetailsCard";
import SocialLinksView from "./SocialLinksView";
import WorkProfile from "./WorkProfile";
import EducationProfile from "./EducationProfile";
import { RecruiterCandidateListContext } from "../../../provider/RecruiterCandidateListContext";
import SkillsPreview from "../../recruiter/dashboard/screens/candidate/SkillsPreview";
import SocialLinkPreview from "../../recruiter/dashboard/screens/candidate/SocialLinkPreview";
import WorkPreview from "../../recruiter/dashboard/screens/candidate/WorkPreview";
import EducationPreview from "../../recruiter/dashboard/screens/candidate/EducationPreview";

const PersonalInfo = () => {
  const { profileData } = useProfileContext();
  const { singleCandidate } = useContext(RecruiterCandidateListContext);
  const location = useLocation();

  const [profile, setProfile] = useState(null);
  let currentPath = location.pathname;
  let candidateProfile = currentPath.includes("/viewcandidate") ? true : false;

  useEffect(() => {
    if (currentPath.includes("/viewcandidate")) {
      setProfile(singleCandidate);
    } else {
      setProfile(profileData);
    }
  }, [location.pathname, singleCandidate, profileData]);

  
  return (
    <>
      {profile && (
        <Row>
          <Col lg={8}>
            <div className="mb-5 pb-2 border-bottom">
              <h4 className=" fw-bold text-dark mb-3">About me</h4>
              <p
                className=" text-dark"
                style={{ lineHeight: "2", fontSize: "14px" }}
              >
                {profile.profsummary}
              </p>
            </div>
            {candidateProfile ? <SkillsPreview /> : <SkillProfile />}
            {candidateProfile ? <WorkPreview /> : <WorkProfile />}
            {candidateProfile ? <EducationPreview /> : <EducationProfile />}
          </Col>
          <Col lg={4}>
            <Container>
              <PersonalDetailsCard profileDataDetails={profile} />
              {candidateProfile ? <SocialLinkPreview /> : <SocialLinksView />}

              <div className={classes.jobsearchinfo}>
                <h5 className=" text-white fw-semibold mb-3">
                  Looking for Job?
                </h5>
                <p className=" text-white " style={{ fontSize: "14px" }}>
                  Advertise your jobs to millions of monthly users and search
                  16.8 million CVs in our database.
                </p>
                <Button
                  className="bg-white text-primary border-0 fw-semibold"
                  as={Link}
                  to="/jobs"
                >
                  Search Jobs
                </Button>
              </div>
            </Container>
          </Col>
        </Row>
      )}
    </>
  );
};

export default PersonalInfo;
