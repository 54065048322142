import React, { useState } from "react";
import { resumeimages } from "../../data/ResumeImages";
import classes from "./Template.module.css";
import { Row, Col, Image } from "react-bootstrap";
import { useResumeBuilderContext } from "../../provider/ResumeBuilderContext";
import templateChangeHandling from "../../utils/TemplateChangeHandling";
import UploadResume from "./resumeForm/UploadResume";
import CustomButton from "./resumeUI/CustomButton";

const Templates = () => {
  const { setResume, setSelectedTemplate, selectSection, handleNext } =
    useResumeBuilderContext();
  const templateChosen = localStorage.getItem("selectedTemplate");

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const changeTemplate = (newTemplate) => {
    const template = templateChangeHandling(newTemplate);
    setResume(template);
    // selectSection(3);
  };

  return (
    <div>
      <Row className=" d-flex align-items-center py-2">
        <div
          style={{ color: "#04364A" }}
          className="fw-semibold fw-semibold d-flex "
        >
          {/* Pick a template that speaks your professional language. */}
        </div>
        {resumeimages.map((image) => (
          <>
            <Col
              key={image.id}
              lg={6}
              md={12}
              sm={12}
              xs={12}
              className="d-flex justify-content-center my-4 "
            >
              <div >
              <Image
                key={image.id}
                src={image.src}
                alt={image.alt}
                className={`${classes.templateimg} ${
                  templateChosen === image.alt ? classes.activetemplate : ""
                }`}
                onClick={() => {
                  localStorage.setItem("selectedTemplate", image.alt);
                  setSelectedTemplate(image.alt);
                  changeTemplate(image.alt);
                }}
              /></div>
            </Col>
          </>
        ))}
        <div className="d-flex align-items-center justify-content-end mt-3">
          <CustomButton text="Next" onClickFn={handleNext} />
        </div>
      </Row>
    </div>
  );
};

export default Templates;
