import React, { useEffect, useState } from "react";
import Footer from "../../home/layouts/Footer";
import CandidateHeader from "../layouts/CandidateHeader";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { useProfileContext } from "../../../provider/ProfileContext";
import classes from "./Profile.module.css";
import { FaRegEdit } from "react-icons/fa";
import { Link } from "react-router-dom";
import ProfileTabs from "./ProfileTabs";
import { HiDevicePhoneMobile } from "react-icons/hi2";
import { MdOutlineEmail } from "react-icons/md";
import PersonalInfoProfile from "./profileForms/PersonalInfoProfile";
import profile from "../../../assets/profile.png";

const ProfileView = () => {
  const { profileData, file, handleShowPersonal } = useProfileContext();

  return (
    <div>
      <div
        className={classes.boxheadsingle + " mt-4"}
        style={{ backgroundColor: "#f2f6fd" }}
      >
        <Container>
          <Row>
            <Col lg={2}>
              <div className={classes.headingimage + " text-center"}>
                {profileData.profImage !== null && file === null ? (
                  <img src={profileData.profImage} alt="candidateimage" />
                ) : file !== null ? (
                  <img src={file} alt="candidateimage" />
                ) : (
                  <img src={profile} alt="candidateimage" />
                )}
              </div>
            </Col>
            <Col lg={8}>
              <div className="text-center">
                <div className="d-flex flex-column d-lg-flex flex-lg-row justify-content-lg-between align-items-lg-center ">
                  <h4
                    className="pt-3 ps-2"
                    style={{
                      fontSize: "30px",
                      fontWeight: "700",
                    }}
                  >
                    {profileData.proffirstName}
                    <span className="ps-1">{profileData.proflastName}</span>
                  </h4>
                </div>

                <div className={classes.profileinfo + " my-2 text-muted"}>
                  <div className="ps-2 fw-semibold d-lg-flex flex-lg-row justify-content-lg-between align-items-lg-center ">
                    {profileData.profjobTitle}
                  </div>
                  <div className="me-3 my-2 fw-normal  d-flex flex-column d-lg-flex flex-lg-row align-items-lg-center ">
                    <div className="mb-1 mb-lg-0">
                      <HiDevicePhoneMobile className="me-lg-3 fs-5 " />
                      {profileData.profphone}
                    </div>
                    <div>
                      <MdOutlineEmail className="mx-lg-3 fs-5" />
                      {profileData.profemail}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={2}>
              <div className="text-end">
                <Button className=" gap-2" onClick={handleShowPersonal}>
                  Edit Profile <FaRegEdit className="ms-1 mb-1" />
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row className=" mt-5">
          <ProfileTabs />
        </Row>
      </Container>
      <PersonalInfoProfile />
      
    </div>
  );
};

export default ProfileView;
