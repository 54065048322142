import React from "react";
import {
  Page,
  Text,
  Document,
  StyleSheet,
  View,
  Font,
  Link,
} from "@react-pdf/renderer";
// import MyCustomFont from "../../fonts/Roboto.ttf"
import {
  formatDateOfBirth,
  extractListItems,
  extractParagraphs,
} from "../../../utils/ResumeHelpers";

const Resume9 = ({ data, loading }) => {
  //   Font.register({ family: 'Roboto', src: MyCustomFont });

  const styles = StyleSheet.create({
    body: {
      paddingVertical: 30,
      paddingHorizontal: 30,
    },
    headerSection: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      gap: "10px",
      marginBottom: 5,
    },
    mainheading: {
      fontSize: 26,
      fontFamily: "Times-Roman",
      textAlign: "center",
    },
    job_title: {
      textAlign: "center",
    },
    personalInfo: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      color: "#8f8f8f",
      flexWrap: "wrap",
    },
    headingContainer: {
      flexDirection: "row",
      alignItems: "center",
    },
    sectionheadingLine: {
      flex: 1,
      borderBottom: "1pt solid #ccc",
      marginRight: 5,
    },
    section: {
      padding: 7,
    },
    sectionheading: {
      fontSize: 14,
      marginBottom: 5,
      fontFamily: "Times-Roman",
    },
    smtext: {
      fontFamily: "Times-Roman",
      fontSize: 11,
      lineHeight: 1.5,
    },
    xsmtext: {
      fontFamily: "Times-Roman",
      fontSize: 10,
      paddingBottom: 3,
    },
    xxsmtext: {
      fontFamily: "Times-Roman",
      fontSize: 9,
      paddingBottom: 3,
    },
    sectionwrap: {
     paddingBottom: 8,
    },
    eachsectionwrap: {
      paddingVertical: 3,
    },
    sectext: {
      fontSize: 12,
      fontFamily: "Helvetica-Bold",
      marginBottom: 5,
    },
    line: {
      borderBottom: "1pt solid #ccc",
      marginHorizontal: 8,
    },

    rowContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "baseline",
    },
  });
  return (
    <Document>
      {loading ? (
        <Page>
          <Text>Loading document...</Text>
        </Page>
      ) : (
        <Page style={styles.body} size="A4" wrap>
          {/* personal and weblinks */}
          <View style={[styles.headerSection]}>
            <Text style={styles.mainheading}>
              {data.firstName} {data.lastName}
            </Text>
            <Text style={styles.job_title}>{data.job_title}</Text>
            {data.social && data.social.some((social) => social.link) ? (
              <Text style={{ textAlign: "center" }}>
                {data.social.map((soc, id) => (
                  <View key={id}>
                    <Link
                      src={soc.link}
                      style={[styles.smtext, { textDecoration: "none" }]}
                    >
                      {soc.name} &nbsp;
                      <Text style={{ color: "black", fontWeight: "800" }}>
                        |
                      </Text>
                      &nbsp;
                    </Link>
                  </View>
                ))}
              </Text>
            ) : null}
            {/* <Text style={styles.line}></Text> */}

            <View style={styles.personalInfo}>
              {data.address && (
                <Text style={styles.xsmtext}>{data.address} &nbsp;|&nbsp;</Text>
              )}
              {data.phone && (
                <Text style={styles.xsmtext}>{data.phone} &nbsp;|&nbsp; </Text>
              )}
              {data.email && <Text style={styles.xsmtext}>{data.email} </Text>}
              {data.dob && (
                <Text style={styles.xsmtext}>
                  {formatDateOfBirth(data.dob)} &nbsp;|&nbsp;
                </Text>
              )}
              {data.nationality && (
                <Text style={styles.xsmtext}>
                  {data.nationality} &nbsp;|&nbsp;
                </Text>
              )}
              {data.gender === "F" ? (
                <Text style={styles.xsmtext}>Female</Text>
              ) : data.gender === "M" ? (
                <Text style={styles.xsmtext}>Male</Text>
              ) : data.gender === "O" ? (
                <Text style={styles.xsmtext}>Others</Text>
              ) : null}
            </View>
          </View>
          {/* summary */}
          {data.summary && (
            <View style={styles.section}>
              <View style={styles.sectionwrap} wrap={false}>
                <View style={styles.headingContainer}>
                  <View style={styles.sectionheadingLine} />
                  <Text style={styles.sectionheading}>SUMMARY</Text>
                  <View style={styles.sectionheadingLine} />
                </View>
                <View style={{ flexDirection: "column", paddingLeft: 8 }}>
                  {extractParagraphs(data.summary).map((paragraph, index) => (
                    <View
                      key={index}
                      style={{ flexDirection: "row", marginBottom: 2 }}
                    >
                      <Text style={styles.smtext}>{paragraph}</Text>
                    </View>
                  ))}
                  {extractListItems(data.summary).map((item, index) => (
                    <View
                      key={index}
                      style={{ flexDirection: "row", marginBottom: 2 }}
                    >
                      <Text style={styles.smtext}>• &nbsp;</Text>
                      <Text style={styles.smtext}>{item}</Text>
                    </View>
                  ))}
                </View>
              </View>
            </View>
          )}
          {/* experience */}
          <View style={styles.section}>
            {data.employement &&
            data.employement.some(
              (wrk) =>
                wrk.company ||
                wrk.designation ||
                wrk.startDateWe ||
                wrk.endDateWe ||
                wrk.responsibilities
            ) ? (
              <View style={styles.sectionwrap}>
                <View style={styles.headingContainer}>
                  <View style={styles.sectionheadingLine} />
                  <Text style={styles.sectionheading}>EXPERIENCE</Text>
                  <View style={styles.sectionheadingLine} />
                </View>
                {data.employement.map((emp, id) => (
                  <View style={styles.eachsectionwrap} key={id} wrap={false}>
                    <View style={styles.rowContainer}>
                      <Text style={styles.sectext}>{emp.designation} </Text>

                      <Text
                        style={[
                          styles.xsmtext,
                          {
                            color: "#8f8f8f",
                            fontFamily: "Helvetica",
                            fontSize: 10,
                          },
                        ]}
                      >
                        {emp.startDateWe} {emp.startDateWe && "- "}
                        <Text style={styles.xsmtext}>
                          {emp.present === false ? emp.endDateWe : "Present"}
                        </Text>
                      </Text>
                    </View>
                    <Text style={styles.smtext}>{emp.company}</Text>
                    {emp.responsibilities && (
                      <View style={{ flexDirection: "column", paddingLeft: 8 }}>
                        {extractParagraphs(emp.responsibilities).map(
                          (paragraph, index) => (
                            <View key={index} style={{ flexDirection: "row" }}>
                              <Text style={styles.smtext}>{paragraph}</Text>
                            </View>
                          )
                        )}
                        {extractListItems(emp.responsibilities).map(
                          (item, index) => (
                            <View key={index} style={{ flexDirection: "row" }}>
                              <Text style={styles.smtext}>• &nbsp;</Text>
                              <Text style={styles.smtext}>{item}</Text>
                            </View>
                          )
                        )}
                      </View>
                    )}
                  </View>
                ))}
              </View>
            ) : null}
          </View>
          {/* skills */}
          <View style={styles.section}>
            {data.techskills && data.techskills.some((skl) => skl.techSkill) ? (
              <View style={styles.sectionwrap} wrap={false}>
                {/* <Text style={styles.sectionheading}>Skills</Text> */}
                <View style={styles.headingContainer}>
                  <View style={styles.sectionheadingLine} />
                  <Text style={styles.sectionheading}>TECHNICAL SKILLS</Text>
                  <View style={styles.sectionheadingLine} />
                </View>
                <Text style={styles.eachsectionwrap}>
                  <Text style={styles.smtext}>
                    {data.techskills.map((tech) => tech.techSkill).join(", ")}
                  </Text>
                </Text>
              </View>
            ) : null}
            {data.skill && data.skill.some((skl) => skl.softSkill) ? (
              <View style={styles.sectionwrap} wrap={false}>
                <View style={styles.headingContainer}>
                  <View style={styles.sectionheadingLine} />
                  <Text style={styles.sectionheading}>SOFT SKILLS</Text>
                  <View style={styles.sectionheadingLine} />
                </View>
                <Text style={styles.eachsectionwrap}>
                  <Text style={styles.smtext}>
                    {data.skill.map((soft) => soft.softSkill).join(", ")}
                  </Text>
                </Text>
              </View>
            ) : null}
          </View>
          {/* education */}
          <View style={styles.section}>
            {data.education &&
            data.education.some(
              (edu) =>
                edu.institution ||
                edu.course ||
                edu.startDate ||
                edu.endDate ||
                edu.proficiency
            ) ? (
              <View style={styles.sectionwrap}>
                <View style={styles.headingContainer}>
                  <View style={styles.sectionheadingLine} />
                  <Text style={styles.sectionheading}>EDUCATION</Text>
                  <View style={styles.sectionheadingLine} />
                </View>
                {data.education.map((edu, id) => (
                  <View style={styles.eachsectionwrap} key={id} wrap={false}>
                    <View style={styles.rowContainer}>
                      <Text style={styles.sectext}>{edu.course} </Text>
                      <Text
                        style={[
                          styles.xsmtext,
                          {
                            color: "#8f8f8f",
                            fontFamily: "Helvetica",
                            fontSize: "10",
                          },
                        ]}
                      >
                        {edu.startDate} {edu.startDate && "- "}
                        <Text style={styles.xsmtext}>
                          {edu.present === false ? edu.endDate : "Present"}
                        </Text>
                      </Text>
                    </View>
                    <Text style={styles.smtext}>{edu.institution}</Text>

                    <Text style={styles.xsmtext}>
                      {edu.proficiency}&nbsp;
                      <Text style={styles.xsmtext}>
                        {edu.proficiency_type === "G"
                          ? "GPA"
                          : edu.proficiency_type === "P"
                          ? "%"
                          : null}
                      </Text>
                    </Text>
                  </View>
                ))}
              </View>
            ) : null}
          </View>

          {/* referance */}
          <View style={styles.section}>
            {data.reference &&
            data.reference.some(
              (ref) =>
                ref.contact_name ||
                ref.company ||
                ref.country ||
                ref.phone_number || ref.desc ||
                ref.email
            ) ? (
              <View style={styles.sectionwrap}>
                <View style={styles.headingContainer}>
                  <View style={styles.sectionheadingLine} />
                  <Text style={styles.sectionheading}>REFERENCE</Text>
                  <View style={styles.sectionheadingLine} />
                </View>
                {data.reference.map((ref, id) => (
                  <View style={styles.eachsectionwrap} key={id} wrap={false}>
                    <Text style={styles.sectext}>{ref.contact_name}</Text>
                    <Text style={styles.xsmtext}>{ref.desc}</Text>
                    <Text style={styles.xsmtext}>
                      {ref.company},
                      <Text style={styles.xsmtext}>{ref.country}</Text>
                    </Text>
                    <Text>
                      <Text style={styles.smtext}>{ref.email} </Text>
                      <Text style={{ fontWeight: 800, fontSize: 11 }}>
                        &nbsp;|&nbsp;
                      </Text>
                      <Text style={styles.xsmtext}> {ref.phone_number}</Text>
                    </Text>
                  </View>
                ))}
              </View>
            ) : null}
          </View>
          {/* Languages */}
          <View style={styles.section}>
            {data.language && data.language.some((lan) => lan.language) ? (
              <View style={styles.sectionwrap} wrap={false}>
                <View style={styles.headingContainer}>
                  <View style={styles.sectionheadingLine} />
                  <Text style={styles.sectionheading}>LANGUAGES</Text>
                  <View style={styles.sectionheadingLine} />
                </View>
                <Text style={styles.smtext}>
                  {data.language.map((lan) => lan.language).join(",  ")}
                </Text>
              </View>
            ) : null}
          </View>

          <View>
            {data.achievement &&
            data.achievement.some((ach) => ach.achievement) ? (
              <View style={styles.section}>
                <View>
                  <View style={styles.sectionwrap} wrap={false}>
                    <View style={styles.headingContainer}>
                      <View style={styles.sectionheadingLine} />
                      <Text style={styles.sectionheading}>ACHIEVEMENTS</Text>
                      <View style={styles.sectionheadingLine} />
                    </View>
                    <Text style={styles.eachsectionwrap}>
                      <Text style={styles.smtext}>
                        {data.achievement
                          .map((ach) => ach.achievement)
                          .join(",")}
                      </Text>
                    </Text>
                  </View>
                </View>
              </View>
            ) : null}
          </View>

          {data.award && data.award.some((aw) => aw.award) ? (
            <View style={styles.section}>
              <View>
                <View style={styles.sectionwrap} wrap={false}>
                  <View style={styles.headingContainer}>
                    <View style={styles.sectionheadingLine} />
                    <Text style={styles.sectionheading}>AWARDS</Text>
                    <View style={styles.sectionheadingLine} />
                  </View>
                  <Text style={styles.eachsectionwrap}>
                    <Text style={styles.smtext}>
                      {data.award.map((aw) => aw.award).join(",")}
                    </Text>
                  </Text>
                </View>
              </View>
            </View>
          ) : null}

          {data.certification &&
          data.certification.some((cert) => cert.certification) ? (
            <View style={styles.section}>
              <View>
                <View style={styles.sectionwrap} wrap={false}>
                  <View style={styles.headingContainer}>
                    <View style={styles.sectionheadingLine} />
                    <Text style={styles.sectionheading}>CERTIFICATIONS</Text>
                    <View style={styles.sectionheadingLine} />
                  </View>{" "}
                  <Text style={styles.eachsectionwrap}>
                    <Text style={styles.smtext}>
                      {data.certification
                        .map((cert) => cert.certification)
                        .join(",")}
                    </Text>
                  </Text>
                </View>
              </View>
            </View>
          ) : null}

          <View>
            {data.publication &&
            data.publication.some((pub) => pub.publication) ? (
              <View style={styles.section}>
                <View>
                  <View style={styles.sectionwrap} wrap={false}>
                    <View style={styles.headingContainer}>
                      <View style={styles.sectionheadingLine} />
                      <Text style={styles.sectionheading}>PUBLICATIONS</Text>
                      <View style={styles.sectionheadingLine} />
                    </View>{" "}
                    <Text style={styles.eachsectionwrap}>
                      <Text style={styles.smtext}>
                        {data.publication
                          .map((pub) => pub.publication)
                          .join(",")}
                      </Text>
                    </Text>
                  </View>
                </View>
              </View>
            ) : null}
          </View>

          <View>
            {data.volunteer && data.volunteer.some((vol) => vol.volunteer) ? (
              <View style={styles.section}>
                <View>
                  <View style={styles.sectionwrap} wrap={false}>
                    <View style={styles.headingContainer}>
                      <View style={styles.sectionheadingLine} />
                      <Text style={styles.sectionheading}>
                        VOLUNTEER EXPERIENCE
                      </Text>
                      <View style={styles.sectionheadingLine} />
                    </View>
                    <Text style={styles.eachsectionwrap}>
                      <Text style={styles.smtext}>
                        {data.volunteer.map((vol) => vol.volunteer).join(",")}
                      </Text>
                    </Text>
                  </View>
                </View>
              </View>
            ) : null}
          </View>

          <View>
            {data.interests && data.interests.some((int) => int.interest) ? (
              <View style={styles.section}>
                <View>
                  <View style={styles.sectionwrap} wrap={false}>
                    <View style={styles.headingContainer}>
                      <View style={styles.sectionheadingLine} />
                      <Text style={styles.sectionheading}>INTERESTS</Text>
                      <View style={styles.sectionheadingLine} />
                    </View>
                    <Text style={styles.eachsectionwrap}>
                      <Text style={styles.smtext}>
                        {data.interests.map((int) => int.interest).join(",")}
                      </Text>
                    </Text>
                  </View>
                </View>
              </View>
            ) : null}
          </View>

          <View>
            {data.hobby && data.hobby.some((hob) => hob.hobby) ? (
              <View style={styles.section}>
                <View>
                  <View style={styles.sectionwrap} wrap={false}>
                    <View style={styles.headingContainer}>
                      <View style={styles.sectionheadingLine} />
                      <Text style={styles.sectionheading}>HOBBIES</Text>
                      <View style={styles.sectionheadingLine} />
                    </View>{" "}
                    <Text style={styles.eachsectionwrap}>
                      <Text style={styles.smtext}>
                        {data.hobby.map((hob) => hob.hobby).join(",")}
                      </Text>
                    </Text>
                  </View>
                </View>
              </View>
            ) : null}
          </View>
        </Page>
      )}
    </Document>
  );
};

export default Resume9;
