import React, { useState } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import confirm from "../.././../assets/confirmimage.png";
import noresume from "../../../assets/Noresume.svg";
import { useResumeListContext } from "../../../provider/ResumeListContext";
import { Link } from "react-router-dom";

const JobModal = (props) => {
  const { activeResume } = useResumeListContext();

  // console.log("loading_______", props.onLoading);
  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      backdrop="static"
      keyboard={false}
    >
      {activeResume && activeResume.length > 0 ? (
        <>
          <Modal.Header closeButton>
            <Modal.Title>
              <div className="mt-4 px-1 mb-2" style={{ fontSize: "14px" }}>
                <div
                  className="text-center text-white bg-danger  mb-1 rounded mx-1 p-1"
                  style={{ display: "inline-block", fontSize: "12px" }}
                >
                  Active Resume
                </div>
                <span className="mx-1 text-dark fw-semibold">
                  {activeResume.find((resume) => resume.active_res).resumeName
                    .length > 20
                    ? `${activeResume
                        .find((resume) => resume.active_res)
                        .resumeName.slice(0, 20)}..`
                    : activeResume.find((resume) => resume.active_res)
                        .resumeName}
                </span>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-1 text-center">{props.children}</Modal.Body>
          <>
            {props.compo === "apply" ? (
              <Modal.Footer>
                {props.onLoading === true ? (
                  <Button variant="primary" className="w-100  mx-5 fw-semibold">
                    Applying
                    <Spinner size="sm" className="ms-1" />
                  </Button>
                ) : (
                  <>
                    <Button
                      variant="primary"
                      className="w-100  mx-5 fw-semibold"
                      onClick={props.onSubmitModal}
                    >
                      Apply Now
                    </Button>
                    <Button
                      variant="outline-white"
                      className="w-100  text-primary  mx-5 fw-semibold"
                      onClick={props.handleClose}
                    >
                      <u>Not now</u>
                    </Button>
                  </>
                )}
              </Modal.Footer>
            ) : (
              <Modal.Footer>
                {(!props.onLoading &&
                  props.scores &&
                  Object.keys(props.scores).length > 0) ||
                props.onLoading ? (
                  <Button
                    variant="outline-white"
                    className="w-100  text-primary  mx-5 fw-semibold"
                    onClick={props.handleClose}
                  >
                    <u>Back to jobs</u>
                  </Button>
                ) : (
                  <>
                    <Button
                      variant="primary"
                      className="w-100  mx-5 fw-semibold"
                      onClick={props.onSubmitScore}
                    >
                      Check Score
                    </Button>
                    <Button
                      variant="outline-white"
                      className="w-100  text-primary  mx-5 fw-semibold"
                      onClick={props.handleClose}
                    >
                      <u>Not now</u>
                    </Button>
                  </>
                )}
              </Modal.Footer>
            )}
          </>
        </>
      ) : (
        <>
          <Modal.Header closeButton>
            <div className="ps-5">
              <img src={noresume} alt="confirmimage" className="w-100" />
            </div>
          </Modal.Header>
          <Modal.Body className="px-5 text-center">
            <h5 className="fw-bold">No Active Resume Found !</h5>
            <div style={{ fontSize: "14px" }}>
              Please upload a resume that aligns with the selected job, or
              create a new resume to apply or check your score.
            </div>
          </Modal.Body>
          <Modal.Footer className="px-5 pb-5 text-center">
            <Button
              as={Link}
              to="/resumes"
              variant="primary"
              className="w-100   fw-semibold"
            >
              Upload Resume
            </Button>
            {/* <div className="text-center">OR</div> */}
            <Button
              as={Link}
              to="/build/templates"
              variant="outline-primary"
              className="w-100   fw-semibold"
              onClick={props.handleClose}
            >
              Build Resume
            </Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

export default JobModal;
