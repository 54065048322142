import React, { useState, useEffect } from "react";
import IntroSection from "./homeSections/IntroSection";
import ToolsSection from "./homeSections/ToolsSection";
import Footer from "./layouts/Footer";
import { FaArrowUp } from "react-icons/fa6";
import classes from "./Home.module.css";
import CandidateHeader from "../candidate/layouts/CandidateHeader";
import LatestJobs from "./homeSections/LatestJobs";
import Header from "./layouts/Header"
const Home = () => {
  const [showScrollUp, setShowScrollUp] = useState(false);


  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const toolsSection = document.getElementById("tools-section");
      localStorage.setItem("job","X")
      if (toolsSection) {
        const toolsSectionTop = toolsSection.offsetTop;
        if (scrollPosition > toolsSectionTop) {
          setShowScrollUp(true);
        } else {
          setShowScrollUp(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <>
      <Header/>
      <main>
        <IntroSection />
        <LatestJobs/>
        <ToolsSection />
      </main>
      <Footer />
      {showScrollUp && (
        <button className={classes.scrollUp} onClick={scrollToTop}>
          <FaArrowUp className="mt-1" style={{ fontSize: "18px" }} />
        </button>
      )}
    </>
  );
};

export default Home;
