
export let data = {
    address: null,
    dob: null,
    declaration: null,
    email: null,
    gender: null,
    nationality: null,
    firstName: null,
    lastName: null,
    job_title: null,
    summary: null,
    phone: null,
    is_fresher: 0,
    is_uploaded: false,
    social: [{ name: null, link: null }],
    education: [
      {
        course: null,
        startDate: null,
        endDate: null,
        institution: null,
        proficiency: null,
        proficiency_type:null,
        present: false,
      },
    ],
    employement: [
      {
        company: null,
        designation: null,
        startDateWe: null,
        endDateWe: null,
        present: false,
        responsibility: null,
      },
    ],
  
    reference: [
      {
        contact_name: null,
        company: null,
        country: null,
        phone_number: null,
        desc: null,
        email: null,
      },
    ],
    skill: [{ softSkill: null }],
    techskills: [{ techSkill: null }],
    interests: [{ interest: null }],
    hobby: [{ hobby: null }],
    language: [{ language: null }],
    achievement: [{ achievement: null }],
    award: [{ award: null }],
    certification: [{ certification: null }],
    publication: [
      {
        publication: null,
      },
    ],
    volunteer: [
      {
        volunteer: null,
      },
    ],
  };
  
  export const cleardata = {
    address: "",
    dob: "",
    declaration: "",
    email: "",
    gender: "",
    nationality: "",
    firstName: "",
    lastName: "",
    job_title: "",
    summary: "",
    phone: "",
    is_fresher: 0,
    is_uploaded: false,
  
    social: [{ name: "", link: "" }],
  
    education: [
      {
        course: "",
        endDate: "",
        institution: "",
        proficiency: "",
        proficiency_type: "",
        startDate: "",
        present: false,
      },
    ],
  
    employement: [
      {
        company: "",
        designation: "",
        endDateWe: "",
        experience: "",
        present: false,
        responsibility: "",
        startDateWe: "",
      },
    ],
  
    reference: [
      {
        contact_name: "",
        company: "",
        country: "",
        phone_number: "",
        desc: "",
        email: "",
      },
    ],
  
    skill: [{ softSkill: "" }],
  
    techskills: [{ techSkill: "" }],
  
    interests: [{ interest: "" }],
    hobby: [{ hobby: "" }],
    language: [{ language: "" }],
    achievement: [{ achievement: "" }],
  
    award: [{ award: "" }],
    certification: [{ certification: "" }],
    publication: [{ publication: "" }],
    volunteer: [{ volunteer: "" }],
  };
  
  // export let data = {
  //   address: null,
  //   dob: "1990-05-15",
  //   declaration:
  //     "I declare that the information provided is true to the best of my knowledge.",
  //   email: "rintha@gmail.com",
  //   gender: "M",
  //   nationality: "Indian",
  //   firstName: "Unknown",
  //   lastName: "Fox",
  //   job_title: "React JS Developer",
  //   summary:
  //     "<p>Experienced React.js Developer with one year of hands-on expertise in crafting dynamic and responsive web applications. Proficient in creating efficient and maintainable code, implementing UI/UX designs, and collaborating with cross-functional teams. Demonstrated ability to meet project deadlines and adapt to evolving technologies.</p><p><br></p>",
  //   phone: "1234567890",
  //   is_fresher: 0,
  //   is_uploaded: false,
  //   candidateImage: null,
  
  //   social: [
  //     { name: "LinkedIn", link: "https://www.linkedin.com/in/johndoe" },
  //     { name: "GitHub", link: "https://github.com/johndoe" },
  //   ],
  
  //   education: [
     
  //     {
  //       course: "Bachelor of Science in Computer Science",
  //       endDate: "Jan 2014",
  //       institution: "University of Example",
  //       proficiency: 9.0,
  //       proficiency_type: "G",
  //       startDate: "Jan 2010",
  //       present: false,
  //     },
  //     {
  //       course: "Bachelor of Science in Computer Science",
  //       endDate: "Jan 2014",
  //       institution: "University of Example",
  //       proficiency: 9.0,
  //       proficiency_type: "G",
  //       startDate: "Jan 2010",
  //       present: false,
  //     },
  //   ],
  
  //   employement: [
  //     {
  //       company: "Pfactorial Pvt Ltd.",
  //       designation: "React Developer",
  //       endDateWe: "Jan 2017",
  //       present: false,
  //       responsibility:
  //         "<ul><li>Developed and maintained responsive web applications using React.js</li><li>Developed and maintained responsive web applications using React.js</li><li>Developed and maintained responsive web applications using React.js</li></ul><p><br></p>",
  //       startDateWe: "Jan 2016",
  //     },
  //     {
  //       company: "Tech Solutions Ltd.",
  //       designation: "Senior React Developer",
  //       endDateWe: null,
  //       present: true,
  //       responsibility:
  //         "<ul><li>heello allll how are you </li><li>helloooo</li></ul> ",
  //       startDateWe: "Jan 2017",
  //     },
  //   ],
  
  //   reference: [
  //     {
  //       contact_name: "Jane Smith",
  //       company: "ABC Corp",
  //       country: "USA",
  //       phone_number: "9876543210",
  //       desc: "Ceo",
  //       email: "jane.smith@example.com",
  //     },
  //     {
  //       contact_name: "Jane Smith",
  //       company: "ABC Corp",
  //       country: "USA",
  //       phone_number: "9876543210",
  //       desc: "Ceo",
  //       email: "jane.smith@example.com",
  //     },
  //     {
  //       contact_name: "Jane Smith",
  //       company: "ABC Corp",
  //       country: "USA",
  //       phone_number: "9876543210",
  //       desc: "Ceo",
  //       email: "jane.smith@example.com",
  //     },
  //   ],
  
  //   skill: [{ softSkill: "Communication" }, { softSkill: "Communication" }],
  
  //   techskills: [{ techSkill: "ReactJS" }, { techSkill: "JavaScript" }],
  
  //   interests: [
  //     { interest: "Open Source Projects" },
  //     { interest: "Open Source Projects" },
  //     { interest: "Open Source Projects" },
  //   ],
  //   hobby: [{ hobby: "Photography" }],
  //   language: [{ language: "English" }],
  //   achievement: [{ achievement: "Best Employee of the Year, 2020" }],
  //   award: [{ award: "Tech Innovator Award, 2018" }],
  //   certification: [{ certification: "React Developer Certification" }],
  //   publication: [{ publication: "Introduction to React Programming" }],
  //   volunteer: [{ volunteer: "Community Cleanup Drive, 2019" }],
  // };
  