import React from "react";
import { Placeholder, Stack, Row, Col } from "react-bootstrap";
import classes from "../jobsearch/Jobsearch.module.css";

const CompanyProfileLoading = () => {
  return (
    <div className="mb-3">
      <div>
        <Placeholder
          as="div"
          animation="glow"
          style={{ padding: "20px 20px 15px 20px" }}
        >
          <div className=" mt-2 text-secondary d-flex align-items-center justify-content-between">
            <Placeholder
              bg="secondary"
              as="div"
              size="lg"
              sm={8}
              className="mt-2"
            />
            <Placeholder
              className="px-5"
              size="lg"
              bg="primary"
              sm={2}
              style={{ height: "50px", borderRadius: "5px" }}
            />
          </div>

          <Stack direction="horizontal" gap={3} className="mt-2  pb-4">
            <Placeholder bg="secondary" as="div" size="xs" sm={1} />
            <Placeholder bg="secondary" as="div" size="xs" sm={1} />
          </Stack>
          <Row className="mt-5">
            <Col lg={8} md={12} sm={12}>
              <Stack
                direction="horizontal"
                gap={3}
                className="mt-2 border-bottom pb-4"
              >
                <Placeholder
                  bg="secondary"
                  as="div"
                  size="xs"
                  sm={2}
                  style={{ height: "45px", borderRadius: "5px" }}
                />
                <Placeholder
                  bg="secondary"
                  as="div"
                  size="xs"
                  sm={2}
                  style={{ height: "45px", borderRadius: "5px" }}
                />
              </Stack>

              <Placeholder
                bg="secondary"
                as="div"
                size="lg"
                sm={7}
                className=" my-4"
              />
              <Placeholder
                bg="secondary"
                as="div"
                size="xs"
                sm={12}
                className=" mt-3"
              />
              <Placeholder
                bg="secondary"
                as="div"
                size="xs"
                sm={12}
                className=" mt-3"
              />
              <Placeholder
                bg="secondary"
                as="div"
                size="xs"
                sm={8}
                className=" mt-3"
              />
            </Col>
            <Col lg={4} md={12} sm={12} className="ps-2 ps-lg-4 mt-lg-0 mt-2 ">
              <div className={classes.sidebarborder + " py-4"}>
                <Stack
                  direction="horizontal"
                  gap={3}
                  className="mt-2 border-bottom pb-4"
                >
                  <Placeholder
                    bg="secondary"
                    as="div"
                    size="xs"
                    sm={1}
                    style={{
                      height: "80px",
                      width: "80px",
                      borderRadius: "5px",
                    }}
                  />
                  <Stack>
                    <Placeholder
                      bg="secondary"
                      as="div"
                      size="xs"
                      sm={7}
                      className="mb-3 mt-2"
                    />
                    <Placeholder bg="secondary" as="div" size="xs" sm={4} />
                  </Stack>
                </Stack>
                <Stack>
                  <Placeholder
                    bg="secondary"
                    as="div"
                    size="xs"
                    sm={9}
                    className="mt-4 mb-2"
                  />

                  <Placeholder
                    bg="secondary"
                    as="div"
                    size="xs"
                    sm={9}
                    className="my-2 "
                  />
                  <Placeholder bg="secondary" as="div" size="xs" sm={4} />
                  <Placeholder
                    bg="secondary"
                    as="div"
                    size="lg"
                    sm={10}
                    className="mt-4  mb-2"
                  />
                  <Placeholder
                    bg="secondary"
                    as="div"
                    size="lg"
                    sm={10}
                    className="mt-4  mb-2"
                  />
                  <Placeholder
                    bg="secondary"
                    as="div"
                    size="lg"
                    sm={11}
                    className="mt-4  mb-2"
                  />
                </Stack>
              </div>
            </Col>
          </Row>
        </Placeholder>
      </div>
    </div>
  );
};

export default CompanyProfileLoading;
