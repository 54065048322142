import React from "react";
import { Container } from "react-bootstrap";
import CandidateHeader from "../../../candidate/layouts/CandidateHeader";
import Boxlayout from "../Boxlayout";
import Footer from "../Footer";
import ResumeViewButton from "../../../candidate/resumes/ResumeViewButton";

const CandidateBody = (props) => {
  return (
    <div>
      <CandidateHeader />
      <Container>
        {props.children}
        <Boxlayout />
        <ResumeViewButton/>
      </Container>
      <Footer />
    </div>
  );
};

export default CandidateBody;
