import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Row, Col } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import { useResumeListContext } from "../../../provider/ResumeListContext";
import classes from "./ResumeList.module.css";
import jobclass from "../jobsearch/Jobsearch.module.css"
import CandidateBody from "../../home/layouts/common/CandidateBody";
import UploadResume from "./UploadResume";
import AlertModal from "../../../ui/common/AlertModal";

export const steps = [
  {
    key: 1,
    text: "Upload  resume or Build Resume",
    // des: "You can upload up to three resumes",
  },
  {
    key: 2,
    text: "Choose one resume as active",
    // des: "From the uploaded resumes, select one as active before applying for a job",
  },
  {
    key: 3,
    text: "Check the score",
    // des: "Before applying, you can check the resume score for the corresponding job with the active resume",
  },
  {
    key: 4,
    text: "Apply for the job",
    // des: "Select the appropriate resume and apply to the job with the highest score",
  },
];

const ResumeList = () => {
  const { fetchResumes, resumeFiles } = useResumeListContext();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const fetchData = async () => {
    const data = await fetchResumes();
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <CandidateBody>
        <div className="my-5">
     

          <div id="block" className={classes.sectionbox + " my-5"}>
            <Row className="d-flex  px-lg-5">
              <Col
                lg={9}
                sm={12}
                className="text-white fw-semibold mt-3 "
                style={{ fontSize: "18px" }}
              >
                <div>
                  <h5 className="text-white fw-bold ">How It Work</h5>
                  <div
                    className="mb-3 text-info fw-light "
                    style={{ fontSize: "14px" }}
                  >
                    Follow the steps below to apply for jobs:
                  </div>
                  {steps.map((stepItem) => (
                    <Row key={stepItem.key} className={classes.group}>
                      <Col lg={2} className={classes.numberelement}>
                        {stepItem.key}
                      </Col>
                      <Col className="fw-semibold text-white">
                        {stepItem.text}
                       
                      </Col>
                    </Row>
                  ))}
                </div>
              </Col>
              <Col lg={3} sm={12} className="d-flex flex-column text-end">
                <Button
                 as={Link}
                 to="/jobs"
                  className=" d-flex align-items-center ms-auto"
                  style={{ fontWeight: "800" }}
                  variant="primary"
                  size="lg"
                >
                  <FaSearch className="me-2" /> Search Jobs
                </Button>
              </Col>
            </Row>
          </div>

          <UploadResume resumeFiles={resumeFiles} fetchData={fetchData} />
        </div>
      <AlertModal show={show} handleClose={handleClose} />
     
    </CandidateBody>
  );
};

export default ResumeList;
