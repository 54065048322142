import React, { useState, useEffect } from "react";
import { IoIosAddCircle } from "react-icons/io";
import classes from "./ResumeFormData.module.css";
import classes1 from "../../resumeUI/Input.module.css";
import EducationView from "./EducationView";
import CustomButton from "../../resumeUI/CustomButton";
import CustomButtonOutline from "../../resumeUI/CustomButtonOutline";
import { useResumeBuilderContext } from "../../../../provider/ResumeBuilderContext";
import { Button } from "react-bootstrap";

const Education = () => {
  const { handleNext, handlePrevious, resumeData, handleResumeFormChange } =
    useResumeBuilderContext();
  const [education, setEducation] = useState(resumeData.education || []);
  let [defaultKey, setDefaultKey] = useState(resumeData.education.length);

  const addDataEducation = () => {
    const updatedEducation = [
      ...education,
      {
        course: null,
        endDate: null,
        institution: null,
        proficiency: null,
        proficiency_type: null,
        startDate: null,
        present: false,
      },
    ];
    setDefaultKey(updatedEducation.length - 1);

    setEducation(updatedEducation);
  };

  const handleEduChange = (e, index) => {
    const { name, value } = e.target;
    const updatedEducation = [...education];
    // console.log(updatedEducation);

    if (name === "present") {
      updatedEducation[index] = {
        ...updatedEducation[index],
        [name]: e.target.checked,
        endDate:
          e.target.value === "true" ? null : updatedEducation[index].endDate,
      };
    } else {
      updatedEducation[index] = {
        ...updatedEducation[index],
        [name]: value,
      };
    }

    setEducation(updatedEducation);
    handleResumeFormChange({
      target: { name: "education", value: updatedEducation },
    });
  };

  const removeData = (index) => {
    const updatedEducation = [...education];
    updatedEducation.splice(index, 1);
    setEducation(updatedEducation);
    handleResumeFormChange({
      target: { name: "education", value: updatedEducation },
    });
    setDefaultKey(updatedEducation.length - 1);
  };

  return (
    <div className={classes.resumecontentform}>
      <div className={classes.resumeformheading}>Education</div>
      <div className={classes.resumeformsubheading}>
        Review your Education History
      </div>
      <div>
        {education.map((edu, index) => (
          <EducationView
            defaultKey={defaultKey}
            index={index}
            data={edu}
            onChange={(e, index) => handleEduChange(e, index)}
            onDelete={(index) => removeData(index)}
          />
        ))}

        <div className={classes.addicon} onClick={addDataEducation}>
          <IoIosAddCircle className="me-1 fs-5" />
          Add more
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <CustomButtonOutline text="Back" onClickFn={handlePrevious} />
        <CustomButton text="Next" onClickFn={handleNext} />
      </div>
    </div>
  );
};

export default Education;
