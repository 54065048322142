import React from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import { BsFillBuildingsFill, BsPersonVideo2 } from "react-icons/bs";
import { MdOutlineCardMembership } from "react-icons/md";
import classes1 from "./forms.module.css";
import PersnoalInfo from "./PersnoalInfo";
import CompanyInformation from "./CompanyInformation";
import classes from "../../../../candidate/profile/Profile.module.css";
import { useRecruiterProfileContext } from "../../../../../provider/RecruiterProfileContext";
import PlanUpgrade from "./PlanUpgrade";

const TabContainer = () => {
  const { activeTab, handleTabChange, isDataValid } =
    useRecruiterProfileContext();

  return (
    <Tab.Container
      id="left-tabs-example"
      activeKey={activeTab}
      onSelect={handleTabChange}
    >
      <Row>
        <Col sm={3} className="p-4 d-flex flex-column">
          <div>
          <h4 className=" fw-bold px-3 text-start mb-3">Build Your Profile ...</h4>
            <Nav variant="pills" className="flex-column gap-3 mb-5">
              <Nav.Item>
                <Nav.Link eventKey="first" className={classes1.navlink}>
                  <span>
                    <BsPersonVideo2 className="fs-4" />
                  </span>
                  Contact Info
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="second"
                  className={classes1.navlink}
                  disabled={!isDataValid}
                >
                  <span>
                    <BsFillBuildingsFill className="fs-4" />
                  </span>
                  Company Info
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="third"
                  className={classes1.navlink}
                  disabled={!isDataValid}
                >
                  <span>
                    <MdOutlineCardMembership className="fs-4" />
                  </span>
                  Membership
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          <div className="flex-grow-1 d-flex"></div>
          <Col className={classes.jobsearchinfo}>
            <h5 className="text-white fw-semibold mb-3">
              Create Your Recruiter Profile
            </h5>
            <p className="text-white" style={{ fontSize: "14px" }}>
              Reach millions of job seekers. Post job openings and find the
              ideal candidates from our 16.8 million CVs.
            </p>
          </Col>
        </Col>
        {/* <Col sm={1}></Col> */}
        <Col sm={8} className=" bg-white p-5 mt-2 rounded-3 border shadow-sm">
          <Tab.Content>
            <Tab.Pane eventKey="first">
              <PersnoalInfo onNext={() => handleTabChange("second")} />
            </Tab.Pane>
            <Tab.Pane eventKey="second">
              <CompanyInformation onNext={() => handleTabChange("third")} />
            </Tab.Pane>
            <Tab.Pane eventKey="third">
              <PlanUpgrade />
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
};

export default TabContainer;
