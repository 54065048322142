import React from "react";
import { Col, Row, Image } from "react-bootstrap";
import ToolsCard from "../../../ui/ToolsCard";
import { jobToolCardData } from "../../../ui/uidata/ToolCardData";
import classes from "../Home.module.css"

const JobTools = () => {
  const onSelectTool = (tool) => {
    // console.log(tool);
  };
  return (
    <Row className={classes.tools}>
      <Col sm={12} lg={6}>
        {jobToolCardData.map((tools) => (
          <ToolsCard tools={tools} onSelectTool={onSelectTool} />
        ))}
      </Col>
      <Col lg={6} className="d-none d-lg-block">
        <Image />
      </Col>
    </Row>
  );
};

export default JobTools;
