import React from "react";
import { Placeholder, Stack, Row, Col } from "react-bootstrap";
import classes from "./LatestJobs.module.css";

const LatestJobSkelton = () => {
  return (
    <div className="mb-3">
      <div className={classes.jobcard}>
        <Placeholder
          as="div"
          animation="glow"
          style={{ padding: "40px 20px 15px 20px" }}
        >
          <Stack direction="horizontal" gap={3}>
            <Placeholder
              bg="secondary"
              style={{
                borderRadius: "10px",
                height: "60px",
                width: "60px",
              }}
            />
            <Placeholder bg="secondary" as="div" size="lg" sm={8} />
          </Stack>

          <Placeholder
            bg="secondary"
            as="div"
            size="lg"
            sm={12}
            className="ms-1 mb-2 mt-3"
          />

          <Row className="mt-3 d-flex align-items-center">
            <Col lg={6} sm={6}>
              <Placeholder
                bg="secondary"
                as="div"
                size="xs"
                sm={8}
                className="ms-1 my-2"
              />
            </Col>
            <Col lg={6} sm={6}>
              <Placeholder
               style={{
                borderRadius: "5px",
                height: "40px",
              }}
                bg="secondary"
                as="div"
                size="lg"
                sm={10}
                className="ms-1 my-2"
              />
            </Col>
          </Row>
        </Placeholder>
      </div>
    </div>
  );
};

export default LatestJobSkelton;
