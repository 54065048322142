import React, { useState } from "react";
import { useProfileContext } from "../../../provider/ProfileContext";
import { Badge, Row, Col, Button, Spinner } from "react-bootstrap";
import { CiEdit } from "react-icons/ci";
import FormInput from "../../../ui/common/FormInput";
import ProfileModal from "./ProfileModal";
import { RxCross1 } from "react-icons/rx";

const AwardProfile = () => {
  const {
    profileData,
    handlePersonalChange,
    AddProfileData,
    EditProfileData,
    DeleteProfileData,
    deletingData,
  } = useProfileContext();
  const profileid = localStorage.getItem("profile");

  const [awards, setAwards] = useState(profileData.award_prof || []);
  const [awardProfile, setAwardProfile] = useState({
    award: "",
    profile: profileid,
  });
  const [editId, setEditid] = useState(null);
  const [errors, setErrors] = useState({ award: "" });
  const [modalState, setModalState] = useState({ show: false, edit: false });
  const [selectedProfile, setSelectedProfile] = useState(null);

  const handleShowModal = (edit = false, profile = null) => {
    setErrors({ award: "" });
    setModalState({ show: true, edit });
    if (edit) {
      setEditid(profile.id);
      setSelectedProfile(profile);
      setAwardProfile(profile);
    } else {
      setAwardProfile({ award: "", profile: profileid });
    }
  };

  const handleCloseModal = () => {
    setModalState({ show: false, edit: false });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors({ award: "" });
    setAwardProfile((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateForm = (profile) => {
    if (!profile.award) {
      setErrors({ award: "Please fill in the required field." });
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm(awardProfile)) return;
    try {
      const response = modalState.edit
        ? await EditProfileData(awardProfile, "award", selectedProfile.id)
        : await AddProfileData(awardProfile, "award");

      if (response.status === 201 || response.status === 200) {
        const updatedProfile = response.data;
        setAwards((prevawards) => {
          const newawards = modalState.edit
            ? prevawards.map((int) =>
                int.id === updatedProfile.id ? updatedProfile : int
              )
            : [...prevawards, updatedProfile];

          handlePersonalChange({
            target: { name: "awards_prof", value: newawards },
          });
          return newawards;
        });
        handleCloseModal();
      }
    } catch (error) {
      console.error("Error saving award profile:", error);
    }
  };

  const handleDelete = async () => {
    try {
      const response = await DeleteProfileData("award", editId);
      if (response.status === 204) {
        const updated = awards.filter((data) => data.id !== editId);
        setAwards(updated);
        handleCloseModal();
      }
    } catch (error) {
      console.error("Error deleting :", error);
    }
  };

  return (
    <>
      <div className="mb-5 pb-2 ">
        <div className="fw-bold mb-3 text-secondary">
          <div className="d-flex align-items-center">
            <h4 className="fw-bold text-dark">Awards</h4>
            <div
              className="ms-4 border p-1 rounded text-primary fw-semibold"
              style={{ fontSize: "12px", cursor: "pointer" }}
              onClick={() => handleShowModal()}
            >
              + Add more
            </div>
          </div>
          {awards.length > 0 ? (
            <div className="mt-4">
              {awards.map((int) => (
                <Badge
                  key={int.id}
                  bg="info"
                  className="me-3 mb-3 p-3 text-dark"
                >
                  {int.award}
                  <CiEdit
                    style={{ cursor: "pointer" }}
                    className="ms-2 fs-6 fw-bold"
                    onClick={() => handleShowModal(true, int)}
                  />
                </Badge>
              ))}
            </div>
          ) : (
            <div
              className="mt-4 text-danger fw-normal"
              style={{ fontSize: "14px" }}
            >
              * No award added
            </div>
          )}
        </div>
      </div>
      <ProfileModal
        show={modalState.show}
        handleClose={handleCloseModal}
        formtype={modalState.edit ? "edit" : "add"}
        handleSubmitChange={handleSubmit}
      >
        <Row className="d-flex align-items-center">
          <Col>
            <FormInput
              name="award"
              type="text"
              placeholder="Add your award"
              label="award"
              value={awardProfile.award}
              onChange={handleChange}
              className="mb-3"
              required="required"
              error={errors.award}
            />
          </Col>
          {modalState.edit && (
            <Col lg={2}>
              {deletingData ? (
                <Button variant="outline-danger" className=" mt-3 ">
                  Del <Spinner size="sm" className="ms-1" />
                </Button>
              ) : (
                <Button
                  variant="outline-danger"
                  className=" mt-3 "
                  onClick={handleDelete}
                >
                  Del
                  <RxCross1 className="ms-1 fw-bold" />
                </Button>
              )}
            </Col>
          )}
        </Row>
      </ProfileModal>
    </>
  );
};

export default AwardProfile;
