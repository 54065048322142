import React, { useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";
import classes from "./ResumeFormData.module.css";
import Select from "../../resumeUI/Select";
import AccordionField from "../../resumeUI/AccordionField";
import Input from "../../resumeUI/Input";
import MonthPickerDate from "../../resumeUI/MonthPickerDate";

const EducationView = ({ data, onChange, index, onDelete, defaultKey }) => {
  const [errors, setErrors] = useState({
    startDate: "",
    endDate: "",
    proficiency: "",
  });
  const [present, setPresent] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const pro = [
    { value: null, label: "select" },
    { value: "P", label: "%" },
    { value: "G", label: "GPA" },
  ];

  const onChangeHandler = (e) => {
    setPresent(!present);
    handleInputChange(e, index);
  };

  function checkdatevalidation(startdate, enddate) {
    let newErrors = { ...errors };
    const today = new Date();

    if (new Date(startdate) > new Date(enddate)) {
      newErrors = {
        ...newErrors,
        endDate: "End date must be greater than start date.",
      };
    } else if (new Date(startdate) > today || new Date(enddate) > today) {
      newErrors = {
        ...newErrors,
        endDate: "Dates must not exceed today's date.",
      };
    } else {
      newErrors = {
        ...newErrors,
        endDate: "",
      };
    }

    setErrors(newErrors);
  }
  const validateProficiency = (value, proficiencyType) => {
    if (proficiencyType === "P") {
      if (value < 0 || value > 100) {
        return "Enter a valid mark";
      }
    } else if (proficiencyType === "G") {
      if (value < 0 || value > 10) {
        return "Enter a valid mark";
      }
    }
    if (value && !/^\d+(\.\d{1,2})?$/.test(value)) {
      return "not valid";
    }
    return "";
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const today = new Date();

    if (name === "startDate") {
      const endDate = data.endDate;
      const startDate = value;

      if (endDate) {
        checkdatevalidation(startDate, endDate);
      } else if (new Date(startDate) > today) {
        setErrors({
          ...errors,
          startDate: "Start date must not exceed today's date.",
        });
      } else {
        setErrors({ ...errors, startDate: "" });
      }
    } else if (name === "endDate") {
      const startDate = data.startDate;
      const endDate = value;

      if (startDate) {
        checkdatevalidation(startDate, endDate);
      } else if (new Date(endDate) > today) {
        setErrors({
          ...errors,
          endDate: "End date must not exceed today's date.",
        });
      } else {
        setErrors({ ...errors, endDate: "" });
      }
    } else {
      setErrors({ startDate: "", endDate: "" });
    }

    if (name === "proficiency_type" && value === "") {
      setDisabled(true);
    }else{
      setDisabled(false);
    }
    let error = "";

    if (name === "proficiency") {
      error = validateProficiency(value, data.proficiency_type);
      setErrors((prevErrors) => ({
        ...prevErrors,
        proficiency: error,
      }));
    }

    onChange(e, index);
  };

  return (
    <Row>
      <Col lg={11} xs={11}>
        <AccordionField
          defaultKey={defaultKey}
          index={index}
          key={index}
          subheading={
            data.course || data.institution
              ? `${data.course || " "} at ${data.institution || ""}`
              : "not specified"
          }
        >
          <Row>
            <Col lg={12} xs={12}>
              <Input
                name="course"
                value={data.course}
                type="text"
                text="Course"
                font="12px"
                onChange={(e) => handleInputChange(e, index)}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Input
                name="institution"
                value={data.institution}
                type="text"
                text="Your Institution"
                font="12px"
                onChange={(e) => handleInputChange(e, index)}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={4} xs={6}>
              <Input
                name="proficiency"
                value={data.proficiency}
                type="number"
                text="Proficiency"
                font="12px"
                onChange={(e) => handleInputChange(e, index)}
                error={errors.proficiency}
                disabled={disabled}
              />
              <span style={{ fontSize: "10px", color: "#04364a" }}>
                *score (number)
              </span>
            </Col>
            <Col lg={4} xs={6} className="mt-1">
              <Select
                name="proficiency_type"
                options={pro}
                value={data.proficiency_type}
                text="Grade format"
                onChange={(e) => handleInputChange(e, index)}
              />
            </Col>
          </Row>
          <Row className="d-flex align-items-center mt-3">
            <Col lg={4} xs={5}>
              <MonthPickerDate
                type="text"
                text="start date"
                font="12px"
                value={data.startDate}
                placeholder="Start Date"
                name="startDate"
                onChange={(e) => handleInputChange(e, index)}
                error={errors.startDate}
              />
            </Col>
            <Col lg={1} xs={2} className="mt-2">
              <span style={{ fontSize: "12px" }} className="fw-semibold">
                to
              </span>
            </Col>

            {!data.present && (
              <Col lg={4} xs={5}>
                <MonthPickerDate
                  type="text"
                  text="end date"
                  font="12px"
                  value={data.endDate}
                  placeholder="End Date"
                  name="endDate"
                  onChange={(e) => handleInputChange(e, index)}
                  disabled={data.present === true}
                  error={errors.endDate}
                />
              </Col>
            )}
            <Col lg={3} xs={3} style={{ fontSize: "14px" }}>
              <Form.Check
                className="mt-3 ms-2"
                checked={data.present}
                value={!present}
                inline
                label="Present"
                name="present"
                type="checkbox"
                id={`present-${index}`}
                onChange={onChangeHandler}
              />
            </Col>
            <span
              style={{ fontSize: "12px" }}
              className="text-danger fw-semibold"
            >
              {errors.endDate}
            </span>
          </Row>
        </AccordionField>
      </Col>
      <Col lg={1} xs={1}>
        <div className={classes.trash + " mt-4"}>
          <FaTrashAlt onClick={() => onDelete(index)} />
        </div>
      </Col>
    </Row>
  );
};

export default EducationView;
