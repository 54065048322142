import React from "react";
import classes from "./Jobsearch.module.css";
import { Link, useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import { Row, Col, Badge } from "react-bootstrap";
import { SlHandbag } from "react-icons/sl";
import { IoIosTimer } from "react-icons/io";
import { LuIndianRupee } from "react-icons/lu";
import { IoLocationOutline } from "react-icons/io5";
import {
  getLabelByValue,
  formatTimeDifference,
  formatDateDifference,timeAgo
} from "../../../utils/jobutils";
import { toCamelCase } from "../../../utils/Validation";
import { jobtype, salary } from "../../../data/JobData";
import logo from "../../../assets/company.png";
import { useJobContext } from "../../../provider/JobContext";

const JobCard = ({ job, section }) => {
  const { fetchSingleJob, selectedJob, fetchSingleExJob } = useJobContext();
  const user = localStorage.getItem("userData");

  const navigate = useNavigate();

  let trimmedDescription = "";

  if (job.det_desc) {
    trimmedDescription =
      job.det_desc.replace(/\n/g, "").trim().slice(0, 90) +
      (job.det_desc.length > 90 ? "..." : "");
  }

  // const isKnownSalary = (value) => salary.some((item) => item.value === value);

  return (
    <>
      {job && (
        <div className={classes.jobcard}>
          <div className={classes.cardimggrid}>
            {job.is_applied && (
              <span
                className={classes.flash}
                title="applied"
                style={{ cursor: "pointer" }}
              ></span>
            )}
            <div className={classes.imagebox}>
              <img
                onError={(e) => {
                  e.target.src = logo;
                }}
                src={job.company_logo || logo}
                alt="company logos"
              />
            </div>
            <div className={classes.rightinfo}>
              <a href={job.company_link} target="_blank" rel="noopener">
                {job.company_name}
              </a>
              <span className="fw-normal">
                <IoLocationOutline
                  className="me-1"
                  style={{ marginBottom: "2px", fontSize: "13px" }}
                />
                {job.job_location}
              </span>
            </div>
          </div>
          <div className={classes.cardimggriddet}>
            {section === "latestjobs" ? (
              <h6 className="text-dark" style={{ cursor: "pointer" }}>
                {toCamelCase(job.job_title)}
              </h6>
            ) : (
              <>
                {selectedJob === "E" ? (
                  <Link to={`/job/${job.id}`}>
                    <h6 className="text-dark" style={{ cursor: "pointer" }}>
                      {toCamelCase(job.job_title)}
                    </h6>
                  </Link>
                ) : (
                  <Link to={`/exjob/${job.id}`}>
                    <h6 className="text-dark" style={{ cursor: "pointer" }}>
                      {toCamelCase(
                        job.job_title &&
                          job.job_title.trim().slice(0, 35) +
                            (job.job_title.length > 35 ? ".." : "")
                      )}
                    </h6>
                  </Link>
                )}
              </>
            )}

            <div className={classes.jobicons + " mt-2"}>
              <span className="me-3">
                <SlHandbag className="me-1" style={{ marginBottom: "4px" }} />
                {getLabelByValue(job.job_type, jobtype)}
              </span>
            {job.job_posted_date && (
                <span>
                  <IoIosTimer
                    className="me-1 fw-light"
                    style={{ marginBottom: "4px", fontSize: "14px" }}
                  />
                  {formatDateDifference(job.job_posted_date)}
                </span>
              )}
              {job.posted_time && (
                <span>
                  <IoIosTimer
                    className="me-1 fw-light"
                    style={{ marginBottom: "4px", fontSize: "14px" }}
                  />
                  {job.posted_time }
                </span>
              )}
              {job.created_at && (
                <span>
                  <IoIosTimer
                    className="me-1 fw-light"
                    style={{ marginBottom: "4px", fontSize: "14px" }}
                  />
                  {timeAgo(job.created_at)}
                </span>
              )}


            </div>

            {trimmedDescription === "" ? (
              <div className={classes.description + " pt-3 pb-2"}>
                {job.job_description &&
                  job.job_description.trim().slice(0, 60) +
                    (job.job_description.length > 60 ? "..." : "")}
              </div>
            ) : (
              <div className={classes.description + " pt-3 pb-2"}>
                {parse(trimmedDescription)}
              </div>
            )}

            {/* <div className={classes.skillreq}>
              {job.skills_req &&
                job.skills_req?.slice(0, 3).map(
                  (skill, index) =>
                    skill.trim() !== "" && (
                      <Badge
                        key={index}
                        className=" me-2 py-2 mb-2 bg-info fw-normal text-secondary"
                      >
                        {skill.trim()}
                      </Badge>
                    )
                )}
              {job.skills_req && job.skills_req.length > 3 && (
                <span
                  className=" text-secondary ps-1"
                  style={{ fontSize: "12px" }}
                >
                  more...
                </span>
              )}
            </div> */}
            <div className="mt-4">
              <Row className="d-flex align-items-center">
                <Col lg={6} sm={6}>
                  <span className={classes.salprice}>
                    {job.salary && job.salary !== "nan" && (
                      <LuIndianRupee
                        style={{ marginBottom: "3px", fontSize: "16px" }}
                      />
                    )}

                    {getLabelByValue(job.salary, salary)}
                  </span>
                  <span className={classes.salprice}>
                    {job.salary_ && job.salary_ !== "" && (
                      <LuIndianRupee
                        style={{ marginBottom: "3px", fontSize: "16px" }}
                      />
                    )}

                    {job.salary_ &&
                      job.salary_ !== "" &&
                      job.salary_.trim().slice(0, 1) +
                        (job.salary_.length > 2 ? " LPA" : "")}
                  </span>
                </Col>
                <Col lg={6} sm={6} className="text-center">
                  {section === "latestjobs" ? (
                    <a
                      onClick={() => {
                        // if (user !== "null") {
                          navigate(`/exjob/${job.id}`);
                          fetchSingleExJob(job.id,"free");
                        // } else {
                        //   navigate("/signin");
                        // }
                      }}
                    >
                      <div className={classes.applybtncard}>View Details</div>
                    </a>
                  ) : (
                    <>
                      {selectedJob === "E" ? (
                        <div
                          className={classes.applybtncard}
                          onClick={() => {
                            if (user !== "null") {
                              navigate(`/job/${job.id}`);
                              fetchSingleJob(job.id);
                            } else {
                              navigate("/signin");
                            }
                          }}
                        >
                          View Details
                        </div>
                      ) : (
                        <div
                          className={classes.applybtncard}
                          onClick={() => {
                            if (user !== "null") {
                              navigate(`/exjob/${job.id}`);
                              fetchSingleExJob(job.id);
                            } else {
                              navigate("/signin");
                            }
                          }}
                        >
                          View Details
                        </div>
                      )}
                    </>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default JobCard;
