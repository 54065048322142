import React from "react";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import classes from "../Home.module.css";
import hiring from "../../../assets/hiring.png";
import { useNavigate } from "react-router-dom";

const EmpSectionBox = () => {
  const navigate = useNavigate();

  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");

  const redirectToHirer = () => {
    if (token) {
      navigate("/company/profile");
    } else {
      localStorage.setItem("role", "H");
      navigate("/signin");
    }
  };



  

  return (
    <section className="my-3">
      <Container>
        <div className={classes.empsectionbox}>
          <Row className="align-items-center">
            <Col lg={8}>
              <div className="mx-5">
                <h2 className="text-white" style={{ fontWeight: "800" }}>
                  Recruiting?
                </h2>
                <p
                  className="text-white"
                  style={{
                    fontSize: "14px",
                    textAlign: "justify",
                    maxWidth: "550px",
                  }}
                >
                  Advertise your jobs to millions of monthly users and search
                  15.8 million CVs in our databasAdvertise your jobs to millions
                  of monthly users and search 15.8 million CVs in our database.
                </p>
                <Button onClick={redirectToHirer}>Start Recruiting Now</Button>
              </div>
            </Col>
            <Col lg={4}>
              <Image width={300} src={hiring} alt="hiring" />
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default EmpSectionBox;
