import React from "react";
import { Col, Row } from "react-bootstrap";

const AccordionHeader = () => {
  return (
    <Row className=" text-center mb-2 d-none d-lg-flex">
      <Col lg={3} sm={12}></Col>{" "}
      <Col lg={1} sm={12} style={{ fontSize: "12px" }} className=" text-center">
        <small className=" me-3">Resume</small>
      </Col>{" "}
      {/* <Col lg={2} sm={12} style={{ fontSize: "10px" }}>
        <small>Verified</small>
      </Col>{" "} */}
    
      <Col lg={6} sm={12} style={{ fontSize: "12px" }} className=" ">
        <Row className=" d-flex justify-content-center">
          <Col lg={2}>
            <small>Skill score</small>
          </Col>{" "}
          <Col lg={2}>
            <small>Job Des score</small>
          </Col>
          <Col lg={2}>
            <small>Keyword score</small>
          </Col>
          <Col lg={2}>
            <small>Semantic score</small>
          </Col>
          <Col lg={2}>
            <small>Weighted score</small>
          </Col>
        </Row>
      </Col>
      {/* <Col lg={1} sm={12} style={{ fontSize: "10px" }}>
        <small>Messages</small>{" "}
      </Col> */}
    </Row>
  );
};

export default AccordionHeader;
