import React from "react";
import classes from "./ResumeFormData.module.css";
import { Row, Col } from "react-bootstrap";
import TagsInput from "../../resumeUI/TagsInput";
import { useResumeBuilderContext } from "../../../../provider/ResumeBuilderContext";
import AccordionField from "../../resumeUI/AccordionField";

const VolunteerExperience = () => {
  const { resumeData, handleResumeFormChange } = useResumeBuilderContext();

  const handleSelectedTags = (tags) => {
    const updatedResumeData = {
      ...resumeData,
      volunteer: tags.map((tag) => ({ volunteer: tag })),
    };

    handleResumeFormChange({
      target: {
        name: "volunteer",
        value: updatedResumeData.volunteer,
      },
    });
  };

  const handleTagRemoval = (indexToRemove) => {
    const updatedTags = resumeData.volunteer.filter(
      (_, index) => index !== indexToRemove
    );

    handleResumeFormChange({
      target: {
        name: "volunteer",
        value: updatedTags,
      },
    });
  };
  return (
    <div className="mb-3">
      <AccordionField
        heading="Volunteer Experience"
        subheading="This highlights your ability to balance different aspects of your life."
      >
        <div>
          <Row>
            <Col>
              <TagsInput
                selectedTags={handleSelectedTags}
                handleTagRemoval={handleTagRemoval}
                tags={
                  resumeData.volunteer.some((vol) => vol.volunteer !== null)
                    ? resumeData.volunteer.map((vol) => vol.volunteer)
                    : []
                }
                placeholder="+ Add volunteer experience and press enter"
              />
            </Col>
          </Row>
        </div>
      </AccordionField>
    </div>
  );
};

export default VolunteerExperience;
