import React, { createContext, useContext, useState } from "react";
import Toast from "react-bootstrap/Toast";
import { ToastContainer } from "react-bootstrap";

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastBg, setToastBg] = useState("success");

  const handleCloseToast = () => setShowToast(false);

  const contextValue = {
    showToast,
    setShowToast,
    toastMessage,
    setToastMessage,
    toastBg,
    setToastBg,
    handleCloseToast,
  };

  return (
    <ToastContext.Provider value={contextValue}>
      {children}
      <ToastContainer
        position="bottom-end"
        className="p-5 text-white"
        style={{ zIndex: 1 }}
      >
        <Toast
          bg={toastBg}
          show={showToast}
          onClose={handleCloseToast}
          delay={5000}
          autohide
          animation={true}
          className="text-white"
        >
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </ToastContext.Provider>
  );
};

export const useToast = () => useContext(ToastContext);
