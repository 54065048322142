import React from "react";
import parse from "html-react-parser";
import classes from "../../Screens.module.css";

const AboutUs = ({ company }) => {
  console.log("com______", company);
  return (
    <>
      <div className={classes.aboutus}>
        <h2 className=" fw-bold  mb-4">
          Welcome to {company.company_name} Teams
        </h2>
        {company.company_desc || company.company_description ? (
          <p className="text-secondary">
            {(company.company_desc || company.company_description)
              .split(/\r?\n/)
              .map((line, index) => (
                <div key={index}>{parse(line)}</div>
              ))}
          </p>
        ) : null}
        <div className="border-bottom pt-4"></div>
        {/* <div className=" d-flex flex-column  gap-2">
              <h4 className=" fw-bold  my-3 text-dark">Latest Jobs</h4>
              <JobCard />
            </div> */}
      </div>
    </>
  );
};

export default AboutUs;
