import React from "react";
import classes from "./Common.module.css";
import { FaLightbulb } from "react-icons/fa";


const ScoreSuggestion = ({ suggestionData }) => {
  // console.log(suggestionData);
  const scoreSuggestion =
    suggestionData !== null
      ? [
          suggestionData.expSummary !== null &&
            suggestionData.expSummary !== "" && {
              section: 0,
              name: "Summary",
              display: suggestionData.expSummary,
            },
          suggestionData.missTechSkills !== null &&
            suggestionData.missTechSkills !== "" && {
              section: 1,
              name: "Technical Skills required",
              display: suggestionData.missTechSkills,
            },
          suggestionData.missSoftSkills !== null &&
            suggestionData.missSoftSkills !== "" && {
              section: 2,
              name: "Soft Skills",
              display: suggestionData.missSoftSkills,
            },
          suggestionData.missKeywords !== null &&
            suggestionData.missKeywords !== "" && {
              section: 3,
              name: "Missing Keywords",
              display: suggestionData.missKeywords,
            },
          suggestionData.suggestions !== null &&
            suggestionData.suggestions !== "" && {
              section: 4,
              name: "Suggestions",
              display: suggestionData.suggestions,
            },
        ].filter(Boolean)
      : [];

  return (
    <div className={classes.suggestionbody}>
      <div
        className="fw-semibold mb-3 "
        style={{ textAlign: "justify", fontSize: "14px" }}
      >
       <span className="text-warning pe-2"><FaLightbulb /></span>
         Explore the recommendations below to pinpoint areas for improvement and
        receive tailored suggestions on how to boost your compatibility score
        with your job.
      </div>

      <div className={classes.suggestionsection}>
        {scoreSuggestion.map((item, index) => (
          <div key={index} className={classes.suggestions + " mb-3"}>
            <div
              className=" text-dark fw-semibold p-1 mb-2"
              style={{ fontSize: "16px" }}
            >
              {item.name}
            </div>
            <div>
              <div
                style={{ fontSize: "14px" }}
                className="fw-semibold text-secondary"
              >
                {item.display}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ScoreSuggestion;

