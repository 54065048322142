import React, { useState } from "react";
import { useProfileContext } from "../../../provider/ProfileContext";
import { Row, Col } from "react-bootstrap";
import classes from "./Profile.module.css";
import { LuClipboardEdit } from "react-icons/lu";
import EducationProfileForm from "./profileForms/EducationProfileForm";
import { BiTrash } from "react-icons/bi";
import DeleteProfile from "./DeleteProfile";

const EducationProfile = () => {
  const {
    profileData,
    AddProfileData,
    handlePersonalChange,
    EditProfileData,
    DeleteProfileData,
  } = useProfileContext();
  const profileid = localStorage.getItem("profile");

  const [educationProfile, setEducationProfile] = useState(
    profileData.education_prof || []
  );
  const [education, setEducation] = useState({
    course: null,
    institution: null,
    proficiency: 0,
    proficiency_type: null,
    startDate: null,
    endDate: null,
    present: false,
    profile: profileid,
  });
  const [errors, setErrors] = useState({
    institution: "",
    course: "",
    startDate: "",
    endDate: "",
  });
  const [selectedEducationProfile, setSelectedEducationProfile] =
    useState(null);
  const [showDel, setShowDel] = useState(false);
  const [showEduProfile, setShowEduProfile] = useState(false);
  const handleCloseEduProfile = () => setShowEduProfile(false);
  const [present, setPresent] = useState(false);
  const [editId, setEditid] = useState(null);
  const [showEditEdu, setShowEditEdu] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const handleCloseEditProfile = () => setShowEditEdu(false);
  const handleShowEduProfile = () => {
    setDisabled(true);
    setErrors({
      institution: "",
      course: "",
      startDate: "",
      endDate: "",
      proficiency: "",
    });
    setEducation({
      course: null,
      institution: null,
      proficiency: 0,
      proficiency_type: null,
      startDate: null,
      endDate: null,
      present: false,
      profile: profileid,
    });
    setPresent(false);
    setShowEduProfile(true);
  };

  const handleShowDelEdu = (id) => {
    setEditid(id);
    setShowDel(true);
  };

  const handleCloseDelEdu = () => setShowDel(false);

  const handleShowEditProfile = (id) => {
    setDisabled(true);
    setErrors({
      institution: "",
      course: "",
      startDate: "",
      endDate: "",
      proficiency: "",
    });
    setEditid(id);
    const selectedProfile = educationProfile.find(
      (profile) => profile.id === id
    );
    setSelectedEducationProfile(selectedProfile);
    setShowEditEdu(true);
  };

  const checkDateValidation = (startDate, endDate) => {
    let newErrors = { ...errors };
    const today = new Date();

    if (new Date(startDate) > new Date(endDate)) {
      newErrors = {
        ...newErrors,
        endDate: "End date must be greater than start date.",
      };
    } else if (new Date(startDate) > today || new Date(endDate) > today) {
      newErrors = {
        ...newErrors,
        endDate: "Dates must not exceed today's date.",
      };
    } else {
      newErrors = {
        ...newErrors,
        endDate: "",
        startDate: "",
      };
    }
    setErrors(newErrors);
  };

  const validateProficiency = (value, proficiencyType) => {
    if (proficiencyType === "P") {
      if (value < 0 || value > 100) {
        return "GPA upto value 10";
      }
    } else if (proficiencyType === "G") {
      if (value < 0 || value > 10) {
        return "Enter a valid mark";
      }
    }
    if (value && !/^\d+(\.\d{1,2})?$/.test(value)) {
      return "not valid";
    }
    return "";
  };

  const handleDateChange = (e, profile, setProfile) => {
    const { name, value } = e.target;
    const today = new Date();
    const updatedProfile = { ...profile, [name]: value };

    let error = "";

    if (name === "course" || name === "institution") {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }

    if (name === "startDate") {
      const endDate = updatedProfile.endDate;
      if (endDate) {
        checkDateValidation(value, endDate);
      } else if (new Date(value) > today) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          startDate: "Start date must not exceed today's date.",
        }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, startDate: "" }));
      }
    } else if (name === "endDate") {
      const startDate = updatedProfile.startDate;
      if (startDate) {
        checkDateValidation(startDate, value);
      } else if (new Date(value) > today) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          endDate: "End date must not exceed today's date.",
        }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, endDate: "" }));
      }
    } else if (name === "proficiency") {
      error = validateProficiency(value, profile.proficiency_type);
      console.log("p_______", error);
      setErrors((prevErrors) => ({
        ...prevErrors,
        proficiency: error,
      }));
    }

    if (name === "proficiency_type" && value === "") {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
    setProfile(updatedProfile);
  };

  const handleEduChange = (e) => {
    handleDateChange(e, education, setEducation);
  };

  const onChangeHandler = (e) => {
    setPresent(!present);
    handleEduChange(e);
  };

  const onChangeEditHandler = (e) => {
    const { name, checked } = e.target;
    setSelectedEducationProfile((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const validateProfileData = (profile) => {
    let hasError = false;
    let newErrors = { ...errors };

    if (!profile.institution) {
      newErrors.institution = "Please fill in the required field.";
      hasError = true;
    }

    if (!profile.course) {
      newErrors.course = "Please fill in the required field.";
      hasError = true;
    }

    if (Object.values(newErrors).some((error) => error !== "")) {
      alert("Please fix the errors in the form before submitting.");
      hasError = true;
    }

    setErrors(newErrors);
    return hasError;
  };

  const handleSubmit = async (profile, apiCall, successCallback) => {
    if (validateProfileData(profile)) {
      return;
    }

    let updatedProfile = { ...profile };

    if (profile.present === true) {
      updatedProfile.endDate = null;
      updatedProfile.present = true;
    }

    try {
      const response = await apiCall(updatedProfile);
      if (response.status === 201 || response.status === 200) {
        successCallback(response.data);
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  const addEduData = () => {
    handleSubmit(
      education,
      (data) => AddProfileData(data, "education"),
      (newEdu) => {
        setEducationProfile((prevEdu) => {
          const updatedEdu = [...prevEdu, newEdu];
          handlePersonalChange({
            target: { name: "education_prof", value: updatedEdu },
          });
          return updatedEdu;
        });
        setShowEduProfile(false);
        setEducation({
          course: null,
          institution: null,
          proficiency: 0,
          proficiency_type: null,
          startDate: null,
          endDate: null,
          present: false,
          profile: profileid,
        });
      }
    );
  };

  const handleEditEduChange = (e) => {
    handleDateChange(e, selectedEducationProfile, setSelectedEducationProfile);
  };

  const addEditEduData = () => {
    handleSubmit(
      { ...selectedEducationProfile, profile: profileid },
      (data) => EditProfileData(data, "education", editId),
      (newEdu) => {
        setEducationProfile((prevEdu) => {
          const updatedEdu = prevEdu.map((edu) =>
            edu.id === newEdu.id ? newEdu : edu
          );
          handlePersonalChange({
            target: { name: "education_prof", value: updatedEdu },
          });
          return updatedEdu;
        });
        setShowEditEdu(false);
      }
    );
  };

  const handleDeleteEdu = async () => {
    try {
      const response = await DeleteProfileData("education", editId);
      if (response.status === 204) {
        const updated = educationProfile.filter((data) => data.id !== editId);
        setEducationProfile(updated);
        setShowDel(false);
      }
    } catch (error) {
      console.error("Error deleting :", error);
    }
  };

  return (
    <div>
      <div className="mb-2 pb-2 border-bottom">
        <div className="d-flex align-items-center mb-4 fw-bold ">
          <h4 className=" fw-bold text-dark ">Education</h4>
          <div
            className="ms-4 border p-1 rounded text-primary fw-semibold"
            style={{ fontSize: "12px", cursor: "pointer" }}
            onClick={handleShowEduProfile}
          >
            + Add more
          </div>
        </div>
        {educationProfile && educationProfile.length > 0 ? (
          <div>
            {educationProfile.map((edu) => (
              <Row key={edu.id} className="mb-4">
                <Col lg={1} sm={2} xs={2}>
                  {edu.course && (
                    <div className={classes.eduelement}>
                      {edu.course.charAt(0)}
                    </div>
                  )}
                </Col>
                <Col lg={9} sm={8} xs={8}>
                  <div className="d-flex flex-column flex-sm-row align-items-start align-items-sm-center">
                    <div
                      className="fw-semibold text-dark"
                      style={{ fontSize: "16px" }}
                    >
                      {edu.course}
                    </div>

                    <div
                      className="d-none d-sm-block ms-lg-5 text-danger"
                      style={{ fontSize: "13px" }}
                    >
                      {edu.proficiency}
                      <span className="ms-1">
                        {edu.proficiency_type === "G"
                          ? "GPA"
                          : edu.proficiency_type === "P"
                          ? "%"
                          : null}{" "}
                      </span>
                    </div>

                    <div
                      className="d-sm-none mt-1 text-dark"
                      style={{ fontSize: "12px" }}
                    >
                      {edu.proficiency}
                      <span className="ms-1">
                        {edu.proficiency_type === "G"
                          ? "GPA"
                          : edu.proficiency_type === "P"
                          ? "%"
                          : null}{" "}
                      </span>
                    </div>
                  </div>

                  <div style={{ fontSize: "14px" }} className="text-danger">
                    {edu.institution}
                  </div>
                  {edu.startDate && edu.endDate && (
                    <div
                      className=" mt-1 text-secondary"
                      style={{ fontSize: "13px" }}
                    >
                      {edu.startDate} - {edu.endDate}
                    </div>
                  )}
                </Col>
                <Col
                  lg={1}
                  sm={1}
                  xs={1}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleShowEditProfile(edu.id)}
                >
                  <LuClipboardEdit />
                </Col>
                <Col
                  lg={1}
                  sm={1}
                  xs={1}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleShowDelEdu(edu.id)}
                >
                  <BiTrash className="ms-1 fs-5 fw-bold text-danger" />
                </Col>
              </Row>
            ))}
          </div>
        ) : (
          <div className="my-3 text-danger" style={{ fontSize: "14px" }}>
            * No experience added
          </div>
        )}
      </div>
      <EducationProfileForm
        show={showEduProfile}
        handleClose={handleCloseEduProfile}
        data={educationProfile}
        formtype="add"
        add={addEduData}
        handleChange={handleEduChange}
        onChangeHandler={onChangeHandler}
        errors={errors}
        present={present}
        disabled={disabled}
      />
      {showEditEdu && (
        <EducationProfileForm
          show={showEditEdu}
          handleClose={handleCloseEditProfile}
          data={selectedEducationProfile}
          formtype="edit"
          add={addEditEduData}
          handleChange={handleEditEduChange}
          onChangeHandler={onChangeEditHandler}
          errors={errors}
          disabled={disabled}
        />
      )}
      <DeleteProfile
      show={showDel}
      handleClose={handleCloseDelEdu}
      handleDelete={handleDeleteEdu}
      />
    </div>
  );
};

export default EducationProfile;
