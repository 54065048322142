import React, { useContext } from "react";
import classes from "../../../../candidate/jobsearch/Jobsearch.module.css";
import { Link, useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import { Row, Col, Image, Badge, Button } from "react-bootstrap";
import { IoLocationOutline } from "react-icons/io5";
import { toCamelCase } from "../../../../../utils/Validation";
import logo from "../../../../../assets/Candidateimg.png";
import { RecruiterCandidateListContext } from "../../../../../provider/RecruiterCandidateListContext";
import { useProfileContext } from "../../../../../provider/ProfileContext";

const CandidatesCard = ({ candidate }) => {
  const navigate = useNavigate();
  const { fetchSingleCandidate, fetchProfile } = useContext(
    RecruiterCandidateListContext
  );

  let trimmedDescription = "";

  if (candidate.profsummary && candidate) {
    trimmedDescription =
      candidate.profsummary.replace(/\n/g, "").trim().slice(0, 120) +
      (candidate.profsummary.length > 120 ? "..." : "");
  }

  return (
    <div className={classes.jobcard}>
      <Row className={classes.cardimggrid + " align-items-center "}>
        <Col className={classes.rightinfo + " ps-3"}>
          <div target="_blank" rel="noopener">
            {candidate.proffirstName && candidate.proflastName && (
              <h5>
                {candidate.proffirstName} {candidate.proflastName}
              </h5>
            )}
            {candidate.name && <h5>{candidate.name} </h5>}
          </div>
          {candidate.profaddress !== "" && (
            <span className="fw-normal">
              <IoLocationOutline
                style={{ marginBottom: "2px", fontSize: "13px" }}
              />
              {candidate.profaddress}
            </span>
          )}
        </Col>
      </Row>
      <div className={classes.cardimggriddet}>
        {candidate.profjobTitle && (
          <h6 className="text-dark">{toCamelCase(candidate.profjobTitle)}</h6>
        )}
        {candidate.title && (
          <h6 className="text-dark">{toCamelCase(candidate.title)}</h6>
        )}

        <div className={classes.description + " pt-1 pb-3"}>
          {parse(trimmedDescription)}
        </div>

        <div>
          {candidate.skills && (
            <>
              {/* Soft Skills */}
              {candidate.skills.soft_skills &&
                candidate.skills.soft_skills.slice(0, 2).map((skill, index) =>
                  skill.trim() !== "" ? (
                    <Badge
                      key={index}
                      style={{ fontSize: "12px" }}
                      className="px-3 py-2 fw-normal bg-info text-dark me-2 my-2 rounded-1"
                    >
                      {skill.trim().length > 15
                        ? skill.trim().substring(0, 15) + "..."
                        : skill.trim()}
                    </Badge>
                  ) : null
                )}

              {/* Technical Skills */}
              {candidate.skills.technical_skills &&
                candidate.skills.technical_skills
                  .slice(0, 2)
                  .map((skill, index) =>
                    skill.trim() !== "" ? (
                      <Badge
                        key={index}
                        style={{ fontSize: "12px" }}
                        className="px-3 py-2 fw-normal bg-info text-dark me-2 my-2 rounded-1"
                      >
                        {skill.trim().length > 15
                          ? skill.trim().substring(0, 15) + "..."
                          : skill.trim()}
                      </Badge>
                    ) : null
                  )}
            </>
          )}
        </div>

        <div className="mt-4">
          <Row className="d-flex align-items-center border-top pt-3">
            <Col lg={6} sm={6}>
              {/* <span
                className="text-light fw-semibold"
                style={{ fontSize: "17px" }}
              >
                <IoLocationOutline
                  className="me-1"
                  style={{ marginBottom: "2px", fontSize: "17px" }}
                />
                {job.job_location}
              </span> */}
            </Col>
            <Col lg={6} sm={6} className="text-center">
              {candidate.type === "internal" ? (
                <Button
                  size="sm"
                  className="p-2"
                  onClick={() => {
                    navigate(`/viewcandidate/${candidate.id}`, {
                      state: { candidateType: "internal" },
                    });
                    fetchProfile(candidate.id);
                  }}
                >
                  View Profile
                </Button>
              ) : (
                <Button
                  size="sm"
                  className="p-2"
                  onClick={() => {
                    navigate(`/viewcandidate/${candidate.id}`, {
                      state: { candidateType: "external" },
                    });
                    fetchSingleCandidate(candidate.id);
                  }}
                >
                  View Profile
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default CandidatesCard;
