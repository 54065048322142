import React, { useState } from "react";
import { IoIosAddCircle } from "react-icons/io";
import classes from "./ResumeFormData.module.css";
import classes1 from "../../resumeUI/Input.module.css";
import CustomButton from "../../resumeUI/CustomButton";
import CustomButtonOutline from "../../resumeUI/CustomButtonOutline";
import { useResumeBuilderContext } from "../../../../provider/ResumeBuilderContext";
import ReferenceView from "./ReferenceView";

const References = () => {
  const { handleNext, handlePrevious, resumeData, handleResumeFormChange } =
    useResumeBuilderContext();
  const [reference, setReference] = useState(resumeData.reference || []);
  let [defaultKey, setDefaultKey] = useState(resumeData.reference.length);

  const addDataReference = () => {
    const updatedReference = [
      ...reference,
      {
        contact_name: null,
        company: null,
        country: null,
        phone_number: null,
        email: null,
      },
    ];
    setDefaultKey(updatedReference.length - 1);
    setReference(updatedReference);
  };

  const handleRefChange = (e, index) => {
    const { name, value } = e.target;
    const updatedReference = [...reference];
    updatedReference[index] = {
      ...updatedReference[index],
      [name]: value,
    };
    setReference(updatedReference);
    handleResumeFormChange({
      target: { name: "reference", value: updatedReference },
    });
  };

  const removeData = (index) => {
    const updatedReference = [...reference];
    updatedReference.splice(index, 1);
    setReference(updatedReference);
    handleResumeFormChange({
      target: { name: "reference", value: updatedReference },
    });
    setDefaultKey(updatedReference.length - 1);
  };
  return (
    <div>
      <div className={classes.resumecontentform}>
        <div className={classes.resumeformheading}>Reference</div>
        <div className={classes.resumeformsubheading}>
          Review your reference History
        </div>

        <div>
          {reference.map((ref, index) => (
            <ReferenceView
              defaultKey={defaultKey}
              index={index}
              data={ref}
              onChange={(e, index) => handleRefChange(e, index)}
              onDelete={(index) => removeData(index)}
            />
          ))}
          <div className={classes.addicon + " mt-3"} onClick={addDataReference}>
            <IoIosAddCircle style={{ fontSize: "20px", cursor: "pointer" }} />
            <div className={classes1.addformtext}>Add</div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between ">
        <CustomButtonOutline text="Back" onClickFn={handlePrevious} />
        <CustomButton text="Next" onClickFn={handleNext} />
      </div>
      </div>
     
    </div>
  );
};

export default References;
