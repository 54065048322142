import React, { useState } from "react";
import axios from "axios";
import ScoreFooter from "./ScoreFooter";
import { Row, Col, Spinner, Modal, Button, Form } from "react-bootstrap";
import { useResumeBuilderContext } from "../../../provider/ResumeBuilderContext";
import { validateUrl } from "../../../utils/Validation";
import { url } from "../../../data/Constants";
import { useAuthContext } from "../../../provider/AuthContext";
import ResumeSuggestion from "./ResumeSuggestion";
import classes1 from "../resumeUI/ScoreCalculator.module.css";
import ResumeScoreLoading from "./ResumeScoreLoading";
import ResumeScoreForm from "./ResumeScoreForm";

const ScoreResume = () => {
  const { headers } = useAuthContext();
  const {
    setShowScore,
    saveResumeApi,
    downloaded,
    setDownloaded,
    selectSection,
    resumeData,
    handlePrevious,
  } = useResumeBuilderContext();

  const resumeId = localStorage.getItem("resumeId");
  const [disabled, setDisabled] = useState(false);
  const [inpdisabled, setinpDisabled] = useState(false);
  const [urlError, setUrlError] = useState(null);
  const [score, setScore] = useState(null);
  const [scoreError, setScoreError] = useState("");
  const handleClose = () => setShowScore(false);
  const [scoreLoading, setScoreLoading] = useState(false);
  const [showScoreType, setShowScoreType] = useState("");
  const [suggestion, setSuggestion] = useState(null);
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState("LINK");
  const [showSuggestion, setShowSuggestion] = useState(false);
  const handleCloseSuggestion = () => setShowSuggestion(false);
  const handleCloseError = () => setShow(false);
  const handleShow = () => setShow(true);
  const [scoreData, setScoreData] = useState({
    resume: resumeId,
    link: null,
    scoreType: selected,
    jobDesc: null,
    jobListing:"SC"
  });

  const handleScoreChange = (e) => {
    e.preventDefault();
    if (e && e.target) {
      const { name, value } = e.target;

      let isValid = true;
      let errorMessage = "";

      switch (name) {
        case "link":
          isValid = validateUrl(value);
          errorMessage = isValid
            ? ""
            : "Oops! Something seems to be wrong with the link. Please check that you copied it correctly or choose another page to link";
          break;
        case "scoreType":
          setSelected(value);
          break;
        default:
          break;
      }

      if (!isValid) {
        setUrlError(errorMessage);
      }

      if (name !== "scoreType") {
        setScoreData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      }

      if (isValid || value === "") {
        setUrlError("");
      }
    }
  };

  
  const onCheckScoreProceed = async () => {
    setScoreLoading(true);

    if (!downloaded) {
      try {
        const response = await saveResumeApi();

        if (response && response.status === 201) {
          localStorage.setItem("resumeId", response.data.id);
          setDownloaded(true);
        } else {
          setScoreLoading(false);
          return;
        }
      } catch (error) {
        console.error("Error in saveResumeApi:", error);
        setScoreLoading(false);
        return;
      }
    }
    setDisabled(true);
    setinpDisabled(true);

    try {
      const response = await axios.post(`${url}user/score`, scoreData, {
        headers,
      });

      if (response.data) {
        setScore(response.data.score);
        setSuggestion(response.data);
        setShowSuggestion(true);
        setScoreLoading(false);
        setShowScoreType("score");
        setDisabled(true);
        setinpDisabled(true);
      }
    } catch (err) {
      console.log(err);
      setScoreLoading(false);
      // setShowScoreType("score");
      setShowScoreType("error");

      if (err.response && err.response.status === 400 && err.response.data) {
        setSuggestion(err.response.data);
        setShowSuggestion(true);
        if (err.response.data.error === "Please Try again later") {
          setScoreError(" Oops! Something went wrong. Please try again.");
          setShowScoreType("error");
        } else {
          setScore(err.response.data.score);
          setShowScoreType("score");
          setShowSuggestion(true);
        }
      }

      setDisabled(false);
      setinpDisabled(true);
      setShowSuggestion(false);
    }
  };

  const redirectToScore = () => {
    selectSection(6);
  };

  const onCheckScore = () => {
    if (
      resumeData.techskills &&
      resumeData.techskills.some((skl) => skl.techSkill) &&
      resumeData.skill &&
      resumeData.skill.some((skl) => skl.softSkill)
    ) {
      onCheckScoreProceed();
    } else {
      handleShow();
    }
  };

  const onCheckScoreAgain = () => {
    setScoreLoading(false);
    setDisabled(false);
    setinpDisabled(false);
    setShowScoreType("");
    setShowSuggestion(false);
    // setScoreData({
    //   resume: resumeId,
    //   link: null,
    //   scoreType: "LINK",
    //   jobDesc: null,
    // });
  };

  // console.log("score___________", scoreData);
  
  return (
    <div className={classes1.resumescore + " px-3 pt-3"}>
      <Row>
        <Button className="my-2 d-block d-md-none" onClick={handlePrevious}>
          Back to Resume builder
        </Button>
        <Col lg={8} md={6} className="pe-5 mb-5">
          <h3 className="fw-bold mb-3" style={{ lineHeight: "2rem" }}>
            Get Insights into your
            <span className="text-primary"> Resume's Compatibility </span>
            <div>with Desired Job!</div>
          </h3>
          <div
            className="text-secondary"
            style={{ fontSize: "14px", textAlign: "justify" }}
          >
            Simply input the URL or description of your desired job, and
            instantly receive a compatibility score matching your resume. Gain
            valuable insights and tailored suggestions to enhance your resume,
            ensuring it aligns perfectly with the job requirements.
          </div>
          <ResumeScoreForm
            selected={selected}
            inpdisabled={inpdisabled}
            handleScoreChange={handleScoreChange}
            urlError={urlError}
            scoreData={scoreData}
            scoreLoading={scoreLoading}
            showScoreType={showScoreType}
            onCheckScoreAgain={onCheckScoreAgain}
            onCheckScore={onCheckScore}
            setSelected={setSelected}
            setScoreData={setScoreData}
            showSuggestion={showSuggestion}
          />
          <ScoreFooter />
        </Col>
        <Col lg={4} md={6}>
          <div
            className={` ${showSuggestion ? `${classes1.scoreprogress}` : ""}`}
          >
            <ResumeScoreLoading
              showScoreType={showScoreType}
              scoreLoading={scoreLoading}
              score={score}
              scoreError={scoreError}
            />
            {showSuggestion && <ResumeSuggestion suggestionData={suggestion} />}
          </div>
        </Col>
      </Row>

      {/* modal to show alert to fill skills */}
      <Modal show={show} onHide={handleCloseError}>
        <Modal.Body className="m-3 fw-semibold" style={{ fontSize: "15px" }}>
          To calculate your score, it's essential to populate the skills section
          with both technical and soft skills. Kindly ensure that you provide
          information in both categories.
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button size="sm" variant="primary" onClick={redirectToScore}>
            Add skills
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ScoreResume;
