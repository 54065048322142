import React from "react";
import Select from "react-select";

const CustomSelect = (props) => {
  return (
    <Select
      options={props.options}
    //   defaultValue={props.options[0]}
      value={props.selectedvalue}
      onChange={props.handleChange}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          fontSize: props.fontSize,
          textAlign: "left",
          borderColor: "transparent",
          borderBottom: state.isFocused && "2px solid white",
          boxShadow: state.isFocused && "none",
          "&:hover": {
            borderBottom: "2px solid transparent",
          },
        }),
        singleValue: (baseStyles) => ({
          ...baseStyles,
          color: "gray", 
        }),
        option: (baseStyles) => ({
          ...baseStyles,
          fontSize: props.fontSize,
          textAlign: "left",
        }),
      }}
      isSearchable={false}
    />
  );
}

export default CustomSelect;
