import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import classes1 from "./Input.module.css";
import classes from "./MonthYearPicker.module.css";

const months = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};

const MonthPickerDate = (props) => {
  const [year, setYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState("");
  const [showDateFormat, setShowDateFormat] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [disabled, setDisabled] = useState(true);
  const inputRef = useRef(null);
  const currentYear = new Date().getFullYear();

  const handleMonthClick = (name) => {
    setSelectedMonth(name);
    setShowDateFormat(false);
  };

  const handleDecrementDate = () => {
    setYear((prevYear) => prevYear - 1);
    setDisabled(false);
  };

  const handleIncrementDate = () => {
    if (year < currentYear) {
      setDisabled(false);
      setYear((prevYear) => prevYear + 1);
    } else {
      setDisabled(true);
      // setYear();
    }
  };
  const handleClickOutside = (e) => {
    const dateFormatElement = document.getElementById("dateformat");

    if (
      inputRef.current &&
      !inputRef.current.contains(e.target) &&
      dateFormatElement &&
      !dateFormatElement.contains(e.target)
    ) {
      setShowDateFormat(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // const onchangeDate = (e) => {
  //   const inputDate = document.getElementById("dateform");
  //   const value = e.target.value;
  //   if (value === "") {
  //     inputDate.value("");
  //   } else {
  //     setInputValue(value);
  //     const [inputMonth, inputYear] = value.split(" ");
  //   }
  // };

  return (
    <div >
      <label className={classes1.inputlabel}>{props.placeholder}</label>
      <div
        style={{ cursor: "pointer" }}
        id="dateform"
        type={props.type}
        name={props.name}
        className={`${props.error ? classes1.inputError : classes1.input}`}
        placeholder={props.placeholder}
        onClick={() => setShowDateFormat(true)}
        ref={inputRef}
        disabled={true}
      >
        {selectedMonth ? `${selectedMonth} ${year}` : props.value}
      </div>
      {props.error ? "" : <span className={classes1.inputborder}></span>}

      {/* <div>
        {props.error && (
          <div className={classes1.errorMessage}>{props.error}</div>
        )}
      </div> */}
      {showDateFormat && (
        <div id="dateformat" style={{ position: "relative" }}>
          <div className={classes.datewrap}>
            <div>
              <div className={classes.yeardate}>
                <div className={classes.radio}>
                  <label htmlFor="before" className={classes.dateicon}>
                    <BsChevronLeft />
                  </label>
                  <input
                    id="before"
                    type="radio"
                    className={classes.dateicon}
                    name={props.name}
                    placeholder={props.placeholder}
                    value={
                      selectedMonth !== ""
                        ? `${selectedMonth} ${parseInt(year) - 1}`
                        : parseInt(year) - 1
                    }
                    onClick={(e) => {
                      handleDecrementDate(e);
                      props.onChange(e);
                    }}
                  />
                </div>
                <div className={classes.year}>{year}</div>
                <div className={classes.radio}>
                  <label htmlFor="after" className={classes.dateicon}>
                    <BsChevronRight />
                  </label>
                  <input
                    id="after"
                    type="radio"
                    name={props.name}
                    value={
                      selectedMonth !== ""
                        ? `${selectedMonth} ${parseInt(year) + 1}`
                        : parseInt(year) + 1
                    }
                    onClick={(e) => {
                      handleIncrementDate(e);
                      props.onChange(e);
                    }}
                    disabled={disabled}
                    autoComplete="false"
                  />
                </div>
              </div>
              <Row className="mx-1 mx-lg-0">
                {Object.entries(months).map(([number, name]) => (
                  <Col lg={3} xs={3} key={number}>
                    <div className={classes.radio}>
                      <input
                        label={name}
                        name={props.name}
                        type="radio"
                        data-number={number}
                        value={`${name} ${year}`}
                        className={`${classes.month} ${
                          selectedMonth && selectedMonth === name
                            ? classes.activeMonth
                            : ""
                        }`}
                        onClick={(e) => {
                          handleMonthClick(name);
                          props.onChange(e);
                        }}
                        autoComplete="false"
                      />
                    </div>
                    {/* {name} */}
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MonthPickerDate;
