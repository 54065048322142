import React, { useState } from "react";
import { useProfileContext } from "../../../provider/ProfileContext";
import WorkProfileForm from "./profileForms/WorkProfileForm";
import { Row, Col } from "react-bootstrap";
import classes from "./Profile.module.css";
import { LuClipboardEdit } from "react-icons/lu";
import parse from "html-react-parser";
import { BiTrash } from "react-icons/bi";
import DeleteProfile from "./DeleteProfile";


const WorkProfile = () => {
  const { profileData, AddProfileData, handlePersonalChange, EditProfileData,    DeleteProfileData, } =
    useProfileContext();
  const profileid = localStorage.getItem("profile");

  const [employmentProfile, setEmploymentProfile] = useState(
    profileData.employement_prof || []
  );
  
  const [employe, setEmploye] = useState({
    company: null,
    designation: null,
    startDateWe: null,
    endDateWe: null,
    present: false,
    responsibility: null,
    profile: profileid,
  });
  const [errors, setErrors] = useState({
    company: "",
    designation: "",
    startDateWe: "",
    endDateWe: "",
  });
  const [selectedEmpProfile, setSelectedEmpProfile] = useState(null);
  const [showWorkProfile, setShowWorkProfile] = useState(false);
  const [showDel, setShowDel] = useState(false);
  const handleCloseWorkProfile = () => setShowWorkProfile(false);
  const [editId, setEditid] = useState(null);
  const [showEditEmp, setShowEditEmp] = useState(false);
  const handleCloseEditProfile = () => setShowEditEmp(false);
  const [present, setPresent] = useState(false);
  const handleShowWorkProfile = () => {
    setErrors({
      name: "",
      company: "",
      designation: "",
    });
    setEmploye({
      company: null,
      designation: null,
      startDateWe: null,
      endDateWe: null,
      present: false,
      responsibility: null,
      profile: profileid,
    });
    setPresent(false);
    setShowWorkProfile(true);
  };

  const handleShowEditProfile = (id) => {
    setErrors({
      name: "",
      company: "",
      designation: "",
    });
    setEditid(id);
    const selectedProfile = employmentProfile.find(
      (profile) => profile.id === id
    );
    setSelectedEmpProfile(selectedProfile);
    setShowEditEmp(true);
  };

  const handleShowDelExp = (id) => {
    setEditid(id);
    setShowDel(true);
  };

  const handleCloseDelExp = () => setShowDel(false);

  const checkDateValidation = (startDate, endDate) => {
    let newErrors = { ...errors };
    const today = new Date();

    if (new Date(startDate) > new Date(endDate)) {
      newErrors = {
        ...newErrors,
        endDateWe: "End date must be greater than start date.",
      };
    } else if (new Date(startDate) > today || new Date(endDate) > today) {
      newErrors = {
        ...newErrors,
        endDateWe: "Dates must not exceed today's date.",
      };
    } else {
      newErrors = {
        ...newErrors,
        endDateWe: "",
        startDateWe: "",
      };
    }
    setErrors(newErrors);
  };

  const handleDateChange = (e, profile, setProfile) => {
    const { name, value } = e.target;
    const today = new Date();
    const updatedProfile = { ...profile, [name]: value };

    if (name === "company" || name === "designation") {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" })); 
    }

    if (name === "startDateWe") {
      const endDate = updatedProfile.endDateWe;
      if (endDate) {
        checkDateValidation(value, endDate);
      } else if (new Date(value) > today) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          startDateWe: "Start date must not exceed today's date.",
        }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, startDateWe: "" }));
      }
    } else if (name === "endDateWe") {
      const startDate = updatedProfile.startDateWe;
      if (startDate) {
        checkDateValidation(startDate, value);
      } else if (new Date(value) > today) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          endDateWe: "End date must not exceed today's date.",
        }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, endDateWe: "" }));
      }
    }

    setProfile(updatedProfile);
  };

  const handleEmpChange = (e) => {
    handleDateChange(e, employe, setEmploye);
  };

  const onChangeHandler = (e) => {
    setPresent(!present);
    handleEmpChange(e);
  };

  const onChangeEditHandler = (e) => {
    const { name, checked } = e.target;
    setSelectedEmpProfile((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const validateProfileData = (profile) => {
    let hasError = false;
    let newErrors = { ...errors };

    if (!profile.company) {
      newErrors.company = "Please fill in the required field.";
      hasError = true;
    }

    if (!profile.designation) {
      newErrors.designation = "Please fill in the required field.";
      hasError = true;
    }

    if (Object.values(newErrors).some((error) => error !== "")) {
      alert("Please fix the errors in the form before submitting.");
      hasError = true;
    }

    setErrors(newErrors);
    return hasError;
  };

  const handleSubmit = async (profile, apiCall, successCallback) => {
    if (validateProfileData(profile)) {
      return;
    }

    let updatedProfile = { ...profile };

    if (profile.present === true) {
      updatedProfile.endDateWe = null;
      updatedProfile.present = true;
    }

    try {
      const response = await apiCall(updatedProfile);
      if (response.status === 201 || response.status === 200) {
        successCallback(response.data);
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  const addEmpData = () => {
    handleSubmit(
      employe,
      (data) => AddProfileData(data, "wrkexp"),
      (newEmp) => {
        setEmploymentProfile((prevEmp) => {
          const updatedEmp = [...prevEmp, newEmp];
          handlePersonalChange({
            target: { name: "employment_prof", value: updatedEmp },
          });
          return updatedEmp;
        });
        setShowWorkProfile(false);
        setEmploye({
          company: null,
          designation: null,
          startDateWe: null,
          endDateWe: null,
          present: false,
          responsibility: null,
          profile: profileid,
        });
      }
    );
  };

  const handleEditEduChange = (e) => {
    handleDateChange(e, selectedEmpProfile, setSelectedEmpProfile);
  };

  const addEditEmpData = () => {
    handleSubmit(
      { ...selectedEmpProfile, profile: profileid },
      (data) => EditProfileData(data, "wrkexp", editId),
      (newEmp) => {
        setEmploymentProfile((prevEmp) => {
          const updatedEmp = prevEmp.map((emp) =>
            emp.id === newEmp.id ? newEmp : emp
          );
          handlePersonalChange({
            target: { name: "employment_prof", value: updatedEmp },
          });
          return updatedEmp;
        });
        setShowEditEmp(false);
      }
    );
  };

  const handleDeleteExp = async () => {
    try {
      const response = await DeleteProfileData("wrkexp", editId);
      if (response.status === 204) {
        const updated = employmentProfile.filter((data) => data.id !== editId);
        setEmploymentProfile(updated);
        setShowDel(false);
      }
    } catch (error) {
      console.error("Error deleting :", error);
    }
  };

  return (
    <div>
      <div className="mb-5 pb-2 border-bottom">
        <div className="d-flex align-items-center mb-4 fw-bold ">
          <h4 className=" fw-bold text-dark ">Work Experience</h4>
          <div
            className="ms-4 border p-1 rounded text-primary fw-semibold"
            style={{ fontSize: "12px", cursor: "pointer" }}
            onClick={handleShowWorkProfile}
          >
            + Add more
          </div>
        </div>
        {employmentProfile && employmentProfile.length > 0 ? (
          <div className="">
            {employmentProfile.map((emp) => (
              <Row key={emp.id} className="mb-4">
                <Col lg={1} sm={2} xs={2}>
                  {emp.designation && (
                    <div className={classes.eduelement}>
                      {emp.designation.charAt(0)}
                    </div>
                  )}
                </Col>
                <Col lg={9} sm={8} xs={8}>
                  <div key={emp.id}>
                    <div className="d-flex flex-column flex-sm-row align-items-start align-items-sm-center">
                      <div
                        className="fw-semibold text-dark"
                        style={{ fontSize: "16px" }}
                      >
                        {emp.designation}
                      </div>
                    </div>
                    <div style={{ fontSize: "14px" }} className="text-danger">
                      {emp.company}
                    </div>
                    {(emp.startDateWe || emp.endDateWe || emp.present) && (
                      <>
                        <div
                          className="text-secondary mt-1"
                          style={{ fontSize: "12px" }}
                        >
                          {emp.startDateWe && (
                            <>
                              {emp.startDateWe}
                              <span className="px-1">-</span>
                            </>
                          )}
                          {emp.present === true ? "Present" : emp.endDateWe}
                        </div>
                      </>
                    )}
                    {emp.responsibility && (
                      <div
                        className=" mt-2 text-secondary"
                        style={{ fontSize: "14px" }}
                      >
                        {parse(emp.responsibility)}
                      </div>
                    )}
                  </div>
                </Col>
                <Col
                  lg={1}
                  sm={1}
                  xs={1}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleShowEditProfile(emp.id)}
                >
                  <LuClipboardEdit />
                </Col>   <Col
                  lg={1}
                  sm={1}
                  xs={1}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleShowDelExp(emp.id)}
                >
                  <BiTrash className="ms-1 fs-5 fw-bold text-danger" />
                </Col>
              </Row>
            ))}
          </div>
        ) : (
          <div className="my-3 text-danger" style={{ fontSize: "14px" }}>
            * No experience added
          </div>
        )}
      </div>
      <WorkProfileForm
        show={showWorkProfile}
        handleClose={handleCloseWorkProfile}
        data={employmentProfile}
        formtype="add"
        add={addEmpData}
        handleChange={handleEmpChange}
        onChangeHandler={onChangeHandler}
        errors={errors}
        present={present}
      />
      {showEditEmp && (
        <WorkProfileForm
          show={showEditEmp}
          handleClose={handleCloseEditProfile}
          data={selectedEmpProfile}
          formtype="edit"
          add={addEditEmpData}
          handleChange={handleEditEduChange}
          onChangeHandler={onChangeEditHandler}
          errors={errors}
        />
      )}
        <DeleteProfile
      show={showDel}
      handleClose={handleCloseDelExp}
      handleDelete={handleDeleteExp}
      />
    </div>
  );
};

export default WorkProfile;
