import React, { useContext } from "react";
import { Row, Col, Badge } from "react-bootstrap";
import { RecruiterCandidateListContext } from "../../../../../provider/RecruiterCandidateListContext";
import classes from "../../../../candidate/profile/Profile.module.css";

const AwardsPreview = () => {
  const { singleCandidate } = useContext(RecruiterCandidateListContext);
  const awardProfile = singleCandidate.awards ? singleCandidate.awards : [];
  return (
    <div className=" ">
      {awardProfile.length > 0 ? (
        <div className="fw-bold mb-3 text-secondary mt-3">
          <div className="d-flex align-items-center">
            <h4 className="fw-bold text-dark">Awards</h4>
          </div>
          <div className="mt-4">
            {awardProfile.map((language, index) => (
              <Badge key={index} bg="info" className="me-3 mb-3 p-3 text-dark">
                {language}
              </Badge>
            ))}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default AwardsPreview;
