import React from 'react';

const Star = ({ fill }) => {
  const starStyle = {
    color: fill ? '#FFD700' : '#e4e5e9', 
    cursor: 'default',
    fontSize: '1.2rem'
  };

  return <span style={starStyle}>&#9733;</span>; 
};

export default Star;
