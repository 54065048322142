import React from "react";
import classes from "./CustomButton.module.css";
import { FaArrowCircleRight } from "react-icons/fa";
import { Button } from "react-bootstrap";

const CustomButton = (props) => {
  return (
    <div className="mt-3">
      <Button
        size="sm"
        className="px-4 py-2 fw-semibold d-flex align-items-center rounded-5"
        type={props.type}
        onClick={props.onClickFn}
        disabled={props.disabled}
        variant="dark"
      >
        {props.text} <FaArrowCircleRight className="ms-2 fs-6" />
      </Button>
    </div>
  );
};

export default CustomButton;
