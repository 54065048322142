import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Toast from "react-bootstrap/Toast";
import Button from "react-bootstrap/Button";
import { ToastContainer } from "react-bootstrap";
import { useToast } from "./ToastContext";

const HirerJobListContext = createContext();

export const HirerJobListProvider = ({ children }) => {
  const [jobs, setJobs] = useState([]);
  const [jobCount, setJobCount] = useState(0);
  const [activeJobCount, setActiveJobCount] = useState(0);
  const [closedJobCount, setClosedJobCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [prevPageUrl, setPrevPageUrl] = useState(null);

  const updateJobs = (newJobs) => {
    // console.log("New jobs:", newJobs);
    setJobs(newJobs);
  };
  const { showToast, setShowToast, setToastMessage, setToastBg } = useToast();

  // get job
  const fetchJobsData = async (url) => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        setJobs(response.data.results);
        setJobCount(response.data.count);
        setActiveJobCount(
          response.data.results.filter((job) => job.is_active).length
        );
        setClosedJobCount(
          response.data.results.filter((job) => !job.is_active).length
        );
        setNextPageUrl(response.data.next);
        setPrevPageUrl(response.data.previous);
      } else {
        console.error(`An error occurred: ${response.statusText}`);
      }
    } catch (error) {
      console.error("An error occurred while fetching jobs:", error);
      setToastMessage("An error occurred while fetching jobs:", error);
      setShowToast(true);
      setToastBg("danger");
    } finally {
      setLoading(false);
    }
  };

  // Post a job

  const postJob = async (formData) => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}hirer/add/job`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log("Job Posted:", response.data);
      setToastMessage("Job posted successfully!");
      setShowToast(true);
      setToastBg("success");
      // alert("Job posted successfully!");

      updateJobs([...jobs, response.data]);
    } catch (error) {
      console.log("Error posting job from context:", error);
      if(error.response.data.error){
        setToastMessage(error.response.data.error[0]);
        setShowToast(true);
        setToastBg("danger");
      }
      if(error.response.data.job_role){
        setToastMessage(error.response.data.job_role[0]);
        setShowToast(true);
        setToastBg("danger");
      }
      
      throw error;
    }
  };

  // Patch jobs
  const handlePatchJob = async (jobId, formData) => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}hirer/add/job/${jobId}`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        updateJobs([...jobs, response.data]);
        setToastMessage("Job updated successfully!");
        setShowToast(true);
        setToastBg("success");
        // alert("Job updated successfully!");
      } else {
        throw new Error("Failed to update job.");
      }
    } catch (error) {
      console.error("Error updating job:", error.message);
      setToastMessage("Error updating job:", error.message);
      setShowToast(true);
      setToastBg("danger");
      throw error;
    }
  };

  const contextValue = {
    jobs,
    loading,
    updateJobs,
    fetchJobsData,
    postJob,
    handlePatchJob,
    nextPageUrl,
    prevPageUrl,
    jobCount,
    activeJobCount,
    closedJobCount,
    setToastMessage,
    setShowToast,
  };

  return (
    <HirerJobListContext.Provider value={contextValue}>
      {children}{" "}
    </HirerJobListContext.Provider>
  );
};

export const useHirerJobListContext = () => useContext(HirerJobListContext);
