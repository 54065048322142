import React, { useState } from "react";
import { Row, Col, Image, Container } from "react-bootstrap";
import ResumeForm from "./resumeForm/ResumeForm";
import classes from "./ResumeBuilder.module.css";
import classes1 from "./Template.module.css";
import Sidebar from "./resumeForm/Sidebar";
import ResumeTemplate from "./ResumeTemplate";
import UploadResume from "./resumeForm/UploadResume";
import { useResumeBuilderContext } from "../../provider/ResumeBuilderContext";
import { useAuthContext } from "../../provider/AuthContext";
import ScoreResume from "./resumeForm/ScoreResume";
import Loading from "../../ui/common/Loading";
import { Link } from "react-router-dom";
import CandidateHeader from "../candidate/layouts/CandidateHeader";
import RBHeader from "./resumeUI/RBHeader";

const ResumeBuilder = () => {
  const { showResume, showScore, currentStep } = useResumeBuilderContext();
  const { isLoading, userData, logout } = useAuthContext();

  const signOut = () => {
    logout();
  };
  return (
    <>
      {isLoading === true ? (
        <Loading text="Signing out" />
      ) : (
        <>
          <div>
            <UploadResume />
            <Row style={{height:"100vh"}}>
              <div className={classes.bghomepage}></div>
              {parseInt(currentStep) === 9 ? (
                <Col>
                  
                  <ScoreResume />
                </Col>
              ) : (
                <Col>
                  <RBHeader />
                  <Row>
                    <Col>
                      <div className="d-flex flex-column align-items-center ">
                        <Sidebar />
                      </div>
                      <ResumeForm />
                    </Col>
                    <Col style={{  backgroundColor: "#F2F6FD" }}>
                      <ResumeTemplate />
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </div>
        </>
      )}
    </>
  );
};

export default ResumeBuilder;
