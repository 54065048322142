import React from "react";
import classes from "./Common.module.css";
import { Link } from "react-router-dom";
import NavbarCommon from "../../components/recruiter/layouts/NavbarCommon";

const NotFound = () => {
  return (
    <div className=" container">
      <NavbarCommon />
      <section className={classes.page_404  }>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 ">
              <div className="col-sm-12 col-sm-offset-1  text-center">
                <div className={classes.four_zero_four_bg}>
                  <h1 className="text-center ">404</h1>
                </div>

                <div className={classes.contant_box_404}>
                  <h3 className="h2">Look like you're lost</h3>

                  <p>The page you are looking for not avaible!</p>

                  <Link to={"/"} className=' btn btn-success text-white'>
                    Back Home
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default NotFound;
