import React from "react";
import classes from "./ResumeFormData.module.css";
import { Row, Col, Accordion } from "react-bootstrap";
import TagsInput from "../../resumeUI/TagsInput";
import { useResumeBuilderContext } from "../../../../provider/ResumeBuilderContext";
import AccordionField from "../../resumeUI/AccordionField";

const TechincalSkills = () => {
  const { resumeData, handleResumeFormChange } =
  useResumeBuilderContext();

const handleSelectedTags = (tags) => {
  const updatedResumeData = {
    ...resumeData,
    techskills: tags.map((tag) => ({ techSkill: tag })),
  };

  handleResumeFormChange({
    target: {
      name: "techskills",
      value: updatedResumeData.techskills,
    },
  });
};

const handleTagRemoval = (indexToRemove) => {
  const updatedTags = resumeData.techskills.filter(
    (_, index) => index !== indexToRemove
  );

  handleResumeFormChange({
    target: {
      name: "techskills",
      value: updatedTags,
    },
  });
};
  return (
    <div className="mb-3">
    <AccordionField heading="Tech Skills">
      <div>
        <Row>
          <Col>
            <TagsInput
              name="techskills"
              selectedTags={handleSelectedTags}
              handleTagRemoval={handleTagRemoval}
              tags={
                resumeData.techskills &&
                (resumeData.techskills.some((hob) => hob.techSkill !== null)
                  ? resumeData.techskills.map((hob) => hob.techSkill)
                  : [])
              }
              placeholder="Add Skills and press enter"
            />
          </Col>
        </Row>
      </div>
    </AccordionField>
  </div>
  );
};

export default TechincalSkills;
