import parse from "html-react-parser";

export const formatDateOfBirth = (dob) => {
  const originalDate = new Date(dob);
  return `${originalDate.getDate().toString().padStart(2, "0")}-${(
    originalDate.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${originalDate.getFullYear()}`;
};

export const extractParagraphs = (htmlContent) => {
  const paragraphs = [];
  const pTagRegex = /<p>(.*?)<\/p>/gs;
  const brTagRegex = /<br\s*\/?>/g;

  htmlContent = htmlContent.replace(brTagRegex, "");

  let match;
  while ((match = pTagRegex.exec(htmlContent)) !== null) {
    paragraphs.push(match[1]);
  }
  // console.log(paragraphs)
  return paragraphs;
};

export const extractListItems = (htmlContent) => {
  const listItems = [];
  const ulTagRegex = /<ul>(.*?)<\/ul>/gs;
  const liTagRegex = /<li>(.*?)<\/li>/g;
  const brTagRegex = /<br\s*\/?>/g;

  let ulMatch;
  while ((ulMatch = ulTagRegex.exec(htmlContent)) !== null) {
    const ulContent = ulMatch[1].replace(brTagRegex, "");
    let liMatch;
    while ((liMatch = liTagRegex.exec(ulContent)) !== null) {
      listItems.push(liMatch[1]);
    }
  }
  // console.log(listItems)
  return listItems;
};

// ResumeHelpers.js
export const extractContent = (htmlContent) => {
  const content = [];
  
  // Regular expressions for <p>, <ul>, <li>, and <br> tags
  const pTagRegex = /<p>(.*?)<\/p>/gs;
  const ulTagRegex = /<ul>(.*?)<\/ul>/gs;
  const liTagRegex = /<li>(.*?)<\/li>/g;
  const brTagRegex = /<br\s*\/?>/g;

  // Remove <br> tags from the HTML content
  htmlContent = htmlContent.replace(brTagRegex, '');

  let match;
  let lastIndex = 0;

  // Extract paragraphs and keep track of the last matched index
  while ((match = pTagRegex.exec(htmlContent)) !== null) {
    if (match.index > lastIndex) {
      content.push({ type: 'text', value: htmlContent.slice(lastIndex, match.index) });
    }
    content.push({ type: 'paragraph', value: match[1] });
    lastIndex = pTagRegex.lastIndex;
  }

  // Extract list items
  let ulMatch;
  while ((ulMatch = ulTagRegex.exec(htmlContent)) !== null) {
    const ulContent = ulMatch[1].replace(brTagRegex, '');
    let liMatch;
    while ((liMatch = liTagRegex.exec(ulContent)) !== null) {
      content.push({ type: 'listItem', value: liMatch[1] });
    }
  }

  // Push remaining text if any
  if (lastIndex < htmlContent.length) {
    content.push({ type: 'text', value: htmlContent.slice(lastIndex) });
  }

  return content;
};

