import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import classes from "../Screens.module.css";
import classes2 from "./forms.module.css";
import { GrFormNextLink } from "react-icons/gr";
import FormInput from "../../../../../ui/common/FormInput";
import { useRecruiterProfileContext } from "../../../../../provider/RecruiterProfileContext";
import { validatePhone } from "../../../../../utils/Validation";
import { useParams } from "react-router-dom";

const PersnoalInfo = ({ onNext }) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const firstName = userData.first_name
    ? userData.first_name
    : userData.user_info.first_name;
  const lastName = userData.last_name
    ? userData.last_name
    : userData.user_info.last_name;

  const {
    handleFormDataChange,
    recruiterProfileData,
    errors,
    setRecruiterProfileData,
    fetchedData,
  } = useRecruiterProfileContext();

  const [isData, setIsData] = [
    {
      email: recruiterProfileData.email,
      phone: recruiterProfileData.company_phone,
    },
  ];

  const handleNext = () => {
    onNext();
  };
  

  return (
    <Form>
      <Row>
        <h2 className={classes.companyName + " mb-3 "}>Contact Informations</h2>
        <Col lg={6}>
          <FormInput
            name="firstName"
            type="text"
            placeholder="First Name"
            label="First name"
            value={firstName}
            disabled
            className="mb-3"
            required={true}
          />
        </Col>
        <Col lg={6}>
          <FormInput
            name="lastName"
            type="text"
            placeholder="Last Name"
            label="Last name"
            value={lastName}
            disabled
            className="mb-3"
            required="required"
          />
        </Col>
      </Row>
      <Row>
        <Col className="position-relative">
          <FormInput
            name="email"
            type="email"
            placeholder="name@company.com"
            label="Email address"
            value={recruiterProfileData.email}
            onChange={handleFormDataChange}
            className="mb-3 "
            required="required"
            error={errors.email}
          />
        </Col>
        <Col className=" position-relative">
          <FormInput
            name="company_phone"
            type="text"
            placeholder=""
            label="Phone number"
            value={recruiterProfileData.company_phone}
            onChange={handleFormDataChange}
            className="mb-3"
            required="required"
            error={errors.company_phone}
          />
        </Col>
      </Row>
      <div className="d-flex justify-content-end align-items-end text-end">
        <Button
          className={classes2.navlink + " gap-1 "}
          onClick={handleNext}
          disabled={
            Object.values(errors).some((field) => field !== "") ||
            Object.values(isData).some((value) => value === "")
          }
        >
          Next
          <span>
            <GrFormNextLink className="fs-4" />
          </span>
        </Button>
      </div>
    </Form>
  );
};

export default PersnoalInfo;
