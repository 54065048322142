import React, { useState, useEffect, useRef } from "react";
import { Col, Container, Row, Pagination } from "react-bootstrap";
import CustomSelect from "../../candidate/jobsearch/CustomSelect";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Header2 from "../layouts/Header2";
import Footer from "../layouts/Footer";
import LatestJobsCard from "./LatestJobsCard";
import noresume from "../../../assets/Noresume.svg"
import LatestJobSkelton from "./LatestJobSkelton";
import SearchSelect from "../../../ui/common/SearchSelect";
import classes from "./LatestJobs.module.css";
import { url } from "../../../data/Constants";


export const routesMapping = {
  "/latest-jobs": "",
  "/latest-jobs/data-analytics": "Data Analytics",
  "/latest-jobs/gen-ai": "Gen AI",
  "/latest-jobs/frontend-developer": "Frontend Developer",
  "/latest-jobs/backend-developer": "Backend Developer",
  "/latest-jobs/fullstack-developer": "Full stack Developer",
  "/latest-jobs/data-analytics/": "Data Analytics",
  "/latest-jobs/gen-ai/": "Gen AI",
  "/latest-jobs/frontend-developer/": "Frontend Developer",
  "/latest-jobs/backend-developer/": "Backend Developer",
  "/latest-jobs/fullstack-developer/": "Full stack Developer",
};

export const sorting = [
  { value: "desc", label: " Newest to Oldest" },
  { value: "asc", label: " Oldest to Newest" },
];
export const daysago = [
  { value: "", label: " Posted on" },
  { value: "1 day ago", label: " 1 day ago" },
  { value: "3 days ago", label: " 3 days ago" },
  { value: "1 week ago", label: " 1 week ago" },
];

const LatestJobs = () => {
  const [LatestJobs, setLatestJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [suggestions, setSuggestions] = useState("");
  const [suggestionsFilters, setSuggestionsFilters] = useState([]);
  const [show, setShow] = useState(false);
  const [filters, setFilters] = useState({
    keyword: "",
    fresher: false,
  });
  const [selectFilter, setSelectFilter] = useState({
    date_sort: {
      value: "desc",
      label: " Newest to Oldest",
    },
    days_ago: { value: "", label: "Posted On" },
  });

  const dropdownRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShow(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    const currentPath = location.pathname;
    const fresher = currentPath.includes("/fresher" || "/fresher/");
    const baseRoute = fresher
      ? currentPath.replace("/fresher", "")
      : currentPath;
    const keyword = routesMapping[baseRoute] || "";

    setFilters((prevFilters) => ({
      ...prevFilters,
      keyword,
      fresher,
    }));

    fetchLatestJobs(1, keyword, fresher);
  }, [
    location,
    selectFilter.date_sort,
    selectFilter.days_ago,
    filters.keyword,
    filters.fresher,
  ]);

  const fetchLatestJobs = async (
    page,
    keyword,
    fresher,
    locationSuggestion
  ) => {
    setShow(false);
    const params = {
      fresher: fresher || filters.fresher,
      keyword: keyword || filters.keyword,
      location: locationSuggestion || suggestions,
      date_sort: selectFilter.date_sort.value,
      page: page,
      suggestions: false,
      days_ago: selectFilter.days_ago.value,
    };
    Object.keys(params).forEach((key) => {
      if (!params[key]) {
        delete params[key];
      }
    });
    const url_ = `${url}user/job/daily_jobs`;
    console.log(url_)
    setIsLoading(true);
    try {
      const response = await axios.get(url_, { params });
      if (response.data) {
        const data = response.data;
        setLatestJobs(data.values);
        let pages = page;
        if (data.next_page) {
          pages = parseInt(data.next_page) - 1;
        } else {
          pages = parseInt(data.previous_page) + 1;
        }
        setCurrentPage(pages);
        setTotalPage(data.total_jobs);
      } else {
        setLatestJobs([]);
      }
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      if (err.response && err.response.data && err.response.data.error) {
      } else {
        alert("something went wrong");
      }
      setIsLoading(false);
      return [];
    }
  };

  const fetchLatestfilters = async (value, name) => {
    const params = {
      location: value,
      suggestions: true,
    };

    if (name in params) {
      params[name] = value;
    }

    Object.keys(params).forEach((key) => {
      if (!params[key]) {
        delete params[key];
      }
    });

    const filterurl = `${url}user/job/daily_jobs`;
    try {
      const response = await axios.get(filterurl, { params });
      setSuggestionsFilters(response.data.locations);
      return response.data.locations;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const handleSelectFilter = (suggestion) => {
    setSuggestions(suggestion);
    setShow(false);
    fetchLatestJobs(1, filters.keyword, filters.fresher, suggestion);
  };

  const handleInputSearchChange = (e) => {
    const inputValue = e.target.value;
    console.log(inputValue);
    setSuggestions(inputValue);
    setTimeout(() => {
      if (inputValue.length >= 3) {
        setShow(true);
        fetchLatestfilters(inputValue, "location");
      } else {
        setSuggestionsFilters([]);
        setShow(false);
      }
    }, 300);
  };

  const handleChangeFilter = (name) => (selectedOption) => {
    setSelectFilter((prevFilters) => ({
      ...prevFilters,
      [name]: selectedOption,
    }));
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchLatestJobs(pageNumber, filters.keyword, filters.fresher);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const renderPaginationItems = () => {
    const totalPages = Math.ceil(totalPage / 12);
    const maxPagesToShow = 5;
    const items = [];

    if (totalPages <= maxPagesToShow) {
      for (let number = 1; number <= totalPages; number++) {
        items.push(
          <Pagination.Item
            key={number}
            active={number === currentPage}
            onClick={() => handlePageChange(number)}
          >
            {number}
          </Pagination.Item>
        );
      }
    } else {
      let startPage = currentPage - Math.floor(maxPagesToShow / 2);
      let endPage = currentPage + Math.floor(maxPagesToShow / 2);

      if (startPage < 1) {
        startPage = 1;
        endPage = maxPagesToShow;
      }

      if (endPage > totalPages) {
        endPage = totalPages;
        startPage = totalPages - maxPagesToShow + 1;
      }

      if (startPage > 1) {
        items.push(
          <Pagination.Item
            key={1}
            active={currentPage === 1}
            onClick={() => handlePageChange(1)}
          >
            1
          </Pagination.Item>
        );
        items.push(<Pagination.Ellipsis key="start-ellipsis" />);
      }

      for (let number = startPage; number <= endPage; number++) {
        items.push(
          <Pagination.Item
            key={number}
            active={number === currentPage}
            onClick={() => handlePageChange(number)}
          >
            {number}
          </Pagination.Item>
        );
      }

      if (endPage < totalPages) {
        items.push(<Pagination.Ellipsis key="end-ellipsis" />);
        items.push(
          <Pagination.Item
            key={totalPages}
            active={currentPage === totalPages}
            onClick={() => handlePageChange(totalPages)}
          >
            {totalPages}
          </Pagination.Item>
        );
      }
    }

    return items;
  };

  return (
    <div>
      <Header2 />
      <Container>
        <h2 className="fw-bold text-dark text-capitalize my-4">
          {filters.keyword ? (
            <>
              Latest Jobs for
              <span className="text-primary ps-2 "> {filters.keyword}</span>
            </>
          ) : (
            "Latest Jobs"
          )}
        </h2>
        <Row className="mb-5 mt-4 ">
          <Col lg={6} md={6} sm={12} xs={12}>
            <div className="position-relative">
              {" "}
              <SearchSelect
                value={suggestions}
                handleInputChange={handleInputSearchChange}
                search={() => {
                  fetchLatestJobs(1, filters.keyword, filters.fresher);
                }}
                filters={suggestionsFilters}
                show={show}
              />
              {suggestionsFilters.length > 0 && show && (
                <div
                  ref={dropdownRef}
                  className={
                    classes.options +
                    " bg-white text-start w-100 position-absolute  z-2"
                  }
                  style={{ minHeight: "3rem" }}
                >
                  <div>
                    {suggestionsFilters.map((suggestion, index) => (
                      <div
                        key={index}
                        className={
                          classes.search + " text-light  py-2 text-dark fw-600 "
                        }
                        style={{ fontSize: "14px", cursor: "pointer" }}
                        onClick={() => {
                          handleSelectFilter(suggestion);
                        }}
                      >
                        {suggestion}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </Col>
          <Col
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className=" d-flex align-items-center justify-content-lg-end gap-2"
          >
            <div className=" border rounded py-1">
              <CustomSelect
                options={daysago}
                handleChange={handleChangeFilter("days_ago")}
                selectedvalue={selectFilter.days_ago}
                fontSize="12px"
              />
            </div>
            <div className="d-flex align-items-center border rounded px-3 py-1 ">
              <span
                className="fw-semibold d-none d-md-block"
                style={{ fontSize: "12px", color: "#A0ABC3" }}
              >
                Sort by :
              </span>
              <CustomSelect
                options={sorting}
                handleChange={handleChangeFilter("date_sort")}
                selectedvalue={selectFilter.date_sort}
                fontSize="12px"
              />
            </div>
          </Col>
        </Row>
        {totalPage <= 0 && !isLoading ? (
          <Row className="text-center my-5" style={{ height: "65vh" }}>
            <Col className="d-flex  align-items-center flex-column justify-content-center">
              <img
                src={noresume}
                alt="noresumes"
                width={600}
                className="img-fuid"
              />
              <div className="fw-semibold fs-6">
                Uh.. Oh no jobs available for this filter
              </div>
            </Col>
          </Row>
        ) : (
          <>
            {isLoading ? (
              <Row>
                {[...Array(12)].map((_, index) => (
                  <Col xl={3} lg={4} md={6} sm={12} xs={12} key={index}>
                    <LatestJobSkelton />
                  </Col>
                ))}
              </Row>
            ) : (
              <Row>
                {LatestJobs.map((job) => (
                  <Col xl={3} lg={4} md={6} sm={12} xs={12} key={job.id}>
                    <LatestJobsCard job={job} />
                  </Col>
                ))}
                <div className="mt-3">
                  <Pagination className="custom-pagination">
                    <Pagination.Prev
                      className="custom-page-prev-next"
                      onClick={() =>
                        handlePageChange(currentPage > 1 ? currentPage - 1 : 1)
                      }
                      disabled={currentPage === 1}
                    />
                    {renderPaginationItems()}
                    <Pagination.Next
                      className="custom-page-prev-next"
                      disabled={currentPage === Math.ceil(totalPage / 12)}
                      onClick={() =>
                        handlePageChange(
                          currentPage < Math.ceil(totalPage / 12)
                            ? currentPage + 1
                            : currentPage
                        )
                      }
                    />
                  </Pagination>
                </div>
              </Row>
            )}
          </>
        )}
      </Container>
      <Footer />
    </div>
  );
};

export default LatestJobs;
