import React from "react";
import classes from "./Input.module.css";

const Select = ({ name, options, value, text, placeholder, onChange }) => {
  return (
    <div className={classes.form}>
      <label className={classes.inputlabel}>{text}</label>
      <select
        className={classes.input}
        name={name}
        onChange={onChange}
        value={value}
      >
        {placeholder && !value && (
          <option value="">
            {placeholder}
          </option>
        )}
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
