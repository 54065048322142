import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useJobContext } from "../../../provider/JobContext";
import confirm from "../.././../assets/confirmimage.png";
import JobModal from "./JobModal";

const JobApply = ({ job, show, handleClose,setShow }) => {
  const { applyJob, fetchJobs, selectedJob } = useJobContext();
  const [isApplying, setIsApplying] = useState(false);

  const [applyJobs, setApplyJobs] = useState([]);

  const onApplyJob = async () => {
    setIsApplying(true);

    if (selectedJob === "E") {
      try {
        const jobApplied = {
          jobid: job.id,
          desc: job.det_desc,
        };

        const data = await applyJob(jobApplied);
        // console.log("applyyyydat_________", data);

        if (data && data.status === 201) {
          setApplyJobs(data.data);
          fetchJobs();
        } else {
          setApplyJobs([]);
          alert("Failed to apply for the job.");
          // fetchJobs();
        }
      } catch (error) {
        if (error.response.data.error) {
          alert(error.response.data.error);
        } else {
          alert("An error occurred while applying for the job.");
        }
      } finally {
        setIsApplying(false);
      }
    } else {
      window.open(job.job_link, "_blank");
    }
    setIsApplying(false);
    setShow(false)
  };
  return (
    <JobModal
      show={show}
      handleClose={handleClose}
      onSubmitModal={onApplyJob}
      onLoading={isApplying}
      compo="apply"
    >
      <div className="fw-semibold px-2 text-black" style={{ fontSize: "18px" }}>
        Apply for
        <span className=" text-primary mx-1">{job.job_title}</span>?
      </div>
    </JobModal>
  );
};

export default JobApply;
