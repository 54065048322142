import React from "react";
import { Container, Image, Row } from "react-bootstrap";
import NavbarCommon from "./NavbarCommon";
import { Link } from "react-router-dom";

const GetStarted = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const firstName = userData.first_name
    ? userData.first_name
    : userData.user_info.first_name;
  const lastName = userData.last_name
    ? userData.last_name
    : userData.user_info.last_name;
  return (
    <>
      <NavbarCommon />
      <Container style={{ marginTop: "10vh" }} className=" py-5  rounded">
        <div className="d-flex justify-content-center ">
          <div
            className=" d-flex flex-column justify-content-center align-items-center  rounded-3  py-5 w-50 "
            style={{ backgroundColor: "#F2F6FD" }}
          >
            <div className=" text-center  mb-3 d-flex flex-column align-items-center mt-3">
              <h4 className={"  px-3 "}>
                Hey <b>{firstName} 👋</b>
              </h4>
              <h4 className=" px-3 fw-semibold mb-3">
                Welcome to <b>EmCareer</b>
              </h4>
              <p className=" w-75" style={{ lineHeight: "30px" }}>
                Before you get started, we'd love to get to know you better.
                Completing your profile helps us tailor your experience and
                connect you with relevant opportunities.
              </p>
            </div>

            <Link to={"/createprofile"} className=" btn btn-primary">
              Create Profile
            </Link>
          </div>
        </div>
      </Container>
    </>
  );
};

export default GetStarted;
