import React, { useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { RecruiterCandidateListContext } from "../../../../../provider/RecruiterCandidateListContext";
import classes from "../../../../candidate/profile/Profile.module.css";
import parse from "html-react-parser";

const WorkPreview = () => {
  const { singleCandidate } = useContext(RecruiterCandidateListContext);
  const employmentProfile = singleCandidate.work_experience
    ? singleCandidate.work_experience
    : [];
  return (
    <>
      {employmentProfile && employmentProfile.length > 0 && (
        <div className="mb-5 pb-2 border-bottom">
          <div className="d-flex align-items-center mb-4 fw-bold ">
            <h4 className="fw-bold text-dark">Work Experience</h4>
          </div>
          <div>
            {employmentProfile.map((emp) => (
              <Row key={emp.id} className="mb-4">
                <Col lg={1} sm={2} xs={2}>
                  {emp.designation && (
                    <div className={classes.eduelement}>
                      {emp.designation.charAt(0)}
                    </div>
                  )}
                </Col>
                <Col lg={11} sm={10} xs={10}>
                  <div>
                    <div className="d-flex flex-column flex-sm-row align-items-start align-items-sm-center">
                      <div
                        className="fw-semibold text-dark"
                        style={{ fontSize: "16px" }}
                      >
                        {emp.designation}
                      </div>
                    </div>
                    <div style={{ fontSize: "14px" }} className="text-danger">
                      {emp.company}
                    </div>
                    {(emp.start_date || emp.end_date || emp.present) && (
                      <div
                        className="text-secondary mt-1"
                        style={{ fontSize: "12px" }}
                      >
                        {emp.start_date && (
                          <>
                            {emp.start_date}
                            <span className="px-1">-</span>
                          </>
                        )}
                        {emp.present === true ? "Present" : emp.end_date}
                      </div>
                    )}
                    {emp.responsibilities && (
                      <div
                        className="mt-2 text-secondary"
                        style={{ fontSize: "14px" }}
                      >
                        <ul>
                          {emp.responsibilities.map((responsibility, index) => (
                            <li key={index}>{responsibility}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default WorkPreview;
