import React from "react";
import { BsLinkedin, BsInstagram, BsGoogle, BsGlobe } from "react-icons/bs";
import { Container, Row, Col } from "react-bootstrap";
import classes from "../Home.module.css";

const Footer = () => {
  const role = localStorage.getItem("role");

  return (
    <footer className={classes.footersection}>
      <Container>
        <Row className={classes.footercontent + " py-5"}>
          <Col xl={4} lg={4} sm={12}>
            <div className=" fs-4 text-dark d-flex align-items-center fw-bold mb-3">
              <div
                className="bg-primary p-1 text-white"
                style={{ borderRadius: "50px", height: "45px", width: "45px" }}
              >
                em
              </div>
              Career
            </div>
            <div style={{ color: "#66789C", fontSize: "13px" }}>
              EmCareer bridges the gap between
              opportunities and skilled professionals, making job search and
              hiring processes seamless and efficient.
            </div>
            <div className={classes.socialicon + " mt-4"}>
              <a href="https://pfactorial.ai/" target="_blank" rel="noreferrer">
                <BsGlobe className="mt-2" />
              </a>
              <a
                href="https://www.linkedin.com/company/pfactorial-technologies-pvt-ltd/"
                target="_blank"
                rel="noreferrer"
              >
                <BsLinkedin className="mt-2" />
              </a>
              <a
                href="https://www.instagram.com/pfactorial_technologies/"
                target="_blank"
                rel="noreferrer"
              >
                <BsInstagram className="mt-2" />
              </a>
              <a
                href="https://www.google.com/search?q=pfactorial+technologies"
                target="_blank"
                rel="noreferrer"
              >
                <BsGoogle className="mt-2" />
              </a>
            </div>
          </Col>
          <Col xl={2} lg={2} sm={6}>
            <div className={classes.footerwidget}>
              <h3 className="text-dark ">Quick Links</h3>
              <ul>
                <li>
                  <a href="/jobs">Search Jobs</a>
                </li>

                <li>
                  <a href="/postJob">Post Jobs</a>
                </li>
                <li>
                  <a href="/candidate">Candidates</a>
                </li>
                <li>
                  <a href="/build/resume">Resume Builder</a>
                </li>
                <li>
                  <a
                    href="mailto:info@pfactorial.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Contact us
                  </a>
                </li>
              </ul>
            </div>
          </Col>
          <Col xl={2} lg={2} sm={6}>
            <div className={classes.footerwidget}>
              <h3 className="text-dark">Resources</h3>
              <ul>
                <li>
                  <a
                    href="https://pfactorial.ai/#aboutus"
                    target="_blank"
                    rel="noreferrer"
                  >
                    About us
                  </a>
                </li>

                <li>
                  <a
                    href="https://pfactorial.ai/#services"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Our services
                  </a>
                </li>

                <li>
                  <a
                    href="mailto:sales@pfactorial.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Advertise with us
                  </a>
                </li>

                <li>
                  <a
                    href="https://pfactorial.ai/applications"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Other apps
                  </a>
                </li>

                <li>
                  <a
                    href="mailto:info@pfactorial.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Report a bug
                  </a>
                </li>
              </ul>
            </div>
          </Col>
          <Col lg={4} xl={4} md={12}>
            <div className={classes.footerwidget}>
              <h3>Contact Us</h3>
              <div style={{ fontSize: "14px" }} className="text-secondary">
                <span>
                  B14, CYBER PARK (Sahya), Nellikode, Kozhikode, Kerala 673016
                </span>
              </div>
              {/* <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15652.404446381102!2d75.8371491!3d11.2539719!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2152f99d9026de23!2sPfactorial%20technologies%20private%20ltd!5e0!3m2!1sen!2sin!4v1672029653907!5m2!1sen!2sin"
                className="maps"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe> */}
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className={classes.copyrightarea}>
          <Col lg={6} sm={12}>
            <div className="text-secondary mt-2">
              Copyright &copy; 2024. all right reserved
              <a
                href="https://pfactorial.ai/"
                rel="noopener"
                className="ms-1"
                target="_blank"
              >
                Pfactorial Technologies Pvt Ltd
              </a>
            </div>
          </Col>
          <Col lg={6} sm={12} className="text-lg-end">
            <div className={classes.footermenu}>
              <ul>
                <li>
                  <a
                    href="/termsandconditions"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms
                  </a>
                </li>
                <li className="ps-4">
                  <a href="/privacypolicy" target="_blank" rel="noopener">
                    Privacy
                  </a>
                </li>
                <li className="ps-4">
                  <a href="/eula" target="_blank" rel="noopener">
                    EULA
                  </a>
                </li>
                <li className="ps-4">
                  <a href="/cookies" target="_blank" rel="noopener">
                    Cookies
                  </a>
                </li>
                <li className="ps-4 ">
                  <a href="/disclaimer" target="_blank" rel="noreferrer">
                    Disclaimer
                  </a>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
