import React, { useState, useEffect } from "react";
import { IoPersonCircle } from "react-icons/io5";
import {
  Container,
  Dropdown,
  NavItem,
  NavLink,
  Navbar,
  Nav,
  Image,
  Spinner,
} from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { useAuthContext } from "../../../provider/AuthContext";
import { useProfileContext } from "../../../provider/ProfileContext";

const CandidateHeader = () => {
  const role = localStorage.getItem("role");
  const navigate = useNavigate();
  let token = localStorage.getItem("userData");
  let user = token ? JSON.parse(token) : null;

  const { logout } = useAuthContext();
  const { onCheckProfileExist, isLoadingProfile } = useProfileContext();
  const profile = localStorage.getItem("profile");

  const [isNavFixed, setIsNavFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 0) {
        setIsNavFixed(true);
      } else {
        setIsNavFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navigateSigninHirer = () => {
    localStorage.setItem("role", "H");
    navigate("/signin");
  };

  const navigateSigninCandidate = () => {
    localStorage.setItem("role", "C");
    navigate("/signin");
  };

  const navigateRegister = () => {
    if (role === null) {
      navigate("/select");
    } else {
      navigate("/register");
    }
  };
  const signOut = async (e) => {
    e.preventDefault();
    logout();
  };

  const onViewProfile = async (e) => {
    e.preventDefault();
    if (profile === "null") {
      onCheckProfileExist();
    } else {
      navigate("/viewprofile");
    }
  };

  return (
    <Navbar
      expand="lg"
      className={`py-3 ${isNavFixed ? "fixed-top bg-white shadow-lg" : ""}`}
    >
      <Container>
        <Navbar.Brand
          as={Link}
          to={user ? "/jobs" : "/"}
          className=" fs-4 text-dark d-flex align-items-center "
          style={{ fontWeight: "700" }}
        >
          <div
            className="bg-primary p-1 text-white"
            style={{ borderRadius: "50px", height: "45px", width: "45px" }}
          >
            em
          </div>
          Career
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            style={{ fontSize: "16px" }}
            className="mx-auto my-2 gap-lg-4 gap-0"
            navbarScroll
            variant="underline"
            defaultActiveKey="default"
          >
            {user ? (
              <>
                {" "}
                <Nav.Item>
                  <Nav.Link
                    as={Link}
                    to="/jobs"
                    eventKey="default"
                    active={window.location.pathname === "/jobs"}
                  >
                    Jobs
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    as={Link}
                    to="/companies"
                    eventKey="default"
                    active={window.location.pathname === "/companies"}
                  >
                    Companies
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    as={Link}
                    to="/resumes"
                    eventKey="link-2"
                    active={window.location.pathname === "/resumes"}
                  >
                    Resumes
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    as={Link}
                    to="/build/templates"
                    eventKey="link-3"
                    active={window.location.pathname === "/build/templates"}
                  >
                    Build Resume
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    as={Link}
                    eventKey="link-4"
                    to="/archives"
                    active={window.location.pathname === "/archives"}
                  >
                    Archives
                  </Nav.Link>
                </Nav.Item>
              </>
            ) : (
              ""
            )}
          </Nav>
          {user ? (
            <>
              {user.user_info && user.user_info.picture ? (
                <Image
                  width={25}
                  src={user.user_info.picture}
                  alt="userimage"
                  roundedCircle
                  className="mt-2 me-2"
                />
              ) : (
                <div className="fs-2 d-none d-lg-block text-secondary me-lg-1">
                  <IoPersonCircle />
                </div>
              )}
              <Dropdown as={NavItem} className="mt-2">
                {!isLoadingProfile ? (
                  <Dropdown.Toggle
                    as={NavLink}
                    className="text-primary"
                    style={{ fontSize: "14px" }}
                  >
                    {user.first_name && user.last_name
                      ? `${user.first_name} ${user.last_name}`
                      : user.user_info.name}
                  </Dropdown.Toggle>
                ) : (
                  <span style={{ fontSize: "14px" }}>
                    Fetching Profile
                    <Spinner size="sm" className="ms-1 text-primary" />
                  </span>
                )}
                <Dropdown.Menu>
                  <Dropdown.Item onClick={onViewProfile}>
                    View Profile
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to="/changepassword">
                    Change Password
                  </Dropdown.Item>
                  <Dropdown.Item onClick={signOut}>Sign out</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          ) : (
            <div className="ms-lg-auto flex-row gap-5 d-lg-flex align-items-center ">
              <div
                className="text-dark my-3"
                onClick={navigateRegister}
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                Register
              </div>
              <Dropdown className="custom-dropdown">
                <Dropdown.Toggle id="dropdown-basic">Sign In</Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={navigateSigninHirer}>
                    as Hirer
                  </Dropdown.Item>
                  <Dropdown.Item onClick={navigateSigninCandidate}>
                    as Candidate
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default CandidateHeader;
