import React, { useContext } from "react";
import classes from "../../../../candidate/profile/Profile.module.css";
import { Row, Col, Badge } from "react-bootstrap";
import { RecruiterCandidateListContext } from "../../../../../provider/RecruiterCandidateListContext";

const SkillsPreview = () => {
  const { singleCandidate } = useContext(RecruiterCandidateListContext);
  const techSkills = singleCandidate.skills?.technical_skills || [];
  const softSkills = singleCandidate.skills?.soft_skills || [];
  const internalSkills = singleCandidate.skills?.skills || [];

  return (
    <>
      {(techSkills.length > 0 ||
        softSkills.length > 0 ||
        internalSkills.length > 0) && (
        <div className="mb-5 pb-2 border-bottom">
          <h4 className="fw-bold text-dark">Skills</h4>{" "}
          <div>
            {/* Technical Skills Section */}
            {techSkills.length > 0 ? (
              <div className=" fw-bold mb-3 text-secondary">
                <div className="d-flex align-items-center">
                  <div style={{ fontSize: "16px" }}>Technical Skills</div>
                </div>

                <div className="mt-4">
                  {techSkills.map((techSkill, index) => (
                    <Badge
                      key={index}
                      bg="info"
                      className="me-3 mb-3 p-3 text-dark "
                    >
                      {techSkill}
                    </Badge>
                  ))}
                </div>
              </div>
            ) : (
              ""
            )}

            {/* Soft Skills Section */}
            {softSkills.length > 0 ? (
              <div className=" fw-bold mb-3 text-secondary">
                <div className="d-flex align-items-center">
                  <div style={{ fontSize: "16px" }}>Soft Skills</div>
                </div>

                <div className="mt-4">
                  {softSkills.map((softSkill, index) => (
                    <Badge
                      key={index}
                      bg="info"
                      className="me-3 mb-3 p-3 text-dark "
                    >
                      {softSkill}
                    </Badge>
                  ))}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default SkillsPreview;
