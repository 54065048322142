import RB from "../../assets/RBIcon.png";
import score from "../../assets/score.png";
import job from "../../assets/job.png";

export const jobToolCardData = [
  {
    id: 1,
    image: job,
    name: "Jobs",
    description:
      "A list of jobs posted by verified companies will be listed here. Upload your resume to match the job you need and apply for the jobs.",
  },
  {
    id: 2,
    image: score,
    name: "Job Score",
    description:
      "Users will receive a job score before or after applying, along with suggestions to improve their selected resume to better match the job requirements.",
  },
  {
    id: 3,

    image: RB,
    name: "Resume / CV Builder",
    description:
      "You can create a resume from scratch or uploading an existing resume. It also provides suggestions for improvement when comparing the build resume with a job description or job link .",
  },
];

export const empToolCardData = [
  {
    id: 1,
    image: RB,
    name: "Post Jobs",
    description:
      "Establish your company profile and commence job postings tailored to your unique requirements, efficiently connecting with potential candidates.",
  },
  {
    id: 2,
    image: score,
    name: "Top Candidates",
    description:
      "You can see candidates who've applied along with their five different scores, helping you choose the best fit. Plus, we'll list the top matches for your convenience",
  },
  // {
  //   id: 3,
  //   image: job,
  //   name: "Exam App",
  //   description:
  //     " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy",
  // },
];
