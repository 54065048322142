import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams, useNavigate } from "react-router-dom";
import AuthenticationBg from "./AuthenticationBg";
import { url } from "../../data/Constants";
import { validatepassword } from "../../utils/Validation";
import { Form, Button } from "react-bootstrap";
import FormInput from "../../ui/common/FormInput";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [passwordType, setPasswordType] = useState("password");
  const [passwordType1, setPasswordType1] = useState("password");
  const [isLoading, setIsLoading] = useState(false);
  const [passwordReset, setPasswordReset] = useState(false);
  const [resetData, setResetData] = useState({
    password: "",
    password2: "",
  });

  const [error, setError] = useState({
    password: "",
    password2: "",
  });

  const { uid, token } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${url}auth/password-reset/${uid}/${token}/`
        );
        const { success, message } = response.data;

        if (success) {
          // setSuccess(true);
          setIsLoading(false);
        } else {
          alert(message);
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
        alert("An error occurred while processing the request.");
      }
    };

    fetchData();
  }, [uid, token]);

  const togglePassword1 = () => {
    if (passwordType1 === "password") {
      setPasswordType1("text");
      return;
    }
    setPasswordType1("password");
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const handleChangeResetPassword = (e) => {
    if (e && e.target) {
      const { name, value } = e.target;
      let isValid = true;
      let errorMessage = "";
      switch (name) {
        case "password2":
          isValid = value === resetData.password;
          errorMessage = isValid ? "" : "Passwords do not match";
          break;
        case "password":
          isValid = validatepassword(value);
          errorMessage = isValid
            ? ""
            : "Password should be at least 8 characters which include numbers and letters";
          break;
        default:
          break;
      }
      setError((prevErrors) => ({
        ...prevErrors,
        [name]: isValid ? "" : errorMessage,
      }));

      setResetData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleSubmitResetPassword  = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const requestData = {
        password: resetData.password,
        password2: resetData.password2,
        token: token,
        uidb64: uid,
      };
      const response = await axios.patch(
        `${url}auth/password-reset-complete/`,
        requestData
      );
      alert("Password has been changed successfully");
      navigate("/");
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <AuthenticationBg  subheading="New Password"
    heading="Reset Your Password"
    subheading2="Set a new password and begin again!">
      <Form className="mt-3">
        <FormInput
          type={passwordType}
          name="password"
          toggle={togglePassword}
          error={error.password}
          form="pw"
          className="mb-3"
          label="Password"
          placeholder="**********"
          required="required"
          onChange={handleChangeResetPassword}
          value={resetData.password}
        />
        <FormInput
          form="pw"
          className="mb-3"
          name="password2"
          label="Confirm Password"
          type={passwordType1}
          value={resetData.password2}
          toggle={togglePassword1}
          placeholder="**********"
          required="required"
          onChange={handleChangeResetPassword}
          error={error.password2}
        />
        <Button
          onClick={handleSubmitResetPassword}
          variant="dark"
          className=" mt-2 w-100"
          type="submit"
          disabled={
            Object.values(resetData).some((value) => value === "") || isLoading
          }
        >
          Save New Password
        </Button>
      </Form>
    </AuthenticationBg>
  );
};

export default ResetPassword;
