import React, { useState } from "react";
import classes from "./Jobsearch.module.css";
import classes1 from "../../home/Home.module.css";
import { FiSearch } from "react-icons/fi";
import { IoBagHandleOutline } from "react-icons/io5";
import { BsGrid3X3Gap } from "react-icons/bs";
import { CiLocationOn } from "react-icons/ci";
import { Row, Col, Placeholder, Button } from "react-bootstrap";
import { useJobContext } from "../../../provider/JobContext";
import SearchSelect from "../../../ui/common/SearchSelect";
import CustomSelect from "./CustomSelect";
import { sector } from "../../../data/JobData";

const JobFilter = ({ isLoading }) => {
  const {
    jobsCount,
    handleChangeFilter,
    filters,
    fetchJobs,
    fetchJobsExt,
    selectedJob,
    exJobsCount,
    searchFilters,
    fetchExfilters,
    setSearchFilters,
    fetchfilters,
  } = useJobContext();

  const [suggestions, setSuggestions] = useState({
    job_location: [],
    company_name: [],
    job_title: [],
    job_role: [],
  });

  const [loading, setLoading] = useState(false);

  const handleInputChange = async (e) => {
    const { value, name } = e.target;

    setSearchFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));

    if (value.length >= 1) {
      setLoading(true);
      let response = [];
      if (selectedJob === "E") {
        response = await fetchfilters(value, name);
      } else {
        response = await fetchExfilters(value, name);
      }
      setSuggestions((prevSuggestions) => ({
        ...prevSuggestions,
        [name]: response,
      }));
      setLoading(false);
    } else {
      setSuggestions((prevSuggestions) => ({
        ...prevSuggestions,
        [name]: [],
      }));
    }
  };

  const handleOptionSelect = (option, name) => {
    setSearchFilters((prevFilters) => ({
      ...prevFilters,
      [name]: option,
    }));
    setSuggestions((prevSuggestions) => ({
      ...prevSuggestions,
      [name]: [],
    }));
  };

  const fetchJobsList = () => {
    if (selectedJob === "E") {
      fetchJobs(1);
    } else {
      fetchJobsExt(1);
    }
  };

  return (
    <div className={classes.filterbanner + " mt-2 mb-4"}>
      <div className={classes.filterblock + " text-center"}>
        {isLoading ? (
          <Placeholder as="span" animation="glow">
            <Placeholder
              bg="primary"
              as="div"
              size="lg"
              sm={5}
              className="mb-2"
              style={{ height: "30px" }}
            />
          </Placeholder>
        ) : (
          <h3 className="fw-bold mb-3">
            {selectedJob === "E" ? (
              <span className={classes1.textbg + " text-primary me-2"}>
                {jobsCount} Jobs
              </span>
            ) : (
              <span className={classes1.textbg + " text-primary me-2"}>
                {exJobsCount} Jobs
              </span>
            )}
            Available Now
          </h3>
        )}
        <div
          className={
            classes1.smtext +
            " text-secondary d-flex align-items-center justify-content-center "
          }
        >
          <div style={{ maxWidth: "500px", fontWeight: "500" }}>
            Discover exciting opportunities for career growth and find the
            perfect match for your skills.
          </div>
        </div>

        <div className={classes.filterform}>
          <Row className="mt-2">
            {/* <Col sm={12} lg={3} className="mb-2 d-flex align-items-center">
              <IoBagHandleOutline className="text-secondary" />
              <div className="flex-grow-1 w-100">
                <CustomSelect
                  options={sector}
                  handleChange={handleChangeFilter("sector")}
                  selectedvalue={filters.sector}
                  fontSize="14px"
                />
              </div>
            </Col> */}
            <Col sm={12} lg={5} className="mb-2 ">
              <div className="d-flex align-items-center flex-grow-1 w-100">
                <CiLocationOn className="text-dark fw-lighter me-2" />
                <SearchSelect
                  name="job_location"
                  placeholder="Location"
                  value={searchFilters.job_location}
                  handleChange={handleInputChange}
                  options={suggestions.job_location}
                  onOptionSelect={(option) =>
                    handleOptionSelect(option, "job_location")
                  }
                  loading={loading}
                />
              </div>
            </Col>
            <Col sm={12} lg={5} className="mb-2 ">
              <div className="d-flex align-items-center flex-grow-1 w-100">
                <BsGrid3X3Gap className="text-secondary fw-lighter me-2" />
                {selectedJob === "E" ? (
                  <SearchSelect
                    name="job_role"
                    placeholder="Enter  your  Job  Role ..."
                    value={searchFilters.job_role}
                    handleChange={handleInputChange}
                    options={suggestions.job_role}
                    onOptionSelect={(option) =>
                      handleOptionSelect(option, "job_role")
                    }
                    loading={loading}
                  />
                ) : (
                  <SearchSelect
                    name="job_title"
                    placeholder="Enter your job role"
                    value={searchFilters.job_title}
                    handleChange={handleInputChange}
                    options={suggestions.job_title}
                    onOptionSelect={(option) =>
                      handleOptionSelect(option, "job_title")
                    }
                    loading={loading}
                  />
                )}
              </div>
            </Col>
            <Col
              sm={12}
              lg={2}
              style={{ fontSize: "14px", cursor: "pointer" }}
              className="mb-2 d-flex align-items-center justify-content-center"
            >
              <Button
                className="d-flex align-items-center"
                onClick={fetchJobsList}
              >
                <FiSearch />
                <span className=" ps-1" style={{ fontSize: "13px" }}>
                  {" "}
                  Search
                </span>
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default JobFilter;
