import React from "react";
import { Row, Col } from "react-bootstrap";
import jobclasses from "./LatestJobs.module.css";
import { IoIosTimer } from "react-icons/io";
import { IoLocationOutline } from "react-icons/io5";
import logo from "../../../assets/company.png";

const LatestJobsCard = ({ job }) => {
  const truncateText = (text, maxLength) => {
    if (text.length > 0 && text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  return (
    <div className={jobclasses.jobcard}>
      {job.is_fresher ? (
        <div className={jobclasses.ribbon + " bg-success"}>Fresher</div>
      ) : (
        <div className={jobclasses.ribbon + " bg-warning"}>Experienced</div>
      )}
      <div className="aligncardcenter d-flex flex-column justify-content-center h-100">
        <Row className={jobclasses.cardimggrid + " mt-3"}>
          <Col lg={3} className={jobclasses.imagebox}>
            <img
              onError={(e) => {
                e.target.src = logo;
              }}
              src={job.company_logo || logo}
              alt="company logos"
            />
          </Col>
          <Col lg={9} className={jobclasses.rightinfo}>
            <a href={job.company_link} target="_blank" rel="noopener">
              {job.company_name}
            </a>
            <span className="fw-normal">
              <IoLocationOutline
                className="me-1"
                style={{ marginBottom: "2px", fontSize: "13px" }}
              />
              {job.job_location}
            </span>
          </Col>
        </Row>
        <div className={jobclasses.cardimggriddet}>
          <h6 className="text-dark fw-bold">
            {truncateText(job.job_title, 50)}
          </h6>
          <Row className="mt-lg-4 d-flex align-items-center">
            <Col lg={6} sm={6}>
              <div className={jobclasses.jobicons}>
                <span>
                  <IoIosTimer
                    className="me-1 fw-light"
                    style={{ marginBottom: "4px", fontSize: "14px" }}
                  />
                  {job.job_date_weeks_}
                </span>
              </div>
            </Col>
            <Col lg={6} sm={6}>
              <a href={job.job_link} target="_blank" rel="noopener">
                <div
                  className={jobclasses.applybtncard + " px-4 mt-1 text-center"}
                >
                  Apply Job
                </div>
              </a>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default LatestJobsCard;
