import React from "react";
import {  Placeholder, Stack } from "react-bootstrap";
import classes from "./Jobsearch.module.css";

const JobCardskelton = () => {
  return (
   
        <div className="mb-3">
          <div className={classes.jobcard}>
            <Placeholder
              as="div"
              animation="glow"
              style={{ padding: "40px 20px 15px 20px" }}
            >
              <Stack direction="horizontal" gap={3}>
                <Placeholder
                  bg="secondary"
                  style={{
                    borderRadius: "10px",
                    height: "50px",
                    width: "50px",
                  }}
                />
                <Placeholder bg="secondary" as="div" size="lg" sm={8} />
              </Stack>
              <Placeholder
                bg="secondary"
                as="div"
                size="sm"
                sm={11}
                className="ms-1 mt-4"
              />
              <Placeholder
                bg="secondary"
                as="div"
                size="xs"
                sm={6}
                className="ms-1 mt-3"
              />
              <Placeholder
                bg="secondary"
                as="div"
                size="xs"
                sm={12}
                className="ms-1 mt-4"
              />
              <Placeholder
                bg="secondary"
                as="div"
                size="xs"
                sm={12}
                className="ms-1 mt-2"
              />
              <Placeholder
                bg="secondary"
                as="div"
                size="xs"
                sm={4}
                className="ms-1 mt-2"
              />
              <Stack direction="horizontal" gap={3} className="mt-4 ms-1">
                <Placeholder
                  bg="secondary"
                  sm={2}
                  style={{ borderRadius: "5px", height: "30px" }}
                />{" "}
                <Placeholder
                  bg="secondary"
                  sm={3}
                  style={{ borderRadius: "5px", height: "30px" }}
                />
              </Stack>
              <div className="text-end mt-"> <Placeholder
                  bg="secondary"
                  sm={5}
                  style={{ borderRadius: "5px", height: "45px" }}
                /></div>
            </Placeholder>
          </div>
        </div>
  );
};

export default JobCardskelton;
