import React, { createContext, useContext, useState } from "react";
import axios from "axios";
import { url } from "../data/Constants";
import { useAuthContext } from "./AuthContext";

const ArchiveContext = createContext();

export const ArchiveProvider = ({ children }) => {
  const { headers } = useAuthContext();
  const [selectedScore, setSelectedScore] = useState({
    value: "-score",
    label: "High to Low",
  });
  const [selectedSort, setSelectedSort] = useState({
    value: "-created_at",
    label: " Newest Post",
  });

  const [selectedJob, setSelectedJob] = useState("IN");
  const [archivesData, setArchivesData] = useState([]);
  const [archiveCount, setarchiveCount] = useState(0);
  const [archiveLoading, setArchiveLoading] = useState(false);

  const handleCheckboxChange = (value) => {
    setSelectedJob(value);
    // console.log("selectedJob______", value);
  };

  const handleChangeSort = (selectedOption) => {
    setSelectedSort(selectedOption);
  };
  const handleChangeScore = (selectedOption) => {
    setSelectedScore(selectedOption);
  };

  const onResetFilterArchive = () => {
    setSelectedSort({
      value: "-created_at",
      label: " Newest Post",
    });
    setSelectedScore({
      value: "-score",
      label: "High to Low",
    });
    setSelectedJob("");
  };

  const fetchArchives = async () => {
    setarchiveCount(0);
    const archiveurl = `${url}user/archives?jobListing=${selectedJob}&ordering=${selectedScore.value},${selectedSort.value}`;
    setArchiveLoading(true);
    try {
      const response = await axios.get(archiveurl, { headers });
      // console.log(response.data);

      if (response.data && response.data.count > 0) {
        const data = response.data;
        // console.log("response from archives ", data);
        setArchivesData(data.results);
        setarchiveCount(data.count);
        setArchiveLoading(false);
      } else {
        setArchivesData([]);
        setArchiveLoading(false);
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.data && err.response.data.error) {
        console.log(err.response.data.error);
        setArchiveLoading(false);
      } else {
        alert("something went wrong");
        setArchiveLoading(false);
      }
      setArchiveLoading(false);
      return [];
    }
  };

  const contextValue = {
    selectedScore,
    handleChangeScore,
    selectedSort,
    handleChangeSort,
    selectedJob,
    handleCheckboxChange,
    archivesData,
    archiveCount,
    archiveLoading,
    fetchArchives,onResetFilterArchive
  };
  return (
    <ArchiveContext.Provider value={contextValue}>
      {children}
    </ArchiveContext.Provider>
  );
};

export const useArchiveContext = () => useContext(ArchiveContext);
