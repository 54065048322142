import React from "react";
import classes from "./ResumeFormData.module.css";
import { Row, Col } from "react-bootstrap";
import TagsInput from "../../resumeUI/TagsInput";
import { useResumeBuilderContext } from "../../../../provider/ResumeBuilderContext";
import AccordionField from "../../resumeUI/AccordionField";

const Achievements = () => {
  const { resumeData, handleResumeFormChange } = useResumeBuilderContext();

  const handleSelectedTags = (tags) => {
    const updatedResumeData = {
      ...resumeData,
      achievement: tags.map((tag) => ({ achievement: tag })),
    };

    handleResumeFormChange({
      target: {
        name: "achievement",
        value: updatedResumeData.achievement,
      },
    });
  };

  const handleTagRemoval = (indexToRemove) => {
    const updatedTags = resumeData.achievement.filter(
      (_, index) => index !== indexToRemove
    );

    handleResumeFormChange({
      target: {
        name: "achievement",
        value: updatedTags,
      },
    });
  };
  return (
    <div>
      <AccordionField heading="Achievements" subheading="Did you achieve any targets or earn a leadership role ? Make them shine in this section.">
          <div>
            <Row>
              <Col>
                <TagsInput
                  name="achievement"
                  selectedTags={handleSelectedTags}
                  handleTagRemoval={handleTagRemoval}
                  tags={
                    resumeData.achievement.some(
                      (ach) => ach.achievement !== null
                    )
                      ? resumeData.achievement.map((ach) => ach.achievement)
                      : []
                  }
                  placeholder="+ Add  achievements and press enter"
                />
              </Col>
            </Row>
        </div>
      </AccordionField>
    </div>
  );
};

export default Achievements;
