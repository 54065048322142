import React from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { useProfileContext } from "../../../provider/ProfileContext";

const ProfileModal = (props) => {
  const { isEditingProfile, addingData, deletingData } = useProfileContext();

  return (
    <Modal
      backdrop="static"
      keyboard={false}
      size="lg"
      show={props.show}
      onHide={props.handleClose}
    >
      {isEditingProfile ? (
        <></>
      ) : (
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body className="p-4">{props.children}</Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-primary"
          onClick={props.handleClose}
          disabled={deletingData || addingData || isEditingProfile}
        >
          Cancel
        </Button>
        {props.formtype === "add" ? (
          <Button
            variant="primary"
            onClick={props.handleSubmitChange}
            disabled={
              // Object.values(props.data).some((value) => value === "") ||
              addingData
            }
          >
            {addingData ? (
              <span>
                Saving
                <Spinner className="ms-1" size="sm" />
              </span>
            ) : (
              "Save "
            )}
          </Button>
        ) : (
          <>
            {isEditingProfile ? (
              <Button variant="primary">
                <span>
                  Updating
                  <Spinner className="ms-1" size="sm" />
                </span>
              </Button>
            ) : (
              <Button
                variant="primary"
                onClick={props.handleSubmitChange}
                disabled={deletingData}
              >
                Save Changes
              </Button>
            )}
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ProfileModal;
