import React, { useContext, useEffect, useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { Row, Col, Button, Container, Tab, Nav } from "react-bootstrap";
import classes from "../../../../candidate/profile/Profile.module.css";
import { RecruiterCandidateListContext } from "../../../../../provider/RecruiterCandidateListContext";
import { FaRegEdit } from "react-icons/fa";
import RecruiterBody from "../../../layouts/RecruiterBody";
import { useProfileContext } from "../../../../../provider/ProfileContext";
import { HiDevicePhoneMobile } from "react-icons/hi2";
import { MdOutlineEmail } from "react-icons/md";
import ProfileTabs from "../../../../candidate/profile/ProfileTabs";
import Boxlayout from "../../../../home/layouts/Boxlayout";
import RecruiterHeader from "../../../layouts/RecruiterHeader";
import Footer from "../../../../home/layouts/Footer";
import { BsBuildings, BsFillPersonLinesFill } from "react-icons/bs";
import { MdOutlineWorkOutline } from "react-icons/md";
import PersonalInfo from "../../../../candidate/profile/PersonalInfo";
import OtherInfo from "../../../../candidate/profile/OtherInfo";
import Loading from "../../../../../ui/common/Loading";

const CandidateView = () => {
  const location = useLocation();
  const { candidateId } = useParams();
  const { fetchSingleCandidate, singleCandidate, fetchProfile, isLoading } =
    useContext(RecruiterCandidateListContext);
  const candidateType = location.state?.candidateType;

  useEffect(() => {
    if (candidateId && candidateType === "internal") {
      fetchProfile(candidateId);
    } else {
      fetchSingleCandidate(candidateId);
    }
  }, [candidateId]);

  const tabs = [
    {
      key: "first",
      label: "About me",
      icon: <BsFillPersonLinesFill />,
      component: <PersonalInfo />,
    },
    {
      key: "second",
      label: "Other Info",
      icon: <MdOutlineWorkOutline />,
      component: <OtherInfo />,
    },
  ];

  const [activeTab, setActiveTab] = useState("first");

  const handleTabClick = (eventKey) => {
    setActiveTab(eventKey);
  };
  return (
    <>
      <RecruiterHeader />
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div
            className={classes.boxheadsingle}
            style={{ backgroundColor: "#f2f6fd" }}
          >
            <Container>
              <Row>
                <Col lg={8}>
                  <div className="text-center">
                    <div className="d-flex flex-column d-lg-flex flex-lg-row justify-content-lg-between align-items-lg-center ">
                      <h4
                        className="pt-3"
                        style={{
                          fontSize: "30px",
                          fontWeight: "700",
                        }}
                      >
                        {singleCandidate.name
                          ? singleCandidate.name
                          : `${singleCandidate.proffirstName} ${singleCandidate.proflastName}`}
                      </h4>
                    </div>

                    <div className={classes.profileinfo + " my-2 text-muted"}>
                      <div className="fw-semibold d-lg-flex flex-lg-row justify-content-lg-between align-items-lg-center">
                        {singleCandidate.title
                          ? singleCandidate.title
                          : singleCandidate.profjobTitle}
                      </div>

                      <div className="me-3 my-2 fw-normal  d-flex flex-column d-lg-flex flex-lg-row align-items-lg-center ">
                        <div className="mb-1 mb-lg-0">
                          <HiDevicePhoneMobile className="me-lg-3 fs-5 " />
                          {singleCandidate.profphone}
                        </div>
                        <div>
                          <MdOutlineEmail className="mx-lg-3 fs-5 mb-1" />
                          {singleCandidate.profemail || singleCandidate.email}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={2}></Col>
              </Row>
            </Container>
          </div>
          <Container>
            <Row className=" mt-5">
              <div>
                <Tab.Container id="profile-tabs" defaultActiveKey="first">
                  <Row>
                    <Col sm={12} className="mb-4">
                      <Nav className="flex-row gap-3">
                        {tabs.map((tab) => (
                          <Nav.Item key={tab.key}>
                            <Nav.Link
                              eventKey={tab.key}
                              className={classes.customNavlink}
                              style={{
                                color:
                                  activeTab === tab.key ? "#05264E" : "#4F5E64",
                                fontSize: "16px",
                                border:
                                  activeTab === tab.key
                                    ? "1px solid #B4C0E0"
                                    : "1px solid #eff2fb",
                              }}
                              onClick={() => handleTabClick(tab.key)}
                            >
                              {tab.icon} <span>{tab.label}</span>
                            </Nav.Link>
                          </Nav.Item>
                        ))}
                      </Nav>
                    </Col>
                    <Col sm={12} className="border-bottom mb-3 mt-2"></Col>
                    <Col sm={12} className="mt-3">
                      <Tab.Content>
                        {tabs.map((tab) => (
                          <Tab.Pane key={tab.key} eventKey={tab.key}>
                            {tab.component}
                          </Tab.Pane>
                        ))}
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </div>
            </Row>
            <Boxlayout />
          </Container>
          <Footer />
        </>
      )}
    </>
  );
};

export default CandidateView;
