import React from "react";
import classes from "../jobsearch/Jobsearch.module.css";
import classes1 from "../../home/Home.module.css";
import { Button, Stack, Badge } from "react-bootstrap";
import { FaRegBookmark, FaBookmark } from "react-icons/fa";
import { SlHandbag } from "react-icons/sl";
import { IoIosTimer } from "react-icons/io";
import { LuIndianRupee } from "react-icons/lu";
import { IoLocationOutline } from "react-icons/io5";
import { getLabelByValue } from "../../../utils/jobutils";
import { MdVerified } from "react-icons/md";
import { experience, jobtype, salary, workmode } from "../../../data/JobData";

const JobArchive = ({ archive, onClick }) => {
  return (
    <div className=" mb-3" onClick={onClick}>
      <div className={classes.jobcard}>
        <div className={classes.cardimggrid}>
          <div className={classes.imgbox + " me-4"}>
            <img src={archive.company_logo} alt="company logos" />
          </div>
          <div className={classes.rightinfo} style={{ fontSize: "16px" }}>
            <a href={archive.company_link} target="_blank" className="text-dark">
              {archive.company_name}
            </a>
            {archive.company_verify === true ? (
              <MdVerified className="text-success ms-1" />
            ) : (
              ""
            )}
            <span className="ms-lg-4 fst-italic d-none d-lg-inline-flex ">
              Compatability Score :
              <Badge className="ms-2">{`${archive.score}%`}</Badge>
            </span>
            {/* <div className={classes1.smtext + " text-secondary fw-normal"}>
              <IoLocationOutline
                className="me-1"
                style={{ marginBottom: "2px", fontSize: "14px" }}
              />
              {archive.job_location}
            </div> */}
            {/* Score :
            <Badge className="d-lg-none ms-1">{`${archive.score}%`}</Badge> */}
          </div>
          {/* <div className="ms-auto mb-2">
            <Button className="" variant="bg-white text-primary fw-semibold fst-italic"><u>View more</u></Button>
          </div> */}
        </div>
        <div className={classes.cardblock}>
          <h6
            className="text-dark mt-1"
            style={{
              fontSize: "20px",
              lineHeight: "1.5",
              fontWeight: "700",
            }}
          >
            {archive.role}
          </h6>
          <Stack
            className=" text-light fw-normal  d-lg-flex"
            direction="horizontal"
            gap={3}
            style={{ fontSize: "12px" }}
          >
            <div className="rounded ">
              <IoIosTimer
                className="me-1 fw-light"
                style={{ marginBottom: "2px", fontSize: "14px" }}
              />
              <span>{getLabelByValue(archive.job_type, jobtype)} / </span>
              <span>{getLabelByValue(archive.Mode, workmode)}</span>
            </div>

            <div className="rounded">
              <SlHandbag className="me-1" style={{ marginBottom: "4px" }} />
              <span>{getLabelByValue(archive.experience, experience)}</span>
            </div>
            <div lg={4} sm={4} className="rounded">
              <LuIndianRupee
                style={{ marginBottom: "3px", fontSize: "13px" }}
              />
              <span>{getLabelByValue(archive.salary, salary)}</span>
            </div>
          </Stack>
          <div className="mt-3 fw-semibold" style={{ fontSize: "14px" }}>
            {" "}
            Resume Used :{" "}
            <span className="ms-1 text-primary">{archive.resumeName}</span>
          </div>
          {/* <div className="mt-3 ">
            <div>
              {archive.skills
                .split(",")
                .slice(0, 3)
                .map(
                  (skill, index) =>
                    skill.trim() !== "" && (
                      <div
                        key={index}
                        className={`${classes1.smtext} mb-2 px-2 me-2 py-1 text-secondary`}
                        style={{
                          backgroundColor: "#EFF3FC",
                          borderRadius: "3px",
                          display: "inline-block",
                          fontWeight: "500",
                        }}
                      >
                        {skill.trim()}
                      </div>
                    )
                )}
              {archive.skills_req.split(",").length > 3 && (
                <i className={classes1.smtext + " text-secondary"}>more....</i>
              )}
            </div>
          </div> */}

          <div className={classes1.xstext + " mt-2 text-secondary text-end"}>
            <i>applied {archive.created_at}</i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobArchive;
