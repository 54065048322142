import React, { useEffect, useState } from "react";
import AuthenticationBg from "./AuthenticationBg";
import FormInput from "../../ui/common/FormInput";
import { Button, Form } from "react-bootstrap";
import { validateEmail } from "../../utils/Validation";
import axios from "axios";
import { url } from "../../data/Constants";
import { useNavigate } from "react-router-dom";
import Loading from "../../ui/common/Loading";

const ResendMail = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState({ email: "" });
  const [error, setError] = useState({
    email: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
  setTimeout(() => {
    setLoading((loading) => !loading);
  }, 1000)},[]);

  const OnResendMail = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let isValid = true;
    let errorMessage = "";
    if (name === "email") {
      isValid = validateEmail(value);
      errorMessage = isValid ? "" : "Invalid email";
    }
    setError((prevErrors) => ({
      ...prevErrors,
      [name]: isValid ? "" : errorMessage,
    }));
    setEmail((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const keyword = "R";

  const handleSubmitResendMail = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const data = email;
    // console.log(data)
    try {
      const response = await axios.post(`${url}auth/resend-email`, data);
      // console.log(response);
      localStorage.setItem("email", data);
      setIsLoading(false);
      navigate("/verifyemail", { state: { value: email.email, key: keyword } });
    } catch (error) {
      console.log(error);
      // console.log(error.response.status);
      if (error.response && error.response.status === 400) {
        alert(
          "The email you entered doesn't exist! Please check the entered mail."
        );
        setIsLoading(false);
      }
    }
  };

  if (loading) {
    return (<Loading/>);
  } else {
    return (
      <AuthenticationBg
        subheading="Can't find email"
        heading="Resend Email"
        subheading2="Enter email address associated with your account and we'll send you a link to activate your account"
      >
        <Form className="mt-3">
          <FormInput
            label="Email"
            className="mb-3"
            type="email"
            placeholder="Email"
            name="email"
            value={email.email}
            onChange={OnResendMail}
            error={error.email}
            required="required"
          />
          <Button
            onClick={handleSubmitResendMail}
            variant="dark"
            className=" mt-2 w-100"
            type="submit"
            disabled={
              Object.values(email).some((value) => value === "") || isLoading
            }
          >
            Resend Mail
          </Button>
        </Form>
      </AuthenticationBg>
    );
  }
};

export default ResendMail;
