import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { url } from "../data/Constants";
import { useAuthContext } from "./AuthContext";

const JobContext = createContext();

export const JobProvider = ({ children }) => {
  const { headers } = useAuthContext();
  const storedJob = localStorage.getItem("job");
  const user = localStorage.getItem("userData");
  const [exJobData, setExJobData] = useState([]);
  const [exJobsCount, setExJobsCount] = useState(0);
  const [jobData, setJobData] = useState([]);
  const [job, setJob] = useState([]);
  const [similarJobs, setSimilarJobs] = useState([]);
  const [otherJobs, setOtherJobs] = useState([]);
  const [jobsCount, setJobsCount] = useState(0);
  const [scores, setScores] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCheckingScore, setIsCheckingScore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterOptions, setfilterOptions] = useState([]);
  const [selectedJob, setSelectedJob] = useState(storedJob || "E");
  const [show, setShow] = useState(false);
  console.log(selectedJob);

  const [filters, setFilters] = useState({
    jobType: { value: "", label: "Job Type" },
    workMode: { value: "", label: "Mode of Work" },
    salary: { value: "", label: "Expected Salary" },
    experience: { value: "", label: "Experience" },
    sector: { value: "", label: "Sector" },
    sort: { value: "", label: "Newest Post" },
  });

  const [searchFilters, setSearchFilters] = useState({
    job_location: "",
    company_name: "",
    job_title: "",
    job_role: "",
  });

  const [suggestions, setSuggestions] = useState({
    job_location: [],
    company_name: [],
    job_title: [],
    job_role: [],
  });

  useEffect(() => {
    const storedJob = localStorage.getItem("job");
    if (storedJob) {
      setSelectedJob(storedJob);
    } else {
      localStorage.setItem("job", "X");
    }
  }, []);

  const handleJobTypeChange = (key) => {
    if (key === "E") {
      setSelectedJob("E");
      fetchJobs(1);
    } else {
      setSelectedJob("X");
      fetchJobsExt(1);
    }
  };

  const handleChangeFilter = (name) => (selectedOption) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: selectedOption,
    }));
    // if (selectedJob === "E") {
    //   fetchJobs(1);
    // } else {
    //   fetchJobsExt(1);
    // }
  };

  const onResetFilter = () => {
    setFilters({
      jobType: { value: "", label: "Job Type" },
      workMode: { value: "", label: "Mode of Work" },
      salary: { value: "", label: "Expected Salary" },
      experience: { value: "", label: "Experience" },
      sector: { value: "", label: "Sector" },
      sort: { value: "", label: "Newest Post" },
    });
    setSearchFilters({
      job_location: "",
      company_name: "",
      job_title: "",
      job_role: "",
    });
    if (selectedJob === "E") {
      fetchJobs(1);
    } else {
      fetchJobsExt(1);
    }
  };

  const handleCheckboxChange = (name) => (value) => {
    setFilters((prevFilters) => ({
        ...prevFilters,
        [name]: value,
    }));
        // if (selectedJob === "E") {
        //     fetchJobs(1);  
        // } else {
        //     fetchJobsExt(1);  
        // }
};


  const fetchfilters = async (value, name) => {
    const params = {
      job_location: "",
      job_role: "",
      company_name: "",
    };

    if (name in params) {
      params[name] = value;
    }

    if (user === "null") {
      params.free = "free";
    }

    Object.keys(params).forEach((key) => {
      if (!params[key]) {
        delete params[key];
      }
    });

    const filterurl = `${url}user/job/job_search`;

    try {
      const response =
        user === "null"
          ? await axios.get(filterurl, { params })
          : await axios.get(filterurl, { headers, params });
      return response.data.values;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const fetchExfilters = async (value, name) => {
    const params = {
      job_location: "",
      job_title: "",
      company_name: "",
    };

    if (name in params) {
      params[name] = value;
    }

    if (user === "null") {
      params.free = "free";
    }

    Object.keys(params).forEach((key) => {
      if (!params[key]) {
        delete params[key];
      }
    });

    const filterurl = `${url}user/job/external_job_search`;
    try {
      const response =
        user === "null"
          ? await axios.get(filterurl, { params })
          : await axios.get(filterurl, { headers, params });
      return response.data.values;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const fetchJobs = async (page, free) => {
    const params = {
      job_type: filters.jobType.value,
      work_mode: filters.workMode.value,
      salary: filters.salary.value,
      experience_req: filters.experience.value,
      sort_ascend: filters.sort.value,
      job_sector: filters.sector.value,
      job_location: searchFilters.job_location,
      job_role: searchFilters.job_role,
      company_name:searchFilters.company_name,
      page: page,
    };

    Object.keys(params).forEach((key) => {
      if (!params[key]) {
        delete params[key];
      }
    });
    if (user === "null" || free) {
      params.free = "free";
    }
    const joburl = `${url}user/job/list`;
    setIsLoading(true);
    try {
      const response =
        user === "null"
          ? await axios.get(joburl, { params })
          : await axios.get(joburl, { headers, params });
      // console.log(response.data);
      if (response.data && response.data.job_count > 0) {
        const data = response.data;
        setJobData(data.values);
        setJobsCount(data.job_count);
        let pages = page;
        // if (data.next_page !== "null") {
        //   pages = parseInt(data.next_page) - 1;
        // } else if (data.previous_page !== "null") {
        //   pages = parseInt(data.previous_page) + 1;
        // } else {
        //   pages = 0;
        // }
        setCurrentPage(pages);
        setIsLoading(false);
      } else {
        const data = response.data;
        setJobData([]);
        setJobsCount(data.job_count);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.data && err.response.data.error) {
        setIsLoading(false);
      } else {
        // alert("something went wrong");
        setIsLoading(false);
      }
      setIsLoading(false);
      return [];
    }
  };

  const fetchJobsExt = async (page, free) => {
    const params = {
      job_type: filters.jobType.value,
      work_mode: filters.workMode.value,
      salary: filters.salary.value,
      experience_req: filters.experience.value,
      sort_ascend: filters.sort.value,
      job_sector: filters.sector.value,
      job_location: searchFilters.job_location,
      job_title: searchFilters.job_title,
      company_name:searchFilters.company_name,
      page: page,
    };

    if (user === "null" || free) {
      params.free = "free";
    }
    Object.keys(params).forEach((key) => {
      if (!params[key]) {
        delete params[key];
      }
    });
    const joburl = `${url}user/job/list_external`;
    setIsLoading(true);
    try {
      const response =
        user === "null"
          ? await axios.get(joburl, { params })
          : await axios.get(joburl, { headers, params });
      // console.log(response.data);
      if (response.data && response.data.job_count > 0) {
        const data = response.data;
        setExJobData(data.values);
        setExJobsCount(data.job_count);
        let pages = page;
        // if (data.next_page !== "null") {
        //   pages = parseInt(data.next_page) - 1;
        // } else if (data.previous_page !== "null") {
        //   pages = parseInt(data.previous_page) + 1;
        // } else {
        //   pages = 0;
        // }
        setCurrentPage(pages);
        setIsLoading(false);
      } else {
        const data = response.data;
        setExJobData([]);
        console.log(data.values);
        setExJobsCount(data.job_count);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.data && err.response.data.error) {
        setIsLoading(false);
      } else {
        // alert("something went wrong");
        setIsLoading(false);
      }
      setIsLoading(false);
      return [];
    }
  };

  const fetchSingleJob = async (id) => {
    const params = {
      job_id: id,
    };

    const joburl = `${url}user/job/job_page`;
    setIsLoading(true);
    try {
      const response = await axios.get(joburl, { headers, params });
      // console.log(response.data);
      if (response.data) {
        const data = response.data;
        setJob(data.page_values);
        setSimilarJobs(data.similar_jobs);
        setOtherJobs(data.other_jobs);
        setIsLoading(false);
      } else {
        const data = response.data;
        setJob([]);
        setSimilarJobs([]);
        setOtherJobs([]);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.data && err.response.data.error) {
        setIsLoading(false);
      } else {
        alert("something went wrong");
        setIsLoading(false);
      }
      setIsLoading(false);
      return [];
    }
  };

  const fetchSingleExJob = async (id,free) => {
    const params = {
      job_id: id,
    };
    if (user === "null" || free) {
      params.free = "free";
    }
    Object.keys(params).forEach((key) => {
      if (!params[key]) {
        delete params[key];
      }
    });
    const joburl = `${url}user/job/external_job_page`;
    setIsLoading(true);
    try {
      const response =
      user === "null"
        ? await axios.get(joburl, {  params })
        : await axios.get(joburl, { headers, params });
      // console.log(response.data);
      if (response.data) {
        const data = response.data;
        setJob(data.page_values);
        setSimilarJobs(data.similar_jobs);
        setOtherJobs(data.other_jobs);
        setIsLoading(false);
      } else {
        const data = response.data;
        setJob([]);
        setSimilarJobs([]);
        setOtherJobs([]);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.data && err.response.data.error) {
        setIsLoading(false);
      } else {
        alert("something went wrong");
        setIsLoading(false);
      }
      setIsLoading(false);
      return [];
    }
  };

  const applyJob = async (jobdata) => {
    try {
      let data = new FormData();
      data.append("job", jobdata.jobid);
      data.append("description", jobdata.desc);
      const applyurl = `${url}user/apply/job`;
      const response = await axios.post(applyurl, data, { headers });
      return response;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const onCheckScore = async (scoredata) => {
    let data = new FormData();
    data.append("link", scoredata.link);
    data.append("jobDesc", scoredata.jobDesc);
    data.append("scoreType", scoredata.scoreType);
    data.append("jobListing", scoredata.jobListing);
    data.append("externalJob", scoredata.externalJob);
    data.append("internalJob", scoredata.internalJob);

    // console.log("scoredatain contextttt__________", scoredata);
    try {
      const scoreurl = `${url}user/score`;
      const response = await axios.post(scoreurl, scoredata, { headers });
      if (response && response.status === 201) {
        setScores(response.data);
        // fetchJobs();
        setIsCheckingScore(false);
      } else {
        setScores([]);
        alert("Failed to fetch score");
        // fetchJobs();
        setIsCheckingScore(false);
      }
      return response;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const contextValue = {
    isLoading,
    applyJob,
    onResetFilter,
    fetchJobs,
    jobData,
    setJobData,
    jobsCount,
    setJobsCount,
    onCheckScore,
    scores,
    setScores,
    setIsCheckingScore,
    isCheckingScore,
    handleChangeFilter,
    filters,
    handleCheckboxChange,
    fetchfilters,
    filterOptions,
    setFilters,
    fetchJobsExt,
    fetchSingleJob,
    job,
    similarJobs,
    otherJobs,
    exJobData,
    exJobsCount,
    selectedJob,
    handleJobTypeChange,
    fetchSingleExJob,
    fetchExfilters,
    searchFilters,
    setSearchFilters,
    show,
    setShow,
    suggestions,
    setSuggestions,
    currentPage,
    setCurrentPage,
  };
  return (
    <JobContext.Provider value={contextValue}>{children}</JobContext.Provider>
  );
};

export const useJobContext = () => useContext(JobContext);
