import React from "react";
import classes from "./ResumeFormData.module.css";
import classes1 from "../../resumeUI/Input.module.css";
import { Row, Col, Form } from "react-bootstrap";
import { useResumeBuilderContext } from "../../../../provider/ResumeBuilderContext";
import Input from "../../resumeUI/Input";


const PersonalDetails = () => {
  const { resumeData, handleResumeFormChange, errors } = useResumeBuilderContext();

  return (
    <div  className={classes.resumecontentform}>
      {resumeData.firstName || resumeData.lastName !== null ? (
        <div className={classes.resumeformheading}>
          <span className="px-1"> {resumeData.firstName}</span>
          {resumeData.lastName}
        </div>
      ) : (
        <div className={classes.resumeformheading}>Untitled</div>
      )}
      <div className={classes.resumeformsubheading}>
        How do you want employers to contact you ?
      </div>
      <div>
        <Row>
          <Col>
            <Input
              required="required"
              text="Job Title"
              type="text"
              name="job_title"
              value={resumeData.job_title}
              onChange={handleResumeFormChange}
              error={errors.job_title}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={6}>
            <Input
              required="required"
              text="First Name"
              type="text"
              name="firstName"
              value={resumeData.firstName}
              error={errors.firstName}
              onChange={handleResumeFormChange}
            />
          </Col>
          <Col xs={12} lg={6}>
            <Input
              required="required"
              text="Last Name"
              type="text"
              name="lastName"
              value={resumeData.lastName}
              onChange={handleResumeFormChange}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={6}>
            <Input
              required="required"
              text="Email"
              type="email"
              name="email"
              value={resumeData.email}
              onChange={handleResumeFormChange}
              error={errors.email}
            />
          </Col>
          <Col xs={12} lg={6}>
            <Input
              required="required"
              text="Phone"
              type="tel"
              name="phone"
              pattern="[0-9]*"
              value={resumeData.phone}
              onChange={handleResumeFormChange}
              error={errors.phone}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={6}>
            <Input
              text="Address"
              type="text"
              name="address"
              value={resumeData.address}
              onChange={handleResumeFormChange}
              maxLength="75"
              error={errors.address}
            />
          </Col>
          <Col xs={12} lg={6}>
            <Input
              required="required"
              text="Date of Birth"
              type="date"
              name="dob"
              value={resumeData.dob}
              onChange={handleResumeFormChange}
              error={errors.dob}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={6}>
            <Input
              checked={resumeData.gender}
              text="Nationality"
              type="text"
              name="nationality"
              value={resumeData.nationality}
              onChange={handleResumeFormChange}
            />
          </Col>
          <Col xs={12} lg={6}>
            <div className="mt-1">
              <label className={classes1.inputlabel}>
                Gender
                <span className="text-danger ps-1 fw-bold">*</span>
              </label>
              <div
                className={classes1.input +" d-flex " }
                style={{ fontSize: "14px" }}
              >
                <Form.Check
                  checked={resumeData.gender === "F"}
                  inline
                  label="Female"
                  name="gender"
                  type="radio"
                  value="F"
                  id={`inline-gender-1`}
                  onChange={handleResumeFormChange}
                />
                <Form.Check
                  checked={resumeData.gender === "M"}
                  inline
                  label="Male"
                  name="gender"
                  value="M"
                  type="radio"
                  id={`inline-gender-2`}
                  onChange={handleResumeFormChange}
                />
                <Form.Check
                  checked={resumeData.gender === "O"}
                  inline
                  name="gender"
                  label="Others"
                  type="radio"
                  value="O"
                  id={`inline-gender-3`}
                  onChange={handleResumeFormChange}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PersonalDetails;
