import React, { useState } from "react";
import ProfileModal from "../ProfileModal";
import FormInput from "../../../../ui/common/FormInput";
import { Row, Col, Form, InputGroup } from "react-bootstrap";

const ReferenceProfileForm = (props) => {
  return (
    <ProfileModal
      show={props.show}
      handleClose={props.handleClose}
      handleSubmitChange={props.add}
      formtype={props.formtype}
    >
      <Row>
        <Col>
          <FormInput
            name="contact_name"
            type="text"
            placeholder="ex. John Doe"
            label="Contact Name"
            value={props.data.contact_name}
            onChange={props.handleChange}
            error={props.errors.contact_name}
            className="mb-3"
          />
        </Col>
        <Col>
          <FormInput
            name="email"
            type="text"
            placeholder="ex. john@email.com"
            label="Email"
            value={props.data.email}
            onChange={props.handleChange}
            error={props.errors.email}
            className="mb-3"
          />
        </Col>
      </Row>
      <Row>
        <Col >
          <FormInput
            name="company"
            type="text"
            placeholder="ex. Tesla Inc"
            label="Company"
            value={props.data.company}
            onChange={props.handleChange}
            error={props.errors.company}
            className="mb-3"
          />
        </Col>
        <Col>
          <FormInput
            name="desc"
            type="text"
            placeholder="ex. Devops engineer"
            label="Designation"
            value={props.data.desc}
            onChange={props.handleChange}
            error={props.errors.desc}
            className="mb-3"
          />
        </Col>
      </Row>
      <Row>
        <Col >
          <FormInput
            name="country"
            type="text"
            placeholder="ex. India"
            label="Country"
            value={props.data.country}
            onChange={props.handleChange}
            error={props.errors.country}
            className="mb-3"
          />
        </Col>
        <Col>
          <FormInput
            name="phone_number"
            type="text"
            placeholder="ex. 1234567890"
            label="Phone"
            value={props.data.phone_number}
            onChange={props.handleChange}
            error={props.errors.phone_number}
            className="mb-3"
          />
        </Col>
      </Row>
    </ProfileModal>
  );
};

export default ReferenceProfileForm;
