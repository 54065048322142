import React, { useState } from "react";
import { useProfileContext } from "../../../provider/ProfileContext";
import { Badge, Row, Col, Spinner, Button } from "react-bootstrap";
import ProfileModal from "./ProfileModal";
import FormInput from "../../../ui/common/FormInput";
import { CiEdit } from "react-icons/ci";
import { MdDelete } from "react-icons/md";
import { RxCross1 } from "react-icons/rx";

const SkillProfile = () => {

  const {
    profileData,
    handlePersonalChange,
    AddProfileData,
    EditProfileData,
    deletingData,
    DeleteProfileData,
  } = useProfileContext();
  const profileid = localStorage.getItem("profile");
  const [techSkills, setTechSkills] = useState(
    profileData.techskills_prof || []
  );
  const [softSkills, setSoftSkills] = useState(profileData.skill_prof || []);

  const [technical, setTechnical] = useState({
    techSkill: null,
    profile: profileid,
  });
  const [soft, setSoft] = useState({
    softSkill: null,
    profile: profileid,
  });

  const [errors, setErrors] = useState({
    techSkill: "",
    softSkill: "",
  });
  const [showSkill, setShowSkill] = useState(false);
  const handleCloseSkill = () => setShowSkill(false);
  const handleShowSkill = () => {
    setErrors({
      softSkill: "",
    });
    setShowSkill(true);
  };

  const [showTechSkill, setShowTechSkill] = useState(false);
  const handleCloseTechSkill = () => setShowTechSkill(false);
  const handleShowTechSkill = () => {
    setErrors({
      techSkill: "",
    });
    setShowTechSkill(true);
  };

  const [selectedSkillProfile, setSelectedSkillProfile] = useState(null);
  const [selectedTechProfile, setSelectedTechProfile] = useState(null);

  const [editId, setEditid] = useState(null);
  const [showEditSkill, setShowEditSkill] = useState(false);
  const handleCloseEditProfile = () => setShowEditSkill(false);

  const handleShowEdit = (id) => {
    setErrors({
      softSkill: "",
    });
    setEditid(id);
    const selectedProfile = softSkills.find((profile) => profile.id === id);
    setSelectedSkillProfile(selectedProfile);
    setShowEditSkill(true);
  };

  const [showEditTech, setShowEditTech] = useState(false);
  const handleCloseEditProfileTech = () => setShowEditTech(false);
  const handleShowEditTech = (id) => {
    setErrors({
      techSkill: "",
    });
    setEditid(id);
    const selectedProfile = techSkills.find((profile) => profile.id === id);
    setSelectedTechProfile(selectedProfile);
    setShowEditTech(true);
  };

  const handleTechSkillChange = (e) => {
    const { name, value } = e.target;
    setErrors({
      techSkill: "",
    });
    setTechnical((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSoftSkillChange = (e) => {
    const { name, value } = e.target;
    setErrors({
      softSkill: "",
    });
    setSoft((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const addTechSkillData = async () => {
    if (!technical.techSkill) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        techSkill: "Please fill in the required field.",
      }));
      return;
    }
    try {
      const response = await AddProfileData(technical, "technical");
      if (response.status === 201) {
        const newSkill = response.data;
        setTechSkills((prevSkills) => {
          const updatedTechSkills = [...prevSkills, newSkill];
          handlePersonalChange({
            target: { name: "techskills_prof", value: updatedTechSkills },
          });
          return updatedTechSkills;
        });
        setShowTechSkill(false);
        setTechnical({
          softSkill: null,
          profile: profileid,
        });
      }
    } catch (error) {
      console.error("Error adding technical skill:", error);
    }
  };

  const addSoftSkillData = async () => {
    if (!soft.softSkill) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        softSkill: "Please fill in the required field.",
      }));
      return;
    }
    try {
      const response = await AddProfileData(soft, "skill");
      if (response.status === 201) {
        const newSkill = response.data;
        setSoftSkills((prevSkills) => {
          const updatedSoftSkills = [...prevSkills, newSkill];
          handlePersonalChange({
            target: { name: "skill_prof", value: updatedSoftSkills },
          });
          return updatedSoftSkills;
        });
        setShowSkill(false);
        setSoft({
          softSkill: null,
          profile: profileid,
        });
      }
    } catch (error) {
      console.error("Error adding technical skill:", error);
    }
  };

  const handleEditSoftSkillChange = (e) => {
    const { name, value } = e.target;
    setErrors({
      softSkill: "",
    });
    setSelectedSkillProfile((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleEditTechSkillChange = (e) => {
    const { name, value } = e.target;
    setErrors({
      techSkill: "",
    });
    setSelectedTechProfile((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const addEditSkillData = async () => {
    if (!selectedSkillProfile.softSkill) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        softSkill: "Please fill in the required field.",
      }));
      return;
    }
    const updatedProfile = {
      ...selectedSkillProfile,
      profile: profileid,
    };
    const response = await EditProfileData(updatedProfile, "skill", editId);
    if (response.status === 200) {
      const newSkl = response.data;
      setSoftSkills((prevSkill) => {
        const updatedSkill = prevSkill.map((skill) =>
          skill.id === newSkl.id ? newSkl : skill
        );
        handlePersonalChange({
          target: { name: "skill_prof", value: updatedSkill },
        });
        return updatedSkill;
      });
      setShowEditSkill(false);
    }
  };

  const addEditTechSkillData = async () => {
    if (!selectedTechProfile.techSkill) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        techSkill: "Please fill in the required field.",
      }));
      return;
    }
    const updatedProfile = {
      ...selectedTechProfile,
      profile: profileid,
    };
    const response = await EditProfileData(updatedProfile, "technical", editId);
    if (response.status === 200) {
      const newSkl = response.data;
      setTechSkills((prevSkill) => {
        const updatedSkill = prevSkill.map((skill) =>
          skill.id === newSkl.id ? newSkl : skill
        );
        handlePersonalChange({
          target: { name: "techskills_prof", value: updatedSkill },
        });
        return updatedSkill;
      });
      setShowEditTech(false);
    }
  };

  const deleteSkill = async (
    type,
    id,
    updateSkills,
    setSkills,
    setShowEdit
  ) => {
    try {
      const response = await DeleteProfileData(type, id);
      if (response.status === 204) {
        const updatedSkills = updateSkills.filter((skill) => skill.id !== id);
        setSkills(updatedSkills);
        setShowEdit(false);
      }
    } catch (error) {
      console.error("Error deleting skill:", error);
    }
  };

  const handleDeleteTechSkill = () => {
    deleteSkill(
      "technical",
      editId,
      techSkills,
      setTechSkills,
      setShowEditTech
    );
  };

  const handleDeleteSoftSkill = () => {
    deleteSkill("skill", editId, softSkills, setSoftSkills, setShowEditSkill);
  };

  return (
    <>
      <div className="mb-5 pb-2 border-bottom">
        <h4 className=" fw-bold text-dark ">Skills</h4>
        <div className=" fw-bold  mb-3 text-secondary">
          <div className="d-flex align-items-center">
            <div style={{ fontSize: "16px" }}>Technical Skills</div>
            <div
              className="ms-4 border p-1 rounded text-primary fw-semibold"
              style={{ fontSize: "12px", cursor: "pointer" }}
              onClick={handleShowTechSkill}
            >
              + Add more
            </div>
          </div>
          {techSkills && techSkills.length > 0 ? (
            <div className="mt-4">
              {techSkills &&
                techSkills.map((techSkill) => (
                  <Badge
                    key={techSkill.id}
                    bg="info"
                    className="me-3 mb-3 p-3 text-dark "
                  >
                    {techSkill.techSkill}
                    <CiEdit
                      style={{ cursor: "pointer" }}
                      className="ms-2 fs-6 fw-bold"
                      onClick={() => handleShowEditTech(techSkill.id)}
                    />
                  </Badge>
                ))}
            </div>
          ) : (
            <div
              className="mt-4 text-danger fw-normal"
              style={{ fontSize: "14px" }}
            >
              * No skills added
            </div>
          )}
        </div>
        <div className=" fw-bold  mb-3 text-secondary">
          <div className="d-flex align-items-center">
            <div style={{ fontSize: "16px" }}>Soft Skills</div>
            <div
              className="ms-4 border p-1 rounded text-primary fw-semibold"
              style={{ fontSize: "12px", cursor: "pointer" }}
              onClick={handleShowSkill}
            >
              + Add more
            </div>
          </div>
          {softSkills && softSkills.length > 0 ? (
            <div className="mt-4">
              {softSkills &&
                softSkills.map((softSkill) => (
                  <Badge
                    key={softSkill.id}
                    bg="info"
                    className="me-3 mb-3 p-3 text-dark "
                  >
                    {softSkill.softSkill}
                    <CiEdit
                      style={{ cursor: "pointer" }}
                      className="ms-2 fs-6 fw-bold"
                      onClick={() => handleShowEdit(softSkill.id)}
                    />
                  </Badge>
                ))}
            </div>
          ) : (
            <div
              className="mt-4 text-danger fw-normal"
              style={{ fontSize: "14px" }}
            >
              * No skills added
            </div>
          )}
        </div>
      </div>
      <ProfileModal
        show={showTechSkill}
        handleClose={handleCloseTechSkill}
        formtype="add"
        handleSubmitChange={addTechSkillData}
      >
        <FormInput
          name="techSkill"
          type="text"
          placeholder="Add your skill"
          label="Skill"
          value={techSkills.techSkill}
          onChange={(e) => handleTechSkillChange(e)}
          className="mb-3"
          required="required"
          error={errors.techSkill}
        />
      </ProfileModal>

      <ProfileModal
        handleSubmitChange={addSoftSkillData}
        show={showSkill}
        handleClose={handleCloseSkill}
        formtype="add"
      >
        <FormInput
          name="softSkill"
          type="text"
          placeholder="Add your skill"
          label="Skill"
          value={softSkills.techSkill}
          onChange={(e) => handleSoftSkillChange(e)}
          className="mb-3"
          required="required"
          error={errors.softSkill}
        />
      </ProfileModal>

      {showEditSkill && (
        <ProfileModal
          handleSubmitChange={addEditSkillData}
          show={showEditSkill}
          handleClose={handleCloseEditProfile}
          formtype="edit"
        >
          <Row className="d-flex align-items-center">
            <Col>
              <FormInput
                name="softSkill"
                type="text"
                placeholder="Add your skill"
                label="Skill"
                value={selectedSkillProfile.softSkill}
                onChange={(e) => handleEditSoftSkillChange(e)}
                className="mb-3"
                required="required"
                error={errors.softSkill}
              />
            </Col>
            <Col lg={2}>
              {deletingData ? (
                <Button variant="outline-danger" className=" mt-3 ">
                  Del <Spinner size="sm" className="ms-1" />
                </Button>
              ) : (
                <Button
                  variant="outline-danger"
                  className=" mt-3 "
                  onClick={handleDeleteSoftSkill}
                >
                  Del
                  <RxCross1 className="ms-1 fw-bold" />
                </Button>
              )}
            </Col>
          </Row>
        </ProfileModal>
      )}

      {showEditTech && (
        <ProfileModal
          handleSubmitChange={addEditTechSkillData}
          show={showEditTech}
          handleClose={handleCloseEditProfileTech}
          formtype="edit"
        >
          <Row className="d-flex align-items-center">
            <Col>
              <FormInput
                name="techSkill"
                type="text"
                placeholder="Add your skill"
                label="Skill"
                value={selectedTechProfile.techSkill}
                onChange={(e) => handleEditTechSkillChange(e)}
                className="mb-3"
                required="required"
                error={errors.techSkill}
              />
            </Col>
            <Col lg={2}>
              {deletingData ? (
                <Button variant="outline-danger" className=" mt-3 ">
                  Del <Spinner size="sm" className="ms-1" />
                </Button>
              ) : (
                <Button
                  variant="outline-danger"
                  className=" mt-3 "
                  onClick={handleDeleteTechSkill}
                >
                  Del
                  <RxCross1 className="ms-1 fw-bold" />
                </Button>
              )}
            </Col>
          </Row>
        </ProfileModal>
      )}
    </>
  );
};

export default SkillProfile;
