import Home from "./components/home/Home";
import { ArchiveProvider } from "./provider/ArchivesContext";
import { AuthProvider } from "./provider/AuthContext";
import { JobProvider } from "./provider/JobContext";
import { ProfileProvider } from "./provider/ProfileContext";
import { ResumeListProvider } from "./provider/ResumeListContext";
import MainRoutes from "./routes/MainRoutes";
import { RecruiterCandidateListProvider } from "./provider/RecruiterCandidateListContext";
import { RecruiterProfileProvider } from "./provider/RecruiterProfileContext";
import { HirerJobListProvider } from "./provider/RecruiterJobListContext";
import { ResumeBuilderProvider } from "./provider/ResumeBuilderContext";
import { CompanyProvider } from "./provider/CompanyContext";
import { ToastProvider } from "./provider/ToastContext";

function App() {
  return (
    <ToastProvider>
      <AuthProvider>
        <ResumeListProvider>
          <ResumeBuilderProvider>
            <JobProvider>
              <CompanyProvider>
                <ArchiveProvider>
                  <ProfileProvider>
                    <RecruiterProfileProvider>
                      <HirerJobListProvider>
                        <RecruiterCandidateListProvider>
                          <MainRoutes>
                            <Home />
                          </MainRoutes>
                        </RecruiterCandidateListProvider>
                      </HirerJobListProvider>
                    </RecruiterProfileProvider>
                  </ProfileProvider>
                </ArchiveProvider>
              </CompanyProvider>
            </JobProvider>
          </ResumeBuilderProvider>
        </ResumeListProvider>
      </AuthProvider>
    </ToastProvider>
  );
}

export default App;
