import React from "react";
import { Accordion } from "react-bootstrap";
import { useResumeBuilderContext } from "../../../provider/ResumeBuilderContext";

const AccordionField = (props) => {
  const { resumeData } = useResumeBuilderContext();
  const field = props.name;
  const isFieldPresent = resumeData[field];
  const present =
    isFieldPresent &&
    isFieldPresent.length > 0 &&
    isFieldPresent.some((int) => int.interest !== null)
      ? true
      : false;
  return (
    <Accordion
      className="mt-3"
      defaultActiveKey={
        props.defaultKey !== undefined ? props.defaultKey : null
      }
    >
      <Accordion.Item eventKey={props.index}>
        <Accordion.Header>
          <div>
            <div style={{ fontSize: "18px" }} className="fw-bold">
              {props.heading}
              {present === true ? (
                <span
                  className="px-2"
                  style={{ fontSize: "16px", color: "green" }}
                >
                  {/* <FaCheckCircle /> */}
                </span>
              ) : (
                ""
              )}
            </div>
            <div
              style={{ fontSize: "14px" }}
              className="mt-2 fw-semibold text-secondary"
            >
              {props.subheading}
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Body>{props.children}</Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default AccordionField;
