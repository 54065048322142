export const jobtype = [
  { value: "", label: "Job Type" },
  { value: "FT", label: "Full Time" },
  { value: "PT", label: "Part Time" },
  { value: "CT", label: "Contract" },
  { value: "OT", label: "Others" },
];

export const jobtypefilter = [
  { value: "FT", label: "Full Time" },
  { value: "PT", label: "Part Time" },
  { value: "CT", label: "Contract" },
  { value: "OT", label: "Others" },
];

export const workmode = [
  { value: "", label: "Mode of Work" },
  { value: "ON", label: "Onsite" },
  { value: "RE", label: "Remote" },
  { value: "HY", label: "Hybrid" },
];
export const workmodeFilter = [
  { value: "ON", label: "Onsite" },
  { value: "RE", label: "Remote" },
  { value: "HY", label: "Hybrid" },
];

export const salary = [
  { value: "", label: "Expected Salary" },
  { value: "R1", label: "0-2 LPA" },
  { value: "R2", label: "2-5 LPA" },
  { value: "R3", label: "5-10 LPA" },
  { value: "R4", label: "10-20 LPA" },
  { value: "R5", label: "20-30 LPA" },
  { value: "R6", label: " 30+ LPA" },
];

export const experience = [
  { value: "", label: "Experience" },
  { value: "FR", label: "Fresher" },
  { value: "L1", label: "0-1 Year" },
  { value: "L2", label: "1-3 Year" },
  { value: "L3", label: "3-5 Year" },
  { value: "L4", label: "5-10 Year" },
  { value: "L5", label: "10+ Year" },
];

export const sorting = [
  { value: "", label: " Newest Post" },
  { value: true, label: " Oldest Post" },
];

export const companyrating = [
  { value: "-created_at", label: " high to Low" },
  { value: "created_at", label: " Low to High" },
];

export const score = [
  { value: "-score", label: "High to Low" },
  { value: "score", label: "Low to High" },
];

export const joblisting = [
  { value: "", label: "All" },
  { value: "IN", label: "Internal Jobs" },
  { value: "EX", label: "External Jobs" },
  // { value: "SC", label: "Score Checker" },
];

export const archivesorting = [
  { value: false, label: " Newest Post" },
  { value: true, label: " Oldest Post" },
];

export const sector = [
  { value: "", label: "Job Sector" },
  { value: "Java Developer", label: "Java Developer" },
  { value: "Testing", label: "Testing" },
  { value: "DevOps Engineer", label: "DevOps Engineer" },
  { value: "Python Developer", label: "Python Developer" },
  { value: "Web Designing", label: "Web Designing" },
  { value: "Hadoop", label: "Hadoop" },
  { value: "Blockchain", label: "Blockchain" },
  { value: "ETL Developer", label: "ETL Developer" },
  { value: "Data Science", label: "Data Science" },
  { value: "Sales", label: "Sales" },
  { value: "PMO", label: "PMO" },
  { value: "Business Analyst", label: "Business Analyst" },
  { value: "DotNet Developer", label: "DotNet Developer" },
  { value: "Automation Testing", label: "Automation Testing" },
  { value: "Network Security Engineer", label: "Network Security Engineer" },
  { value: "SAP Developer", label: "SAP Developer" },
  { value: "Web Developer", label: "Web Developer" },
  { value: "Database", label: "Database" },
];

export const advanceesorting = [
  { value: "-created_at", label: " Newest Post" },
  { value: "created_at", label: " Oldest Post" },
];



