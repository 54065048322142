import React, { useState } from "react";
import ProfileModal from "../ProfileModal";
import FormInput from "../../../../ui/common/FormInput";
import { Row, Col, Form } from "react-bootstrap";
import { useProfileContext } from "../../../../provider/ProfileContext";
import ImageUpload from "../../../../ui/common/ImageUpload";
import { validateEmail } from "../../../../utils/Validation";

const PersonalInfoProfile = () => {
  const {
    profileData,
    handlePersonalChange,
    EditPersonalInfo,
    errors,
    showPersonal,
    handleClosePersonal,
    handleShowPersonal,
  } = useProfileContext();

  return (
    <ProfileModal
      show={showPersonal}
      handleClose={handleClosePersonal}
      handleSubmitChange={EditPersonalInfo}
    >
      <Row>
        <Col lg={3}>
          <ImageUpload />
        </Col>
        <Col>
          <FormInput
            name="profjobTitle"
            type="text"
            placeholder="ex. Frontend Devloper"
            label="Job Title"
            value={
              profileData.profjobTitle !== null ? profileData.profjobTitle : " "
            }
            onChange={handlePersonalChange}
            className="mb-3"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Label className="ms-1 text-muted">Bio</Form.Label>
          <Form.Control
            className="mb-3"
            as="textarea"
            placeholder="Write about Professional Summary"
            name="profsummary"
            value={profileData.profsummary ? profileData.profsummary : ""}
            onChange={handlePersonalChange}
            style={{ height: "100px" }}
            label="Bio"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormInput
            name="proffirstName"
            type="text"
            placeholder="First Name"
            label="First Name"
            value={profileData.proffirstName}
            onChange={handlePersonalChange}
            className="mb-3"
          />
          <FormInput
            name="profemail"
            type="text"
            placeholder="Email"
            label="Email"
            value={profileData.profemail}
            onChange={handlePersonalChange}
            className="mb-3"
            error={errors.profemail}
          />
          <FormInput
            name="profdob"
            type="date"
            placeholder="Date Of Birth"
            label="Date Of Birth"
            value={profileData.profdob}
            onChange={handlePersonalChange}
            className="mb-3"
            error={errors.profdob}
          />
          <Form.Select
            size="lg"
            onChange={handlePersonalChange}
            name="profgender"
            value={profileData.profgender}
          >
            <option value="">Gender</option>
            <option value="F">Female</option>
            <option value="M">Male</option>
          </Form.Select>
        </Col>
        <Col>
          <FormInput
            name="proflastName"
            type="text"
            placeholder="Last Name"
            label="Last Name"
            value={profileData.proflastName}
            onChange={handlePersonalChange}
            className="mb-3"
          />
          <FormInput
            name="profphone"
            type="text"
            placeholder="phone"
            label="Phone"
            value={profileData.profphone}
            onChange={handlePersonalChange}
            className="mb-3"
            error={errors.profphone}
          />
          <FormInput
            name="profnationality"
            type="text"
            placeholder="ex. Indian"
            label="Nationality"
            value={profileData.profnationality}
            onChange={handlePersonalChange}
            className="mb-3"
          />
        </Col>
      </Row>
    </ProfileModal>
  );
};

export default PersonalInfoProfile;
