import React, { useState } from "react";
import jobclasses from "../jobsearch/Jobsearch.module.css";
import classes from "./Companies.module.css";
import { Row, Col, Button } from "react-bootstrap";
import classes1 from "../../home/Home.module.css";
import { FiSearch } from "react-icons/fi";
import { BsBuildings } from "react-icons/bs";
import { CiLocationOn } from "react-icons/ci";
import SearchSelect from "../../../ui/common/SearchSelect";
import { useCompanyContext } from "../../../provider/CompanyContext";

const CompanyFilter = () => {
  const { filtersComp, fetchCompanies, fetchCompfilters, setFiltersComp } =
    useCompanyContext();
  const [suggestions, setSuggestions] = useState({
    company_name: [],
    job_location: [],
    // companyrating: [],
  });
  const [loading, setLoading] = useState(false);
  const handleInputChange = async (e) => {
    const { value, name } = e.target;

    setFiltersComp((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));

    if (value.length >= 1) {
      setLoading(true);
      const response = await fetchCompfilters(value, name);
      setSuggestions((prevSuggestions) => ({
        ...prevSuggestions,
        [name]: response,
      }));
      setLoading(false);
    } else {
      setSuggestions((prevSuggestions) => ({
        ...prevSuggestions,
        [name]: [],
      }));
    }
  };

  const handleOptionSelect = (option, name) => {
    setFiltersComp((prevFilters) => ({
      ...prevFilters,
      [name]: option,
    }));
    setSuggestions((prevSuggestions) => ({
      ...prevSuggestions,
      [name]: [],
    }));
  };
  return (
    <div className={classes.filterbannerComp + " mt-2 mb-4"}>
      <div className={jobclasses.filterblock + " text-center"}>
        <h3 className="fw-bold mb-3">Browse Companies</h3>
        <div
          className={
            classes1.smtext +
            " text-secondary d-flex align-items-center justify-content-center "
          }
        >
          <div style={{ maxWidth: "500px", fontWeight: "500" }}>
            Discover exciting opportunities for career growth and find the
            perfect match for your skills.
          </div>
        </div>

        <div className={jobclasses.filterform}>
          <Row className="mt-2">
            <Col sm={12} lg={4} className="mb-2  d-flex align-items-center">
              <CiLocationOn className="text-secondary me-2" />
              <div className="flex-sm-grow-1">
                <SearchSelect
                  name="job_location"
                  placeholder="Location"
                  value={filtersComp.job_location}
                  handleChange={handleInputChange}
                  options={suggestions.job_location}
                  onOptionSelect={(option) =>
                    handleOptionSelect(option, "job_location")
                  }
                  loading={loading}
                />
              </div>
            </Col>
            <Col sm={12} lg={6} className="mb-2 d-flex align-items-center">
              <BsBuildings className="text-secondary fw-lighter me-2" />
              <div className="flex-sm-grow-1">
                <SearchSelect
                  name="company_name"
                  placeholder="Search Company.."
                  value={filtersComp.company_name}
                  handleChange={handleInputChange}
                  options={suggestions.company_name}
                  onOptionSelect={(option) =>
                    handleOptionSelect(option, "company_name")
                  }
                  loading={loading}
                />
              </div>
            </Col>
            <Col
              sm={12}
              lg={2}
              style={{ fontSize: "14px", cursor: "pointer" }}
              className="mb-2  d-flex align-items-center justify-content-center"
            >
              <Button
                className="d-flex align-items-center pe-4 "
                onClick={() => {
                  fetchCompanies(1);
                }}
              >
                <FiSearch />
                <span className="ps-2">Search</span>
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default CompanyFilter;
