import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Image, Container } from "react-bootstrap";
import classes from "../Screens.module.css";
import { MdRefresh, MdVerified } from "react-icons/md";
import TextEditor from "./TextEditor";
import FormInput from "../../../../../ui/common/FormInput";
import { useRecruiterProfileContext } from "../../../../../provider/RecruiterProfileContext";

const CompanyInformation = ({onNext}) => {
  const {
    handleFormDataChange,
    recruiterProfileData,
    setRecruiterProfileData,
    handleFileChange,
    setFile,
    setPreview,
    isLoading,
    linkError,
    errors,
  } = useRecruiterProfileContext();
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const requiredFields = [
      recruiterProfileData.company_name,
      recruiterProfileData.company_link,
    ];
    // Check if all required fields are filled
    const allRequiredFilled = requiredFields.every(
      (field) => field.trim() !== ""
    );
    setIsFormValid(allRequiredFilled);
  }, [recruiterProfileData.company_name, recruiterProfileData.company_link]);

  const handleRemoveFile = () => {
    setRecruiterProfileData((prevData) => ({
      ...prevData,
      company_logo: "", 
    }));
    setFile(null);
    setPreview(null);
  };
    

  console.log(recruiterProfileData)
  return (
    <Container>
      <Form>
        <h2 className={classes.companyName + " mb-4 "}>Company Information</h2>
        <Row>
          <Col>
            <div className="d-flex align-items-center mb-3">
              {recruiterProfileData.company_logo ? (
                <>
                  <Image
                    src={URL.createObjectURL(recruiterProfileData.company_logo)}
                    alt="Uploaded"
                    className="img-thumbnail mr-2"
                    style={{ maxWidth: "100px", height: "90px" }}
                  />
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={handleRemoveFile}
                    className="ms-3"
                  >
                    Remove
                  </Button>
                </>
              ) : (
                <>
                  <Image
                    src="https://kodilan.com/img/empty-company-logo.8437254b.png"
                    alt="Default"
                    className="img-thumbnail mr-2"
                    style={{ maxWidth: "100px", height: "90px" }}
                  />
                  <div className="d-flex flex-column ms-4">
                    <label
                      htmlFor="customFile"
                      className="btn btn-primary mb-1"
                    >
                      Upload File*
                    </label>
                    <small className="text-secondary fst-italic">
                      Please upload only image files
                    </small>
                  </div>
                </>
              )}
              <input
                type="file"
                className="form-control"
                id="customFile"
                name="company_logo"
                onChange={handleFormDataChange}
                accept="image/*"
                style={{ display: "none" }}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <FormInput
              name="company_name"
              type="text"
              placeholder=""
              label="Company Name"
              value={recruiterProfileData.company_name}
              onChange={handleFormDataChange}
              className="mb-3"
              required="required"
            />
          </Col>
          <Col lg={6} className=" position-relative">
            <FormInput
              name="company_link"
              type="text"
              placeholder="https://"
              label="Company Website Link"
              value={recruiterProfileData.company_link}
              onChange={handleFormDataChange}
              className="mb-3"
              // required="required"
              error={errors.company_link}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col lg={4}>
            <FormInput
              name="company_address"
              type="text"
              placeholder=""
              label="Company Address"
              value={recruiterProfileData.company_address}
              onChange={handleFormDataChange}
              className="mb-3"
            />
          </Col>
          <Col lg={4}>
            <FormInput
              name="company_location"
              type="text"
              placeholder=""
              label="Company Location"
              value={recruiterProfileData.company_location}
              onChange={handleFormDataChange}
              className="mb-3"
            />
          </Col>
          <Col lg={4}>
            <FormInput
              name="social_link"
              type="text"
              placeholder=""
              label="Social Accounts"
              error={errors.social_link}
              value={recruiterProfileData.social_link}
              onChange={handleFormDataChange}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={12} className="">
            <TextEditor
              name="company_desc"
              placeholder="Write Description about company"
              value={recruiterProfileData.company_desc}
              onChange={handleFormDataChange}
            />
          </Col>
        </Row>
        <Row>
          <div>
           <Button
              onClick={!linkError && onNext}
              className="d-flex align-items-center gap-2 "
              disabled={
                isLoading
              }
            >
              <span>
                {isLoading ? (
                  <MdRefresh className={classes.spinAnimation + " fs-5 "} />
                ) : (
                  <MdVerified className="fs-5 " />
                )}
              </span>
              {isLoading ? "Saving..." : "Save & Next"}
            </Button>
          </div>
        </Row>
      </Form>
    </Container>
  );
};

export default CompanyInformation;
