import React, { useState } from "react";
import { IoIosAddCircle } from "react-icons/io";
import classes from "./ResumeFormData.module.css";
import classes1 from "../../resumeUI/Input.module.css";
import CustomButton from "../../resumeUI/CustomButton";
import CustomButtonOutline from "../../resumeUI/CustomButtonOutline";
import { useResumeBuilderContext } from "../../../../provider/ResumeBuilderContext";
import ExperienceView from "./ExperienceView";

const Experience = () => {
  const { handleNext, handlePrevious, resumeData, handleResumeFormChange } =
    useResumeBuilderContext();
  const [experience, setExperience] = useState(resumeData.employement || []);
  let [defaultKey, setDefaultKey] = useState(resumeData.employement.length);

  const addDataExperience = () => {
    const updatedExperience = [
      ...experience,
      {
        company: null,
        designation: null,
        experience: null,
        responsibilities: null,
        startDateWe: null,
        endDateWe: null,
        present: false,
      },
    ];
    setDefaultKey(updatedExperience.length - 1);
    setExperience(updatedExperience);
  };

  const handleExpChange = (e, index) => {
    const { name, value } = e.target;
    const updatedExperience = [...experience];
    // console.log(updatedExperience);
    if (name === "present") {
      updatedExperience[index] = {
        ...updatedExperience[index],
        [name]: e.target.checked,
        endDateWe:
          e.target.value === "true" ? null : updatedExperience[index].endDateWe,
      };
    } else {
      updatedExperience[index] = {
        ...updatedExperience[index],
        [name]: value,
      };
    }

    setExperience(updatedExperience);
    handleResumeFormChange({
      target: { name: "employement", value: updatedExperience },
    });
  };

  const removeData = (index) => {
    const updatedExperience = [...experience];
    updatedExperience.splice(index, 1);
    setExperience(updatedExperience);
    handleResumeFormChange({
      target: { name: "employement", value: updatedExperience },
    });
    setDefaultKey(updatedExperience.length - 1);
  };
  return (
    <div>
      <div className={classes.resumecontentform}>
        <div className={classes.resumeformheading}>Experience</div>
        <div className={classes.resumeformsubheading}>
          Review your experience History
        </div>
        <div>
          {experience.map((edu, index) => (
            <ExperienceView
              defaultKey={defaultKey}
              index={index}
              data={edu}
              onChange={(e, index) => handleExpChange(e, index)}
              onDelete={(index) => removeData(index)}
            />
          ))}
          <div
            className={classes.addicon }
            onClick={addDataExperience}
          >
            
            <IoIosAddCircle style={{ fontSize: "20px", cursor: "pointer" }} />
            <div className={classes1.addformtext}>Add</div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between">
        <CustomButtonOutline text="Back" onClickFn={handlePrevious} />
        <CustomButton text="Next" onClickFn={handleNext} />
      </div>
      </div>
     
    </div>
  );
};

export default Experience;
